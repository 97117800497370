import { useTranslation } from 'react-i18next';
import classNames from './PopUpTimeIsUp.module.scss';
import { PopUpTimeIsUpProps } from '@/types/game';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import QuizTip from '../QuizTip/QuizTip';

import clock from '@/assets/images/clock.png';
import close from '@/assets/svg/close.svg';
import TheButton from '@/components/TheButton/TheButton';

const PopUpTimeIsUp = ({
  tip,
  className,
  emitOnClosePopUp,
  emitOnNextQuestion,
  allowRetry,
  isLastQuestion,
}: PopUpTimeIsUpProps) => {
  const { t } = useTranslation();
  const [shouldExpand, setShouldExpand] = useState(false);
  const { isMobile, isDesktop } = UseScreenSize();

  const onExpand = () => {
    setShouldExpand(true);
  };

  const onReduce = () => {
    setShouldExpand(false);
  };
  const subTitle = () => {
    if (allowRetry) {
      return (
        <div className={classNames.description}>
          <span>{t('notMadeIt')}</span>
          <span className={classNames.highlight}>{` ${t(
            'tryAgainOneMore'
          )} `}</span>
          <span>{t('toNextQuestion')}</span>
        </div>
      );
    } else {
      return (
        <div className={classNames.description}>
          <span>{t('ran_out_of_time')}</span>
          <span className={classNames.highlight}>{` ${t(
            'incorrect_answer'
          )} `}</span>
        </div>
      );
    }
  };

  const buttons = (
    <div
      className={classes(
        classNames.buttons,
        allowRetry ? '' : 'justify-center'
      )}
    >
      {allowRetry && (
        <TheButton
          className={isDesktop ? 'basis-2/3' : 'w-full'}
          text={t('tryAgainBtn')}
          emitOnClick={() => emitOnClosePopUp?.()}
          showArrow={true}
        />
      )}
      <TheButton
        className={isDesktop ? 'basis-1/3' : 'w-full'}
        text={isLastQuestion ? t('ok') : t('nextQuestion')}
        plain
        emitOnClick={() => emitOnNextQuestion?.()}
      />
    </div>
  );

  return (
    <div className={classes('container', className, classNames.popUpTimeIsUp)}>
      {shouldExpand && isMobile && <div className={classNames.backdrop}></div>}
      <div className={classNames.top}>
        <div className={classNames.titleAndDescription}>
          <h3 style={{ textAlign: isDesktop ? 'left' : 'center' }}>
            {t('timeIsUp')}
          </h3>
          {isDesktop && subTitle()}
        </div>
        <div className={classNames.clock}>
          <img src={clock} alt="clock" />
        </div>
      </div>
      {
        isMobile && subTitle()
        // <div className={classNames.description}>
        //   <span>{t('notMadeIt')}</span>
        //   <span className={classNames.highlight}>{` ${t(
        //     'tryAgainOneMore'
        //   )} `}</span>
        //   <span>{t('toNextQuestion')}</span>
        // </div>
      }
      <div
        className={classes(classNames.bottom, {
          [classNames.expanded]: shouldExpand,
        })}
      >
        {shouldExpand && (
          <div className={classNames.close} onClick={onReduce}>
            <SVG src={close} />
          </div>
        )}
        {tip && (
          <QuizTip
            tip={tip ?? ''}
            shouldExpand={shouldExpand}
            emitOnExpand={onExpand}
          />
        )}
        {isMobile && buttons}
      </div>
      {isDesktop && buttons}
    </div>
  );
};

export default PopUpTimeIsUp;
