import classes from 'classnames';
import { SkillTypes } from '@/types/skill';
import classNames from './SkillsGrid.module.scss';
import Skill from './Skill/Skill';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SkillSettingsItem } from '@/types/user';
import { useNavigate } from 'react-router-dom';
import commonUtils, { skillsColors } from '@/utils/common';
import { useAppDispatch, useAppSelector } from '@/store';
import UseScreenSize from '@/hooks/UseScreenSize';
import { authSelector, skillsSelector } from '@/store/reducers/auth';
import { gameActions } from '@/store/reducers/game';
import { SkillsProgress } from '@/types/progress';
import { t } from 'i18next';

interface SkillsGridProps {
  type?: SkillTypes;
  shouldHighlight?: boolean;
  className?: string;
  style?: {};
  shouldExpand?: boolean;
  showProgress?: boolean;
  progress: SkillsProgress | null;
  loading: boolean;
  isLarge?: boolean;
  handleScrollToMap?: () => void;
}

const SkillsGrid = ({
  type,
  shouldHighlight = true,
  className,
  style,
  shouldExpand = true,
  showProgress = true,
  progress,
  loading,
  isLarge = false,
  handleScrollToMap,
}: SkillsGridProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile, isDesktop, windowHeight, mediumHeight } = UseScreenSize();
  const skillSettings = useAppSelector(skillsSelector);
  const { displayDictionary } = useAppSelector(authSelector);

  const sortedSkills = useMemo(
    () => commonUtils.sortByKey([...skillSettings], 'order'),
    [skillSettings]
  );

  const findSkillByType = (type: SkillTypes) => {
    return progress?.[type];
  };

  const goToSkill = ({
    type,
    skill,
  }: {
    type: SkillTypes;
    skill: SkillSettingsItem;
  }) => {
    if (loading) {
      return;
    }

    if (isDesktop) {
      setTimeout(() => {
        dispatch(gameActions.setSelectedSkill({ type, skill }));
      }, 400);

      return handleScrollToMap?.();
    }

    dispatch(gameActions.setSelectedSkill({ type, skill }));
    return navigate(`/dashboard/skill/${type}`);
  };

  const skillsGridRef = useRef<HTMLDivElement>(null);
  const [offsetFromTop, setOffsetFromTop] = useState(0);
  const [bottomSpacing, setBottomSpacing] = useState(0);

  useEffect(() => {
    if (windowHeight < mediumHeight) {
      const isSafari = commonUtils.isSafari();
      const isChrome = commonUtils.isChrome();

      if (isChrome) {
        setBottomSpacing(120);
      } else if (isSafari) {
        setBottomSpacing(90);
      }
    } else {
      setBottomSpacing(12);
    }

    const dictionaryBtnHeight =
      document.querySelector('#dictionary-btn')?.clientHeight ?? 0;
    const appBannerHeight =
      document.querySelector('#app-banner')?.clientHeight ?? 0;

    const elementHeight = displayDictionary
      ? dictionaryBtnHeight
      : appBannerHeight;

    const spacing = 12;

    setOffsetFromTop(
      (skillsGridRef.current?.getBoundingClientRect().top ?? 0) +
        elementHeight +
        spacing
    );
  }, [windowHeight, bottomSpacing]);

  const skillsElements = sortedSkills.map((skill: SkillSettingsItem) => {
    const skillType = commonUtils.skillByName(skill.name ?? '');
    const skillProgress = findSkillByType(skillType);
    const skillName = skill.name?.toLowerCase();
    return (
      <Skill
        key={skill.name}
        type={skillType}
        skill={skill}
        shouldHighlight={shouldHighlight}
        loading={loading}
        hasProgress={showProgress && (skillProgress?.actual ?? 0) > 0}
        backgroundColor={skill.background ?? ''}
        textColor={
          skillsColors[skillName as SkillTypes] as SkillTypes | undefined
        }
        actual={skillProgress?.actual}
        total={skillProgress?.total}
        progressAmount={commonUtils.calcPercentage(
          skillProgress?.actual ?? 0,
          skillProgress?.total ?? 0
        )}
        progressColor={skill.progressBackground ?? ''}
        lastTimePlayed={skillProgress?.lastTimePlayed}
        goToSkill={goToSkill}
      />
    );
  });

  const mobilePaddingBottom = () => {
    if (shouldExpand) {
      const isIphone = commonUtils.isMobileDevice()?.toString() === 'iPhone';

      return isIphone ? '85px' : '75px';
    }

    return '';
  };

  return (
    <div
      ref={skillsGridRef}
      className={classes(classNames.skillsGrid, className, {
        [classNames.skillsGridLarge]: isLarge,
      })}
      style={{
        ...style,
        height: shouldExpand ? `calc(100dvh - ${offsetFromTop}px)` : '',
      }}
    >
      {skillsElements}
    </div>
  );
};

export default SkillsGrid;
