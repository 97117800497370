import React from 'react';
import classNames from './Navbar.module.scss';
import classes from 'classnames';
import UseScrollPosition from '@/hooks/UseScrollPosition';
import UseScreenSize from '@/hooks/UseScreenSize';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';
import { useDrawer } from '@/context/DrawerContext';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import { useTranslation } from 'react-i18next';

import GoBackBtn from '../GoBackBtn/GoBackBtn';

import menu24 from '@/assets/svg/menu24x24.svg';
import menuIcon from '@/assets/svg/menu.svg';
import settingsIcon from '@/assets/svg/settings.svg';
import logo from '@/assets/svg/logo_new.svg';
import AppMorfixLogo from '../AppMorfixLogo/AppMorfixLogo';

interface NavBarProps {
  className?: string;
  decreaseOpacity?: boolean;
  showArrowIcon?: boolean;
}

const Navbar = ({
  className,
  showArrowIcon,
  decreaseOpacity = true,
}: NavBarProps) => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { isMobile } = UseScreenSize();
  const currentRoute = UseCurrentRoute();
  const navigate = useNavigate();
  const { nativeLanguage } = useAppSelector(authSelector);
  const showMorfixLogo = nativeLanguage === 'he';
  const scrollPosition = UseScrollPosition();

  const showLogo = !(
    currentRoute === ROUTE_NAMES.SKILL || currentRoute === ROUTE_NAMES.QUIZ
  );

  const offsetDelta = 60;

  const calcHeaderOpacity = () => {
    return ((scrollPosition / offsetDelta) * 100) / 100;
  };

  const navbarStyles = () => {
    if (!decreaseOpacity) {
      return {};
    }

    if (scrollPosition >= offsetDelta) {
      return {
        backgroundColor: `rgba(255, 255, 255, 1)`,
        boxShadow: '0px 3px 6px -4px rgba(0,0,0,0.75)',
      };
    }

    return {
      backgroundColor: `rgba(255, 255, 255, ${calcHeaderOpacity()})`,
    };
  };

  const toggleMenu = () => {
    if (isMobile) {
      drawer.openMobileDrawer();
      return;
    }

    drawer.openDrawer();
  };

  const onGoBack = () => {
    return navigate('/dashboard/games');
  };

  const logoElement = showMorfixLogo ? (
    <AppMorfixLogo
      width={isMobile ? 121 : 206}
      height={isMobile ? 19.38 : 33}
    />
  ) : (
    <SVG className={classNames.logo} src={logo} />
  );

  return (
    <div
      className={classes(classNames.navBar, className)}
      style={navbarStyles()}
    >
      {!showArrowIcon && showLogo ? (
        logoElement
      ) : (
        <GoBackBtn
          showText={false}
          showIcon={showArrowIcon}
          emitClick={onGoBack}
        />
      )}
      <div className={classes(classNames.menu)}>
        <button
          onClick={toggleMenu}
          className={classNames.menuIcon}
          aria-label={t('menu_button').toString()}
        >
          <SVG src={isMobile ? menu24 : settingsIcon} />
        </button>
      </div>
    </div>
  );
};

export default React.memo(Navbar);
