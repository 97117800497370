import { useAppSelector } from '@/store';
import classNames from './OnboardingInvitation.module.scss';
import classes from 'classnames';
import { authSelector } from '@/store/reducers/auth';
import SVG from 'react-inlinesvg';
import UserUtils from '@/utils/user';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useNavigate } from 'react-router-dom';

import TheButton from '../TheButton/TheButton';
import AppBackdrop from '../AppBackdrop';
import AppModal from '../AppModal';

import copyIcon from '@/assets/svg/copy.svg';

interface OnboardingInvitationProps {
  className?: string;
  link: string;
  isBtn?: boolean;
  showCopyBtn?: boolean;
  shouldNavigate?: boolean;
  copyText?: string | null;
}

const OnboardingInvitation = ({
  link,
  isBtn = false,
  showCopyBtn = true,
  className,
  shouldNavigate = false,
  copyText = null,
}: OnboardingInvitationProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const { isMobile } = UseScreenSize();
  const navigate = useNavigate();

  const isAdmin = UserUtils.isAdmin(user);

  const [showCopyModal, setShowCopyModal] = useState(false);

  const onCopy = () => {
    console.log(link);

    navigator.clipboard.writeText(link);
    setShowCopyModal(true);
  };

  const copyBtn = (
    <TheButton
      className={classes(classNames.copyBtn, isBtn && className)}
      shouldRecolor={false}
      text={copyText ?? t('copyLink')}
      emitOnClick={onCopy}
    />
  );

  return (
    <div className={classes(classNames.onboardingInvitation, className)}>
      <Transition
        in={showCopyModal}
        timeout={400}
        mountOnEnter
        unmountOnExit
        children={(state) => {
          return (
            <>
              <AppBackdrop
                className={classes(classNames.backdrop, `backdrop-${state}`)}
              />
              <AppModal>
                <div
                  className={classes(
                    classNames.modal,
                    isMobile ? `slide-vertical-${state}` : `fade-${state}`
                  )}
                >
                  <h2>{t('linkCopied')}</h2>
                  <span>{`${t('youMayNowShare')} ${
                    isAdmin ? t('teachers') : t('students')
                  }.`}</span>
                  <TheButton
                    className={classNames.btn}
                    text={t('done')}
                    shouldRecolor={false}
                    emitOnClick={() =>
                      shouldNavigate
                        ? navigate('/dashboard/my-students')
                        : setShowCopyModal(false)
                    }
                  />
                </div>
              </AppModal>
            </>
          );
        }}
      />
      {isBtn ? (
        copyBtn
      ) : (
        <>
          <div className={classNames.invitation} onClick={onCopy}>
            <SVG src={copyIcon} />
            <span className={classNames.linkText}>{link}</span>
          </div>
          {showCopyBtn && copyBtn}
        </>
      )}
    </div>
  );
};

export default OnboardingInvitation;
