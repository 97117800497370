import React, { useEffect, useMemo, useState } from 'react';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue, StylesConfig } from 'react-select';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { SkillTypes } from '@/types/skill';
import TutorEventCard from './TutorEventCard/TutorEventCard/TutorEventCard';
import { useAppDispatch } from '@/store';
import classNames from './TutorEvents.module.scss';
import { FilterTypes } from '@/types/tutor';
import { Card } from '@/types/tutor';
import UseScreenSize from '@/hooks/UseScreenSize';
import { tutorActions } from '@/store/reducers/tutor';

const TutorEvents = ({ title, data }: { title: string; data: Card[] }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile, isDesktop } = UseScreenSize();

  const [filter, setFilter] = useState({
    order: FilterTypes.AscendingAZ,
    skill: 'All Topics',
  });

  // State variables to hold sorted and filtered data
  const [filteredData, setFilteredData] = useState<Card[]>([]);

  const customStyles: StylesConfig<any, false> = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: state.menuIsOpen ? '30px 30px 0 0' : '30px',
      width: isMobile ? '160px' : '240px',
      height: isMobile ? '16px' : '48px',
      border: '1px solid rgba(20, 92, 153, 0.1)',
      padding: '0 10px',
      fontSize: isMobile ? '12px' : '16px',
      boxShadow: 'none',
    }),
    indicatorSeparator: () => ({}),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: '0 0 30px 30px',
      width: isMobile ? '160px' : '240px',
      border: '1px solid rgba(20, 92, 153, 0.1)',
      borderTop: 'none',
      padding: '0 10px',
      marginTop: '1.9px',
      fontSize: isMobile ? '12px' : '16px',
    }),
    menuList: (provided) => ({
      ...provided,
      color: 'rgba(8, 38, 63, 0.3)',
      marginBottom: '10px',
      fontSize: isMobile ? '12px' : '16px',
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    }),
  };

  const options = useMemo(() => {
    let optionList = [
      { value: FilterTypes.AscendingAZ, label: 'Ascending (A-Z)' },
      { value: FilterTypes.DescendingZA, label: 'Descending (Z-A)' },
      { value: FilterTypes.Relevance, label: 'Relevance', isDisabled: true },
    ];

    if (data.some((item) => item?.timestamp_last_time_played)) {
      optionList = [
        ...optionList,
        { value: FilterTypes.NewestFirst, label: 'Newest first' },
        { value: FilterTypes.OldestFirst, label: 'Oldest first' },
        { value: FilterTypes.ByStarRating, label: 'By star rating' },
      ];
    }

    return optionList;
  }, []);

  const skillOptions = [
    { value: 'All Topics', label: 'All Topics' },
    ...(Object.keys(SkillTypes) as Array<keyof typeof SkillTypes>).map(
      (key) => ({
        value: key,
        label:
          SkillTypes[key].slice(0, 1).toUpperCase() + SkillTypes[key].slice(1),
      })
    ),
  ];

  const handleOptionChange = (
    option: SingleValue<{ value: FilterTypes; label: string }> | null
  ) => {
    if (option) {
      setFilter((prev) => ({ ...prev, order: option.value }));
    }
  };

  const handleSkillOptionChange = (
    option: SingleValue<{ value: string; label: string }> | null
  ) => {
    if (option) {
      setFilter((prev) => ({ ...prev, skill: option.value }));
    }
  };

  useEffect(() => {
    // Clone the array to avoid mutating the original data
    let sortedAndFilteredData = [...(data || [])];

    // Apply filtering based on skill
    if (filter.skill !== 'All Topics') {
      sortedAndFilteredData = sortedAndFilteredData.filter(
        (item) => item.skill === filter.skill
      );
    }

    // Function to parse date string "dd-mm-yyyy hh:mm" to a Date object
    const parseDate = (dateStr: string) => {
      const parts = dateStr.match(/(\d+)/g);
      if (parts && parts.length === 5) {
        const [day, month, year, hour, minute] = parts.map(Number);
        return new Date(year, month - 1, day, hour, minute);
      }
      return new Date(0); // Return an epoch date for invalid formats
    };

    // Apply sorting based on orderFilter
    switch (filter.order) {
      case FilterTypes.AscendingAZ:
        sortedAndFilteredData.sort((a, b) =>
          a.quiz_name.localeCompare(b.quiz_name)
        );
        break;
      case FilterTypes.DescendingZA:
        sortedAndFilteredData.sort((a, b) =>
          b.quiz_name.localeCompare(a.quiz_name)
        );
        break;
      case FilterTypes.NewestFirst:
        sortedAndFilteredData.sort((a, b) => {
          // Ensure both date strings are valid and parse them as dates
          const dateA = a.timestamp_last_time_played
            ? parseDate(a.timestamp_last_time_played).getTime()
            : 0;
          const dateB = b.timestamp_last_time_played
            ? parseDate(b.timestamp_last_time_played).getTime()
            : 0;
          return dateB - dateA;
        });
        break;
      case FilterTypes.OldestFirst:
        sortedAndFilteredData.sort((a, b) => {
          // Ensure both date strings are valid and parse them as dates
          const dateA = a.timestamp_last_time_played
            ? parseDate(a.timestamp_last_time_played).getTime()
            : 0;
          const dateB = b.timestamp_last_time_played
            ? parseDate(b.timestamp_last_time_played).getTime()
            : 0;
          return dateA - dateB;
        });
        break;
      case FilterTypes.ByStarRating:
        sortedAndFilteredData.sort((a, b) => {
          const getNumericRating = (rating: string) => {
            const match = rating.match(/\d+/); // Extract the first number from the string
            return match ? parseInt(match[0]) : 0;
          };
          const ratingA = getNumericRating(a.star_rating);
          const ratingB = getNumericRating(b.star_rating);
          return ratingB - ratingA;
        });
        break;
      default:
        // Default behavior (no filter)
        // You can optionally reset the sorting here or leave it as is
        break;
    }

    // Update the state variable with the sorted and filtered data
    setFilteredData(sortedAndFilteredData);
  }, [filter.skill, filter.order]);

  return (
    <div
      className={classes(classNames.tutorEvents, {
        [classNames.isMobile]: isMobile,
      })}
    >
      <div
        className={classes(classNames.header, {
          [classNames.isMobile]: isMobile,
        })}
      >
        <div
          className={classes(classNames.title, {
            [classNames.isMobile]: isMobile,
          })}
        >
          {title}
        </div>

        <div className={classNames.sortFilterSection}>
          <div className={classNames.sortOption}>
            <Select
              defaultValue={options[0]}
              styles={customStyles as any} // Use "as any" to avoid TypeScript errors
              options={options}
              onChange={handleOptionChange}
            />
          </div>
          <div className={classNames.filterOption}>
            <Select
              defaultValue={skillOptions[0]}
              styles={customStyles as any} // Use "as any" to avoid TypeScript errors
              options={skillOptions}
              onChange={handleSkillOptionChange}
            />
          </div>
        </div>
      </div>
      <div
        className={classes(classNames.body, {
          [classNames.isMobile]: isMobile,
        })}
      >
        {filteredData.length ? (
          <Swiper
            className={classNames.mySwiper}
            style={{ width: '100%' }}
            slidesPerView={isMobile ? 2 : 4}
            grid={{
              rows: 1,
            }}
            spaceBetween={20}
            navigation
            modules={[Navigation, Grid]}
          >
            {filteredData.map((item, index) => (
              <SwiperSlide key={index}>
                <TutorEventCard data={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p className={classNames.noDataMessage}>{t('tutorEmptyDashboard')}</p>
        )}
      </div>
    </div>
  );
};

export default React.memo(TutorEvents);
