import { createAsyncThunk } from '@reduxjs/toolkit';
import { skillService } from '@/services';
import { RootState } from '@/types/common';
import commonUtils from '@/utils/common';
import { LevelTestItem, Quiz } from '@/types/quiz';
import { SkillTypes } from '@/types/skill';
import GameUtils from '@/utils/gameUtils';
import { LevelTestResultResponse } from '@/types/response';
import { gameActions } from '../reducers/game';

const levelTestThunks = {
  fetchTestQuestions: createAsyncThunk(
    'skill/testQuestions',
    async (_, { getState }) => {
      try {
        const state = getState() as RootState;
        const questions = await skillService.fetchTestQuestions(
          state.auth.user
        );
        return questions;
      } catch (error) {
        throw error;
      }
    }
  ),
  postUserLevelTestResult: createAsyncThunk(
    'skill/levelTestResult',
    async (_, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const levelTestBySkillUserResult = {
          correctAnswers: state.game.total_correctAnswers,
          finishTime: GameUtils.formatDate(new Date(), '/', 'milliseconds'), //need to wait for moran to change the endpoint to work with this
          questionsResultBySkill: state.game.levelTestQuizResultData,
        };

        const response = await skillService.postUserLevelTestResult(
          state.auth.user,
          levelTestBySkillUserResult
        );

        const sortedArray: LevelTestItem[] = commonUtils.sortByKey(
          response?.questions,
          'order'
        );

        const RefactorLevelTestQuestions = sortedArray.map(
          (item: LevelTestItem) => {
            if (item.quiz.skill !== SkillTypes.Read) {
              return item;
            } else {
              const { questions } = item;

              const refactorReadQuestion = questions?.map((question) => {
                const options = question.distractors
                  .split(',')
                  .map((option) => option.trim());

                options.push(question.answer);

                return {
                  ...question,
                  options: commonUtils.shuffleArray(options ?? []),
                  pickedAnswer: '',
                };
              });

              return { ...item, questions: refactorReadQuestion };
            }
          }
        );
        const last = { ...response, questions: RefactorLevelTestQuestions };

        dispatch(gameActions.resetCurrentReadQuestionIndex());

        return last;
      } catch (err) {
        console.log(err);
      }
    }
  ),
  fetchNewLevelTestQuestions: createAsyncThunk(
    'skill/levelTestQuestions',
    async (_, { getState }) => {
      try {
        const state = getState() as RootState;
        const questions = await skillService.fetchNewLevelTestQuestions(
          state.auth.user
        );
        const sortedArray: LevelTestItem[] = commonUtils.sortByKey(
          questions?.data,
          'order'
        );

        const RefactorLevelTestQuestions = sortedArray.map(
          (item: LevelTestItem) => {
            if (item.quiz.skill !== SkillTypes.Read) {
              return item;
            } else {
              const { questions } = item;
              const refactorReadQuestion = questions?.map((question) => {
                const options = question.distractors
                  .split(',')
                  .map((option) => option.trim());
                options.push(question.answer);
                return { ...question, options, pickedAnswer: '' };
              });

              return { ...item, questions: refactorReadQuestion };
            }
          }
        );

        return RefactorLevelTestQuestions;
      } catch (error) {
        throw error;
      }
    }
  ),
};

export default levelTestThunks;
