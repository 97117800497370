import { FormikHelpers } from 'formik';
import { FormEvent } from 'react';

export type LoginValues = {
  email?: string;
  password?: string;
  confirmPassword?: string;
  code?: string;
};

export type LoginStepProps = {
  emitSubmit?: (
    values: LoginValues,
    formikHelpers: FormikHelpers<LoginValues>
  ) => void;
  loading?: boolean;
  styles: Record<string, string>;
};

export type InitialLoginProps = LoginStepProps & {
  goToResetPassword?: () => void;
};

export type ConfirmVerificationCodeProps = LoginStepProps & {};

export type ForgotPasswordProps = LoginStepProps & {};

export type ConfirmForgotPasswordProps = LoginStepProps & {
  resendForgotPassword?: () => void;
};

export type CognitoPasswordChangeProps = LoginStepProps & {};

export type LoginSteps = {
  type: LoginStep;
  title?: string;
  subTitle?: string;
  component: React.FC<InitialLoginProps | ConfirmVerificationCodeProps>;
};

export enum LoginStep {
  LOGIN,
  CONFIRM_VERIFICATION_CODE,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  COGNITO_PASSWORD_CHANGE,
}
