import {
  OrganizationDetailsProps,
  PersonalDetailsProps,
  RegistrationStepType,
  RegistrationWelcomeProps,
  VerificationCodeProps,
  RegistrationStep,
  CognitoVerificationCodeProps,
} from '@/types/registration';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import UseCodeVerification from './UseCodeVerification';
import UsePersonalDetails from './UsePersonalDetails';
import UseGeneralDetails from '../../hooks/UseOrganizationEditor';
import { cognitoService } from '@/services/cognito';
import { useDispatch } from 'react-redux';
import { authActions, authSelector } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';
import {
  AdminOrganizationDetails,
  GroupDetails,
  CreateUserDetails,
} from '@/types/register';
import { registerService } from '@/services/register';
import { useTranslation } from 'react-i18next';

import RegistrationWelcome from '@/components/Registration/Welcome/RegistrationWelcome';
import VerificationCode from '@/components/Registration/Code/VerificationCode';
import PersonalDetails from '@/components/Registration/PersonalDetails/PersonalDetails';
import GeneralDetails from '@/components/OrganizationDetails/OrganizationDetails';
import UseScreenSize from '@/hooks/UseScreenSize';
import CognitoVerify from '@/components/Registration/CognitoVerify/CognitoVerify';
import UseCognitoVerification from './UseCognitoVerification';
import { useAppSelector } from '@/store';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { UserState } from '@/types/user';

const initialRegistrationSteps: RegistrationStep[] = [
  {
    type: RegistrationStepType.REGISTRATION_WELCOME,
    order: 1,
    component: RegistrationWelcome,
  },
  {
    type: RegistrationStepType.VERIFY_CODE,
    roles: ['admin'],
    order: 2,
    component: VerificationCode,
  },
  {
    type: RegistrationStepType.PERSONAL_DETAILS,
    order: 3,
    component: PersonalDetails,
  },
  {
    type: RegistrationStepType.COGNITO_VERIFY_CODE,
    order: 4,
    component: CognitoVerify,
  },
  {
    type: RegistrationStepType.ORGANIZATION_DETAILS,
    roles: ['admin'],
    order: 5,
    component: GeneralDetails,
  },
];

const UseRegistration = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = UseScreenSize();
  const { nativeLanguage } = useAppSelector(authSelector);

  const {
    verificationCodeField,
    onUpdateVerificationCodeField,
    onCodeVerificationSubmit,
  } = UseCodeVerification();

  const {
    cognitoVerificationCodeField,
    onCognitoCodeVerificationSubmit,
    onCodeError,
  } = UseCognitoVerification();

  const {
    personalDetailsFields,
    onEmailIsAlreadyTaken,
    onUpdatePersonalDetailsFields,
    onPersonalDetailsSubmit,
  } = UsePersonalDetails();

  const {
    organizationDetailsFields,
    onUpdateOrganizationDetailsFields,
    onOrganizationDetailsSubmit,
  } = UseGeneralDetails();

  const [role, setRole] = useState('');
  const [currentStep, setCurrentStep] = useState<RegistrationStepType>(
    RegistrationStepType.REGISTRATION_WELCOME
  );
  const [registrationSteps, setRegistrationSteps] = useState<
    RegistrationStep[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [registerUser, setRegisterUser] = useState<UserState | null>(null);
  const [loginData, setLoginData] = useState<{
    token?: string | undefined;
    userId?: string | undefined;
    newPasswordRequired?: boolean | undefined;
    cognitoUser?: CognitoUser | undefined;
    requiredAttributes?: CognitoUserAttribute[] | undefined;
    nativeLanguage?: CognitoUserAttribute | undefined;
  } | null>(null);

  const [organizationDetails, setOrganizationDetails] = useState<{
    organizationName: string;
    fromToLanguage: string;
    nativeLanguage: string;
  } | null>(null);

  const [groupDetails, setGroupDetails] = useState<GroupDetails | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const isAdmin = role === 'admin';
  const isTeacher = role === 'teacher';
  const isStudent = role === 'student';

  const onNextStep = () => {
    const currentStepIndex = registrationSteps.findIndex(
      (step) => step.type === currentStep
    );

    if (currentStepIndex >= 0) {
      setCurrentStep(registrationSteps[currentStepIndex + 1].type);
    }
  };

  const onCognitoRegister = async () => {
    const firstName =
      personalDetailsFields
        .find((field) => field.id === 'firstname')
        ?.value?.trim() ?? '';

    const lastName =
      personalDetailsFields
        .find((field) => field.id === 'lastname')
        ?.value?.trim() ?? '';

    const username =
      personalDetailsFields
        .find((field) => field.id === 'email')
        ?.value?.trim() ?? '';

    const password =
      personalDetailsFields
        .find((field) => field.id === 'password')
        ?.value?.trim() ?? '';

    return await cognitoService.createAccount(
      firstName,
      username,
      password,
      nativeLanguage ?? '',
      role
    );
  };

  const userDetails = (): CreateUserDetails => {
    const firstName =
      personalDetailsFields
        .find((field) => field.id === 'firstname')
        ?.value?.trim() ?? '';

    const lastName =
      personalDetailsFields
        .find((field) => field.id === 'lastname')
        ?.value?.trim() ?? '';

    return {
      firstName,
      lastName,
      userId,
    };
  };

  const onNavigateToNotFound = (lng: string) => {
    const title = t('sorryThisLinkIsNoLongerValid', { lng });
    const subTitle = `${t('oops', { lng })}...`;
    const description = t('pleaseContactYourSchoolManagerForGuidance', {
      lng,
    });

    navigate('/not-found', {
      state: {
        title,
        subTitle,
        description,
      },
    });
  };

  const onRegister = async () => {
    setIsLoading(true);

    try {
      const _userId = isAdmin ? userId : await onCognitoRegister();

      setUserId(_userId ?? '');

      if (_userId) {
        if (isAdmin) {
          const organizationName =
            organizationDetailsFields
              .find((field) => field.id === 'school_name')
              ?.value?.trim() ?? '';

          const cityOrDistrict =
            organizationDetailsFields
              .find((field) => field.id === 'city')
              ?.label?.trim() ?? '';

          const state =
            organizationDetailsFields
              .find((field) => field.id === 'state')
              ?.label?.trim() ?? '';

          const organization: AdminOrganizationDetails = {
            organizationName,
            cityOrDistrict,
            state,
          };

          const adminOrganizationResponse = (
            await registerService.createOrganization(
              registerUser,
              _userId,
              organization
            )
          )?.data.data;

          if (adminOrganizationResponse) {
            await OnLogin();
            return;
          }

          return;
        }

        const email =
          personalDetailsFields
            .find((field) => field.id === 'email')
            ?.value?.trim() ?? '';

        if (email.includes('tst+ml')) {
          await OnLogin();
          return;
        }

        onNextStep();
      }
    } catch (error: any) {
      commonUtils.showToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCognitoLogin = async () => {
    const email =
      personalDetailsFields
        .find((field) => field.id === 'email')
        ?.value?.trim() ?? '';
    const password =
      personalDetailsFields
        .find((field) => field.id === 'password')
        ?.value?.trim() ?? '';

    return await cognitoService.authenticate(email, password);
  };

  const OnLogin = async () => {
    try {
      const userCognitoInfo = isAdmin ? loginData : await onCognitoLogin();

      const nativeLanguage =
        userCognitoInfo?.nativeLanguage?.Value.trim() ?? 'pt';

      localStorage.removeItem('register-token');

      if (isStudent && isMobile) {
        dispatch(authActions.setIsNewlyRegistered(true));
      }

      dispatch(authActions.setNativeLanguage(nativeLanguage));

      dispatch(
        authActions.signIn({
          token: userCognitoInfo?.token ?? '',
          userId: userCognitoInfo?.userId ?? '',
        })
      );
    } catch (error: any) {
      commonUtils.showToast(error as string);

      if (error.toLowerCase().includes('email')) {
        onEmailIsAlreadyTaken();
      }

      return;
    }

    if (isStudent) {
      dispatch(authActions.setShowAvatarSelection(true));

      return navigate('/dashboard/level-test');
    }
  };

  const onValidateAdminCode = async (verifyCode: string) => {
    setIsLoading(true);

    try {
      const response = (await registerService.validateAdminCode(verifyCode))
        ?.data.data;

      if (response) {
        const { country, nativeLanguageCode, isAllowedToRegister } = response;

        setIsLoading(false);

        dispatch(authActions.setNativeLanguage(nativeLanguageCode));
        dispatch(authActions.setInterfaceLanguage(nativeLanguageCode));

        if (!isAllowedToRegister) {
          onNavigateToNotFound(nativeLanguageCode);
          return;
        }

        if (!response.isCodeValid) {
          onUpdateVerificationCodeField(t('validationCodeInvalid'));
          return;
        }

        onUpdateOrganizationDetailsFields(country, nativeLanguageCode);
        onNextStep();
      }
    } catch (error) {
      console.log(error);
      onUpdateVerificationCodeField(t('validationCodeInvalid'));
      setIsLoading(false);
    }
  };

  const onCodeVerificationFormSubmit = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    const { isValid, verifyCode } = onCodeVerificationSubmit(event);

    if (!verifyCode) return;

    if (isValid) {
      await onValidateAdminCode(verifyCode?.toString());
    }
  };

  const onResendCognitoVerificationCode = async () => {
    try {
      setIsLoading(true);

      const email =
        personalDetailsFields
          .find((field) => field.id === 'email')
          ?.value?.trim() ?? '';

      await cognitoService.resendVerificationCode(email);
    } catch (e) {
      commonUtils.showToast(t(e as string));
    } finally {
      setIsLoading(false);
    }
  };

  const onCognitoCodeVerificationSend = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    try {
      setIsLoading(true);
      const { isValid, verifyCode } = onCognitoCodeVerificationSubmit(event);
      const email =
        personalDetailsFields
          .find((field) => field.id === 'email')
          ?.value?.trim() ?? '';

      await cognitoService.verifyAccount(email, verifyCode ?? '');

      const _loginData = await onCognitoLogin();

      if (!_loginData) {
        return;
      }

      localStorage.setItem('token', _loginData?.token ?? '');
      setLoginData(_loginData);

      if (isAdmin) {
        const userRegister = (
          await registerService.createAdmin({
            ...userDetails(),
            userId: userId,
          })
        )?.data.data;

        if (userRegister) {
          setRegisterUser(userRegister);
        }

        onNextStep();
        return;
      } else {
        const { fromToLanguage } = isTeacher
          ? organizationDetails ?? {}
          : groupDetails ?? {};

        const { response } = isTeacher
          ? (
              await registerService.createTeacher(fromToLanguage ?? '', {
                ...userDetails(),
                userId: userId,
              })
            )?.data.data ?? {}
          : (
              await registerService.createStudent(fromToLanguage ?? '', {
                ...userDetails(),
                userId: userId,
              })
            )?.data.data ?? {};

        if (response) {
          await OnLogin();
        }
      }
    } catch (error) {
      console.log(error);
      onCodeError();
    } finally {
      setIsLoading(false);
    }
  };

  const stepProps = ():
    | {}
    | RegistrationWelcomeProps
    | VerificationCodeProps
    | PersonalDetailsProps
    | CognitoVerificationCodeProps
    | OrganizationDetailsProps => {
    if (currentStep === RegistrationStepType.REGISTRATION_WELCOME) {
      return {
        role,
        organizationDetails,
        groupDetails,
        emitNextStep: onNextStep,
      };
    } else if (currentStep === RegistrationStepType.COGNITO_VERIFY_CODE) {
      return {
        verificationCodeField: cognitoVerificationCodeField,
        role,
        email:
          personalDetailsFields
            .find((field) => field.id === 'email')
            ?.value?.trim() ?? '',
        emitSubmit: onCognitoCodeVerificationSend,
        resendVerificationCode: onResendCognitoVerificationCode,
        // onUpdateCognitoVerificationCodeField,
      };
    } else if (currentStep === RegistrationStepType.VERIFY_CODE) {
      return {
        verificationCodeField,
        emitSubmit: onCodeVerificationFormSubmit,
      };
    } else if (currentStep === RegistrationStepType.PERSONAL_DETAILS) {
      return {
        fields: personalDetailsFields,
        role,
        emitSubmit: onPersonalDetailsSubmit,
      };
    } else if (currentStep === RegistrationStepType.ORGANIZATION_DETAILS) {
      return {
        fields: organizationDetailsFields,
        emitSubmit: onOrganizationDetailsSubmit,
      };
    }
    return {};
  };

  useEffect(() => {
    const userRole = params.role ?? '';
    const userToken = searchParams.get('token') ?? '';
    const userLanguage =
      searchParams.get('language') ?? localStorage.getItem('register-language');

    if (userLanguage) {
      dispatch(authActions.setNativeLanguage(userLanguage));
      dispatch(authActions.setInterfaceLanguage(userLanguage));

      localStorage.setItem('register-language', userLanguage);
    }

    setRole(userRole);

    setRegistrationSteps(() =>
      initialRegistrationSteps
        .filter((step) => (step.roles ? step.roles.includes(userRole) : true))
        .sort((curr, next) => curr.order - next.order)
    );

    if (userToken) {
      localStorage.setItem('register-token', userToken);
    }

    const currentURL = window.location.href;
    const url = new URL(currentURL);

    url.search = '';

    const newURL = url.href;
    window.history.pushState({ path: newURL }, '', newURL);

    const fetchDetails = async () => {
      setIsLoading(true);

      try {
        if (userRole === 'teacher') {
          const teacherOrganizationDetails = (
            await registerService.getOrganizationDetails()
          )?.data.data;

          setIsLoading(false);

          if (teacherOrganizationDetails) {
            dispatch(
              authActions.setNativeLanguage(
                teacherOrganizationDetails.nativeLanguage
              )
            );
            dispatch(
              authActions.setInterfaceLanguage(
                teacherOrganizationDetails.nativeLanguage
              )
            );

            if (!teacherOrganizationDetails?.isAllowedToRegister) {
              onNavigateToNotFound(teacherOrganizationDetails.nativeLanguage);
              return;
            }

            return setOrganizationDetails(teacherOrganizationDetails);
          }

          return;
        }

        const studentGroupDetails = (await registerService.getGroupDetails())
          ?.data.data;

        setIsLoading(false);

        if (studentGroupDetails) {
          dispatch(
            authActions.setNativeLanguage(studentGroupDetails.nativeLanguage)
          );
          dispatch(
            authActions.setInterfaceLanguage(studentGroupDetails.nativeLanguage)
          );

          if (!studentGroupDetails?.isAllowedToRegister) {
            onNavigateToNotFound(studentGroupDetails.nativeLanguage);
            return;
          }

          setGroupDetails(studentGroupDetails);
        }
      } catch (error) {
        window.location.href = '/login';
        window.localStorage.removeItem('register-token');
        setIsLoading(false);
        console.log(error);
      }
    };

    if (userRole === 'teacher' || userRole === 'student') {
      if (userRole === 'teacher') {
        onUpdatePersonalDetailsFields();
      }

      fetchDetails();
    }
  }, []);

  const RegistrationComponent = useMemo(
    () =>
      registrationSteps.find((step) => step.type === currentStep)?.component,
    [currentStep, registrationSteps]
  );

  useEffect(() => {
    const checkPersonalDetailsForm = async () => {
      const isValid = personalDetailsFields.every((field) => field.isValid);

      if (isValid) {
        if (isAdmin) {
          setIsLoading(true);

          try {
            const _userId = await onCognitoRegister();

            setUserId(_userId ?? '');

            onNextStep();
          } catch (e) {
            commonUtils.showToast(t(e as string));
          } finally {
            setIsLoading(false);
          }

          return;
        }

        await onRegister();
      }
    };

    checkPersonalDetailsForm();
  }, [personalDetailsFields]);

  useEffect(() => {
    const checkGeneralDetailsForm = async () => {
      const isValid = organizationDetailsFields.every((field) => field.isValid);

      if (isValid) {
        await onRegister();
        //TODO change to nextStep insted to COGNITO_VERIFY_CODE,after that ,login
      }
    };

    if (isAdmin) {
      checkGeneralDetailsForm();
    }
  }, [organizationDetailsFields]);

  return {
    role,
    currentStep,
    verificationCodeField,
    organizationDetails,
    stepProps,
    isLoading,
    RegistrationComponent,
    onNextStep,
  };
};

export default UseRegistration;
