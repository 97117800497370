import { KeyboardEvent, useState } from 'react';
import classNames from './TranslationsSearch.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';

import AppInput from '@/components/AppInput/AppInput';

import searchIcon from '@/assets/svg/search-icon.svg';
import magnifyingGlass from '@/assets/svg/magnifying-glass-search.svg';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';

interface TranslationsSearchProps {
  showMagnifyingGlass?: boolean;
  shouldExpand?: boolean;
  className?: string;
  value: string;
  emitChange: (value: string) => void;
  emitKeyPress?: (value: string) => void;
  emitClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  emitClear?: () => void;
}

const TranslationsSearch = ({
  showMagnifyingGlass = true,
  shouldExpand = false,
  className,
  value,
  emitChange,
  emitClear,
  emitClick,
  emitKeyPress,
}: TranslationsSearchProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const { user } = useAppSelector(authSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const [isClicked, setIsClicked] = useState(false);

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    emitChange(event.currentTarget.value);
  };

  const onClear = () => {
    emitClear?.();
  };

  const onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setIsClicked((prevState) => !prevState);
    emitClick?.(event);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      emitKeyPress?.(value);
    }
  };

  const allowClear = Boolean(isMobile && value);
  return (
    <div
      className={classes(
        classNames.translationsSearch,
        // { [classNames.translationsSearchValue]: isDesktop && shouldExpand },
        className
      )}
    >
      <SVG className={classNames.icon} src={searchIcon} />
      <AppInput
        type="search"
        className={classNames.input}
        name="search"
        value={value}
        placeholder={
          (isMobile
            ? t('tapToTranslate')
            : t('trasnlateAWordOrPhrase', {
                nativeLanguage: t(`languages.${user?.nativeLanguage}`),
              })) ?? ''
        }
        allowClear={allowClear}
        emitChange={onInputChange}
        emitClear={onClear}
        emitClick={onClick}
        emitKeyPress={onKeyPress}
      />
      {showMagnifyingGlass && (
        <SVG
          className={classes(classNames.magnifyingGlass, [
            classNames[`magnifyingGlass-${interfaceLanguage?.direction}`],
          ])}
          src={magnifyingGlass}
        />
      )}
    </div>
  );
};

export default TranslationsSearch;
