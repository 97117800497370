import classNames from './LoginViewContent.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useEffect, useState } from 'react';

import TheButton from '@/components/TheButton/TheButton';
import LoginForm from '@/components/LoginForm/LoginForm';
import QuickLogin from '@/components/QuickLogin/QuickLogin';

import leftArrow from '@/assets/svg/left-arrow.svg';
import welcome from '@/assets/svg/login_banner.svg';
import welcomeDesktop from '@/assets/svg/welcome-desktop.svg';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import AppMorfixLogo from '../AppMorfixLogo/AppMorfixLogo';

const LoginViewContent = () => {
  const { t } = useTranslation();
  const { isFromRegistration } = useAppSelector(authSelector);

  const { isMobile } = UseScreenSize();

  const [showForm, setShowForm] = useState(false);
  const { nativeLanguage } = useAppSelector(authSelector);
  const showMorfixLogo = nativeLanguage === 'he';
  const isFirstTime = true;

  const onQuickLogin = () => {};

  useEffect(() => {
    if (isFromRegistration) {
      setShowForm(true);
    }
  }, []);

  const name = 'Sarah Jonas';
  const level = 'Foundation';
  const grade = 9;

  const bottom = isFirstTime ? (
    <TheButton
      className={classNames.newNav}
      text={t('getStarted')}
      emitOnClick={() => setShowForm(true)}
    />
  ) : (
    <div className="flex flex-col gap-6 w-full">
      <QuickLogin
        name={name}
        level={level}
        grade={grade}
        emitQuickLogin={onQuickLogin}
      />
      <button className={classNames.oldNav} onClick={() => setShowForm(true)}>
        {t('loginFromDifferent')}
      </button>
    </div>
  );

  const text = (
    <div className={classNames.text}>
      <span className={classNames.welcomeText}>
        {isFirstTime ? t('welcomeTo') : t('welcomeBack')}
      </span>
      <div className={classNames.title}>
        {showMorfixLogo ? (
          <AppMorfixLogo width={389} height={62.32} showBackGround={false} />
        ) : (
          <>
            <span className={classNames.titleText}>Britannica</span>
            <span className={classNames.englishText}>English</span>
          </>
        )}
      </div>
      <p>
        {!isFirstTime && isMobile
          ? t('pleaseSelectYourAccount')
          : t('discoverAWorld')}
      </p>
    </div>
  );

  const svg = (
    <SVG
      className={classes(classNames.welcomeImg, {
        'flex-1': isMobile,
      })}
      src={!showMorfixLogo && isMobile ? welcome : welcomeDesktop}
    />
  );

  const loginForm = <LoginForm />;

  let content = (
    <div className={classNames.inner}>
      <div className={classNames.innerWelcome}>
        {svg}
        {text}
      </div>
      {loginForm}
    </div>
  );

  if (isMobile) {
    content = showForm ? (
      <>
        <div className={classNames.backBtn}>
          <button onClick={() => setShowForm(false)}>
            <SVG src={leftArrow} />
          </button>
        </div>
        {loginForm}
      </>
    ) : (
      <>
        <div className={classNames.mobileInner}>
          <div
            style={{ flexBasis: '90%' }}
            className={classNames.mobileInnerWelcome}
          >
            {svg}
            {showMorfixLogo && text}
          </div>
          {bottom}
        </div>
      </>
    );
  }

  return <div className={classNames.loginViewContent}>{content}</div>;
};

export default LoginViewContent;
