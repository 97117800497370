import React, { useState } from 'react';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UseUserProgress from '@/hooks/UseUserProgress';

import SkillsGrid from '@/components/SkillsGrid/SkillsGrid';
import Navbar from '@/components/Navbar/Navbar';
import UserInfo from '@/components/UserInfo/UserInfo';
import DictionaryMobile from '@/components/Dictionary/DictionaryMobile/DictionaryMobile';
import AppBanner from '@/components/AppBanner';
import LevelDropdown from '@/components/ProfileProgress/LevelDropdown';
import classNames from '../../../components/ProfileProgress/LevelDropdown.module.scss';
import TheButton from '@/components/TheButton/TheButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FinalAssessmentStartPopUp from '@/components/FinalAssessmentStartPopUp/FinalAssessmentStartPopUp';

const MainViewMobile = () => {
  const { user, userProgress, displayDictionary } =
    useAppSelector(authSelector);
  const navigate = useNavigate();
  const { loadingProgress } = UseUserProgress();
  const { t } = useTranslation();

  const [shouldOpenDictioanry, setShouldOpenDictionary] = useState(false);

  const onOpenDictionary = () => {
    setShouldOpenDictionary(true);
  };

  const onCloseDictionary = () => {
    setShouldOpenDictionary(false);
  };

  return (
    <>
      <FinalAssessmentStartPopUp />
      <Navbar />
      <div className="container">
        {user && (
          <UserInfo
            studentsAmount={user.totalStudents ?? 0}
            classesAmount={user.teacherGroups?.length}
            isTeacher={user.role === 'teacher'}
            fullname={`${user.firstName} ${user.lastName}`}
            profileImage={user.avatar?.avatarName}
            backgroundColor={user.avatar?.avatarColor}
            notificationsAmount={3}
            level={user.level}
            grade={user.grade}
            badges={user.achievements?.badges}
            coins={user.achievements?.coins}
          />
        )}
        {user?.role === 'teacher' && (
          <div className={classNames.teacherGoToLevelTestMobile}>
            <TheButton
              emitOnClick={() => navigate('/dashboard/level-test')}
              text={t('levelTest')}
              showArrow
              className={classNames.button}
            />
          </div>
        )}
        <SkillsGrid loading={loadingProgress} progress={userProgress} />
      </div>
      {displayDictionary ? (
        <DictionaryMobile
          shouldOpen={shouldOpenDictioanry}
          emitOpen={onOpenDictionary}
          emitClose={onCloseDictionary}
        />
      ) : (
        <AppBanner />
      )}
    </>
  );
};

export default React.memo(MainViewMobile);
