import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  CognitoPasswordChangeProps,
  ConfirmForgotPasswordProps,
  ConfirmVerificationCodeProps,
  ForgotPasswordProps,
  InitialLoginProps,
  LoginStep,
  LoginSteps,
  LoginValues,
} from '@/types/login';
import { cognitoService } from '@/services/cognito';
import { authActions } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';
import InitialLogin from '@/components/LoginForm/InitialLogin';
import VerifyRegistrationCode from '@/components/LoginForm/VerifyRegistrationCode';
import ForgotPassword from '@/components/LoginForm/ForgotPassword';
import ConfirmForgotPassword from '@/components/LoginForm/ConfirmForgotPassword';
import CognitoPasswordChange from '@/components/LoginForm/CognitoPasswordChange';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';

const UseLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [cognitoUserAttributes, setCognitoUserAttributes] = useState<
    CognitoUserAttribute[]
  >([]);
  const [loginDetails, setLoginDetails] = useState<LoginValues>({});
  const [loginStep, setLoginStep] = useState(LoginStep.LOGIN);

  const loginSteps: LoginSteps[] = [
    {
      type: LoginStep.LOGIN,
      title: 'signIn',
      subTitle: 'pleaseEnterYourDetails',
      component: InitialLogin,
    },
    {
      type: LoginStep.CONFIRM_VERIFICATION_CODE,
      title: 'signIn',
      subTitle: 'enterVerificationCode',
      component: VerifyRegistrationCode,
    },
    {
      type: LoginStep.FORGOT_PASSWORD,
      title: 'forgotPassword',
      subTitle: 'pleaseEnterYourDetails',
      component: ForgotPassword,
    },
    {
      type: LoginStep.CONFIRM_FORGOT_PASSWORD,
      title: 'forgotPassword',
      subTitle: 'enterVerificationCodeAndNewPassword',
      component: ConfirmForgotPassword,
    },
    {
      type: LoginStep.COGNITO_PASSWORD_CHANGE,
      component: CognitoPasswordChange,
    },
  ];

  const LoginComponent = useMemo(
    () => loginSteps.find((step) => step.type === loginStep)?.component,
    [loginStep]
  );

  const currentLoginStep = useMemo(
    () => loginSteps.find((step) => step.type === loginStep),
    [loginStep]
  );

  const [isFirstTime, setIsFirstTime] = useState(true);

  const OnLoginSubmitted = async (values: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    setLoginDetails(values);

    try {
      const userCognitoInfo = await cognitoService.authenticate(
        values.email,
        values.password
      );

      if (userCognitoInfo.newPasswordRequired && userCognitoInfo.cognitoUser) {
        setCognitoUser(userCognitoInfo.cognitoUser);
        setCognitoUserAttributes(userCognitoInfo.requiredAttributes ?? []);
        setLoginStep(LoginStep.COGNITO_PASSWORD_CHANGE);
        return;
      }

      if (!userCognitoInfo.token || !userCognitoInfo.userId) {
        return;
      }

      const nativeLanguage = userCognitoInfo.nativeLanguage?.Value ?? 'pt';

      // Set native language
      localStorage.setItem('userEmail', values.email);
      dispatch(authActions.setNativeLanguage(nativeLanguage));
      dispatch(
        authActions.signIn({
          token: userCognitoInfo.token,
          userId: userCognitoInfo.userId,
        })
      );
    } catch (e: unknown) {
      const errCode = e as string;

      const errMsg =
        errCode === 'NotAuthorizedException'
          ? 'invalidUsernameOrPassword'
          : errCode;

      if (errCode === 'UserNotConfirmedException') {
        try {
          await cognitoService.resendVerificationCode(values.email);
        } catch (err: unknown) {
          commonUtils.showToast(t(err as string));
          return;
        }

        setLoginStep(LoginStep.CONFIRM_VERIFICATION_CODE);
      }

      commonUtils.showToast(t(errMsg));

      return;
    } finally {
      setLoading(false);
    }

    return navigate('/dashboard/games');
  };

  const OnResendForgotPassword = async () => {
    if (!loginDetails.email) {
      return;
    }

    setLoading(true);

    try {
      await cognitoService.forgotPassword(loginDetails.email);
    } catch (e: any) {
      commonUtils.showToast(t(e.code as string));

      return;
    } finally {
      setLoading(false);
    }
  };

  const OnSubmitVerificationForgotPassword = async (values: {
    code: string;
    password: string;
    confirmPassword: string;
  }) => {
    if (!loginDetails.email) {
      return;
    }

    setLoading(true);

    try {
      await cognitoService.confirmForgotPassword(
        loginDetails.email
          .replace(/\s/g, '')
          .replace(/\u202B|\u202C/g, '')
          .split('')
          .join(''),
        values.code,
        values.password
      );
      setLoginStep(LoginStep.LOGIN);

      commonUtils.showToast(t('password_changed_successfully'));
    } catch (e: any) {
      commonUtils.showToast(t(e.code as string));

      return;
    } finally {
      setLoading(false);
    }
  };

  const OnSubmitForgotPassword = async (values: { email: string }) => {
    setLoginDetails(values);

    setLoading(true);

    try {
      await cognitoService.forgotPassword(
        values.email
          .replace(/\s/g, '')
          .replace(/\u202B|\u202C/g, '')
          .split('')
          .join('')
      );
      setLoginStep(LoginStep.CONFIRM_FORGOT_PASSWORD);
    } catch (e: any) {
      commonUtils.showToast(t(e.code as string));

      return;
    } finally {
      setLoading(false);
    }
  };

  const OnVerifyAccountSubmitted = async (values: { code: string }) => {
    if (!loginDetails.email) {
      return;
    }

    setLoading(true);

    try {
      await cognitoService.verifyAccount(loginDetails.email, values.code);
      commonUtils.showToast(t('accountVerifiedCanLoginNow'));
      setLoginStep(LoginStep.LOGIN);
    } catch (e: any) {
      commonUtils.showToast(t(e.code as string));

      return;
    } finally {
      setLoading(false);
    }
  };

  const OnSubmitCognitoPasswordChange = async (values: {
    password: string;
    confirmPassword: string;
  }) => {
    const { password, confirmPassword } = values;

    if (
      !loginDetails.email ||
      !loginDetails.password ||
      !password ||
      !confirmPassword ||
      password !== confirmPassword
    ) {
      return;
    }

    setLoading(true);

    try {
      const userCognitoInfo = await cognitoService.forceChangeNewPassword(
        cognitoUser as CognitoUser,
        cognitoUserAttributes,
        password
      );

      if (!userCognitoInfo.token || !userCognitoInfo.userId) {
        return;
      }

      commonUtils.showToast(t('password_changed_successfully'));

      const nativeLanguage = userCognitoInfo.nativeLanguage?.Value ?? 'pt';

      // Set native language
      localStorage.setItem('userEmail', loginDetails.email);
      dispatch(authActions.setNativeLanguage(nativeLanguage));
      dispatch(
        authActions.signIn({
          token: userCognitoInfo.token,
          userId: userCognitoInfo.userId,
        })
      );
    } catch (e: any) {
      commonUtils.showToast(t(e.code as string));

      return;
    } finally {
      setLoading(false);
    }
  };

  const stepProps = ():
    | {}
    | ForgotPasswordProps
    | ConfirmForgotPasswordProps
    | InitialLoginProps
    | ConfirmVerificationCodeProps
    | CognitoPasswordChangeProps => {
    if (loginStep === LoginStep.LOGIN) {
      return {
        emitSubmit: OnLoginSubmitted,
        loading: loading,
        goToResetPassword: () => {
          setLoginStep(LoginStep.FORGOT_PASSWORD);
        },
      };
    } else if (loginStep === LoginStep.CONFIRM_VERIFICATION_CODE) {
      return {
        emitSubmit: OnVerifyAccountSubmitted,
        loading: loading,
      };
    } else if (loginStep === LoginStep.FORGOT_PASSWORD) {
      return {
        emitSubmit: OnSubmitForgotPassword,
        loading: loading,
      };
    } else if (loginStep === LoginStep.CONFIRM_FORGOT_PASSWORD) {
      return {
        emitSubmit: OnSubmitVerificationForgotPassword,
        resendForgotPassword: OnResendForgotPassword,
        loading: loading,
      };
    } else if (loginStep === LoginStep.COGNITO_PASSWORD_CHANGE) {
      return {
        emitSubmit: OnSubmitCognitoPasswordChange,
        loading: loading,
      };
    }

    return {};
  };

  return {
    isFirstTime,
    loading,
    loginStep,
    LoginComponent,
    stepProps,
    currentLoginStep,
  };
};

export default UseLogin;
