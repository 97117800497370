import classNames from './AddNewStudent.module.scss';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '@/context/DrawerContext';
import SectionSeperator from '../SectionSeperator/SectionSeperator';
import AddNewStudentForm from '../AddNewStudentForm/AddNewStudentForm';

interface AddNewStudentProps {
    groupId: string;
}

const AddNewStudent = ({
    groupId,
}: AddNewStudentProps) => {
    const { t } = useTranslation();
    const drawer = useDrawer();

    return (
        <div className={classNames.addNewClassDrawer}>
            <div className={classNames.top}>
                <h1>{t('settings.add_students.add_student')}</h1>
                <h2>{t('settings.add_students.email_will_be_send')}</h2>
            </div>
            <SectionSeperator className={classNames.seperator} />
            <div className={classNames.body}>
                <AddNewStudentForm
                    groupId={groupId}
                    emitCancel={drawer.closeAddStudent}
                />
            </div>
        </div>
    );
};

export default AddNewStudent;
