// React and related imports
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';

// Component and utility imports
import SVG from 'react-inlinesvg';
import TheButton from '@/components/TheButton/TheButton';
import GameUtils from '@/utils/gameUtils';

// SVG icons
import OppsIcon from '@/assets/svg/tutor/oops.svg';
import completed2Icon from '@/assets/svg/tutor/completed-2.svg';
import completedIcon from '@/assets/svg/tutor/completed.svg';

// Types import
import { TutorTooltipProps } from '@/types/game';

// Styles import
import classNames from './TutorTooltip.module.scss';
import { tutorActions } from '@/store/reducers/tutor';
import UseScreenSize from '@/hooks/UseScreenSize';
import { TriggeredBy } from '@/types/tutor';

const TutorTooltip = ({ score }: TutorTooltipProps) => {
  // Initialize translation function
  const { t } = useTranslation();

  const { isMobile } = UseScreenSize();

  const dispatch = useAppDispatch();

  const { openTutor } = tutorActions;

  // Calculate the number of stars based on the score
  const starsAmount = GameUtils.getStarsAmount(score);

  // Define the tooltip content based on the number of stars
  const getTooltipContent = () => {
    switch (starsAmount) {
      case 0:
        return {
          icon: OppsIcon,
          messageKey: 'tutorPracticeWithMeTitle',
          linkKey: 'tutorPracticeWithMe',
        };
      case 1:
      case 2:
        return {
          icon: completed2Icon,
          messageKey: 'tutorPracticeWithMeTitle',
          linkKey: 'tutorPracticeWithMe',
        };
      case 3:
        return {
          icon: completedIcon,
          messageKey: 'tutorChalangeTitle',
          linkKey: 'tutorChallenge',
        };
      default:
        return {
          icon: OppsIcon,
          messageKey: 'tutorPracticeWithMeTitle',
          linkKey: 'tutorPracticeWithMe',
        };
    }
  };

  // Get the tooltip content based on the stars
  const tooltipContent = getTooltipContent();

  const handleOpenTutor = () =>
    dispatch(tutorActions.openTutor(TriggeredBy.Tooltip));

  return (
    <div className={classNames.tutorContainer}>
      <div className={classNames.tooltip}>
        <SVG src={tooltipContent.icon} />
        <div className={classNames.message}>
          <p>{t('tutorPrivate')}</p>
          <span>{t(tooltipContent.messageKey)}</span>
        </div>
      </div>
      <div className={classNames.link}>
        {starsAmount === 0 && isMobile && (
          <TheButton
            showArrow
            text={t(tooltipContent.linkKey)}
            emitOnClick={handleOpenTutor}
          />
        )}
      </div>
    </div>
  );
};

export default TutorTooltip;
