import classes from 'classnames';
import React, { useEffect, useState } from 'react';
import classNames from './RoadmapCarousel.module.scss';
import commonUtils from '@/utils/common';
import { Road, Topic, TopicTypes } from '@/types/topic';
import SVG from 'react-inlinesvg';
import UseSkills from '@/hooks/UseSkills';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';
import config from '@/config';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';

import TopicInfo from '@/components/SkillPage/SkillTopics/SkillTopic/TopicInfo/TopicInfo';
import AppLoaderCircle from '@/components/AppLoaderCircle';

import previousArrow from '@/assets/svg/carousel-previous-arrow.svg';
import nextArrow from '@/assets/svg/carousel-next-arrow.svg';
import road1 from '@/assets/svg/road-1-desktop.svg';
import road2 from '@/assets/svg/road-2-desktop.svg';
import flag from '@/assets/svg/red_flag.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { endOfSkillSelector } from '@/store/reducers/end-of-skill';
import useEndOfSkillTest from '@/hooks/UseEndOfSkillTest';

interface RoadmapCarouselProps {
  topics: Topic[];
  loading: boolean;
  groupedTopics: Topic[][];
  emitOpenModal: (topicId: number) => void;
}

const RoadmapCarousel = ({
  emitOpenModal,
  topics,
  loading,
  groupedTopics,
}: RoadmapCarouselProps) => {
  const { skillsAssets } = UseSkills();
  const { selectedType } = useAppSelector(gameSelector);
  const { user } = useAppSelector(authSelector);
  const { t } = useTranslation();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const isVocabulary = selectedType === SkillTypes.Vocabulary;
  const isTeacher = UserUtils.isTeacher(user);
  const skillAssets = selectedType && skillsAssets[selectedType];
  const [scrollPosition, setScrollPosition] = useState(0);
  const [displayedSection, setDisplayedSection] = useState(1);

  const { handleStartEndOfSkillTest } = useEndOfSkillTest();

  const roads = [
    {
      order: 0,
      img: road1,
    },
    {
      order: 1,
      img: road2,
    },
  ] as Road[];

  useEffect(() => {
    handleScroll('prev', true);
  }, [selectedType]);

  const handleScroll = (direction: string, shouldReset: boolean) => {
    const container = document.getElementById(
      'roadmap-carousel-container'
    ) as HTMLDivElement;

    const scrollAmount = container.offsetWidth;

    if (direction === 'next') {
      if (displayedSection === groupedTopics.length) {
        return;
      }

      setDisplayedSection((prevState) => (shouldReset ? 1 : prevState + 1));
    } else if (direction === 'prev' && displayedSection) {
      setDisplayedSection((prevState) => (shouldReset ? 1 : prevState - 1));
    }

    let newPosition =
      direction === 'next'
        ? scrollPosition + scrollAmount
        : scrollPosition - scrollAmount;

    if (shouldReset) {
      newPosition = 0;
    }

    setScrollPosition(() => (shouldReset ? 0 : newPosition));

    container.scrollTo({
      left: interfaceLanguage?.direction === 'ltr' ? newPosition : -newPosition,
      behavior: shouldReset ? 'auto' : 'smooth',
    });
  };
  let adaTopicIndex = 1;
  let totalTopicIndex = -1;
  const topicsGroupsElements = groupedTopics.map(
    (topicsGroup, topicsGroupIndex) => {
      const isLastTopicGroup = groupedTopics.length - 1 === topicsGroupIndex;
      const nextTopicGroup = groupedTopics[topicsGroupIndex + 1];
      const firstInNextGroup = nextTopicGroup ? nextTopicGroup[0] : null;

      const firstInNextGroupInTopics = topics.find(
        (topic) => topic.id === firstInNextGroup?.id
      );
      const firstInNextGroupInTopicsIsLocked =
        (firstInNextGroupInTopics && firstInNextGroupInTopics !== undefined
          ? commonUtils.isTopicLocked(firstInNextGroupInTopics, topics)
          : null) &&
        !isVocabulary &&
        !isTeacher;

      const topicsElements = topicsGroup.map((topic, topicIndex) => {
        totalTopicIndex++;
        adaTopicIndex += 1;
        const topicInTopics = topics.findIndex((item) => item.id === topic.id);
        const isLastTopic = topicsGroup.length - 1 === topicIndex;
        const remainder = topicIndex % 2;
        const road = roads.find((road) => road.order === remainder);
        const isEven = remainder === 0;

        // const isLocked = false;
        const isLocked =
          commonUtils.isTopicLocked(topic, topics) &&
          !isVocabulary &&
          !isTeacher;

        const topicBeforeFlag =
          groupedTopics[groupedTopics.length - 1][topicsGroup.length - 2];

        const lockedBeforeFlag =
          topic.id === 0 && commonUtils.isTopicLocked(topicBeforeFlag, topics);
        const sectionConnector = !isLastTopicGroup &&
          topicIndex === config.topicsPerSectionDesktop - 1 && (
            <SVG
              className={classes(
                classNames.roadImg,
                classNames.sectionConnector,
                {
                  [`road-${selectedType}`]: true,
                  [`road-${selectedType}-locked`]:
                    firstInNextGroupInTopicsIsLocked,
                }
              )}
              src={road2}
            />
          );

        return (
          <div
            tabIndex={adaTopicIndex - 1}
            key={topic.id}
            className={classes(classNames.topic, [
              isEven ? classNames.topicEven : classNames.topicOdd,
            ])}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isLocked) {
                emitOpenModal(topic.id);
              }
            }}
          >
            <SVG
              className={classes(classNames.roadImg, {
                [`road-${selectedType}`]: true,
                [`road-${selectedType}-locked`]: lockedBeforeFlag || isLocked,
              })}
              src={road?.img ?? ''}
            />
            {topic.id === 0 ? (
              <SVG
                src={flag}
                className={classes(classNames.topicInfo, classNames.flag)}
              />
            ) : (
              <TopicInfo
                className={classes(
                  classNames.topicInfo,
                  !isLocked ? 'cursor-pointer' : classNames.topicInfoLocked
                )}
                topic={topic}
                isLocked={isLocked}
                isComplete={commonUtils.isTopicCompleted(topic)}
                index={totalTopicIndex}
                emitOpenModal={
                  topic.topicType === TopicTypes.EndOfSkillTest
                    ? () => handleStartEndOfSkillTest(topic)
                    : emitOpenModal
                }
              />
            )}

            {sectionConnector}
          </div>
        );
      });

      return (
        <div key={topicsGroupIndex} className={classNames.topicsGroup}>
          {topicsElements}
        </div>
      );
    }
  );

  const shouldDisablePrevious = displayedSection === 1;
  const shouldDisableNext = displayedSection === groupedTopics.length;

  return (
    <div
      className={classes(classNames.roadmapCarousel, [
        classNames[`roadmapCarousel-${interfaceLanguage?.direction}`],
      ])}
    >
      {!shouldDisablePrevious && (
        <button
          className={classNames.previous}
          onClick={() => handleScroll('prev', false)}
          disabled={shouldDisablePrevious}
        >
          <SVG src={previousArrow} />
        </button>
      )}
      <div className={classNames.levelText}>
        <label>
          {t('level') +
            ' ' +
            user?.currentLevelByskill?.[selectedType as SkillTypes]}
        </label>
      </div>
      <div
        id="roadmap-carousel-container"
        className={classNames.carouselContainer}
        style={{
          backgroundImage: `url(${skillAssets?.backgroundImage})`,
        }}
      >
        {!loading && topicsGroupsElements}

        {loading && <AppLoaderCircle className="mx-auto" />}
      </div>
      {!shouldDisableNext && (
        <button
          className={classNames.next}
          onClick={() => handleScroll('next', false)}
          disabled={shouldDisableNext}
        >
          <SVG src={nextArrow} />
        </button>
      )}
    </div>
  );
};

export default React.memo(RoadmapCarousel);
