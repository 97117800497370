import { ReadPreparationProps } from '@/types/game';
import classNames from './ReadPreparation.module.scss';

import ReadContent from './ReadContent/ReadContent';
import ReadQuestion from './ReadQuestion/ReadQuestion';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import UseScreenSize from '@/hooks/UseScreenSize';

const ReadPreparation = ({
  loading,
  currentQuestion,
  currentQuestionIndex,
  gameQuestions,
  selectedQuiz,
  selectedType,
  selectedSkill,
  selectedTopic,
  coins,
  currentCoins,
  currentTime,
  isPaused,
  emitGoBack,
  emitOnStartQuiz,
  emitPause,
}: ReadPreparationProps) => {
  const { showPreQuiz, isLevelTest, isInEndOfSkillTest } =
    useAppSelector(gameSelector);
  const dispatch = useAppDispatch();
  const [shouldStart, setShouldStart] = useState(isInEndOfSkillTest);
  const [shouldViewAnswers, setShouldViewAnswers] = useState(false);
  const [counter, setCounter] = useState(0);
  const { isDesktop } = UseScreenSize();

  const onStartQuiz = () => {
    setShouldStart(true);
    emitOnStartQuiz?.();
    dispatch(gameActions.onPlay());
    setCounter((prevState) => prevState + 1);
  };

  const onViewAnswers = () => {
    setShouldViewAnswers(true);
    setCounter((prevState) => prevState + 1);
  };

  const onHideAnswers = () => {
    setShouldViewAnswers(false);
  };

  useEffect(() => {
    setShouldStart(!showPreQuiz);
  }, [showPreQuiz]);

  return (
    <div className={classNames.readPreparation}>
      <ReadContent
        selectedType={selectedType}
        selectedSkill={selectedSkill}
        selectedTopic={selectedTopic}
        selectedQuiz={selectedQuiz}
        isPaused={isPaused}
        emitPause={emitPause}
        emitGoBack={emitGoBack}
        shouldStart={shouldStart}
        shouldViewAnswers={shouldViewAnswers}
        counter={counter}
      />
      <div
        className={
          isLevelTest && isDesktop
            ? classNames.levelTestBottom
            : classNames.bottom
        }
        id="read-preparation-bottom"
      >
        {currentQuestion && gameQuestions?.length ? (
          <ReadQuestion
            selectedQuiz={selectedQuiz}
            selectedSkill={selectedSkill}
            selectedTopic={selectedTopic}
            selectedType={selectedType}
            currentQuestion={currentQuestion}
            shouldStart={shouldStart}
            shouldViewAnswers={shouldViewAnswers}
            loading={loading}
            coins={coins}
            currentCoins={currentCoins}
            currentTime={currentTime}
            currentQuestionIndex={currentQuestionIndex}
            gameQuestions={gameQuestions}
            isPaused={isPaused}
            onPause={emitPause}
            onStartQuiz={onStartQuiz}
            onViewAnswers={onViewAnswers}
            onHideAnswers={onHideAnswers}
            emitGoBack={emitGoBack}
          />
        ) : null}
      </div>
    </div>
  );
};
export default ReadPreparation;
