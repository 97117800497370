import React from 'react';
import { UserState } from '@/types/user';
import UseScreenSize from '@/hooks/UseScreenSize';

import StudentCardDesktop from './StudentCardDesktop';
import StudentCardMobile from './StudentCardMobile';

interface StudentCardProps {
  emitShowDataModal: (studentId: string) => void;
}

const StudentCard = ({
  userName,
  firstName,
  lastName,
  level,
  grade,
  studentProgress,
  quizzesProgress,
  currentLevelByskill,
  emitShowDataModal,
}: UserState & StudentCardProps) => {
  const { isMobile } = UseScreenSize();
  const { completedQuizzes, totalQuizzes, currentProgressBySkill } =
    studentProgress ?? {};

  return isMobile ? (
    <StudentCardMobile
      firstname={firstName}
      level={level}
      grade={grade}
      userName={userName}
      currentLevelByskill={currentLevelByskill}
      currentProgressBySkill={currentProgressBySkill}
      quizzesProgress={quizzesProgress}
      completedQuizzes={completedQuizzes}
      totalQuizzes={totalQuizzes}
      emitShowDataModal={emitShowDataModal}
    />
  ) : (
    <StudentCardDesktop
      level={level}
      grade={grade}
      firstname={firstName}
      lastname={lastName}
      userName={userName}
      currentLevelByskill={currentLevelByskill}
      currentProgressBySkill={currentProgressBySkill}
      totalQuizzes={totalQuizzes}
      quizzesProgress={quizzesProgress}
      completedQuizzes={completedQuizzes}
      emitShowDataModal={emitShowDataModal}
    />
  );
};

export default React.memo(StudentCard);
