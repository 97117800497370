import { ChangeEvent, FormEvent, useState } from 'react';
import { AppInputProps } from '@/types/common';
import { useTranslation } from 'react-i18next';

const UseCodeVerification = () => {
  const { t } = useTranslation();

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setVerificationCodeField((prevState) => {
      const updatedState = { ...prevState };

      updatedState.value = value.trim();
      updatedState.error = '';

      return updatedState;
    });
  };

  const onCodeVerificationSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setVerificationCodeField((prevState) => {
      const updatedState = { ...prevState };

      if (!updatedState.value) {
        updatedState.error = t('fieldIsMandatory') ?? '';
      }

      return updatedState;
    });

    const isValid =
      !verificationCodeField.error && verificationCodeField.value?.trim();

    return { isValid, verifyCode: verificationCodeField.value };
  };

  const initialVerificationCodeField: AppInputProps = {
    id: 'verification_code',
    name: 'verificationCode',
    type: 'password',
    label: 'enterVerificationCode',
    value: '',
    error: '',
    emitChange: onInputChange,
  };

  const [verificationCodeField, setVerificationCodeField] =
    useState<AppInputProps>(initialVerificationCodeField);

  const onUpdateVerificationCodeField = (error: string) => {
    setVerificationCodeField((prevState) => {
      return {
        ...prevState,
        error,
      };
    });
  };

  return {
    verificationCodeField,
    onUpdateVerificationCodeField,
    onCodeVerificationSubmit,
  };
};

export default UseCodeVerification;
