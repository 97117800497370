import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from './TutorMuteConfirmPopupDesktop.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import TheButton from '@/components/TheButton/TheButton';
import { tutorActions } from '@/store/reducers/tutor';
import { gameActions } from '@/store/reducers/game';
import tutorIcon from '@/assets/svg/tutor/tutor_notification.svg';
import AppAlert from '@/components/AppAlert';
import commonUtils from '@/utils/common';

type TutorMuteConfirmPopupDesktop = {
  emitClose: () => void;
};

const TutorMuteConfirmPopupDesktop: React.FC<TutorMuteConfirmPopupDesktop> = ({
  emitClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const muteHandler = () => {
    commonUtils.showToast(t('tutorNotificationMuted'), '', {
      bottom: '80px',
      width: '430px',
      height: '10px',
      backgroundColor: 'black',
      color: 'white',
      borderRadius: '100px',
      textAlign: 'center',
      fontSize: '14px',
    });
    dispatch(tutorActions.muteNotifications());
    emitClose();

    setTimeout(() => {
      dispatch(gameActions.goToNextQuestion({}));
    }, 3000); // 3 seconds delay before going to the next question
  };

  return (
    <div className={classNames.assistMuteConfirmPopup}>
      <div className={classNames.header}>
        <span>{t('tutorPrivate')}</span>
        <p>{t('tutorTurnOff')}</p>
        <p>{t('tutorNotification')}?</p>
      </div>
      <div className={classNames.body}>
        <img src={tutorIcon} alt="tutor" />
        <span>{t('tutorNotificationBody')}</span>
      </div>
      <div className={classNames.buttons}>
        <TheButton
          plain
          text={t('tutorYesAndContinue')}
          emitOnClick={muteHandler}
          className={classNames.close}
        />

        <TheButton
          showArrow
          shouldRecolor={true}
          text={t('tutorNoAndContinue')}
          emitOnClick={emitClose}
          className={classNames.theButton}
        />
      </div>
    </div>
  );
};

export default React.memo(TutorMuteConfirmPopupDesktop);
