import { useTranslation } from 'react-i18next';
import classNames from './QuizLanguageToggler.module.scss';
import classes from 'classnames';
import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useEffect, useMemo, useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import { gameActions, gameSelector } from '@/store/reducers/game';

interface QuizLanguageTogglerProps {
  className?: string;
}

const QuizLanguageToggler = ({ className }: QuizLanguageTogglerProps) => {
  const { isDesktop } = UseScreenSize();
  const { t } = useTranslation();
  const { fromToLang } = useAppSelector(gameSelector);
  const dispatch = useAppDispatch();

  const fromCode = useMemo(() => fromToLang?.split('-')[1], [fromToLang]);
  const toCode = useMemo(() => fromToLang?.split('-')[0], [fromToLang]);

  const onSwitch = () => {
    dispatch(gameActions.setFromToLang(`${fromCode}-${toCode}`));
  };

  return (
    <div className={classes(classNames.quizLanguageToggler, className)}>
      <div className={classNames.language}>
        <span>{t('from')}</span>
        <span className={classNames.name}>{t(`languages.${fromCode}`)}</span>
      </div>
      {isDesktop && <SectionSeperator className={classNames.seperator} />}
      <button className={classNames.toggler} onClick={onSwitch}>
        <span></span>
      </button>
      <div className={classNames.language}>
        <span>{t('to')}</span>
        <span className={classNames.name}>{t(`languages.${toCode}`)}</span>
      </div>
    </div>
  );
};

export default QuizLanguageToggler;
