import { useTranslation } from 'react-i18next';
import classNames from './TopCard.module.scss';
import {
  GenerateReportProps,
  ReportTypes,
} from '../../../../../types/generate-report';
import classes from 'classnames';
import graphIcon from '../../../../../assets/svg/graph.svg';
import learningIcon from '../../../../../assets/svg/learning.svg';
import thumbRedIcon from '../../../../../assets/svg/thumb-red.svg';
import SVG from 'react-inlinesvg';

const TopCard = ({ reportType, reportContent }: GenerateReportProps) => {
  const { t } = useTranslation();

  const isStudentReport = reportType === ReportTypes.student;

  const roundNumber = (number: number) => {
    if (number % 1 >= 0.5) {
      return Math.ceil(number);
    } else {
      return Math.floor(number);
    }
  };

  return (
    <div className={classNames.topCard}>
      <div className={classNames.topCardItem}>
        <SVG src={graphIcon} />
        <h2>
          {reportContent?.overallQuizProgress
            ? `${
                isStudentReport
                  ? roundNumber(reportContent.overallQuizProgress) + '%'
                  : roundNumber(reportContent.overallQuizProgress)
              }`
            : '-'}
        </h2>
        <h5>{isStudentReport ? t('overallProgress') : t('avgQuizzes')}</h5>
      </div>
      <div
        className={classes(classNames.topCardItem, {
          [classNames.borders]: true,
        })}
      >
        <SVG src={learningIcon} />
        <h2>
          {reportContent?.quizPassRate
            ? roundNumber(reportContent.quizPassRate) + '%'
            : '-'}
        </h2>
        <h5>{isStudentReport ? t('passRate') : t('avgPassRate')}</h5>
      </div>
      <div className={classNames.topCardItem}>
        <SVG src={thumbRedIcon} />
        <h2>
          {reportContent?.quizFailureRate
            ? roundNumber(reportContent.quizFailureRate) + '%'
            : '-'}
        </h2>
        <h5>{isStudentReport ? t('failureRate') : t('avgFailureRate')}</h5>
      </div>
    </div>
  );
};

export default TopCard;
