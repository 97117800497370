import { useTranslation } from 'react-i18next';
import classNames from './ClassStudentLevels.module.scss';
import {
  ClassLevelsValueObject,
  GenerateReportProps,
  Skills,
} from '../../../../../types/generate-report';

const ClassStudentLevels = ({ reportContent }: GenerateReportProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames.studentLevelCard}>
      <div>
        <h1>{t('studentLevels')}</h1>
        <h5>{t('classAvg')}</h5>
      </div>
      <div className={classNames.container}>
        {reportContent &&
          reportContent.progressPerSkill &&
          Object.entries(
            reportContent?.progressPerSkill as Record<
              keyof Skills,
              ClassLevelsValueObject
            >
          ).map(([skill, { groupCurrentAverageLevel }]) => (
            <div key={skill} className={classNames.card}>
              <h2>{t(`skills.${skill}`)}</h2>
              <h3>{groupCurrentAverageLevel}</h3>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ClassStudentLevels;
