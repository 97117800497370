import { GameTypeProps, MultiChoiceProps } from '@/types/game';
import classNames from './MultiChoiceTutorOptions.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';

import AnswerReward from '../../AnswerReward/AnswerReward';

import correct from '@/assets/svg/correct-circle.svg';
import wrong from '@/assets/svg/wrong-circle.svg';
import { SkillTypes } from '@/types/skill';
import { useMemo } from 'react';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import { TopicTypes } from '@/types/topic';
import UseScreenSize from '@/hooks/UseScreenSize';
import { tutorSelector } from '@/store/reducers/tutor';

type MultiChoiceTutorOptionsProps = {
  className?: string;
  coins?: number;
  correctAnswer: GameTypeProps['correctAnswer'];
  pickedAnswer: MultiChoiceProps['pickedAnswer'];
  options: MultiChoiceProps['options'];
  showAnswerInOptions?: MultiChoiceProps['showAnswerInOptions'];
  isDictionary?: boolean;
  onSelect: (answer: string) => void;
  isTutor?: boolean;
};

const MultiChoiceTutorOptions = ({
  className,
  coins,
  showAnswerInOptions,
  onSelect,
  pickedAnswer,
  correctAnswer,
  options,
  isDictionary = false,
  isTutor = false,
}: MultiChoiceTutorOptionsProps) => {
  const { isMobile, isDesktop } = UseScreenSize();

  const {
    selectedType,
    currentQuestionIndex,
    gameQuestions,
    selectedTopic,
    isInEndOfSkillTest,
  } = useAppSelector(gameSelector);

  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const currentQuestion = useMemo(() => {
    return gameQuestions[currentQuestionIndex];
  }, [currentQuestionIndex, gameQuestions]);

  const { isLevelTest } = useAppSelector(gameSelector);

  const { dashBoard } = useAppSelector(tutorSelector);

  const isRead = selectedType === SkillTypes.Read;

  const optionsElements = options?.map((option, index) => {
    const isOptionSelected =
      option?.toLowerCase() === pickedAnswer?.toLowerCase();

    const isSelectedOptionCorrect =
      pickedAnswer?.toLowerCase() === correctAnswer?.toLowerCase();

    const isAnswer =
      showAnswerInOptions &&
      !isSelectedOptionCorrect &&
      correctAnswer?.toLowerCase() === option?.toLowerCase();

    const isOptionCorrect = isOptionSelected && isSelectedOptionCorrect;
    const isOptionWrong = isOptionSelected && !isSelectedOptionCorrect;

    const optionClasses = classes(classNames.option, {
      'cursor-pointer': !pickedAnswer,
      [classNames.optionCorrect]: isOptionCorrect,
      [classNames.optionWrong]: isOptionWrong,
      [classNames.answer]: isAnswer && !isInEndOfSkillTest,
      [classNames.leftJustify]:
        options.length === 3 &&
        index === 2 &&
        interfaceLanguage?.direction === 'rtl',
      [classNames.tutor]: isTutor,
    });

    const alphabet = String.fromCharCode(index + 97);

    const statusElement = !isOptionSelected ? (
      <span
        className={classes(classNames.alphabet, {
          [classNames[`alphabet-${selectedType}`]]: !isDictionary,
          [classNames.alphabetAnswer]: isAnswer,
        })}
      >
        {alphabet}
      </span>
    ) : (
      <div className={classNames.answerStatus}>
        <SVG src={isOptionCorrect ? correct : wrong} />
      </div>
    );

    return (
      <div
        key={option}
        className={optionClasses}
        onClick={() => (pickedAnswer ? null : onSelect(option))}
      >
        <div
          className={classes(classNames.details, {
            [classNames.tutor]: isTutor && isMobile,
          })}
        >
          {statusElement}
          <span className={classNames.text}>{option}</span>
        </div>
        {currentQuestion !== undefined &&
          !currentQuestion.userData?.achievedCoins &&
          !isInEndOfSkillTest &&
          !isTutor &&
          isOptionCorrect &&
          !isLevelTest &&
          coins && (
            <AnswerReward className={classNames.answerReward} coins={coins} />
          )}
      </div>
    );
  });

  return (
    <div
      className={classes(
        classNames.multiChoiceOptions,
        {
          [classNames.multiChoiceOptionsDictionary]: isDictionary,
          [classNames.multiChoiceOptionsRead]: isRead,
          [classNames.isInEndOfSkillTestRead]: isInEndOfSkillTest && isRead,
          [classNames.isDesktop]: isDesktop && !isTutor, // Ensure this doesn't apply when isTutor is true
          // Apply tutor class only when it's tutor mode and not mobile
          [classNames.tutor]: isTutor && !isMobile && !dashBoard.data,
        },
        className
      )}
    >
      {optionsElements}
    </div>
  );
};

export default MultiChoiceTutorOptions;
