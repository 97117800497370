import { HintsType } from './game';

export enum SkillTypes {
  Speak = 'speak',
  Listen = 'listen',
  Read = 'read',
  Grammar = 'grammar',
  Spelling = 'spelling',
  Vocabulary = 'vocabulary',
}

export type SkillItem = {
  type: SkillTypes;
  backgroundColor?: string;
  textColor?: SkillTypes;
  progressAmount?: string | number;
  progressColor?: string;
  lastTimePlayed?: string | null;
};

export type SkillColors = {
  containerBackgroundColor?: string;
  modalBorderColor?: string;
  modalInnerBorderColor?: string;
};

export type SkillHints = {
  hints: HintsType[];
};

export type SkillImages = {
  medal: string;
  backgroundImage?: string;
};
