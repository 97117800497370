import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import UseSkill from '@/pages/dashboard/skill/UseSkill';

const UseQuizzesModal = () => {
  const dispatch = useAppDispatch();
  const [shouldDisplayModal, setShouldDisplayModal] = useState(false);
  const { selectedQuiz } = useAppSelector(gameSelector);
  const { topics } = UseSkill();

  const onOpenModal = async (topicId: number) => {
    const topic = topics.find((topic) => topic.id === topicId);

    if (topic) {
      dispatch(gameActions.setSelectedTopic(topic));
      setShouldDisplayModal(true);
    }
  };

  const onCloseModal = () => {
    if (!selectedQuiz) {
      dispatch(gameActions.setSelectedTopic(null));
    }

    setShouldDisplayModal(false);
  };

  return { shouldDisplayModal, onOpenModal, onCloseModal };
};

export default UseQuizzesModal;
