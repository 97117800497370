import { useAppSelector } from '@/store';
import classNames from './EditClass.module.scss';
import { authSelector } from '@/store/reducers/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '@/context/DrawerContext';
import { TeacherGroup } from '@/types/user';

import TeacherOnboarding from '../TeacherOnboarding/TeacherOnboarding';
import SectionSeperator from '../SectionSeperator/SectionSeperator';

interface EditClassProps {
  id: string;
}

const EditClass = ({ id }: EditClassProps) => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { user } = useAppSelector(authSelector);

  const [classToEdit, setClassToEdit] = useState<TeacherGroup | null>(null);

  useEffect(() => {
    const classObj = user?.teacherGroups?.find((i) => i.id === id);

    if (classObj) {
      setClassToEdit(classObj);
    }
  }, [id, user]);

  return (
    <div className={classNames.editClassDrawer}>
      <div className={classNames.top}>
        <h1>{t('editClass')}</h1>
        <h2>{t('invitationLinkWontChange')}</h2>
      </div>
      <SectionSeperator className={classNames.seperator} />
      <div className={classNames.body}>
        <TeacherOnboarding
          className={classNames.teacherOnboarding}
          classToEdit={classToEdit}
          showTop={false}
          showCopyBtn={false}
          hasMultipleControls
          isDrawer
          emitCancel={drawer.closeEditClass}
        />
      </div>
    </div>
  );
};

export default EditClass;
