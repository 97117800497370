import classNames from './DictionaryCards.module.scss';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import UseScreenSize from '@/hooks/UseScreenSize';
import { DictionaryWord } from '@/types/dictionary';
import { useEffect, useRef, useState } from 'react';

import DictionaryCard from './DictionaryCard/DictionaryCard';

interface DictionaryCardsProps {
  selectedWord: string;
  cards: DictionaryWord[];
  onValueSearch: (value: string) => void;
}

const DictionaryCards = ({
  selectedWord,
  cards,
  onValueSearch,
}: DictionaryCardsProps) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = UseScreenSize();

  const [offsetFromTop, setOffsetFromTop] = useState(0);
  const swipertRef = useRef<any>(null);

  useEffect(() => {
    // setOffsetFromTop(
    //   (swipertRef.current?.getBoundingClientRect().top ?? 0) + 56
    // );
  }, []);

  const cardsElements = cards.map((card) =>
    isMobile ? (
      <SwiperSlide key={card.id} className={classNames.dictionaryCard}>
        <DictionaryCard {...card} onValueSearch={onValueSearch} />
      </SwiperSlide>
    ) : (
      <DictionaryCard key={card.id} {...card} onValueSearch={onValueSearch} />
    )
  );

  const cardsContainer = isMobile ? (
    <Swiper
      ref={swipertRef}
      slidesPerView={'auto'}
      spaceBetween={0}
      pagination={{
        clickable: true,
        horizontalClass: classNames.swiperPaginationItem,
        bulletClass: classNames.bulletItem,
        bulletActiveClass: classNames.bulletItemActive,
      }}
      modules={[Pagination]}
      className={classNames.cardsSwiper}
      // style={{ height: `calc(100vh - ${offsetFromTop}px)` }}
      draggable={true}
    >
      {cardsElements}
    </Swiper>
  ) : (
    <div className={classNames.cardsContainer}>{cardsElements}</div>
  );
  const isOneResult = cards.length === 1;
  return (
    <div className={classNames.dictionaryCards}>
      {isMobile && (
        <div className={classNames.title}>
          <h2>{t('results')}</h2>
          <span>{`${isOneResult ? t('thereIs') : t('thereAre')} ${
            cards.length
          } ${
            isOneResult
              ? t('resultForTheTranslation')
              : t('resultsForTheTranslation')
          } `}</span>
          <span className="font-bold">{selectedWord}</span>
        </div>
      )}
      {cardsContainer}
    </div>
  );
};

export default DictionaryCards;
