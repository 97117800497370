import { useTranslation } from 'react-i18next';
import classNames from './FinalAssessmentPreparation.module.scss';
import SVG from 'react-inlinesvg';
import { useAppDispatch } from '@/store';
import { gameActions } from '@/store/reducers/game';

import TheButton from '../TheButton/TheButton';

import hat from '@/assets/svg/hat.svg';

const FinalAssessmentPreparation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onStart = () => {
    dispatch(gameActions.setShowPreQuiz(false));
  };

  return (
    <div className={classNames.finalAssessmentPreparation}>
      <div className={classNames.content}>
        <SVG src={hat} />
        <h3>{t('finalEnglishLevelTest')}</h3>
        <h2>{t('whatDidYouLearnAtThisLevel')}</h2>
        <p>{t('thisTestWillHelpYouSeeHowMuchEnglishYouHaveLearnedSoFar')}</p>
        <span className="font-semibold">{t('goodLuck')}</span>
      </div>
      <TheButton
        className={classNames.btn}
        text={t('letsBegin')}
        shouldRecolor={false}
        emitOnClick={onStart}
      />
    </div>
  );
};

export default FinalAssessmentPreparation;
