import UseSkills from '@/hooks/UseSkills';
import classNames from './Waveform.module.scss';
import { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import SVG from 'react-inlinesvg';
import soundPlay from '@/assets/svg/white_speaker.svg';
import classes from 'classnames';
import GameUtils from '@/utils/gameUtils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { gameActions, gameSelector } from '@/store/reducers/game';

type WaveFormProps = {
  audio: string;
};

const WaveForm = ({ audio }: WaveFormProps) => {
  const { skillsAssets } = UseSkills();
  const containerRef = useRef<any>('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const waveSurferRef = useRef<WaveSurfer | null>();

  const [isPlaying, toggleIsPlaying] = useState(false);
  const color = skillsAssets?.listen.modalBorderColor;

  useEffect(() => {
    dispatch(gameActions.toggleSoundWaveStatus(false));
    waveSurferRef.current?.destroy();
    (containerRef.current as HTMLElement).innerHTML = '';

    if (!containerRef.current.innerHTML) {
      GameUtils.getSoundBase64FromUrl(audio).then((value) => {
        const waveSurfer = WaveSurfer.create({
          container: containerRef.current,
          cursorWidth: 0,
          barWidth: 2,
          // barHeight: 2,
          // waveColor: color,
          progressColor: color,

          height: 70,
        });

        waveSurfer.load(value).then(() => {});

        waveSurfer.on('ready', () => {
          dispatch(gameActions.toggleSoundWaveStatus(true));

          waveSurferRef.current = waveSurfer;
        });
      });
    }
  }, [audio]);

  return (
    <div className={classes(classNames.waveform)}>
      <button
        className={classes(classNames.playBtn)}
        aria-label={t('play_sound').toString()}
        onClick={() => {
          waveSurferRef.current?.playPause();

          toggleIsPlaying(waveSurferRef.current?.isPlaying() as boolean);
        }}
        type="button"
      >
        {/* {isPlaying ? 'pause' : 'play'} */}
        <SVG src={soundPlay} color="white" />
      </button>

      <div
        ref={containerRef}
        style={{
          color: skillsAssets?.listen.modalBorderColor,
          flex: 1,
        }}
      />
    </div>
  );
};

export default WaveForm;
