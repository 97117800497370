import classNames from './QuizItem.module.scss';
import classes from 'classnames';
import { SkillTypes } from '@/types/skill';
import SVG from 'react-inlinesvg';

import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import SkillBadge from '../SkillBadge/SkillBadge';
import QuizBadge from '@/components/QuizBadge/QuizBadge';

import rightArrow from '@/assets/svg/right-arrow.svg';
import QuizScore from '@/components/QuizScore/QuizScore';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

interface QuizItemProps {
  shouldDisplayBadge?: boolean;
  medalImg?: string;
  completedLevelsAmount?: number;
  order?: number;
  topic: string;
  title: string;
  skill: SkillTypes;
  badgeColor: string;
  badgeBackgroundColor: string;
  emitStartQuiz?: () => void;
}

const QuizItem = ({
  shouldDisplayBadge = false,
  medalImg,
  completedLevelsAmount,
  order,
  topic,
  title,
  skill,
  badgeColor,
  badgeBackgroundColor,
  emitStartQuiz,
}: QuizItemProps) => {
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const quizBadgeElement = shouldDisplayBadge && (
    <div className={classNames.quizBadge}>
      <QuizBadge
        img={medalImg ?? ''}
        index={order ?? 0}
        isLocked={false}
        isExtraSmall={true}
      />
      <QuizScore
        isLocked={false}
        achievedStars={completedLevelsAmount ?? 0}
        isExtraSmall={true}
      />
    </div>
  );

  return (
    <div className={classNames.quizItem} onClick={emitStartQuiz}>
      <div className={classNames.start}>
        {quizBadgeElement}
        <div className={classNames.text}>
          <span className="opacity-70 font-medium">{topic}</span>
          <DetailSeperator />
          <span className="opacity-80">{title}</span>
        </div>
      </div>
      <div className={classNames.end}>
        <SkillBadge
          skill={skill}
          textColor={badgeColor}
          backgroundColor={badgeBackgroundColor}
        />
        <SVG
          className={classes(classNames.arrow, [
            classNames[`arrow-${interfaceLanguage?.direction}`],
          ])}
          src={rightArrow}
        />
      </div>
    </div>
  );
};

export default QuizItem;
