import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppInputProps } from '@/types/common';
import commonUtils from '@/utils/common';
import { cognitoService } from '@/services/cognito';

const UsePasswordChange = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [hasPasswordUpdated, setHasPasswordUpdated] = useState(false);

  const onChangePasswordFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;

    setChangePasswordFields((prevState) => {
      const updatedState = [...prevState];
      const fieldIndex = updatedState.findIndex((i) => i.name === name);

      updatedState[fieldIndex].value = value.trim();
      updatedState[fieldIndex].isValid = false;
      updatedState[fieldIndex].error = '';

      return updatedState;
    });
  };

  const initialChangePasswordFields = [
    {
      id: 'current_password',
      name: 'currentPassword',
      type: 'password',
      label: 'current_password',
      placeholder: 'password',
      shouldTranslate: true,
      value: '',
      error: '',
      isValid: false,
      emitChange: onChangePasswordFieldChange,
    },
    {
      id: 'new_password',
      name: 'newPassword',
      type: 'password',
      label: 'new_password',
      placeholder: 'password',
      shouldTranslate: true,
      value: '',
      error: '',
      isValid: false,
      showPasswordRequirements: true,
      emitChange: onChangePasswordFieldChange,
    },
    {
      id: 'new_password_verification',
      name: 'newPasswordVerification',
      type: 'password',
      label: 'new_password_verification',
      placeholder: 'confirmPassword',
      shouldTranslate: true,
      value: '',
      error: '',
      isValid: false,
      emitChange: onChangePasswordFieldChange,
    },
  ] as AppInputProps[];

  const [changePasswordFields, setChangePasswordFields] = useState<
    AppInputProps[]
  >(initialChangePasswordFields);

  const onChangePasswordSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    setChangePasswordFields((prevState) => {
      const updatedState = [...prevState];

      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=])[A-Za-z\d\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=]{8,}$/;

      for (let i = 0; i < updatedState.length; i++) {
        const { id, value } = updatedState[i];

        if (!value) continue;

        const isCurrentPassword = id === 'current_password';
        const isNewPasswordField = id === 'new_password';
        const isConfirmPNewasswordField = id === 'new_password_verification';

        const newPasswordIndex = updatedState.findIndex(
          (field) => field.id === 'new_password'
        );

        const newPasswordValue = updatedState[newPasswordIndex].value;

        if (
          isCurrentPassword ||
          isNewPasswordField ||
          isConfirmPNewasswordField
        ) {
          const isPasswordValid = passwordRegex.test(value);

          updatedState[i].isValid = isPasswordValid;

          updatedState[i].error = '';

          if (!isPasswordValid && !isConfirmPNewasswordField) {
            updatedState[i].error = t('invalidPassword');
          } else if (isConfirmPNewasswordField) {
            const isIdentical =
              updatedState[newPasswordIndex].isValid &&
              value === newPasswordValue;

            if (!isIdentical) {
              updatedState[newPasswordIndex].isValid = false;
              updatedState[newPasswordIndex].error = '';

              updatedState[i].isValid = false;
              updatedState[i].error = t('passwordsDontMatch');
            }
          }
        }
      }

      return updatedState;
    });
  };

  useEffect(() => {
    const checkPasswordChangeForm = async () => {
      setIsLoading(true);

      try {
        const isValid = changePasswordFields.every((field) => field.isValid);

        if (isValid) {
          const currentPasswordField =
            changePasswordFields.find(
              (field) => field.id === 'current_password'
            )?.value ?? '';

          const newPasswordField =
            changePasswordFields.find((i) => i.id === 'new_password')?.value ??
            '';

          const userEmail = localStorage.getItem('userEmail') ?? '';

          await cognitoService.updatePassword(
            userEmail,
            currentPasswordField,
            newPasswordField
          );

          commonUtils.showToast(t('password_changed_successfully'), '80px');
          setHasPasswordUpdated(true);
          setChangePasswordFields((prevState) =>
            prevState.map((field) => {
              return {
                ...field,
                value: '',
                error: '',
                isValid: false,
              };
            })
          );
        }

        setIsLoading(false);
      } catch (error: any) {
        const errCode = error as string;
        const errMsg =
          errCode === 'NotAuthorizedException'
            ? 'invalidUsernameOrPassword'
            : errCode;

        if (error) {
          commonUtils.showToast(t(errMsg), '80px');
          setIsLoading(false);
        }
      }
    };

    checkPasswordChangeForm();
  }, [changePasswordFields]);

  return {
    isLoading,
    hasPasswordUpdated,
    changePasswordFields,
    onChangePasswordSubmit,
  };
};

export default UsePasswordChange;
