import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/types/common';
import {
  TutorActivityType,
  TutorElement,
  TutorFeedback,
  TutorGroupMessages,
  TutorMessage,
  TutorQuestionResponse,
  TutorState,
  UserChoice,
} from '@/types/tutor';
import axios from 'axios';
import {
  FilterTypes,
  MessageTypes,
  TriggeredBy,
  TutorSender,
} from '@/types/tutor';
import { HintsResponseData, QuizQuestionResult } from '@/types/game';
import commonUtils from '@/utils/common';
import tutorAxiosInstance from '@/utils/tutor_api';

export const fetchDashboardTutor = createAsyncThunk(
  'tutor/dashboard',
  async (_, { getState }) => {
    try {
      // Get the current Redux state
      const state = getState() as RootState;

      // Extract user ID from the state
      const userID = state.auth.user?.id;
      const getIdToken = localStorage.getItem('idToken');
      const { data } = await tutorAxiosInstance.get(
        `get-activity-from-homepage?userId=${userID}`,
        {
          headers: {
            Authorization: `Bearer ${getIdToken}`,
          },
        }
      );
      // const { data } = await axios.get(
      //   `https://w4vvyimtjwekzcwmvrbrgkgf4i0qrbus.lambda-url.eu-west-1.on.aws/?userId=${userID}`
      // );
      return data;
    } catch (error) {
      // Handle any errors here
      console.error('There was an error!', error);
      throw error; // Rethrow the error to be caught by the rejected action
    }
  }
);

export const shouldInitiateTutor = createAsyncThunk(
  'tutor/initiate',
  async (params: any, { getState }) => {
    try {
      // Send a POST request to the tutor instance API
      //TODO change to post
      const { data } = await tutorAxiosInstance.post(
        'init-tutor',
        { ...params, tutorUsage: 1, sessionTutorCount: 1 } // Todo: remove hardcoded values
      );
      // const { data } = await axios.post(
      //   `https://qtieb5r7g7vsskcxxyjdooshay0cwixi.lambda-url.eu-west-1.on.aws/`,
      //   { ...params, tutorUsage: 1, sessionTutorCount: 1 } // Todo: remove hardcoded values
      // );
      return data.isTutorNeeded;
    } catch (error) {
      // Handle any errors here
      console.error('There was an error!', error);
      return false; // Rethrow the error to be caught by the rejected action
      throw error; // Rethrow the error to be caught by the rejected action
    }
  }
);

// Define an async thunk for fetching tutor process
// get_user_content
export const fetchTutorProcess = createAsyncThunk(
  'tutor/process',
  async (
    {
      quizQuestionResultData,
      hintsData,
    }: {
      quizQuestionResultData?: QuizQuestionResult[];
      hintsData?: HintsResponseData[];
    },
    { getState, dispatch }
  ) => {
    try {
      // Get the current Redux state
      const state = getState() as RootState;
      const _isInGame = state.game.gameQuestions.length > 0;
      // Extract user ID from the state
      const userID = state.auth.user?.id;
      const userChoice = state.tutor.userChoice;
      const userName =
        state.auth.user?.firstName + ' ' + state.auth.user?.lastName;
      const topicId = state.game.selectedTopic?.id;
      const skill = state.game.selectedSkill?.name;

      const user = state.auth.user;
      const currentQuestionIndex = state.game.currentQuestionIndex;
      const gameQuestions = state.game.gameQuestions;
      const currentQuestion = gameQuestions[currentQuestionIndex];
      const selectedQuiz = state.game.selectedQuiz;
      const triggersHistory = state.tutor.triggersHistory;
      const score = +commonUtils.calcPercentage(
        state.game.correctAnswers ?? 0,
        state.game.total_correctAnswers + state.game.total_inCorrectAnswers ?? 0
      );

      const totalHintUsageCount = quizQuestionResultData?.reduce(
        (acc, curr) => acc + curr.hintUsageCount,
        0
      );
      // Send a POST request to the tutor instance API
      let payload = {};
      if (_isInGame) {
        payload = {
          quiz_iteration: state.game.quiz_iteration,
          userId: userID,
          session_id: state.tutor.tutorSessionId || '',
          topicId,
          skill,
          triggeredBy: TriggeredBy.Manual.toLocaleLowerCase(),
          triggersHistory,
          question_id: currentQuestion?.questionId,
          quizId: selectedQuiz?.id,
          userChoice: {
            content_id: state.tutor.userChoice?.content_id ?? null,
            feedback: null,
            // feedback: state.tutor.user_feedback ?? 'noResponse',
            question_response: state.tutor.question_response,
          },
          userName,
          question_level: user?.level || 0,
          activity: {
            Session_questions: state.game.quizQuestionResultData?.map(
              (question, index) => ({
                isCorrect: question.isCorrect,
                isHintUsed:
                  state.game.hintsData &&
                  state.game.hintsData[index]?.hintUsageCount > 0,
                isTimesUp: question.isTimesUp,
                questionId: question.questionId,
                questionLevel: 0, // Todo: get question level
                responseTime: question.responseTime,
                wrongAnswer: question.wrongAnswer,
                wrongAnswerLevel: 0, // Todo: get wrong answer level
              })
            ),
            quizId: selectedQuiz?.id,
            quizName: selectedQuiz?.name,
            gameType: selectedQuiz?.gameType,
            userLevel: user?.level || 0,
            averageScore: score,
            correctAnswers: state.game.correctAnswers,
            wrongAnswers: state.game.inCorrectAnswers,
            tutorUsage: 0.4,
            hintUsageCount: totalHintUsageCount,
            attemptsQuiz: selectedQuiz?.userData?.attempts || 0,
            sessionTutorCount: 2,
          },
        };
      } else {
        payload = {
          userId: userID,
          session_id: state.tutor.tutorSessionId || '',
          userChoice: {
            content_id: state.tutor.userChoice?.content_id ?? null,
            feedback: null,
            // feedback: state.tutor.user_feedback ?? 'noResponse',
            question_response: state.tutor.question_response,
          },
          triggeredBy: TriggeredBy.HomePage.toLocaleLowerCase(),
          userName,
          topicId,
          skill,
          triggersHistory,
          question_level: user?.level || 1,
          quizId: selectedQuiz?.id,
          activity: {
            Session_questions: [],
            quizName: selectedQuiz?.name,
          },
        };
      }
      const idToken = localStorage.getItem('idToken');
      const { data } = await tutorAxiosInstance.post(
        'get-user-content',
        payload,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      // const { data } = await axios.post(
      //   `https://wgnwpa7j2i5aqyjwqb4d6p5boq0wsfmn.lambda-url.eu-west-1.on.aws/`,
      //   payload
      // );

      return data.data;
    } catch (error) {
      // Handle any errors here
      console.error('There was an error!', error);
      throw error; // Rethrow the error to be caught by the rejected action
    }
  }
);

// deprecated
//store_activity
export const fetchTutorActivity = createAsyncThunk(
  'tutor/activity',
  async (
    { activity_type }: { activity_type: TutorActivityType },
    { getState }
  ) => {
    // Get the current Redux state
    const state = getState() as RootState;

    // Extract user ID from the state
    const isInGame = state.game.gameQuestions.length > 0;
    const user = state.auth.user;
    const tutor = state.tutor;
    const userID = user?.id;
    const currentQuestionIndex = isInGame
      ? state?.game.currentQuestionIndex
      : null;
    const quizQuestionResultData = state?.game.quizQuestionResultData;
    const gameQuestions = state?.game.gameQuestions;
    const currentQuestion = isInGame
      ? gameQuestions[currentQuestionIndex ?? 0]
      : null;
    const pickedAnswer = state?.game.pickedAnswer;
    const selectedQuiz = state?.game.selectedQuiz;
    const tutorSessionId = state?.tutor.tutorSessionId;
    const seconds = isInGame
      ? quizQuestionResultData[currentQuestionIndex ?? 0]?.responseTime
      : null;
    const now = new Date();
    const answeredCorrectly = pickedAnswer == currentQuestion?.answer;
    const getStartTime = () => {
      if (activity_type == TutorActivityType.tutor) {
        return null;
      }
      if (seconds != undefined) {
        return new Date(now.getTime() - seconds * 1000).getTime();
      }
      return null;
    };
    let activity_data = {
      hint_used: isInGame
        ? [...new Set(state.game.usedHintsPerQuestion)]
        : null,
      n_question: isInGame ? currentQuestionIndex ?? 0 + 1 : null,
      is_correct: answeredCorrectly,
      tutor_suggested_triggered: tutor.suggested_triggered,
      choosen_answer: pickedAnswer,
      correct_answer: currentQuestion?.answer,
      n_mistakes_in_quiz: state.game.inCorrectAnswers,
      time_elapsed:
        activity_type == TutorActivityType.tutor
          ? null
          : quizQuestionResultData[currentQuestionIndex ?? 0] != undefined
          ? quizQuestionResultData[currentQuestionIndex ?? 0].responseTime
          : null,
      t_started: getStartTime(),
      t_end: now.getTime(),
    } as { [key: string]: any };

    if (activity_type == TutorActivityType.tutor) {
      activity_data = {
        ...activity_data,
        element_id: tutor.userChoice?.content_id,
        suggested_sequence: tutor.elements,
        helpful_feedback: tutor.user_feedback,
      };
    } else {
      activity_data = {
        ...activity_data,
        entered_tutor_trigger: tutor.triggeredBy,
      };
    }

    const baseData = {
      user_id: userID,
      timestamp_created: new Date().getTime(),
      question_level: user?.level,
      user_session_path_id:
        activity_type == TutorActivityType.path
          ? state.game.quiz_iteration
          : null,
      user_tutor_session_id: null,
      activity_type: activity_type,
      question_id: currentQuestion?.questionId,
      quiz_id: selectedQuiz?.id,
      content_level: null,
      level_calculated: null,
      activity_data: activity_data,
    };
    const idToken = localStorage.getItem('idToken');
    const { data } = await tutorAxiosInstance.post(
      'store-user-activity',
      baseData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    // const { data } = await axios.post(
    //   `https://445kenwsb77kvxfm5dmnbxndsq0pkesq.lambda-url.eu-west-1.on.aws/`,
    //   baseData
    // );
    return data;
  }
);

const tutorState: TutorState = {
  showAssistPopup: false,
  showTutorMuteConfirmPopup: false,
  showTutorExitConfirmPopup: false,
  showTutorShiftPopup: false,
  showTutorMute: false,
  mute: false,
  showTutor: false,
  isTutorNeeded: false,
  triggeredBy: null,
  userChoice: null,
  messages: [],
  elements: [],
  activitySessionId: null,
  timeStart: calculateTimeStamp(),
  loading: false,
  error: null,
  didInitiated: false,
  didMinimized: false,
  didSelfOpen: false,
  tutorSessionId: null,
  dashBoard: {
    isLoading: false,
    error: null,
    data: null,
  },
  triggersHistory: [],
  question_response: null,
  user_feedback: null,
  suggested_triggered: null,
};

const tutor = createSlice({
  name: 'Tutor',
  initialState: tutorState,
  reducers: {
    tutorMuteOn: (state) => {
      state.showTutorMute = true;
    },
    tutorMuteOff: (state) => {
      state.showTutorMute = false;
    },
    openTutor: (state, action: PayloadAction<TriggeredBy>) => {
      state.showTutor = true;
      state.triggeredBy = action.payload;
    },
    closeTutor: (state) => {
      state.userChoice = null;
      state.showTutor = false;
      state.triggeredBy = null;
      // state.isTutorNeeded = false;
      state.showAssistPopup = false;
      state.showTutorMuteConfirmPopup = false;
      state.showTutorExitConfirmPopup = false;
      state.showTutorShiftPopup = false;
    },
    setIsTutorNeeded: (state, action: PayloadAction<boolean>) => {
      state.isTutorNeeded = action.payload;
    },
    openTutorAssist: (state) => {
      state.showAssistPopup = true;
    },
    openTutorMuteConfirmPopup: (state) => {
      state.showTutorMuteConfirmPopup = true;
      state.showAssistPopup = false;
    },
    openTutorExitConfirmPopup: (state) => {
      state.showTutorExitConfirmPopup = true;
    },
    closeTutorExitConfirmPopup: (state) => {
      state.showTutorExitConfirmPopup = false;
    },
    closeTutorAssist: (state) => {
      state.showAssistPopup = false;
    },
    muteNotifications: (state) => {
      state.mute = true;
    },
    unmuteNotifications: (state) => {
      state.mute = false;
    },
    setGroupMessages: (state, action: PayloadAction<TutorGroupMessages>) => {
      state.messages = [...state.messages, action.payload];
    },
    setNewElements: (state, action: PayloadAction<TutorElement[]>) => {
      state.elements = action.payload;
    },
    openShiftPopup: (state) => {
      state.showTutorShiftPopup = true;
    },
    closeShiftPopup: (state) => {
      state.showTutorShiftPopup = false;
    },
    updateUserChoice: (state, action: PayloadAction<UserChoice>) => {
      state.userChoice = action.payload;
    },
    updateHasSeen: (state, action: PayloadAction<number>) => {
      const messageIndexToUpdate = action.payload;

      // Check if there are any messages in the state.
      if (state.messages.length > 0) {
        // Identify the index of the last group of messages.
        const lastGroupMessageIndex = state.messages.length - 1;

        // Make a copy of the last group of messages to avoid direct state mutation.
        const lastGroupMessage = { ...state.messages[lastGroupMessageIndex] };

        // Validate that the message index is within a valid range.
        if (
          messageIndexToUpdate >= 0 &&
          messageIndexToUpdate < lastGroupMessage.message.length
        ) {
          // Update the 'hasSeen' property of the specified message.
          lastGroupMessage.message[messageIndexToUpdate] = {
            ...lastGroupMessage.message[messageIndexToUpdate],
            hasSeen: true,
          };

          // Update the state with the modified group of messages.
          state.messages[lastGroupMessageIndex] = lastGroupMessage;
        }
      }
    },

    resetMessages: (state) => {
      // Remove the "tutor-session" cookie
      // document.cookie =
      //   "tutor-session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      // state.showTutor = false;
      state.messages = [];
      // state.elements = [];
      // state.userChoise = null;
    },
    pushTriggresHistory: (state, action: PayloadAction<any>) => {
      state.triggersHistory = [...state.triggersHistory, action.payload];
    },
    resetDashboard: (state) => {
      state.dashBoard = {
        isLoading: false,
        error: null,
        data: null,
      };
    },
    // setUserChoice: (state, action: PayloadAction<TutorElement>) => {},
    setQuestionResponse: (
      state,
      action: PayloadAction<TutorQuestionResponse>
    ) => {
      state.question_response = action.payload;
    },
    updatePickedAnswer: (
      state,
      action: PayloadAction<{
        userAnswer: string;
        lastMessageIndex: number;
        lastGroupMessageIndex: number;
      }>
    ) => {
      const { userAnswer, lastMessageIndex, lastGroupMessageIndex } =
        action.payload;

      // Check if there are any messages in the state.
      if (state.messages.length > 0) {
        // Make a copy of the last group of messages to avoid direct state mutation.
        const lastGroupMessage = { ...state.messages[lastGroupMessageIndex] };

        // Validate that the message index is within a valid range.
        if (
          lastMessageIndex >= 0 &&
          lastMessageIndex < lastGroupMessage.message.length
        ) {
          // Update the 'userAnswer' property of the specified message.
          lastGroupMessage.message[lastMessageIndex] = {
            ...lastGroupMessage.message[lastMessageIndex],
            pickedAnswer: userAnswer,
          };

          // Update the state with the modified group of messages.
          state.messages[lastGroupMessageIndex] = lastGroupMessage;
        }
      }
    },
    setUserFeedback: (state, action: PayloadAction<TutorFeedback>) => {
      state.user_feedback = action.payload;
    },
    setSuggestedTriggered: (state, action: PayloadAction<TriggeredBy>) => {
      state.suggested_triggered = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardTutor.pending, (state) => {
        state.dashBoard.isLoading = true;
        state.dashBoard.error = null;
      })
      .addCase(fetchDashboardTutor.fulfilled, (state, action) => {
        state.dashBoard.isLoading = false;
        const { user_id, ...dataWithoutUserId } = action.payload;
        state.dashBoard.data = dataWithoutUserId;
      })
      .addCase(fetchDashboardTutor.rejected, (state, action) => {
        state.dashBoard.isLoading = false;
        state.dashBoard.error = 'error message test';
      })
      .addCase(shouldInitiateTutor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(shouldInitiateTutor.fulfilled, (state, action) => {
        state.loading = false;
        state.isTutorNeeded = action.payload;
      })
      .addCase(shouldInitiateTutor.rejected, (state, action) => {
        state.loading = false;
        state.error = 'error message test';
      })
      .addCase(fetchTutorProcess.pending, (state) => {
        // Check if the last message in the conversation is from the tutor.
        // If so, there's no need to add another loading message.
        if (isLastMessageTutor(state)) {
          return;
        }

        // Indicate that a tutor-related process is currently loading.
        state.loading = true;

        // Create a tutor message group to indicate a loading state.
        // This helps in providing feedback to the user that the system is processing.
        const tutorLoadingMessage: TutorGroupMessages = {
          sender: TutorSender.tutor,
          message: [
            { hasSeen: false, loading: true, type: MessageTypes.Loading },
          ],
          timeStamp: calculateTimeStamp(), // Ensure this function is defined to generate the current timestamp.
          includeThumbs: false, // No thumbs needed for a loading message.
        };

        // Append the loading message to the messages array.
        // This shows a visual indicator (like a spinner) to the user.
        state.messages = [...state.messages, tutorLoadingMessage];

        // Clear any existing errors in the tutor state.
        // This ensures that previous errors don't persist in the new loading state.
        state.error = null;
      })
      .addCase(fetchTutorProcess.fulfilled, (state, action) => {
        // Check if this is the first message from the tutor.
        const isInitialMessage = state.messages.length === 1;

        // Set the start time for the tutor session if it's the initial message.
        if (isInitialMessage) {
          state.timeStart = calculateTimeStamp();
        }

        // Process the elements received in the action payload.
        let newElements: TutorElement[] = [];

        action.payload.elements.forEach((element: TutorElement) => {
          if (
            element.type === 'conditional_group' &&
            Array.isArray(element.value)
          ) {
            // Extract the conditional group details (on_true and on_false).
            const conditionalElement = element.value[1] as any; // Use 'any' temporarily.
            if (
              conditionalElement &&
              conditionalElement.on_true &&
              conditionalElement.on_false
            ) {
              element.wasClear = {
                true: conditionalElement.on_true as TutorElement[],
                false: conditionalElement.on_false as TutorElement[],
              };
            }
            newElements = newElements.concat(element.value[0]);
          } else {
            newElements.push(element);
          }
        });

        // Update the state elements with the processed list.
        state.elements = newElements;

        // Avoid adding a new tutor message if the last message is already from the tutor.
        if (!isInitialMessage && isLastMessageTutor(state)) {
          return;
        }

        // Set loading to false as the data fetching is complete.
        state.loading = false;

        // Create and add the new tutor message group.
        const tutorMessageGroup: TutorGroupMessages = {
          sender: TutorSender.tutor,
          message: [
            {
              text: action.payload?.message || '',
              hasSeen: false,
              loading: false,
              type: MessageTypes.Response,
            },
          ],
          // timeStamp: action.payload?.timeStamp || '', // Default to an empty string if undefined.
          timeStamp: calculateTimeStamp(), // Default to an empty string if undefined.
          includeThumbs: action.payload?.include_thumbs || false, // Default to false if undefined.
        };

        state.messages = [...state.messages.slice(0, -1), tutorMessageGroup];

        // Retrieve the tutor-session cookie value and update the state.
        const tutorSessionSid = getSidFromCookie('tutor-session');

        if (!state.tutorSessionId && tutorSessionSid) {
          state.tutorSessionId = tutorSessionSid;
        }
      })

      .addCase(fetchTutorProcess.rejected, (state, action) => {
        state.loading = false;
        state.error = 'error message test';
      })
      .addCase(fetchTutorActivity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTutorActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.activitySessionId = 'sessino id test';
        state.timeStart = calculateTimeStamp();
      })
      .addCase(fetchTutorActivity.rejected, (state, action) => {
        state.loading = false;
        // Handle error state here
        state.error = 'error message test';
      });
  },
});

export const tutorActions = tutor.actions;

export const tutorSelector = (state: RootState) => state.tutor;

export default tutor.reducer;

/**
 * Checks if the last message in the state's message array was sent by the tutor.
 *
 * @param {TutorState} state - The current state of the tutor.
 * @returns {boolean} - Returns true if the last message was sent by the tutor, otherwise false.
 */
function isLastMessageTutor(state: TutorState): boolean {
  return (
    state.messages.length > 0 &&
    state.messages[state.messages.length - 1].sender === TutorSender.tutor
  );
}

/**
 * Generates a timestamp string based on the current date and time in 12-hour format.
 *
 * @returns {string} - The formatted timestamp as a string.
 */
function calculateTimeStamp(): string {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const isPM = hours >= 12;
  const formattedHours = hours % 12 || 12; // Adjust for 12-hour clock format

  return `${formattedHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${isPM ? 'PM' : 'AM'}`;
}

/**
 * Extracts and returns a specific session ID (sid) from the browser cookies.
 *
 * @param {string} cookieName - The name of the cookie to extract the session ID from.
 * @returns {string | null} - The extracted session ID, or null if not found.
 */
function getSidFromCookie(cookieName: string): string | null {
  try {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (const cookie of cookieArray) {
      const trimmedCookie = cookie.trim();
      if (trimmedCookie.startsWith(name)) {
        const payload = trimmedCookie.substring(name.length);
        if (payload.startsWith('s:')) {
          const sidWithDot = payload.substring(2);
          const sidParts = sidWithDot.split('.');
          return sidParts.length > 0 ? sidParts[0] : null;
        }
      }
    }
    return null; // Return null if no matching cookie is found
  } catch (error) {
    console.error('Error parsing cookies:', error);
    return null; // Return null in case of an error
  }
}
