import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import StudentCard from './StudentCard/StudentCard';
import classNames from './StudentsCards.module.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { SortByOption } from '@/types/teacher-dashboard';
import { TeacherGroup, UserState } from '@/types/user';
import UseScreenSize from '@/hooks/UseScreenSize';

import SearchStudents from '../../SearchStudents/SearchStudents';
import OnboardingInvitation from '@/components/OnboardingInvitation/OnboardingInvitation';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import upArrowLong from '@/assets/svg/up-arrow-long.svg';
import downArrowLong from '@/assets/svg/down-arrow-long.svg';
import GenerateReport from '../UserData/GenerateReport/GenerateReport';
import { ReportTypes } from '../../../types/generate-report';

interface StudentsCardsProps {
  students: UserState[];
  searchValue: string;
  currentClassroom: TeacherGroup;
  emitShowDataModal: (studentId: string) => void;
  setSearchValue: (value: string) => void;
}

const StudentsCards = ({
  students,
  searchValue,
  emitShowDataModal,
  setSearchValue,
  currentClassroom,
}: StudentsCardsProps) => {
  const { t } = useTranslation();
  const { isDesktop } = UseScreenSize();

  const sortByOptions: SortByOption[] = [
    {
      key: 'progress',
      value: 'asc',
      icon: upArrowLong,
    },
    {
      key: 'progress',
      value: 'desc',
      icon: downArrowLong,
    },
  ];

  const [currentOption, setCurrentOption] = useState(sortByOptions[0]);
  const [shouldDisplayDropdown, setShouldDisplayDropdown] = useState(false);
  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const onToggleDropdown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShouldDisplayDropdown((prevState) => !prevState);
  };

  const onCloseDropdown = () => {
    setShouldDisplayDropdown(false);
  };

  const onSelectedOption = (
    e: React.MouseEvent<HTMLElement>,
    option: SortByOption
  ) => {
    e.stopPropagation();
    onCloseDropdown();
    setCurrentOption(option);
  };

  const sortByOptionsElements = sortByOptions.map((option, index) => {
    return (
      <div
        key={option.value}
        className={classNames.sortByOption}
        onClick={($event) => onSelectedOption($event, option)}
      >
        <span>{t(option.key)}</span>
        <SVG className={classNames.optionIcon} src={option.icon} />
      </div>
    );
  });

  const sortByDropdown = shouldDisplayDropdown && (
    <div className={classNames.sortByDropdown}>{sortByOptionsElements}</div>
  );

  const arrowSrc = shouldDisplayDropdown ? upArrow : downArrow;

  const onSortStudents = (curr: UserState, next: UserState) => {
    const isSortByProgress = currentOption.key === 'progress';

    if (isSortByProgress) {
      const currentProgress = curr.quizzesProgress as number;
      const nextProgress = next.quizzesProgress as number;

      return currentOption.value === 'asc'
        ? currentProgress - nextProgress
        : nextProgress - currentProgress;
    }

    return 1;
  };

  const studentsCards = students
    .filter(
      (student) =>
        student.firstName
          ?.toLowerCase()
          .startsWith(searchValue.toLowerCase()) ||
        student.lastName?.toLowerCase().startsWith(searchValue.toLowerCase())
    )
    .sort(onSortStudents)
    .map((student, index) => {
      const isLast = index === students.length - 1;

      return isDesktop ? (
        <div key={student.userName}>
          <StudentCard {...student} emitShowDataModal={emitShowDataModal} />
          {!isLast && <SectionSeperator className="my-10" />}
        </div>
      ) : (
        <StudentCard
          key={student.userName}
          {...student}
          emitShowDataModal={emitShowDataModal}
        />
      );
    });
  return (
    <div className={classNames.studentsCards}>
      {isDesktop && (
        <div className={classNames.topBar}>
          <div className={classNames.searchAndSort}>
            <SearchStudents
              searchValue={searchValue}
              onInputChange={onInputChange}
            />
            <div className={classNames.sortByContainer}>
              <div
                className={classNames.sortBySelect}
                onClick={onToggleDropdown}
              >
                <span className={classNames.text}>{t('sortBy')}</span>
                <div className="flex items-center gap-1">
                  <span>{t(currentOption.key)}</span>
                  <SVG className={classNames.icon} src={currentOption.icon} />
                </div>
                <SVG className={classNames.arrow} src={arrowSrc} />
              </div>
              {sortByDropdown}
            </div>
          </div>
          {/* <OnboardingInvitation
            className={classNames.onboardingInvitation}
            link={currentClassroom?.invitationLinkForStudents}
            isBtn
          /> */}
          <GenerateReport
            currentClassroom={currentClassroom}
            reportType={ReportTypes.class}
          />
        </div>
      )}
      <div className={classNames.studentsCardsContainer}>{studentsCards}</div>
    </div>
  );
};

export default React.memo(StudentsCards);
