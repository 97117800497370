import React, { useEffect, useMemo } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import TutorDesktop from '@/components/Tutor/TutorDashboard/TutorDesktop/TutorDesktop';
import TutorMobile from '@/components/Tutor/TutorDashboard/TutorMobile/TutorMobile';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  fetchDashboardTutor,
  tutorActions,
  tutorSelector,
} from '@/store/reducers/tutor';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import { Card } from '@/types/tutor';
import useTutor from '@/hooks/useTutor';

const Tutor = () => {
  const { isMobile } = UseScreenSize();
  const dispatch = useAppDispatch();
  const { handleExitConfirmPopup, isLoadingDashBaord, dashBoardData } =
    useTutor();

  useEffect(() => {
    dispatch(fetchDashboardTutor());
  }, []);

  const props = {
    handleExitConfirmPopup,
    data: dashBoardData,
  };

  return isLoadingDashBaord ? (
    <div className="h-full w-full flex justify-center">
      <AppLoaderCircle />
    </div>
  ) : isMobile ? (
    <TutorMobile {...props} />
  ) : (
    <TutorDesktop {...props} />
  );
};

export default React.memo(Tutor);
