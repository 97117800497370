import classNames from './ProgressGroup.module.scss';
import classes from 'classnames';
import { SkillProgress } from '@/types/progress';
import { SkillTypes } from '@/types/skill';

import ProgressItem from './ProgressItem/ProgressItem';
import UseScreenSize from '@/hooks/UseScreenSize';
import React from 'react';

interface ProgressGroupProps {
  className?: string;
  group: [string, SkillProgress | null][];
}

const ProgressGroup = ({ className, group }: ProgressGroupProps) => {
  const { isDesktop } = UseScreenSize();
  const groupElements = group.map((entry, index) => {
    const key = entry[0];
    const value = entry[1];

    return (
      <React.Fragment key={key}>
        <ProgressItem
          key={key}
          skill={key as SkillTypes}
          actual={value?.actual}
          total={value?.total}
        />
        {isDesktop && index !== group.length - 1 && (
          <div className={classNames.separator} />
        )}
      </React.Fragment>
    );
  });

  return (
    <div className={classes(classNames.progressGroup, className)}>
      {groupElements}
    </div>
  );
};

export default ProgressGroup;
