import React from 'react';
import UseDictionary from '@/hooks/UseDictionary';
import UseMyStudents from './UseMyStudents';

import DictionaryDesktop from '@/components/Dictionary/DictionaryDesktop/DictionaryDesktop';
import DictionaryDesktopComponents from '@/components/Dictionary/DictionaryDesktopComponents/DictionaryDesktopComponents';
import StudentsData from '@/components/StudentsData/StudentsData';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import AppBanner from '@/components/AppBanner';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

const MyStudentsViewDesktop = () => {
  const { displayDictionary } = useAppSelector(authSelector);

  const {
    searchValue,
    selectedWord,
    recentTranslations,
    autoCompleteResults,
    suggestions,
    cards,
    phrases,
    pictureOfTheDay,
    options,
    question,
    quizzes,
    isSearching,
    onInputChange,
    onClear,
    onBack,
    onSelectOption,
    onSearch,
    onValueSearch,
  } = UseDictionary();

  const {
    isLoading,
    isLoadingProgress,
    students,
    classrooms,
    statistics,
    selectedStudent,
    showModal,
    showAssessments,
    currentClassroom,
    searchValue: studentsSearchValue,
    showSkillData,
    selectedSkill,
    showEdit,
    onShowModal,
    onHideModal,
    onSetCurrentClassroom,
    onShowSkillData,
    onHideSkillData,
    onShowAssessments,
    onCloseEdit,
    onShowEdit,
    onSaveEdit,
    setSearchValue,
    onHideAssessment,
  } = UseMyStudents();

  const dictionaryComponents = (
    <DictionaryDesktopComponents
      selectedWord={selectedWord}
      cards={cards}
      phrases={phrases}
      question={question}
      pictureOfTheDay={pictureOfTheDay}
      options={options}
      quizzes={quizzes}
      emitBack={onBack}
      onValueSearch={onValueSearch}
    />
  );

  return (
    <>
      {displayDictionary && (
        <DictionaryDesktop
          searchValue={searchValue}
          recentTranslations={recentTranslations}
          results={autoCompleteResults}
          suggestions={suggestions}
          onInputChange={onInputChange}
          onClear={onClear}
          onSelectOption={onSelectOption}
          onSearch={onSearch}
        />
      )}
      {/* {displayDictionary ? (
        <DictionaryDesktop
          searchValue={searchValue}
          recentTranslations={recentTranslations}
          results={autoCompleteResults}
          suggestions={suggestions}
          onInputChange={onInputChange}
          onClear={onClear}
          onSelectOption={onSelectOption}
          onSearch={onSearch}
        />
      ) : (
        <AppBanner />
      )} */}
      {isSearching ? (
        <AppLoaderCircle className="mx-auto" />
      ) : selectedWord ? (
        dictionaryComponents
      ) : (
        <StudentsData
          students={students}
          classrooms={classrooms}
          isLoading={isLoading}
          isLoadingProgress={isLoadingProgress}
          statistics={statistics}
          selectedStudent={selectedStudent}
          showModal={showModal}
          showAssessments={showAssessments}
          currentClassroom={currentClassroom}
          searchValue={studentsSearchValue}
          showSkillData={showSkillData}
          selectedSkill={selectedSkill}
          showEdit={showEdit}
          onShowModal={onShowModal}
          onHideModal={onHideModal}
          onSetCurrentClassroom={onSetCurrentClassroom}
          onShowSkillData={onShowSkillData}
          onHideSkillData={onHideSkillData}
          onShowAssessments={onShowAssessments}
          onHideAssessment={onHideAssessment}
          onCloseEdit={onCloseEdit}
          onShowEdit={onShowEdit}
          onSaveEdit={onSaveEdit}
          setSearchValue={setSearchValue}
        />
      )}
    </>
  );
};

export default React.memo(MyStudentsViewDesktop);
