import React, { useEffect } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import MainViewDesktop from './MainViewDesktop';
import MainViewMobile from './MainViewMobile';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';

const Games = () => {
  const { isMobile } = UseScreenSize();
  const dispatch = useAppDispatch();
  const { selectedType } = useAppSelector(gameSelector);
  const { skillSettings } = useAppSelector(authSelector);

  useEffect(() => {
    const initialSkillType = (localStorage.getItem('selected-skill') ||
      SkillTypes.Grammar) as SkillTypes;

    if (!selectedType) {
      const skill =
        skillSettings.find(
          (i) => i.name?.toLocaleLowerCase() === initialSkillType
        ) ?? null;

      dispatch(
        gameActions.setSelectedSkill({ type: initialSkillType, skill: skill })
      );
      localStorage.removeItem('selected-skill');
    }
  }, []);

  if (isMobile) {
    return <MainViewMobile />;
  }

  return <MainViewDesktop />;
};

export default React.memo(Games);
