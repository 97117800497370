import UseThemeColors from '@/hooks/UseThemeColors';
import React from 'react';

const PageWrap = ({ children }: { children: React.ReactNode }) => {
  const { color } = UseThemeColors();

  return (
    <div
      style={{ backgroundColor: color('pageBackground') }}
      className="flex flex-1 flex-col gap-10 p-7"
    >
      {children}
    </div>
  );
};

export default PageWrap;
