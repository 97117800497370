import UseLevelTest from './UseLevelTest';
import LevelTestIntro from '@/components/LevelTest/LevelTestIntro/LevelTestIntro';
import LevelTestLoading from '@/components/LevelTest/LevelTestLoading/LevelTestLoading';
import LevelTestSummary from '@/components/LevelTest/LevelTestSummary/LevelTestSummary';
import classes from './LevelTestView.module.scss';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useLocation } from 'react-router-dom';
import {
  levelTestActions,
  levelTestSelector,
} from '@/store/reducers/level-test';
const LevelTestView = () => {
  const { user } = useAppSelector(authSelector);
  const location = useLocation();
  const { showSummary, isSubmitted, onSubmit, onBegin, setShowSummary } =
    UseLevelTest();
  const reVisited = location.state;

  const { isFinishLevelTest } = useAppSelector(levelTestSelector);

  let content = <LevelTestIntro reVisited={reVisited} emitBegin={onBegin} />;
  if (isFinishLevelTest) {
    content = (
      <LevelTestSummary
        firstname={user?.firstName}
        level={user?.level}
        coins={user?.achievements.coins}
      />
    );
  }

  return <div className={classes.container}>{content}</div>;
};

export default LevelTestView;
