import { MeResponse } from '@/types/response';
import { AuthService } from '@/types/services';
import axiosInstance from '@/utils/api';

import config from '@/config';

export const authService: AuthService = {
  me: async (fromToLanguageParams, userId) => {
    const resp: MeResponse | null = await axiosInstance.get(
      `user-metadata/${config.apiVersion}/${fromToLanguageParams}/${userId}?isLoginRequest=true`
    );
    return resp;
  },
};
