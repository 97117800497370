import { SkillTypes } from '@/types/skill';
import { Topic } from '@/types/topic';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { View } from '@/types/common';

import Navbar from '@/components/Navbar/Navbar';
import BadgesSection from '@/components/BadgesSection/BadgesSection';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import QuizzesSection from '@/components/QuizzesSection/QuizzesSection';
import UserInfo from '@/components/UserInfo/UserInfo';
import ViewToggler from '@/components/ViewToggler/ViewToggler';
import UserStreak from '@/components/UserStreak/UserStreak';
import AchievementsSection from '../AchievementsSection/AchievementsSection';
import UseMyProfile from '@/hooks/UseMyProfile';
import { useTranslation } from 'react-i18next';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import { tutorSelector } from '@/store/reducers/tutor';

interface SidebarProps {
  viewTabs: View[];
  emitSelectView: (view: View) => void;
}

const UserSidebar = ({ viewTabs, emitSelectView }: SidebarProps) => {
  const { user } = useAppSelector(authSelector);
  const { t } = useTranslation();
  const { bestOfTheWeek } = UseMyProfile();
  const currentRoute = UseCurrentRoute();
  const { showTutor } = useAppSelector(tutorSelector);

  const badges = [
    {
      skill: SkillTypes.Grammar,
      topic: { name: 'Verbs and stuff', nameLocal: 'Verbs and stuff' } as Topic,
    },
    {
      skill: SkillTypes.Listen,
      topic: { name: 'Syntax', nameLocal: 'Syntax' } as Topic,
    },
    {
      skill: SkillTypes.Spelling,
      topic: { name: 'Vowels', nameLocal: 'Vowels' } as Topic,
    },
  ];

  return (
    <>
      <Navbar decreaseOpacity={false} />
      {user && (
        <UserInfo
          fullname={`${user.firstName} ${user.lastName}`}
          profileImage={user.avatar?.avatarName}
          backgroundColor={user.avatar?.avatarColor}
          level={user.level}
          grade={user.grade}
          badges={user.achievements?.badges}
          coins={user.achievements?.coins}
        />
      )}
      <ViewToggler
        className="mt-10"
        viewTabs={viewTabs}
        emitSelectView={emitSelectView}
      />
      {bestOfTheWeek !== null && (
        <AchievementsSection
          firstname={user?.firstName ?? 'N/A'}
          achievement={bestOfTheWeek}
          // achievement={{
          //   skill: SkillTypes.Grammar,
          //   topic: {
          //     name: 'Verbs and stuff',
          //     nameLocal: 'Verbs and stuff',
          //   } as Topic,
          //   rate: 3,
          // }}
        />
      )}
      {/* <SectionSeperator className="my-10" />
      <BadgesSection badges={badges} /> */}

      {!showTutor && (
        <>
          <SectionSeperator className="my-10" />
          <QuizzesSection />
          {currentRoute !== ROUTE_NAMES.PROFILE && (
            <SectionSeperator className="my-10" />
          )}

          {user?.streak && currentRoute !== ROUTE_NAMES.PROFILE && (
            <UserStreak
              className="mt-9"
              streak={user.streak}
              title={t('userStreak.title') ?? ''}
            />
          )}
        </>
      )}
    </>
  );
};

export default UserSidebar;
