import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import userThunks from '@/store/thunks/user';
import { useTranslation } from 'react-i18next';
import topicsThunks from '@/store/thunks/topics';
import UserUtils from '@/utils/user';
import { cognitoService } from '@/services/cognito';
import { CognitoSession } from '@/types/user';
import commonUtils from '@/utils/common';

const UseCheckAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loggedIn, user, token, appLoaded } = useAppSelector(authSelector);
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [fetchingProgress, setFetchingProgress] = useState(false);

  const fetchProgressAndSkills = async () => {
    await Promise.all([
      dispatch(userThunks.fetchSkills()).unwrap(),
      dispatch(userThunks.fetchLevels()).unwrap(),
      dispatch(userThunks.fetchLanguages()).unwrap(),
      dispatch(userThunks.fetchImprovableRecentQuizzes()).unwrap(),
      dispatch(userThunks.fetchComingUpQuizzes()).unwrap(),

      dispatch(topicsThunks.prefetchSkillTopics()).unwrap(),
    ]);
  };

  const checkAuthStatus = async () => {
    // If the loggedIn state is set to false, it means the localStorage has no item called 'loggedIn'
    // We are going to use cookies in the future, so that will be our only indicator for whether the user is logged in or not
    if (!token) {
      dispatch(authActions.appLoaded());

      const isRegistrationPage =
        window.location.pathname.startsWith('/registration');

      if (isRegistrationPage) {
        return;
      }

      return navigate('/login');
    }

    // Since the cookie is going to be HTTP only which means accessed only on backend,
    // We will an authentication request and if the server verifies the user, they will remain logged in

    try {
      setFetchingProgress(true);
      const user = await dispatch(userThunks.userInfo()).unwrap();
      const isTeacher = UserUtils.isTeacher(user?.data);
      const isAdmin = UserUtils.isAdmin(user?.data);

      const cognitoSession =
        (await cognitoService.checkCognitoSession()) as CognitoSession;
      if (cognitoSession && isTeacher) {
        localStorage.setItem(
          'teacher-level',
          cognitoSession.teacherLevel.toString()
        );
        dispatch(
          authActions.setUserLevel(
            parseInt(cognitoSession.teacherLevel.toString())
          )
        );
      }

      if ((!isAdmin && user?.data.level) || isTeacher) {
        await fetchProgressAndSkills();
      } else {
        await dispatch(userThunks.fetchLanguages()).unwrap();
      }

      setFetchingProgress(false);

      dispatch(authActions.appLoaded());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (user?.level && !fetchingProgress) {
      fetchProgressAndSkills();
    }
  }, [user?.level]);

  useEffect(() => {
    i18n.changeLanguage(user?.interfaceLanguage);
  }, [user]);

  useEffect(() => {
    const lastUrl = window.location.href
      .split('/')
      .filter((url) => url.length)
      .pop();

    const isTeacher = UserUtils.isTeacher(user);
    const isAdmin = UserUtils.isAdmin(user);

    if (appLoaded && loggedIn) {
      if (isAdmin) {
        return navigate('/onboarding');
      } else if (isTeacher) {
        if (lastUrl === 'games' && user?.teacherGroups?.length) {
          return navigate('/dashboard/games');
        } else if (!user?.teacherGroups?.length) {
          // return navigate('/onboarding'); No longer required screen
          return navigate('/dashboard/my-classes');
        }

        return navigate('/dashboard/my-students');
      }

      if (user?.level !== null) {
        return searchParams.get('skill')
          ? navigate(`/dashboard/games?skill=${searchParams.get('skill')}`)
          : navigate('/dashboard/games');
      }

      return navigate('/dashboard/level-test');
    }
  }, [appLoaded, loggedIn]);

  return checkAuthStatus;
};

export default UseCheckAuth;
