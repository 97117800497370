import { useTranslation } from 'react-i18next';
import classNames from './DictionaryPhrases.module.scss';
import SVG from 'react-inlinesvg';

import connect from '@/assets/svg/connect.svg';
import rightArrow from '@/assets/svg/right-arrow.svg';
import leftArrow from '@/assets/svg/left-arrow.svg';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import { DictionaryPhrase } from '@/types/dictionary';
import UseDictionary from '@/hooks/UseDictionary';
import { useState } from 'react';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

interface DictionaryPhrasesProps {
  selectedWord: string;
  phrases: DictionaryPhrase[];
  onValueSearch: (value: string) => void;
}

const DictionaryPhrases = ({
  selectedWord,
  phrases,
  onValueSearch,
}: DictionaryPhrasesProps) => {
  const { t } = useTranslation();
  const [isViewAll, setIsViewAll] = useState(false);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const amount = 3;

  const onPhraseClick = (phrase: DictionaryPhrase) => {
    onValueSearch(phrase.phrase);
  };

  const displayedPhrases = isViewAll ? phrases : phrases.slice(0, amount);

  const phrasesElements = displayedPhrases.map((phrase, index) => (
    <div
      key={index}
      className={classNames.phrase}
      onClick={() => onPhraseClick(phrase)}
    >
      <span className={classNames.name}>{phrase.phrase}</span>
      <span className={classNames.translation}>{phrase.trans}</span>
      <SVG
        className={
          interfaceLanguage?.direction === 'rtl' ? classNames.svgRtl : ''
        }
        src={rightArrow}
      />
    </div>
  ));

  return (
    <div className={classNames.dictionaryPhrases}>
      <div className={classNames.top}>
        <div className={classNames.partOne}>
          <SVG src={connect} />
          <div className={classNames.title}>
            <span>{t('phrasesFor') + ' '}</span>
            <span className="font-bold">{selectedWord}</span>
          </div>
        </div>
        {phrases.length > 3 && (
          <button onClick={() => setIsViewAll((previous) => !previous)}>
            {t('viewAll')}
          </button>
        )}
      </div>
      <SectionSeperator className="my-6" />
      <div className={classNames.body}>{phrasesElements}</div>
    </div>
  );
};

export default DictionaryPhrases;
