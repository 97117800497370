import React from 'react';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';

import { CreateCsvStudentsResponse } from '@/types/response';

import classNames from '../StudentsTable.module.scss';

import closeIcon from '@/assets/svg/closeFill.svg';

interface ResponseBodyProps {
    studentsDataResponse: CreateCsvStudentsResponse | null;
}

const ResponseBody: React.FC<ResponseBodyProps> = ({
    studentsDataResponse
}) => {
    const { t } = useTranslation();

    const registeredStudents = studentsDataResponse?.data?.data?.registeredStudents.map(student => ({
        ...student,
        isRegistered: true
    })) || [];

    const unregisteredStudents = studentsDataResponse?.data?.data?.unregisteredStudents.map(student => ({
        ...student,
        isRegistered: false
    })) || [];

    const combinedStudents = [...registeredStudents, ...unregisteredStudents];

    return (
        <>
            {combinedStudents.map((student, index) => (
                <tr key={student.email + index} className={classNames.studentRow}>
                    <td>{student.firstName}</td>
                    <td>{student.lastName}</td>
                    <td className={!student.isRegistered ? classNames.studentError : ''}>
                        {!student.isRegistered && (
                            <SVG src={closeIcon} className={classNames.checkmarkError} />
                        )}
                        {student.email}
                        {!student.isRegistered && (
                            <span className={classNames.rowError}>{t('settings.add_students.errors.email_in_use')}</span>
                        )}
                    </td>
                    <td className={classNames.actionIcons}></td>
                </tr>
            ))}
        </>
    );
};

export default ResponseBody;