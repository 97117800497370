import { useAppSelector } from '@/store';
import classNames from './UserAvatar.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import {
  authActions,
  authSelector,
  interfaceLanguageSelector,
} from '@/store/reducers/auth';
import { Transition, TransitionStatus } from 'react-transition-group';
import { ROUTE_NAMES } from '@/router';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { useEffect } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import AvatarSelection from '@/components/AvatarSelection/AvatarSelection';

import pencil from '@/assets/svg/pencil.svg';
import commonUtils from '@/utils/common';
import UserUtils from '@/utils/user';

interface UserAvatarProps {
  className?: string;
  img?: string | null;
  backgroundColor?: string | null;
  // notificationsAmount?: number;
  userFirstName: string;
  allowEdit?: boolean;
}

const UserAvatar = ({
  className,
  img,
  backgroundColor,
  // notificationsAmount,
  userFirstName,
  allowEdit = true,
}: UserAvatarProps) => {
  const dispatch = useDispatch();
  const { isDesktop } = UseScreenSize();
  const { showAvatarSelection, user } = useAppSelector(authSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const currentRoute = UseCurrentRoute();
  const isProfilePage = currentRoute === ROUTE_NAMES.PROFILE;
  const isTeacher = UserUtils.isTeacher(user);

  const content = img ? (
    // <img src={img} alt="profile-image" />
    <SVG src={commonUtils.getAvatarImage(img) ?? ''} />
  ) : (
    <span>{userFirstName[0]}</span>
  );

  const onOpen = () => {
    dispatch(authActions.setShowAvatarSelection(true));
  };

  const onClose = () => {
    dispatch(authActions.setShowAvatarSelection(false));
  };

  const setModalClasses = (state: TransitionStatus) => {
    return isDesktop
      ? `slide-horizontal-${interfaceLanguage?.direction}-${state}`
      : `fade-${state}`;
  };

  return (
    <>
      <div
        className={classes(classNames.userAvatar, className, {
          [classNames.userAvatarDefault]: !img,
        })}
        style={{ backgroundColor: backgroundColor ?? '' }}
      >
        {content}
        {/* {notificationsAmount && (
        <span className={classNames.notification}>{notificationsAmount}</span>
      )} */}

        {/**removed for now bec in the new design we need to display the level instead of the pencil edit */}
        {user?.avatar && !isProfilePage && !isTeacher ? (
          <></>
        ) : (
          allowEdit &&
          currentRoute !== ROUTE_NAMES.QUIZ && (
            <button className={classNames.editBtn} onClick={onOpen}>
              <SVG src={pencil} />
            </button>
          )
        )}
      </div>
      <Transition
        in={showAvatarSelection}
        timeout={400}
        mountOnEnter
        unmountOnExit
        children={(state) => {
          return (
            <>
              <AppBackdrop
                className={classes(classNames.backdrop, `backdrop-${state}`)}
                emitClose={onClose}
              />
              <AppModal>
                <AvatarSelection
                  className={classes(classNames.modal, setModalClasses(state))}
                  emitClose={onClose}
                />
              </AppModal>
            </>
          );
        }}
      />
    </>
  );
};

export default UserAvatar;
