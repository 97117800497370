import { useEffect, useState } from 'react';
import classNames from './FinalAssessmentStartPopUp.module.scss';
import classes from 'classnames';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';

import AppBackdrop from '../AppBackdrop';
import AppModal from '../AppModal';

import handWave from '@/assets/svg/hand-wave.svg';
import TheButton from '../TheButton/TheButton';
import finalAssessmentThunks from '@/store/thunks/final-assessment';
import { gameActions } from '@/store/reducers/game';
import { useNavigate, useNavigation } from 'react-router-dom';
import { finalAssessmentSelector } from '@/store/reducers/final-assessment';
import { FinalAssessmentQuiz } from '@/types/final-assessment';
import { SkillTypes } from '@/types/skill';

const FinalAssessmentStartPopUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, skillSettings } = useAppSelector(authSelector);
  const { currentQuizIndex } = useAppSelector(finalAssessmentSelector);

  const { shouldStartFinalAssessment } = useAppSelector(authSelector);

  useEffect(() => {
    const finalAssessmentDateString = user?.finalAssessmentDate;

    const finalAssessmentDate = dayjs(finalAssessmentDateString)
      .utc()
      .utcOffset(0, true);

    const isToday = finalAssessmentDate.isSame(dayjs(), 'day');
    const isBeforeToday = finalAssessmentDate.isBefore(dayjs(), 'day');

    if (
      user?.finalAssessmentDate &&
      (isToday || isBeforeToday) &&
      user?.isUserCompletedFinalAssessment === false
    ) {
      document.body.style.overflowY = 'hidden';
      window.scrollTo({
        top: 0,
      });

      dispatch(authActions.setShouldStartFinalAssessment(true));
    }
  }, []);

  const onClose = () => {
    document.body.style.overflowY = '';
    dispatch(authActions.setShouldStartFinalAssessment(false));
  };

  const onStartFinalAssessment = async () => {
    const response = await dispatch(
      finalAssessmentThunks.fetchFinalAssessment()
    ).unwrap();

    if (response) {
      const currentQuiz: FinalAssessmentQuiz = response[currentQuizIndex];

      const type = currentQuiz.quiz.skill as SkillTypes;
      const skill = skillSettings.find(
        (i) => i.name?.toLocaleLowerCase() === SkillTypes.Grammar
      );

      dispatch(gameActions.setIsFinalAssessmentTest(true));
      dispatch(gameActions.setShowPreQuiz(true));

      dispatch(gameActions.setSelectedSkill({ type, skill }));
      dispatch(gameActions.setSelectedQuiz(currentQuiz.quiz));
      dispatch(
        gameActions.startGame({
          gameType: currentQuiz.quiz.gameType,
          questions: currentQuiz.questions,
        })
      );

      onClose();

      navigate('/dashboard/quiz');
    }
  };

  return (
    <Transition
      in={shouldStartFinalAssessment}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
            />
            <AppModal>
              <div
                className={classes(
                  classNames.finalAssessmentStartPopUp,
                  `fade-${state}`
                )}
              >
                <div className={classNames.content}>
                  <div className={classNames.title}>
                    <SVG src={handWave} />
                    <h2>{`${t('hi')} ${user?.firstName}!`}</h2>
                  </div>
                  <div className={classNames.description}>
                    <span>{t('pleaseCompleteTheFollowingTest')}</span>
                    <span className="font-semibold">{t('goodLuck')}</span>
                  </div>
                </div>
                <div className={classNames.buttons}>
                  <TheButton
                    text={t('letsGo')}
                    shouldRecolor={false}
                    emitOnClick={onStartFinalAssessment}
                  />
                  <TheButton
                    text={t('illDoItLater')}
                    shouldRecolor={false}
                    plain
                    emitOnClick={onClose}
                  />
                </div>
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );
};

export default FinalAssessmentStartPopUp;
