import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import { gameActions } from '@/store/reducers/game';
import {
  levelTestActions,
  levelTestSelector,
} from '@/store/reducers/level-test';
import levelTestThunks from '@/store/thunks/level-test';
import { GameTypes } from '@/types/game';
import { LevelTestItem } from '@/types/quiz';
import { LevelTestResultResponse } from '@/types/response';
import { useNavigate } from 'react-router-dom';
import UseUserProgress from './UseUserProgress';
import UserUtils from '@/utils/user';

const UseLevelTestGame = () => {
  const { quizzes } = useAppSelector(levelTestSelector);
  const { skillSettings, user } = useAppSelector(authSelector);
  const { loadUserProgress } = UseUserProgress();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const init = async (
    quizIndex: number,
    currentLevelTestItem: LevelTestItem
  ) => {
    dispatch(levelTestActions.updateCurrentQuizIndex(quizIndex));

    const skillType = currentLevelTestItem?.quiz?.skill;
    const skill = skillSettings.find(
      (i) => i.name?.toLocaleLowerCase() === skillType
    );

    dispatch(
      gameActions.setSelectedSkill({
        type: skillType ?? null,
        skill: skill,
      })
    );

    dispatch(
      gameActions.startGame({
        gameType: currentLevelTestItem?.quiz?.gameType ?? GameTypes.Multichoice,
        questions: currentLevelTestItem?.questions ?? [],
      })
    );

    if (currentLevelTestItem?.quiz) {
      dispatch(gameActions.setSelectedQuiz(currentLevelTestItem?.quiz));
    }
  };

  const startLevelTestGame = async (quizIndex: number) => {
    if (quizzes && quizIndex > quizzes?.length - 1) {
      const result = await dispatch(
        levelTestThunks.postUserLevelTestResult()
      ).unwrap();

      if (result) {
        const currentLevelTestItem = result.questions[0];

        init(0, currentLevelTestItem);

        dispatch(gameActions.resetLevelTestQuizResultData());

        if (!result.getMoreQuestions) {
          if (!UserUtils.isTeacher(user)) {
            dispatch(authActions.setUserAfterLevelTest(result));
          }

          dispatch(gameActions.setIsLevelTest(false));
          dispatch(gameActions.setSelectedQuiz(null));
          // await loadUserProgress();

          navigate('/dashboard/level-test');
        }
      }

      return;
    }

    console.log('Continue with level test.');
    const currentLevelTestItem = quizzes?.[quizIndex];

    if (currentLevelTestItem) {
      init(quizIndex, currentLevelTestItem);
    }
  };

  return { startLevelTestGame };
};

export default UseLevelTestGame;
