import { useTranslation } from 'react-i18next';
import classNames from './TeacherOnboarding.module.scss';
import classes from 'classnames';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import { useEffect, useState } from 'react';
import commonUtils from '@/utils/common';
import SVG from 'react-inlinesvg';
import { registerService } from '@/services/register';
import { CreateGroupDetails, EditGroupDetails } from '@/types/register';
import UseScreenSize from '@/hooks/UseScreenSize';
import { TeacherGroup } from '@/types/user';
import { userService } from '@/services';

import OnboardingInvitation from '../OnboardingInvitation/OnboardingInvitation';
import TheButton from '../TheButton/TheButton';
import AppInput from '@/components/AppInput/AppInput';
import AppLoaderCircle from '../AppLoaderCircle';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import { useDrawer } from '@/context/DrawerContext';
import { teacherActions } from '@/store/reducers/teacher';

interface TeacherOnboardingProps {
  className?: string;
  showTop?: boolean;
  showCopyBtn?: boolean;
  hasMultipleControls?: boolean;
  isDrawer?: boolean;
  classToEdit?: TeacherGroup | null;
  shouldNavigate?: boolean;
  emitCancel?: () => void;
}

const TeacherOnboarding = ({
  className,
  showTop = true,
  showCopyBtn = true,
  hasMultipleControls,
  isDrawer = false,
  classToEdit,
  shouldNavigate,
  emitCancel,
}: TeacherOnboardingProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const { isMobile } = UseScreenSize();

  const [link, setLink] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [showInvitation, setShowInvitation] = useState(false);

  const [teacherClassName, setTeacherClassName] = useState('');

  const [seletedGrade, setSelectedGrade] = useState('');
  const [grades, setGrades] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const drawer = useDrawer();

  useEffect(() => {
    const schoolGrades = Array.from({ length: 12 }, (_, i) =>
      t(`grade_${commonUtils.fixGradeSymbol((i + 1).toString())}`)
    );
    const namedGrades = [t('university'), t('none')];

    setGrades([...schoolGrades, ...namedGrades]);
  }, []);

  useEffect(() => {
    if (classToEdit) {
      setLink(classToEdit.invitationLinkForStudents);
      setTeacherClassName(classToEdit.name);
      setSelectedGrade(classToEdit.grade);
      setShowInvitation(true);
    }
  }, [classToEdit]);

  useEffect(() => {
    setTeacherClassName('');
    setSelectedGrade('');
    setLink('');
  }, [drawer.isAddClassDrawerOpen]);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showDropdown) {
        return;
      }

      onCloseDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showDropdown]);

  const onToggleDropdown = (e: React.MouseEvent<HTMLElement>) => {
    if (showInvitation && !classToEdit) {
      return;
    }

    e.stopPropagation();
    setShowDropdown((prevState) => !prevState);
  };

  const onCloseDropdown = () => {
    setShowDropdown(false);
  };

  const onSelectedOption = (
    e: React.MouseEvent<HTMLElement>,
    option: string
  ) => {
    e.stopPropagation();
    onCloseDropdown();
    setSelectedGrade(option);
  };

  const onInviteStudents = async () => {
    if (shouldDisableBtn) {
      return;
    } else if (showInvitation) {
      return onClose();
    }
    setIsLoading(true);

    try {
      const groupDetails: CreateGroupDetails = {
        groupName: teacherClassName.trim(),
        grade: seletedGrade.trim(),
        finalAssessment: {
          isActivated: false,
        },
      };
      console.log(groupDetails);
      const response = (await registerService.createGroup(user, groupDetails))
        ?.data.data;

      if (response) {
        setLink(response.invitationLinkForStudents ?? '');
        dispatch(authActions.addNewClass(response));
        setShowInvitation(true);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onEditClass = async () => {
    if (!classToEdit) {
      return;
    }

    setIsLoading(true);

    try {
      const groupDetails: EditGroupDetails = {
        groupId: classToEdit.id,
        groupName: teacherClassName.trim(),
        grade: seletedGrade.trim(),
      };

      const response = (
        await userService.updateTeacherClass(user, groupDetails)
      )?.data.data;

      if (response) {
        dispatch(authActions.updateTeacherClass(groupDetails));
        dispatch(teacherActions.editStudentsGrade(groupDetails.grade));
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onClose = () => {
    if (!classToEdit) {
      setTeacherClassName('');
      setSelectedGrade('');
      setLink('');
      setShowInvitation(false);
    }

    emitCancel?.();
  };

  const gradeOptions = grades.map((grade) => {
    const isSelected = grade === seletedGrade;

    return (
      <div
        key={grade}
        className={classNames.grade}
        onClick={(event) => onSelectedOption(event, grade)}
      >
        {t(`grade_${grade}`)}
      </div>
    );
  });

  const gradesDropdown = showDropdown && (
    <div className={classNames.gradesDropdown}>{gradeOptions}</div>
  );

  let gradeText = '';

  const isUniversityGrade = seletedGrade?.toLowerCase() === 'university';
  const isNoneGrade = seletedGrade?.toLowerCase() === 'none';

  if (!isNoneGrade) {
    if (isUniversityGrade) {
      gradeText = t('university');
    } else {
      gradeText =
        isNaN(+seletedGrade) || Number.isInteger(+seletedGrade)
          ? t(`grade_${commonUtils.fixGradeSymbol(seletedGrade)}`)
          : seletedGrade;
    }
  }

  const gradesSelect = (
    <div className={classNames.gradesSelectContainer}>
      <span className={classNames.label}>{`*${t('grade')}`}</span>
      <div
        className={classes(classNames.gradesSelect, {
          'cursor-pointer': !showInvitation || (showInvitation && classToEdit),
        })}
        onClick={onToggleDropdown}
      >
        <span className={!seletedGrade ? 'opacity-20' : ''}>
          {seletedGrade ? gradeText : t('chooseGrade')}
        </span>
        {(!showInvitation || (showInvitation && classToEdit)) && (
          <SVG src={showDropdown ? upArrow : downArrow} />
        )}
      </div>
      {gradesDropdown}
    </div>
  );

  let shouldDisableBtn = false;

  if (!teacherClassName || !seletedGrade) {
    shouldDisableBtn = true;
  } else if (classToEdit) {
    shouldDisableBtn =
      classToEdit.name.trim() === teacherClassName.trim() &&
      classToEdit.grade === seletedGrade;
  }

  const confirmBtn = (
    <TheButton
      className={
        hasMultipleControls ? classNames.saveBtn : classNames.invitationBtn
      }
      text={hasMultipleControls ? t('save') : t('inviteYourStudents')}
      disabled={shouldDisableBtn}
      shouldRecolor={false}
      emitOnClick={() => (classToEdit ? onEditClass() : onInviteStudents())}
    />
  );

  const content = showInvitation ? (
    <OnboardingInvitation
      className={classNames.onboardingInvitation}
      link={link}
      showCopyBtn={showCopyBtn}
      shouldNavigate={shouldNavigate}
    />
  ) : (
    <>{!hasMultipleControls && confirmBtn}</>
  );

  if (isLoading) {
    return <AppLoaderCircle className={classNames.loader} />;
  }

  return (
    <div
      className={classes(classNames.teacherOnboarding, className, {
        [classNames.teacherOnboardingDrawer]: isDrawer,
      })}
    >
      {showTop && (
        <div className={classNames.top}>
          <span>{`${t('welcome')}, ${user?.firstName}!`}</span>
          <h2>
            {isMobile
              ? t('createYourFirstClass')
              : `${t('createYourFirstClass')} ${t('andThenInvite')}`}
          </h2>
          <span className={classNames.tip}>{t('youCanCreateMore')}</span>
        </div>
      )}
      <div className={classNames.body}>
        <div className={classNames.inputs}>
          <AppInput
            className={classNames.input}
            inputWrapperClassName={classNames.inputWrapperClassName}
            id="class_name"
            name="className"
            label={t('className') ?? ''}
            placeholder={
              (isMobile ? t('writeHere') : t('writeClassNameHere')) ?? ''
            }
            value={teacherClassName}
            isRequired
            emitChange={({ target }) => setTeacherClassName(target.value)}
          />
          {gradesSelect}
        </div>
        {content}
        {hasMultipleControls && (
          <div className={classNames.controls}>
            <TheButton plain text={t('cancel')} emitOnClick={onClose} />
            {confirmBtn}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherOnboarding;
