import classNames from './ProfileSkillsProgress.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import { SkillsProgress } from '@/types/progress';

import ProgressGroup from '../ProgressSection/ProgressGroup';
import UseScreenSize from '@/hooks/UseScreenSize';

interface ProfileSkillsProgressProps {
  className?: string;
  progress: SkillsProgress | null;
}

const ProfileSkillsProgress = ({
  className,
  progress,
}: ProfileSkillsProgressProps) => {
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { isMobile } = UseScreenSize();
  const progressEntries = progress ? Object.entries(progress) : [];

  const middleIndex = Math.floor(progressEntries.length / 2);

  const firstHalf = progressEntries.slice(0, middleIndex);
  const secondHalf = progressEntries.slice(middleIndex);
  return (
    <div
      style={isMobile ? { flexBasis: '45%' } : { margin: '24px 40px' }}
      className={classes(classNames.profileSkillsProgress, className, [
        classNames[`profileSkillsProgress-${interfaceLanguage?.direction}`],
      ])}
    >
      <ProgressGroup group={isMobile ? firstHalf : progressEntries} />
      {isMobile && (
        <>
          <div className={classNames.seperator}></div>
          <ProgressGroup group={secondHalf} />
        </>
      )}
    </div>
  );
};

export default ProfileSkillsProgress;
