import {
  CurrentProgressBySkill,
  StudentsSkillsProgress,
} from '@/types/progress';
import classNames from './StudentCard.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import UseLevel from '@/hooks/UseLevel';
import { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { SkillTypes } from '@/types/skill';
import UseSkills from '@/hooks/UseSkills';

import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import Detail from '@/components/UserInfo/Detail/Detail';
import SkillIcon from '@/components/SkillIcon/SkillIcon';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import medal from '@/assets/svg/medal-gold.svg';
import commonUtils from '@/utils/common';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

interface StudentCardMobileProps {
  firstname: string;
  level?: number;
  grade: string;
  userName?: string;
  currentProgressBySkill: CurrentProgressBySkill | undefined;
  totalQuizzes?: number;
  quizzesProgress?: number;
  completedQuizzes?: number;
  currentLevelByskill?: StudentsSkillsProgress | undefined;

  emitShowDataModal: (studentId: string) => void;
}

const StudentCardMobile = ({
  firstname,
  level,
  grade,
  userName,
  currentProgressBySkill,
  quizzesProgress,
  completedQuizzes,
  totalQuizzes,
  currentLevelByskill,

  emitShowDataModal,
}: StudentCardMobileProps) => {
  const { t } = useTranslation();
  const { getSkillName } = UseSkills();

  const { getLevelText } = UseLevel();
  const levelText = getLevelText(level);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const [shouldExpand, setShouldExpand] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number | string>('');

  const topRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const spacing = 40;

  useEffect(() => {
    const topRefHeight = topRef.current?.clientHeight;
    const bodyRefHeight = bodyRef.current?.clientHeight;

    if (topRefHeight && bodyRefHeight) {
      setMaxHeight(
        () =>
          (shouldExpand
            ? topRefHeight + bodyRefHeight + spacing
            : topRefHeight) + spacing
      );
    }
  }, [shouldExpand]);

  const onToggle = () => {
    setShouldExpand((prevState) => !prevState);
  };

  const emptyProgressBySkill = {
    speak: {
      total: 0,
      actual: 0,
    },
    grammar: {
      total: 0,
      actual: 0,
    },
    read: {
      total: 0,
      actual: 0,
    },
    vocabulary: {
      total: 0,
      actual: 0,
    },
    spelling: {
      total: 0,
      actual: 0,
    },
    listen: {
      total: 0,
      actual: 0,
    },
  };

  const progressBySkill =
    currentProgressBySkill && Object.entries(currentProgressBySkill).length
      ? currentProgressBySkill
      : emptyProgressBySkill;

  const skillEntries = Object.entries(progressBySkill);

  const skillsElements = skillEntries.map((entry, index) => {
    const [skill, progress] = entry;
    const { actual, total } = progress;

    const percentage = commonUtils.calcPercentage(actual, total);

    const isLast = index === skillEntries.length - 1;
    const isEven = index % 2 === 0;

    return (
      <div key={skill} className={classNames.skill}>
        {isEven && !isLast && shouldExpand && (
          <DetailSeperator
            className={classes(classNames.seperator, [
              interfaceLanguage?.direction === 'rtl'
                ? classNames[`seperator_${index / 2}`]
                : classNames[`seperator_${index / 2}-ltr`],
            ])}
          />
        )}
        <SkillIcon className={classNames.icon} skill={skill as SkillTypes} />
        <div className={classNames.texts}>
          <span className={classNames.name}>
            {getSkillName(skill as SkillTypes)}
          </span>
          <span>{`${percentage}%`}</span>
        </div>
        {currentLevelByskill?.[skill as SkillTypes] && (
          <h1 className={classNames.levelText}>
            {t('level') + ' ' + currentLevelByskill?.[skill as SkillTypes]}
          </h1>
        )}
      </div>
    );
  });

  const isUniversityGrade = grade.toLowerCase() === 'university';
  const isNoneGrade = grade.toLowerCase() === 'none';

  let gradeValue = null;

  if (!isNoneGrade) {
    if (isUniversityGrade) {
      gradeValue = t('university');
    } else {
      gradeValue =
        isNaN(+grade) || Number.isInteger(+grade)
          ? t(`grade_${commonUtils.fixGradeSymbol(grade)}`)
          : grade;
    }
  }

  return (
    <div
      className={classNames.studentCardMobile}
      style={{ maxHeight }}
      onClick={() => emitShowDataModal(userName ?? '')}
    >
      <div ref={topRef} className={classNames.top}>
        {/* <UserAvatar
            className={classNames.img}
            img={imageUrl}
          notificationsAmount={3}
          /> */}
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex items-center gap-3">
            <span className={classNames.firstname}>{firstname}</span>
            <div
              className={classes(classNames.level, {
                [classNames.levelTest]: !level,
              })}
            >
              <span>
                {level ? `${t('level')} ${level}` : t('levelTestIncomplete')}
              </span>
              {level ? (
                <>
                  <DetailSeperator className={classNames.seperator} />
                  <span>{levelText}</span>
                </>
              ) : null}
            </div>
          </div>
          <div className="flex items-center gap-2">
            {/* <Detail
                className={classNames.detail}
                topic={t('username')}
                value={userName}
              />
              <DetailSeperator /> */}
            {gradeValue && (
              <Detail
                className={classNames.detail}
                topic={t('grade')}
                value={gradeValue}
              />
            )}
          </div>
        </div>
        <button className={classNames.toggler} onClick={onToggle}>
          <SVG src={shouldExpand ? upArrow : downArrow} />
        </button>
      </div>
      <div ref={bodyRef} className={classNames.body}>
        <div className={classNames.progress}>
          <div className={classNames.barWrapper}>
            <div
              className={classNames.bar}
              style={{ width: `${quizzesProgress}%` }}
            ></div>
          </div>
          <div className={classNames.quizzes}>
            <SVG src={medal} />
            <span>{`${completedQuizzes}/${totalQuizzes} ${t('quizzes')}`}</span>
          </div>
        </div>
        {skillsElements.length ? (
          <div className={classNames.skills}>{skillsElements}</div>
        ) : null}
        <button>{t('showMore')}</button>
      </div>
    </div>
  );
};

export default StudentCardMobile;
