import classNames from './QuizScore.module.scss';
import classes from 'classnames';
import Rating from '../Rating/Rating';

interface QuizScoreProps {
  isLocked: boolean;
  isSmall?: boolean;
  isExtraSmall?: boolean;
  achievedStars: number;
}

const QuizScore = ({
  achievedStars,
  isLocked,
  isSmall,
  isExtraSmall,
}: QuizScoreProps) => {
  return (
    <div
      className={classes(classNames.quizScore, {
        [classNames.quizScoreLocked]: isLocked,
        [classNames.quizScoreSmall]: isSmall,
        [classNames.quizScoreExtraSmall]: isExtraSmall,
      })}
    >
      <Rating
        className={classes(
          classNames.rating,
          !isLocked ? classNames.ratingsUnlocked : ''
        )}
        achievedStars={achievedStars}
      />
    </div>
  );
};

export default QuizScore;
