import axiosInstance from '@/utils/api';
import config from '@/config';
import UserUtils from '@/utils/user';
import { TeacherGroup, UserState } from '@/types/user';
import { ReportResponse } from '@/types/response/report';
import { ReportService } from '@/types/services/report';

export const reportService: ReportService = {
  fetchStudentReport: async (
    student: UserState | null,
    user: UserState | null
  ) => {
    //todo need to check if this is the correct id of the student

    const resp: ReportResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/reports/student-report/${student?.userName}`
    );

    return resp;
  },
  fetchClassReport: async (
    currentClassroom: TeacherGroup | null,
    user: UserState | null
  ) => {
    console.log(user?.organizationId);
    console.log(currentClassroom?.id);

    const orgIdGroupId = user?.organizationId + '_' + currentClassroom?.id;

    const resp: ReportResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/reports/group-report/${user?.id}/${orgIdGroupId}`
    );
    console.log(resp);
    return resp;
  },
};
