// import { useEffect } from 'react';
// import AppLoaderCircle from './AppLoaderCircle';

// const AppLoader = () => {
//   useEffect(() => {
//     const accessbilityElement = document.getElementById('INDWrap')?.style;
//     if (accessbilityElement != undefined) {
//       accessbilityElement.display = 'none';
//     }

//     return () => {
//       if (accessbilityElement != undefined) {
//         accessbilityElement.display = 'block';
//       }
//     };
//   }, []);
//   return (
//     <div className="app-loader">
//       <div className="app-loader-top">
//         <div className="app-loader-brand">
//           Britannica
//           <br />
//           English
//         </div>
//         <AppLoaderCircle className="w-full h-full" />
//       </div>
//       <div className="app-loader-footer">powered by Melingo</div>
//     </div>
//   );
// };

// export default AppLoader;

import { useEffect } from 'react';
import AppLoaderCircle from './AppLoaderCircle';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import AppMorfixLogo from './AppMorfixLogo/AppMorfixLogo';

const AppLoader = () => {
  const { nativeLanguage } = useAppSelector(authSelector);
  const showMorfixLogo = nativeLanguage === 'he';
  useEffect(() => {
    const accessbilityElement = document.getElementById('INDWrap')?.style;
    if (accessbilityElement != undefined) {
      accessbilityElement.display = 'none';
    }

    return () => {
      if (accessbilityElement != undefined) {
        accessbilityElement.display = 'block';
      }
    };
  }, []);
  return (
    <div className="app-loader">
      <div className="app-loader-top">
        <div className="app-loader-brand">
          {showMorfixLogo ? (
            <AppMorfixLogo width={360} height={60} showBackGround={false} />
          ) : (
            <>
              Britannica
              <br />
              English
            </>
          )}
        </div>
        <AppLoaderCircle className="w-full h-full" />
      </div>
      <div className="app-loader-footer">powered by Melingo</div>
    </div>
  );
};

export default AppLoader;
