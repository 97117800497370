import React, { FormEvent } from 'react';
import { AppInputProps } from './common';
import {
  GroupDetails,
  OrganizationDetails,
  ValidateAdminCode,
} from './register';

export enum RegistrationStepType {
  REGISTRATION_WELCOME = 'registration_welcome',
  VERIFY_CODE = 'verify_code',
  PERSONAL_DETAILS = 'personal_details',
  COGNITO_VERIFY_CODE = 'cognito_verify_code',
  ORGANIZATION_DETAILS = 'organization_details',
}

export type RegistrationStep = {
  type: RegistrationStepType;
  roles?: string[];
  order: number;
  component: React.FC<
    | RegistrationWelcomeProps
    | VerificationCodeProps
    | PersonalDetailsProps
    | CognitoVerificationCodeProps
    | OrganizationDetailsProps
  >;
};

export type RegistrationStepProps = {
  role?: string;
  fields?: AppInputProps[];
  emitSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

export type RegistrationWelcomeProps = RegistrationStepProps & {
  organizationDetails?: OrganizationDetails | null;
  groupDetails?: GroupDetails | null;
  emitNextStep?: () => void;
};

export type VerificationCodeProps = RegistrationStepProps & {
  verificationCodeField?: AppInputProps;
  verificationCodeLength?: number;
};
export type CognitoVerificationCodeProps = RegistrationStepProps & {
  verificationCodeField?: AppInputProps;
  verificationCodeLength?: number;
  resendVerificationCode?: () => void;
  email?: string;
};

export type PersonalDetailsProps = RegistrationStepProps & {};

export type OrganizationDetailsProps = RegistrationStepProps & {};

export interface RegistrationValues {
  [key: string]: string;
}
