import config from '@/config';
import {
  AdminOrganizationDetails,
  CreateGroupDetails,
  CreateUserDetails,
  CreateStudentData,
} from '@/types/register';
import {
  GroupDetailsResponse,
  CreateOrganizationResponse,
  CreateStudentResponse,
  CreateTeacherResponse,
  OrganizationDetailsResponse,
  RegisteredTeachersResponse,
  ValidateAdminCodeResponse,
  CreateGroupResponse,
  CreateAdminResponse,
  CreateCsvStudentsResponse,
  GroupStudentsResponse,
} from '@/types/response';
import { UserState } from '@/types/user';
import axiosInstance from '@/utils/api';
import UserUtils from '@/utils/user';

// BASE REGISTRATION ENDPOINT //
const REGISTRATION_ENDPOINT = `registration/${config.apiVersion}`;

// KEY IN LOCAL STORAGE TO SAVE TEMPORARY REGISTRATION TOKEN PASSED VIA THE URL //
const REGISTER_TOKEN_KEY = 'register-token';

export const registerService = {
  /* GET ORGANIZATION DETAILS FOR TEACHER - FIRST CALL ON FIRST STEP OF TEACHER FLOW */
  getOrganizationDetails: async () => {
    const token = localStorage.getItem(REGISTER_TOKEN_KEY);

    const resp: OrganizationDetailsResponse | null = await axiosInstance.get(
      `${REGISTRATION_ENDPOINT}/teacher/organization-details?token=${token}`
    );

    return resp;
  },
  /* GET CLASS DETAILS FOR STUDENT - FIRST CALL ON FIRST STEP OF STUDENT FLOW */
  getGroupDetails: async () => {
    const token = localStorage.getItem(REGISTER_TOKEN_KEY);

    const resp: GroupDetailsResponse | null = await axiosInstance.get(
      `${REGISTRATION_ENDPOINT}/student/group-details?token=${token}`
    );

    return resp;
  },
  /* VALIDATE THE ADMIN CODE IN FIRST STEP */
  validateAdminCode: async (code: string) => {
    const resp: ValidateAdminCodeResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/admin/validate-code`,
      {
        verificationCode: code,
        token: localStorage.getItem(REGISTER_TOKEN_KEY),
      }
    );

    return resp;
  },
  /* GET REGISTERED TEACHERS UNDER ADMIN ACCOUNT */
  getRegisteredTeachers: async (user: UserState | null) => {
    const resp: RegisteredTeachersResponse | null = await axiosInstance.get(
      `${REGISTRATION_ENDPOINT}/${UserUtils.fromToLanguage(
        user
      )}/admin/registered-teachers/${user?.id}`
    );

    return resp;
  },
  /* 
    CREATE THE ADMIN USER
  */
  createAdmin: async (adminUser: CreateUserDetails) => {
    const resp: CreateAdminResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/admin/create-admin`,
      {
        token: localStorage.getItem(REGISTER_TOKEN_KEY),
        adminUser,
      }
    );

    return resp;
  },
  /* 
    CREATE ADMIN'S ORGANIZATION
  */
  createOrganization: async (
    user: UserState | null,
    userId: string,
    organizationDetails: AdminOrganizationDetails
  ) => {
    const resp: CreateOrganizationResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/${UserUtils.fromToLanguage(
        user
      )}/admin/create-organization/${userId}`,
      organizationDetails
    );

    return resp;
  },
  /* 
    CREATE A CLASS + GRADE (GROUP)
  */
  createGroup: async (
    user: UserState | null,
    groupDetails: CreateGroupDetails
  ) => {
    const resp: CreateGroupResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/${UserUtils.fromToLanguage(
        user
      )}/teacher/create-group/${user?.id}`,
      groupDetails
    );

    return resp;
  },

  getGroupStudents: async (
    user: UserState | null,
    teacherId: string,
    groupId: string
  ) => {
    const token = localStorage.getItem(REGISTER_TOKEN_KEY);

    const resp: GroupStudentsResponse | null = await axiosInstance.get(
      `${REGISTRATION_ENDPOINT}/${UserUtils.fromToLanguage(
        user
      )}/teacher/students/${teacherId}/${groupId}?token=${token}`
    );

    return resp;
  },
  /* 
    CREATE THE TEACHER USER
    CALL THIS WHEN YOU FINISHED CALLING COGNITO CREATE ACCOUNT
    YOU MUST SEND THE COGNITO USER ID HERE
  */
  createTeacher: async (
    fromToLanguage: string,
    userDetails: CreateUserDetails
  ) => {
    const resp: CreateTeacherResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/${fromToLanguage}/teacher/create-teacher`,
      {
        token: localStorage.getItem(REGISTER_TOKEN_KEY),
        teacherDetails: userDetails,
      }
    );

    return resp;
  },
  /* 
    CREATE THE STUDENT USER
    CALL THIS WHEN YOU FINISHED CALLING COGNITO CREATE ACCOUNT
    YOU MUST SEND THE COGNITO USER ID HERE
  */
  createStudent: async (
    fromToLanguage: string,
    userDetails: CreateUserDetails
  ) => {
    const resp: CreateStudentResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/${fromToLanguage}/student/create-student`,
      {
        token: localStorage.getItem(REGISTER_TOKEN_KEY),
        studentDetails: userDetails,
      }
    );

    return resp;
  },

  /* 
    CREATE STUDENTS FROM CSV FILE
  */
  createCsvStudents: async (
    user: UserState | null,
    groupId: string | undefined,
    students: CreateStudentData[]
  ) => {
    const resp: CreateCsvStudentsResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/${user?.fromToLanguageParams}/teacher/create-students-csv/${user?.id}`,
      {
        token: localStorage.getItem('token'),
        organizationId: user?.organizationId,
        groupId,
        students,
      }
    );

    return resp;
  },
};
