import classNames from './QuizDetails.module.scss';
import classes from 'classnames';
import commonUtils from '@/utils/common';
import React from 'react';
import { SkillSettingsItem } from '@/types/user';
import { Topic, TopicTypes } from '@/types/topic';
import UseScreenSize from '@/hooks/UseScreenSize';

import QuizDetailsHeading from '../QuizDetailsHeading/QuizDetailsHeading';
import QuizImageIndicator from '../QuizImageIndicator/QuizImageIndicator';
import { SkillTypes } from '@/types/skill';
import { useTranslation } from 'react-i18next';
import UseLocalLang from '@/hooks/UseLocalLang';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { Question } from '@/types/question';
import { Quiz } from '@/types/quiz';
import { use } from 'i18next';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';

type QuizDetailsProps = {
  className?: string;
  headingClassName?: string;
  selectedType?: SkillTypes | null;
  selectedSkill?: SkillSettingsItem | null;
  selectedTopic: Topic | null;
  selectedQuiz: Quiz | null;
  shouldDisplayImage?: boolean;
  shouldDisplayInstructions?: boolean;
};

const QuizDetails = ({
  className,
  headingClassName,
  selectedType,
  selectedSkill,
  selectedTopic,
  selectedQuiz,
  shouldDisplayImage = false,
  shouldDisplayInstructions = false,
}: QuizDetailsProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile, windowHeight, mediumHeight } = UseScreenSize();
  const localLang = UseLocalLang();
  const { user } = useAppSelector(authSelector);
  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const isInEndOfSkillTest =
    selectedTopic?.topicType === TopicTypes.EndOfSkillTest;
  const isRead = selectedType === SkillTypes.Read;
  let name = 'N/A';

  if (selectedQuiz) {
    name = isLevelTest
      ? t(`skills.${selectedQuiz.skill ?? 'N/A'}`)
      : commonUtils.getName(selectedQuiz, localLang) || 'N/A';
  }

  const isInEndOfSkillTestAndDesktop = isInEndOfSkillTest && isDesktop;

  const nameText = name.toLowerCase();

  let title = nameText;

  if (isMobile) {
    if (isInEndOfSkillTest) {
      title = selectedQuiz?.name ?? '';
    } else if (isLevelTest && isRead) {
      title = `${nameText} | ${selectedQuiz?.contentTitle}`;
    } else if (isFinalAssessment) {
      title = selectedType ?? '';
    }
  }

  if (isDesktop) {
    if (isInEndOfSkillTest) {
      const currentLevel =
        user?.currentLevelByskill?.[selectedType as SkillTypes];

      title = `${t('level')} ${currentLevel} ${t('assessment')}`;
    }
  }

  return (
    <div
      className={classes(classNames.quizDetails, className, {
        [classNames.quizDetailsRead]: isRead,
      })}
    >
      {shouldDisplayImage && (
        <QuizImageIndicator
          className={classNames.imageIndicator}
          starsClassName={classNames.stars}
          skillClassName={classNames.skill}
          shouldDisplayStars={!isLevelTest && !isInEndOfSkillTest}
        />
      )}
      <div className={classNames.content}>
        {isLevelTest ? (
          <div className={classNames.levelTest}>{t('howsYourEnglish')}</div>
        ) : (
          <QuizDetailsHeading
            className={classes(
              {
                [classNames.quizDetailsHeadingMobile]:
                  isMobile && windowHeight > mediumHeight,
                [classNames.quizDetailsHeadingDesktop]: isDesktop,
              },
              headingClassName
            )}
            selectedType={selectedType}
            selectedSkill={selectedSkill}
            selectedTopic={selectedTopic}
            isInGameWindow={true}
          />
        )}
        <div className={classNames.details}>
          <h3>{title}</h3>
          {shouldDisplayInstructions && !isInEndOfSkillTestAndDesktop && (
            <span
              className={classes(
                classNames[`instructions-${interfaceLanguage?.direction}`]
              )}
            >
              {selectedQuiz
                ? commonUtils.getQuizInstructions(selectedQuiz, localLang)
                : 'N/A'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(QuizDetails);
