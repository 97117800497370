import classNames from './StudentCard.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import UseLevel from '@/hooks/UseLevel';
import {
  CurrentProgressBySkill,
  StudentsSkillsProgress,
} from '@/types/progress';
import UseSkills from '@/hooks/UseSkills';
import { SkillTypes } from '@/types/skill';
import SVG from 'react-inlinesvg';

import Detail from '@/components/UserInfo/Detail/Detail';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import SkillIcon from '@/components/SkillIcon/SkillIcon';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import leftArrow from '@/assets/svg/left-arrow.svg';
import rightArrow from '@/assets/svg/right-arrow-large.svg';
import commonUtils from '@/utils/common';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';

interface StudentCardDesktopProps {
  firstname: string;
  lastname: string;
  level?: number;
  grade: string;
  userName?: string;
  currentProgressBySkill: CurrentProgressBySkill | undefined;
  totalQuizzes?: number;
  quizzesProgress?: number;
  completedQuizzes?: number;
  currentLevelByskill?: StudentsSkillsProgress | undefined;
  emitShowDataModal: (studentId: string) => void;
}
const StudentCardDesktop = ({
  level,
  grade,
  firstname,
  lastname,
  userName,
  currentProgressBySkill,
  totalQuizzes,
  quizzesProgress,
  completedQuizzes,
  currentLevelByskill,
  emitShowDataModal,
}: StudentCardDesktopProps) => {
  const { t } = useTranslation();
  const { getSkillName } = UseSkills();
  const { user } = useAppSelector(authSelector);

  const { getLevelText } = UseLevel();
  const levelText = getLevelText(level);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const isInterfaceHE = interfaceLanguage?.code === 'he';

  const onOpenUserProfile = () => {
    if (userName) {
      emitShowDataModal(userName);
    }
  };

  const emptyProgressBySkill = {
    speak: {
      total: 0,
      actual: 0,
    },
    grammar: {
      total: 0,
      actual: 0,
    },
    read: {
      total: 0,
      actual: 0,
    },
    vocabulary: {
      total: 0,
      actual: 0,
    },
    spelling: {
      total: 0,
      actual: 0,
    },
    listen: {
      total: 0,
      actual: 0,
    },
  };

  const progressBySkill =
    currentProgressBySkill && Object.entries(currentProgressBySkill).length
      ? currentProgressBySkill
      : emptyProgressBySkill;
  const skillsElements = Object.entries(progressBySkill).map((entry) => {
    const [skill, progress] = entry;
    const { actual, total } = progress;
    const percentage = commonUtils.calcPercentage(actual, total);
    return (
      <div key={skill} className={classNames.skill}>
        <SkillIcon className={classNames.icon} skill={skill as SkillTypes} />
        <div className={classNames.texts}>
          <span
            className={classes(classNames.name, [classNames[`color-${skill}`]])}
          >
            {getSkillName(skill as SkillTypes)}
          </span>
          <span>{`${percentage}%`}</span>
        </div>
        {currentLevelByskill?.[skill as SkillTypes] && (
          <h1 className={classNames.levelText}>
            {t('level') + ' ' + currentLevelByskill?.[skill as SkillTypes]}
          </h1>
        )}
      </div>
    );
  });

  const isUniversityGrade = grade.toLowerCase() === 'university';
  const isNoneGrade = grade.toLowerCase() === 'none';

  let gradeValue = null;

  if (!isNoneGrade) {
    if (isUniversityGrade) {
      gradeValue = t('university');
    } else {
      gradeValue =
        isNaN(+grade) || Number.isInteger(+grade)
          ? t(`grade_${commonUtils.fixGradeSymbol(grade)}`)
          : grade;
    }
  }

  return (
    <div className={classNames.studentCardDesktop} onClick={onOpenUserProfile}>
      <div className={classNames.content}>
        <div className={classNames.userInfo}>
          <div className={classNames.profile}>
            {/* <UserAvatar
            className={classNames.img}
            img={imageUrl}
            notificationsAmount={3}
          /> */}
            <div className="flex flex-col gap-0.5">
              <div className="flex items-center gap-2">
                <span className={classNames.firstname}>{firstname}</span>
                <span className={classNames.lastname}>{lastname}</span>
              </div>
              <div className="flex items-center gap-2">
                {/* <Detail
                className={classNames.detail}
                topic={t('username')}
                value={userName}
              />
              <DetailSeperator /> */}
                {gradeValue && (
                  <>
                    <Detail
                      className={classNames.detail}
                      topic={t('grade')}
                      value={isUniversityGrade ? t('university') : gradeValue}
                    />
                    <DetailSeperator className={classNames.seperator} />
                  </>
                )}

                <div
                  className={classes(classNames.level, {
                    [classNames.levelTest]: !level,
                  })}
                >
                  <span>
                    {level
                      ? `${t('level')} ${level}`
                      : t('levelTestIncomplete')}
                  </span>
                  {level ? (
                    <>
                      <DetailSeperator className={classNames.seperator} />
                      <span>{levelText}</span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={classNames.progressAndQuizzes}>
            <button className={classNames.button}>
              <SVG
                src={
                  interfaceLanguage?.direction === 'ltr'
                    ? rightArrow
                    : leftArrow
                }
              />
            </button>
            <div className={classNames.progress}>
              <span className={classNames.text}>{`${quizzesProgress}%`}</span>
              <div className={classNames.barWrapper}>
                <div
                  className={classNames.bar}
                  style={{ width: `${quizzesProgress}%` }}
                ></div>
              </div>
            </div>
            {totalQuizzes ? (
              <span
                className={classNames.quizzes}
              >{`${completedQuizzes}/${totalQuizzes} ${t('quizzes')}`}</span>
            ) : null}
          </div>
        </div>
        <SectionSeperator className="my-4" />
        <div className={classNames.skills}>{skillsElements}</div>
      </div>
    </div>
  );
};

export default StudentCardDesktop;
