import { useTranslation } from 'react-i18next';
import classNames from './TutorDesktop.module.scss';
import classes from 'classnames';
import TutorIcon from '@/assets/svg/tutor/tutorDashboard.svg';
import TutorEvents from '../TutorEvents/TutorEvents';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { tutorSelector } from '@/store/reducers/tutor';
import TutorChatBody from '../../TutorDialogueManager/TutorChatBody/TutorChatBody';
import { Card } from '@/types/tutor';

interface TutorDesktop {
  handleExitConfirmPopup: () => void;
  data: [string, Card[]][];
}

const TutorDesktop = ({ handleExitConfirmPopup, data }: TutorDesktop) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showTutor } = useAppSelector(tutorSelector);

  const content = (
    <div className={classNames.dashboard}>
      {!showTutor && (
        <div className="flex items-center justify-between">
          <h2 className={classNames.title}>{t('settings.my_tutor')}</h2>
        </div>
      )}

      <div className={classNames.header}>
        <div
          className={classes(classNames.banner, {
            [classNames.justifyCenter]: showTutor,
          })}
        >
          {showTutor ? (
            <div className={classNames.tutor}>
              <div className={classNames.back}>
                <span onClick={handleExitConfirmPopup}>{t('tutorBack')}</span>
              </div>
              <img src={TutorIcon} alt="tutorIcon" />
              <p>{t('tutorPrivate')}</p>
            </div>
          ) : (
            <>
              <div className={classNames.content}>
                <p>{t('tutorWelcomeTitle')}</p>
                <span>{t('tutorBannerTitle')}</span>
              </div>
              <img src={TutorIcon} alt="tutorIcon" />
            </>
          )}
        </div>
      </div>
      <div className={classNames.body}>
        {showTutor ? (
          <TutorChatBody isDashboard />
        ) : (
          data.map(([key, value]) => (
            <TutorEvents key={key} title={t(`tutor_${key}`)} data={value} />
          ))
        )}
      </div>
    </div>
  );

  return (
    <div
      className={classes(classNames.myTutorDesktop, {
        [classNames.isShowTutor]: showTutor,
      })}
    >
      {content}
    </div>
  );
};

export default TutorDesktop;
