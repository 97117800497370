import React, { useState, useCallback, FocusEvent } from 'react';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';

import { StudentData, GroupStudentData, StudentValidationError } from '@/types/student-data';
import ValidateStudent from '@/utils/studentDataValidationUtils';
import classNames from '../StudentsTable.module.scss';
import binIcon from '@/assets/svg/bin.svg';
import pencilIcon from '@/assets/svg/pencil.svg';
import saveIcon from '@/assets/svg/check-plain.svg';

interface StudentRowProps {
    student: StudentData | GroupStudentData;
    index: number;
    allowActions?: boolean;
    studentsData: StudentData[] | GroupStudentData[];
    setStudentsData?: React.Dispatch<React.SetStateAction<StudentData[]>>;
    setDisableSubmitButton?: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudentRow: React.FC<StudentRowProps> = ({
    student,
    index,
    allowActions,
    studentsData,
    setStudentsData,
    setDisableSubmitButton
}) => {
    const { t } = useTranslation();

    const [validationErrors, setValidationErrors] = useState<StudentValidationError[]>([]);
    const [editingStudent, setEditingStudent] = useState<StudentData>({ firstName: '', lastName: '', email: '' });
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    const handleStudentRemove = useCallback((index: number) => {
        if(setStudentsData)
        setStudentsData(prev => prev.filter((_, i) => i !== index));
    }, [setStudentsData]);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, field: keyof StudentData) => {
        if (editingStudent && editingIndex !== null) {
            setEditingStudent(prev => ({ ...prev!, [field]: e.target.value }));
        }
    }, [editingStudent, editingIndex]);

    const handleEditClick = (index: number) => {
        setEditingIndex(index);
        if(setDisableSubmitButton) {
            setDisableSubmitButton(true);
        }
        setEditingStudent({ ...studentsData[index] });
    };

    const saveEdits = (index: number) => {
        if (editingStudent !== null) {
            const validationErrors = ValidateStudent(editingStudent);
            if (validationErrors.length > 0) {
                setValidationErrors(validationErrors);
                return;
            }

            const updatedStudents = studentsData.map((student, i) =>
                i === index ? editingStudent : student
            );
            if(setStudentsData) {
                setStudentsData(updatedStudents);
            }
            setValidationErrors([]);
            setEditingIndex(null);
            setEditingStudent({ firstName: '', lastName: '', email: '' });
            if(setDisableSubmitButton) {
                setDisableSubmitButton(false);
            }
        }
    };

    const findErrorMessage = useCallback((field: string): string | undefined => {
        const error = validationErrors.find(error => error.field === field);
        return error ? error.message : undefined;
    }, [validationErrors]);

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const errors = ValidateStudent(editingStudent);
        setValidationErrors(errors);
    };

    return (
        <tr key={index} className={`${classNames.studentRow} ${validationErrors.length > 0 ? classNames.errorRow : ''}`}>
            {['firstName', 'lastName', 'email'].map((field) => (
                <td key={field}>
                    {editingIndex === index ? (
                        <>
                            <input
                                type={field === 'email' ? 'email' : 'text'}
                                value={editingStudent ? editingStudent[field] : ''}
                                onChange={(e) => handleInputChange(e, field)}
                                className={findErrorMessage(field) ? classNames.errorInput : ''}
                                onBlur={handleBlur}
                            />
                            {findErrorMessage(field) && (
                                <span className={classNames.rowError}>{t(`settings.add_students.errors.${findErrorMessage(field)}`)}</span>
                            )}
                        </>
                    ) : (
                        student[field]
                    )}
                </td>
            ))}
            <td className={classNames.actionIcons}>
                {allowActions && (
                    <>
                        {editingIndex === index ? (
                            <button className={classNames.saveSvg} onClick={() => saveEdits(index)}>
                                <SVG src={saveIcon} />
                            </button>
                        ) : (
                            <>
                                <button className={classNames.buttonSvg} onClick={() => handleEditClick(index)}>
                                    <SVG src={pencilIcon} />
                                </button>
                                <button className={classNames.buttonSvg} onClick={() => handleStudentRemove(index)}>
                                    <SVG src={binIcon} className={classNames.closingBtn} />
                                </button>
                            </>
                        )}
                    </>
                )}
            </td>
        </tr>
    );
};

export default StudentRow;