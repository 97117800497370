import { useTranslation } from 'react-i18next';
import classNames from './LoginForm.module.scss';
import { useEffect, useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import QuickLogin from '../QuickLogin/QuickLogin';
import TheButton from '../TheButton/TheButton';
import AppInput from '@/components/AppInput/AppInput';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import UseLogin from '@/pages/login/UseLogin';

interface LoginFormProps {
  // isFirstTime: boolean;
  // loading: boolean;
  // LoginSchema: Yup.ObjectSchema<LoginValues>;
  // OnLoginSubmitted: (values: LoginValues) => Promise<void>;
  // InitialLoginValues: LoginValues;
}

const LoginForm = ({}: LoginFormProps) => {
  const { t } = useTranslation();
  const {
    loading,
    isFirstTime,
    loginStep,
    LoginComponent,
    currentLoginStep,
    stepProps,
  } = UseLogin();

  const { isDesktop } = UseScreenSize();

  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if (isDesktop && !isFirstTime) {
      setShowForm(false);
    }
  }, [isFirstTime, isDesktop]);

  const onGoToForgotPassword = () => {};

  const onQuickLogin = () => {};

  const title = showForm ? t('signIn') : t('recentLogin');
  const subTitle = showForm
    ? t('pleaseEnterYourDetails')
    : t('pleaseSelectYourAccount');

  const name = 'Sarah Jonas';
  const level = 'Foundation';
  const grade = 9;

  const bottom = !showForm && (
    <TheButton
      className={classNames.loginFromDifferent}
      plain
      text={t('loginFromDifferent')}
      emitOnClick={() => setShowForm(true)}
    />
  );

  // const content = showForm ? (
  //   <>old</>
  // ) : (
  //   <QuickLogin
  //     name={name}
  //     level={level}
  //     grade={grade}
  //     emitQuickLogin={onQuickLogin}
  //   />
  // );

  return (
    <div className={classNames.loginForm}>
      {(currentLoginStep?.title || currentLoginStep?.subTitle) && (
        <div className={classNames.top}>
          {currentLoginStep?.title && <h2>{t(currentLoginStep?.title)}</h2>}
          {currentLoginStep?.subTitle && (
            <span>{t(currentLoginStep?.subTitle)}</span>
          )}
        </div>
      )}
      {/* {content} */}
      <div className={classNames.contentWrapper}>
        {LoginComponent && (
          <LoginComponent styles={classNames} {...stepProps()} />
        )}
      </div>
      {!loading && bottom}
    </div>
  );
};

export default LoginForm;
