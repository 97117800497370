import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from './TutorMuteConfirmPopupMobile.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import TheButton from '@/components/TheButton/TheButton';
import { tutorActions } from '@/store/reducers/tutor';
import { gameActions } from '@/store/reducers/game';

type TutorMuteConfirmPopupMobile = {
  emitClose: () => void;
};

const TutorMuteConfirmPopupMobile: React.FC<TutorMuteConfirmPopupMobile> = ({
  emitClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const muteHandler = () => {
    dispatch(tutorActions.muteNotifications());
    dispatch(gameActions.goToNextQuestion({}));
    emitClose();
  };

  return (
    <div className={classNames.assistMuteConfirmPopup}>
      <div className={classNames.header}>
        <div className={classNames.seperator} />
      </div>
      <div className={classNames.body}>
        <div className={classNames.context}>
          <p>{t('tutorNotification')}</p>
          <span>{t('tutorNotificationBody')}</span>
        </div>
        <TheButton
          showArrow
          shouldRecolor={true}
          text={t('tutorNotificationOff')}
          emitOnClick={muteHandler}
          className={classNames.theButton}
        />
        <TheButton
          plain
          text={t('tutorNotificationBack')}
          emitOnClick={emitClose}
        />
      </div>
    </div>
  );
};

export default React.memo(TutorMuteConfirmPopupMobile);
