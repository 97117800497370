import config from '@/config';
import {
  LanguageSettingsResponse,
  LevelSettingsResponse,
  SetUserLanguageSettingsResponse,
  SkillSettingsResponse,
  UserAvatarResponse,
  UpdatedStudentSettingsResponse,
  UpdatedTeacherClassResponse,
  ContactInfoResponse,
  changeTeacherSkillLevelResponse,
  GetGroupResponse,
} from '@/types/response';
import { UserService } from '@/types/services';
import axiosInstance from '@/utils/api';
import UserUtils from '@/utils/user';

export const userService: UserService = {
  fetchSkillSettings: async (user) => {
    const resp: SkillSettingsResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/Settings/Skills`
    );

    return resp;
  },
  fetchLevelSettings: async (user) => {
    const resp: LevelSettingsResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/settings/levels`
    );

    return resp;
  },
  fetchLanguageSettings: async () => {
    const resp: LanguageSettingsResponse | null = await axiosInstance.get(
      `user-metadata/${config.apiVersion}/supported-languages`
    );

    return resp;
  },
  setUserLanguageSettings: async (user, interfaceLanguage: string) => {
    const resp: SetUserLanguageSettingsResponse | null =
      await axiosInstance.post(
        `user-metadata/${config.apiVersion}/${UserUtils.fromToLanguage(
          user
        )}/set-user-language-settings/${user?.id}`,
        {
          interfaceLanguageCode: interfaceLanguage,
        }
      );

    return resp;
  },
  setUserLogOut: async (user) => {
    await axiosInstance.post(
      `user-metadata/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/set-user-logout/${user?.id}`
    );
  },
  updateUserAvatar: async (user, avatarName, avatarColor) => {
    const resp: UserAvatarResponse | null = await axiosInstance.post(
      `user-metadata/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/set-user-avatar/${user?.id}`,
      {
        avatarName,
        avatarColor,
      }
    );

    return resp;
  },
  updateStudentSettings: async (
    user,
    studentId,
    level: number,
    interfaceLanguage: string
  ) => {
    const resp: UpdatedStudentSettingsResponse | null =
      await axiosInstance.post(
        `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
          user
        )}/teacher/dashboard/class-progress/${
          user?.id
        }/update-student-settings/${studentId}`,
        {
          level,
          interfaceLanguage,
        }
      );

    return resp;
  },
  updateTeacherClass: async (user, groupDetails) => {
    const resp: UpdatedTeacherClassResponse | null = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/edit-group-details/${user?.id}`,
      groupDetails
    );

    return resp;
  },

  editGroup: async (user, groupId, groupDetails) => {
    const resp: UpdatedTeacherClassResponse | null = await axiosInstance.post(
      `user-metadata/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/edit-group/${user?.id}/${groupId}`,
      groupDetails
    );

    return resp;
  },

  getGroup: async (user, groupId) => {
    const resp: GetGroupResponse | null = await axiosInstance.get(
      `user-metadata/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/group/${user?.id}/${groupId}`
    );

    return resp;
  },

  changeTeacherSkillLevel: async (user, skill, level) => {
    const resp: changeTeacherSkillLevelResponse = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/my-games/teacher-level/${user?.id}`,
      { skill, level }
    );
    return resp;
  },

  changeStudentLevelBySkill: async (
    teacher,
    studentId,
    selectedLevelsBySkills
  ) => {
    const resp: changeTeacherSkillLevelResponse = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        teacher
      )}/teacher/dashboard/my-students/student-profile/change-student-level-by-skill/${
        teacher?.id
      }/${studentId}`,
      selectedLevelsBySkills
    );
    return resp;
  },

  sendContactInfo: async (user, info) => {
    const resp: ContactInfoResponse | null = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/contact-us`,
      info
    );

    return resp;
  },
};
