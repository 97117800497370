import { SkillTypes } from '@/types/skill';
import WithSettings, { WithSettingsProps } from '@/hocs/WithSettings';
import classNames from './AchievementsSection.module.scss';
import commonUtils from '@/utils/common';
import { Topic } from '@/types/topic';
import classes from 'classnames';
import trophy from '@/assets/svg/trophy-achievement.svg';
import star from '@/assets/images/star-achievement.png';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import UseSkills from '@/hooks/UseSkills';
import UseLocalLang from '@/hooks/UseLocalLang';
import { AchievementOfTheWeek } from '@/types/user';

interface AchievementsSectionProps {
  firstname: string;
  achievement: AchievementOfTheWeek;
}

const AchievementsSection = ({
  firstname,
  achievement,
  t,
}: AchievementsSectionProps & WithSettingsProps) => {
  const localLang = UseLocalLang();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const starElements = Array.from(Array(achievement.achievedStars)).map(
    (rate, index) => <img key={index} src={star} alt="star" />
  );

  return (
    <div className={classNames.achievementsSection}>
      <img
        className={classes(classNames.trophy, {
          [classNames.trophyInHebrew]: interfaceLanguage?.direction === 'rtl',
        })}
        src={trophy}
        alt="trophy"
      />

      <div className={classNames.top}>
        <div className={classNames.text}>
          <div className={classNames.compliment}>
            <h2>{firstname},</h2>
            <h2>{t('greatJob')}</h2>
          </div>
          <span>{t('achievmentsSection.feedback')}</span>
        </div>
      </div>
      <div className={classNames.bottom}>
        <div className={classNames.text}>
          <span className={classNames.weeklyAchievement}>
            {t('achievmentsSection.weeklyAchievement')}
          </span>
          <div className={classNames.info}>
            <div className={classNames.description}>
              <span className={classNames.in}>
                {t('achievmentsSection.in')}
              </span>
              <span>{achievement.quizName}</span>
            </div>
            <div className={classNames.stars}>{starElements}</div>
          </div>
        </div>
        <span className={classNames.skill}>
          {t(`skills.${achievement.skill}`)}
        </span>
      </div>
    </div>
  );
};

export default WithSettings(AchievementsSection);
