import UseScreenSize from '@/hooks/UseScreenSize';
import QuizViewMobile from './QuizViewMobile';
import QuizViewDesktop from './QuizViewDesktop';
import { useEffect } from 'react';
import { useAppDispatch } from '@/store';
import { tutorActions } from '@/store/reducers/tutor';

const QuizView = () => {
  const { isMobile } = UseScreenSize();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(tutorActions.resetMessages());
    };
  }, []);

  return isMobile ? <QuizViewMobile /> : <QuizViewDesktop />;
};

export default QuizView;
