import classNames from './QuizzesSection.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

import QuizzesList from '@/components/QuizzesList/QuizzesList';

import stars from '@/assets/images/stars-3.png';
import SectionSeperator from '../SectionSeperator/SectionSeperator';
import UseScreenSize from '@/hooks/UseScreenSize';

const QuizzesSection = () => {
  const { t } = useTranslation();
  const { isDesktop } = UseScreenSize();
  const { improvableRecentQuizzes, comingUpQuizzes } =
    useAppSelector(authSelector);

  return (
    <div className={classNames.quizzesSection}>
      {isDesktop && (
        <>
          {improvableRecentQuizzes.length ? (
            <QuizzesList
              title={t('improveYourRecentGames')}
              quizzesList={improvableRecentQuizzes}
            />
          ) : (
            <div className={classNames.keepUpMessage}>
              <img src={stars} alt="3-stars" />
              <h2>{t('looksLikeYouOnARoll')}</h2>
              <span>{t('keepUpTheGoodWork')}</span>
            </div>
          )}
        </>
      )}
      {comingUpQuizzes.length > 0 && (
        <>
          {isDesktop && <SectionSeperator className="my-10" />}

          <QuizzesList
            className={improvableRecentQuizzes ? 'mt-5' : ''}
            title={t('nextUpInYourLearningPath')}
            quizzesList={comingUpQuizzes}
          />
        </>
      )}
    </div>
  );
};

export default QuizzesSection;
