import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from './TutorDialogueManager.module.scss';
import classes from 'classnames';
import TutorImage from '@/assets/svg/tutor/chat/tutor.svg';
import CloseMobileIcon from '@/assets/svg/tutor/chat/close_chat.svg';
import CloseDesktopIcon from '@/assets/svg/tutor/chat/closeDesktop.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/store';
import UseScreenSize from '@/hooks/UseScreenSize';
import TutorChatBody from './TutorChatBody/TutorChatBody';

type TutorDialogueManager = {
  emitClose: () => void;
};

const TutorDialogueManager: React.FC<TutorDialogueManager> = ({
  emitClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile, isDesktop } = UseScreenSize();

  return (
    <div
      className={classes(classNames.tutorDialogueManager, {
        [classNames.desktop]: isDesktop,
      })}
    >
      <div className={classNames.chatHeader}>
        {isMobile && <span />}

        <div
          className={classes(classNames.chatHeaderButtons, {
            [classNames.desktop]: isDesktop,
          })}
        >
          {/* <img src={SessionIcon} alt="End Session" onClick={emitClose} /> */}
          <img
            src={isMobile ? CloseMobileIcon : CloseDesktopIcon}
            alt="Close Chat"
            onClick={emitClose}
          />
        </div>
        <div className={classNames.tutorLogo}>
          <img src={TutorImage} />
          <p>{t('tutorPrivate')}</p>
        </div>
      </div>
      <TutorChatBody />
    </div>
  );
};

export default React.memo(TutorDialogueManager);
