import classNames from './ClosedSpelling.module.scss';
import { Block, ClosedSpellingProps, GameStatus } from '@/types/game';
import { useEffect, useMemo, useState } from 'react';
import commonUtils from '@/utils/common';
import { useDispatch } from 'react-redux';
import UseScreenSize from '@/hooks/UseScreenSize';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { studyingLanguageSelector } from '@/store/reducers/auth';
import { useAppSelector } from '@/store';
import UseLocalLang from '@/hooks/UseLocalLang';

import ClosedSpellingImage from './ClosedSpellingImage/ClosedSpellingImage';
import ClosedSpellingOptions from './ClosedSpellingOptions/ClosedSpellingOptions';
import ClosedSpellingDictionary from './ClosedSpellingDictionary/ClosedSpellingDictionary';

const ClosedSpelling = ({
  currentQuestion,
  options,
  correctAnswer,
  pickedAnswer,
  allowRetry,
  emitOnRetry,
  emitOnAnswer,
  emitOnNextQuestion,
}: ClosedSpellingProps) => {
  const localLang = UseLocalLang();
  const dispatch = useDispatch();
  const { isDesktop } = UseScreenSize();
  const {
    currentQuestionIndex,
    gameQuestions,
    selectedQuiz,
    showLetter,
    removeLetter,
    revealTranslation,
  } = useAppSelector(gameSelector);
  const studyingLanguage = useAppSelector(studyingLanguageSelector);
  const { isInEndOfSkillTest, isFinalAssessment } =
    useAppSelector(gameSelector);
  const [answerBlocks, setAnswerBlocks] = useState<Block[]>([]);
  const [optionBlocks, setOptionBlocks] = useState<Block[]>([]);
  const [isFilled, setIsFilled] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [hideTranslation, setHideTranslation] = useState(false);

  const splittedAnswer = correctAnswer?.split(',') ?? [];

  const correctWord = useMemo(
    () => splittedAnswer.join('') ?? '',
    [correctAnswer]
  );

  const isCorrect = useMemo(
    () => pickedAnswer === currentQuestion.answer,
    [pickedAnswer, currentQuestion]
  );

  useEffect(() => {
    setHideTranslation(false);

    if (correctWord) {
      initCorrectAnswerBlock();
    }

    if (options) {
      const optionsBlocks = options.map((option) => {
        const id = commonUtils.generateRandomId(21);

        return {
          id,
          value: option,
          isDisabled: false,
        };
      });
      setOptionBlocks(optionsBlocks);
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (!allowRetry) {
      initCorrectAnswerBlock();
    }
  }, [allowRetry]);

  useEffect(() => {
    if (revealTranslation) {
      setShowBackdrop(true);
    }
  }, [revealTranslation]);

  useEffect(() => {
    if (showLetter && !isFilled) {
      const firstEmptyIndex = answerBlocks.findIndex(
        (block) => !block || !block.value
      );

      const revealedLetter = splittedAnswer[firstEmptyIndex];

      const options = optionBlocks.filter(
        (block) => block.value === revealedLetter
      );

      const randomOption = options[Math.floor(Math.random() * options.length)];

      onSelect(randomOption, false);
      dispatch(gameActions.setShowLetter(false));
    }
  }, [showLetter, isFilled]);

  useEffect(() => {
    if (removeLetter) {
      const availableOptions = optionBlocks.filter((option, index) => {
        const isIncluded = correctWord.includes(option.value);

        const firstIndex = index;
        const lastIndex = commonUtils.findLastIndex(
          optionBlocks,
          (_option: Block) => _option.value === option.value
        );

        const isUnique = firstIndex !== lastIndex;

        return !option.isDisabled && (!isIncluded || (isIncluded && isUnique));
      });

      const randomOption =
        availableOptions[Math.floor(Math.random() * availableOptions.length)];

      const randomOptionIndex = optionBlocks.findIndex(
        (_option) => _option.id === randomOption?.id
      );

      if (randomOptionIndex >= 0) {
        onUpdateOptionsBlocks(randomOptionIndex, {
          ...randomOption,
          isDisabled: true,
        });
      }

      dispatch(gameActions.setRemoveLetter(false));
    }
  }, [removeLetter]);

  const initCorrectAnswerBlock = () => {
    setAnswerBlocks(Array.from(Array(correctWord?.length)).fill(null));
    setIsFilled(false);
  };

  const onRetry = () => {
    initCorrectAnswerBlock();
    setHideTranslation(false);
    setShowBackdrop(revealTranslation);
    setShowFeedback(false);
    setOptionBlocks((prevState) =>
      prevState.map((block) => {
        return {
          ...block,
          isDisabled: false,
        };
      })
    );
    emitOnRetry?.();
  };

  const onUpdateOptionsBlocks = (index: number, option: Block) => {
    setOptionBlocks((prevState) => {
      const updatedState = [...prevState];

      updatedState[index] = option;

      return updatedState;
    });
  };

  const onUpdateAnswerBlocks = (index: number, option: Block) => {
    setAnswerBlocks((prevState) => {
      const updatedState = [...prevState];

      updatedState[index] = option;

      return updatedState;
    });
  };

  const onSelect = async (option: Block, isRemovable?: boolean) => {
    if (isFilled) {
      return;
    }

    const optionBlockIndex = optionBlocks.findIndex(
      (block) => block.id === option.id
    );
    const firstEmptyIndex = answerBlocks.findIndex(
      (block) => !block || !block.value
    );

    onUpdateOptionsBlocks(optionBlockIndex, { ...option, isDisabled: true });
    onUpdateAnswerBlocks(firstEmptyIndex, {
      ...option,
      isRemovable: isRemovable === undefined,
    });

    const filledBlocks = answerBlocks.filter((block) => block && block.value);

    const isLast = filledBlocks.length === correctWord.length - 1;

    if (isLast) {
      setIsFilled(true);

      setHideTranslation(true);
      dispatch(gameActions.toggleGameStatus(GameStatus.QUESTION_ANSWERED));

      const answer = [
        ...filledBlocks.map((block) => block.value),
        option.value,
      ].join(',');

      dispatch(gameActions.setPickedAnswer({ answer }));

      if (!isFinalAssessment) {
        setShowFeedback(true);
        setShowBackdrop(true);
        await commonUtils.sleep(1000);
        setShowFeedback(false);
      }

      emitOnAnswer(answer);

      if (isFinalAssessment) {
        await commonUtils.sleep(1000);
        onNextQuestion();
      }

      return;
    }
  };

  const onRemove = (option: Block) => {
    if (isFilled || !option || option.isRemovable === false) {
      return;
    }

    const optionBlockIndex = optionBlocks.findIndex(
      (block) => block.id === option.id
    );

    onUpdateOptionsBlocks(optionBlockIndex, { ...option, isDisabled: false });

    setAnswerBlocks((prevState) => {
      let updatedState = [...prevState];

      let updatedStateCopy = Array.from(Array(correctWord?.length)).fill(null);

      updatedStateCopy = [...updatedState];

      const index = updatedState.findIndex(
        (block) => block && block.id == option.id
      );

      updatedStateCopy[index] = null;

      return updatedStateCopy;
    });
  };

  const onNextQuestion = () => {
    const isLast = gameQuestions.length - 1 === currentQuestionIndex;

    setShowBackdrop(false);
    setShowFeedback(false);
    setHideTranslation(false);

    if (!isLast) {
      setIsFilled(false);
    }

    emitOnNextQuestion?.();
  };

  return (
    <div className={classNames.closedSpelling}>
      <div
        className={classNames.top}
        style={{
          direction:
            (studyingLanguage?.direction as React.CSSProperties['direction']) ??
            'ltr',
        }}
      >
        <h2>{currentQuestion.question}</h2>
        {isDesktop && selectedQuiz && (
          <span className={classNames.instructions}>
            {commonUtils.getQuizInstructions(selectedQuiz, localLang)}
          </span>
        )}
      </div>

      <div className={classNames.content}>
        <ClosedSpellingImage
          question={currentQuestion}
          allowRetry={
            isInEndOfSkillTest || isFinalAssessment ? false : allowRetry
          }
          showBackdrop={showBackdrop}
          revealTranslation={revealTranslation && !hideTranslation}
          showFeedback={showFeedback}
          isCorrect={isCorrect}
          emitOnRetry={onRetry}
          emitOnNextQuestion={onNextQuestion}
          translation={currentQuestion.dictionaryDetails?.translation ?? ''}
        />
        {isCorrect && !isFinalAssessment && !showFeedback ? (
          <ClosedSpellingDictionary
            answer={correctWord}
            partOfSpeech={currentQuestion.dictionaryDetails?.partOfSpeech ?? ''}
            translation={currentQuestion.dictionaryDetails?.translation ?? ''}
            exampleSentences={
              currentQuestion.dictionaryDetails?.exampleSentences ?? []
            }
            soundPath={currentQuestion.dictionaryDetails?.soundPath ?? ''}
          />
        ) : (
          <ClosedSpellingOptions
            answerBlocks={answerBlocks}
            optionBlocks={optionBlocks}
            isCorrect={isCorrect}
            isFilled={isFilled}
            emitOnSelect={onSelect}
            emitOnRemove={onRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ClosedSpelling;
