import { RootState } from '@/types/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { reportService } from '@/services/report';
import { TeacherGroup, UserState } from '@/types/user';

const reportThunks = {
  getStudentReport: createAsyncThunk(
    'getStudentReport',
    async (currentStudent: UserState | null, { getState }) => {
      try {
        const state = getState() as RootState;
        const { user } = state.auth;
        const studentReport = await reportService.fetchStudentReport(
          currentStudent,
          user
        );
        return studentReport?.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  ),
  getClassReport: createAsyncThunk(
    'getClassReport',
    async (currentClassroom: TeacherGroup | null, { getState }) => {
      try {
        const state = getState() as RootState;
        const { user } = state.auth;

        const classReport = await reportService.fetchClassReport(
          currentClassroom,
          user
        );
        return classReport?.data;
      } catch (error) {
        console.log(error);

        throw error;
      }
    }
  ),
};

export default reportThunks;
