import classNames from './NotFoundContent.module.scss';
import SVG from 'react-inlinesvg';

import close from '@/assets/svg/wrong-circle.svg';

interface NotFoundContentProps {
  title: string;
  subTitle: string;
  description: string;
}

const NotFoundContent = ({
  title,
  subTitle,
  description,
}: NotFoundContentProps) => {
  return (
    <div className={classNames.notFoundContent}>
      <SVG src={close} />
      <span>{subTitle}</span>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};

export default NotFoundContent;
