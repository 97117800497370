import { useTranslation } from 'react-i18next';
import classNames from './GradesFilter.module.scss';
import React, { useEffect, useState } from 'react';
import commonUtils from '@/utils/common';
import SVG from 'react-inlinesvg';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';

interface GradesFilterProps {
  selectedFilter: string;
  selectedFilterText: string;
  emitFilter: (option: string) => void;
}

const GradesFilter = ({
  selectedFilter,
  selectedFilterText,
  emitFilter,
}: GradesFilterProps) => {
  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = useState(false);
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  useEffect(() => {
    setFilterOptions([
      '*',
      ...Array.from({ length: 12 }, (_, i) => String(i + 1)),
      'university',
      'none',
    ]);
  }, []);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showDropdown) {
        return;
      }

      onCloseDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showDropdown]);

  const onToggleDropdown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowDropdown((prevState) => !prevState);
  };

  const onCloseDropdown = () => {
    setShowDropdown(false);
  };

  const onSelectedOption = (
    e: React.MouseEvent<HTMLElement>,
    option: string
  ) => {
    e.stopPropagation();
    onCloseDropdown();
    emitFilter(option);
  };

  const options = filterOptions.map((option, index) => {
    let optionText = '';

    const isUniversityGrade = option === 'university';
    const isNoneGrade = option === 'none';

    if (option === '*') {
      optionText = t('all');
    } else if (isUniversityGrade || isNoneGrade) {
      optionText = t(option);
    } else {
      optionText =
        isNaN(+option) || Number.isInteger(+option)
          ? t(`grade_${commonUtils.fixGradeSymbol(option)}`)
          : option;
    }

    return (
      <div
        key={index}
        className={classNames.option}
        onClick={($event) => onSelectedOption($event, option)}
      >
        <span>{optionText}</span>
      </div>
    );
  });

  const dropdown = showDropdown && (
    <div className={classNames.dropdown}>{options}</div>
  );

  const isUniversityGrade = selectedFilterText === 'university';
  const isNoneGrade = selectedFilterText === 'none';

  let gradeText = '';

  if (selectedFilterText === t('all')) {
    gradeText = selectedFilterText;
  } else if (isUniversityGrade || isNoneGrade) {
    gradeText = t(selectedFilterText);
  } else {
    gradeText =
      isNaN(+selectedFilterText) || Number.isInteger(+selectedFilterText)
        ? t(`grade_${commonUtils.fixGradeSymbol(selectedFilterText)}`)
        : selectedFilterText;
  }

  return (
    <div id="grades_filter" className={classNames.gradesFilter}>
      <div className={classNames.select} onClick={onToggleDropdown}>
        <div className={classNames.texts}>
          <span className={classNames.label}>{t('grade')}:</span>
          <span className={classNames.selectedFilter}>{gradeText}</span>
        </div>
        <SVG src={showDropdown ? upArrow : downArrow} />
      </div>
      {dropdown}
    </div>
  );
};

export default GradesFilter;
