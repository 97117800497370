import { StudentData, StudentValidationError } from '@/types/student-data';

const validateEmail = (email: string): boolean => {
    const pattern = /\S+@\S+\.\S+/;
    return pattern.test(email);
};

const validateName = (name: string): boolean => {
    const pattern = /^[-A-Za-z\s'\u0590-\u05FF\u00C0-\u00FF]+$/;
    return pattern.test(name);
};

const ValidateStudent = (student: StudentData): StudentValidationError[] => {
    const errors: StudentValidationError[] = [];

    if (!student.firstName) {
        errors.push({ field: "firstName", message: "first_name_missing" });
    } else if (!validateName(student.firstName)) {
        errors.push({ field: "firstName", message: "first_name_incorrect" });
    }

    if (!student.lastName) {
        errors.push({ field: "lastName", message: "last_name_missing" });
    } else if (!validateName(student.lastName)) {
        errors.push({ field: "lastName", message: "last_name_incorrect" });
    }

    if (!student.email || !validateEmail(student.email)) {
        errors.push({ field: "email", message: "email_incorrect" });
    }

    return errors; 
};

export default ValidateStudent;