import classNames from './WordsList.module.scss';
import classes from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KnowledgeIcon from '../../../WordKnowledgeControls/KnowledgeIcon/KnowledgeIcon';
import { Question } from '@/types/question';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';

import WordsListItem from '../WordsListItem/WordsListItem';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import UseScreenSize from '@/hooks/UseScreenSize';

interface WordsListProps {
  className?: string;
  emitOnAknowledge?: (id: number, isKnown: boolean) => void;
}

interface Tab {
  value: string;
  isKnown: boolean | null;
}

const WordsList = ({ className, emitOnAknowledge }: WordsListProps) => {
  const { t } = useTranslation();
  const { isDesktop } = UseScreenSize();
  const { gameQuestions } = useAppSelector(gameSelector);

  const tabs: Tab[] = [
    {
      value: 'all',
      isKnown: null,
    },
    {
      value: 'know',
      isKnown: true,
    },
    {
      value: 'dontKnow',
      isKnown: false,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [wordsList, setWordsList] = useState<Question[]>([]);
  const [allKnownWords, setAllKnownWords] = useState<Question[]>([]);
  const [allUnknownWords, setAllUnknownWords] = useState<Question[]>([]);

  const [offsetFromTop, setOffsetFromTop] = useState(0);
  const wordsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAllKnownWords(() =>
      gameQuestions.filter(
        (question) => question.userData?.practiceMode?.isKnown === true
      )
    );

    setAllUnknownWords(() =>
      gameQuestions.filter(
        (question) => question.userData?.practiceMode?.isKnown === false
      )
    );
  }, [gameQuestions]);

  useEffect(() => {
    if (gameQuestions) {
      setWordsList(() => {
        return currentTab.isKnown === true
          ? gameQuestions.filter(
              (question) => question.userData?.practiceMode?.isKnown === true
            )
          : currentTab.isKnown === false
          ? gameQuestions.filter(
              (question) => question.userData?.practiceMode?.isKnown === false
            )
          : [...gameQuestions];
      });
    }
  }, [currentTab, gameQuestions]);

  useEffect(() => {
    // const headerHeight =
    //   document.querySelector('#the-header')?.clientHeight ?? 0;

    const bannerHeight =
      document.querySelector('#app-banner')?.clientHeight ?? 0;

    setOffsetFromTop(
      (wordsListRef.current?.getBoundingClientRect().top ?? 0) +
        bannerHeight +
        15
    );
  }, []);

  const onSelectTab = useCallback((tab: Tab) => {
    setCurrentTab(tab);
  }, []);

  const tabEelements = tabs.map((tab) => {
    const isSelected = currentTab.value === tab.value;
    const amount = (
      tab.isKnown === true
        ? allKnownWords
        : tab.isKnown === false
        ? allUnknownWords
        : gameQuestions
    )?.length;

    return (
      <div
        key={tab.value}
        className={classes(classNames.tab, {
          [classNames.tabSelected]: isSelected,
        })}
        onClick={() => onSelectTab(tab)}
      >
        {tab.isKnown !== null && (
          <KnowledgeIcon
            className={classNames.icon}
            isKnown={tab.isKnown}
            shouldHighlight={true}
          />
        )}
        <span>{`${t(tab.value)} (${amount})`}</span>
      </div>
    );
  });

  const wordsListItems =
    gameQuestions &&
    wordsList.map((wordItem, index) => {
      const isLast = index === wordsList.length - 1;

      return (
        <div className={classNames.wordsListItem} key={wordItem.questionId}>
          <WordsListItem
            question={wordItem}
            emitOnAknowledge={emitOnAknowledge}
          />
          {!isLast && (
            <SectionSeperator className={isDesktop ? 'my-8' : 'my-6'} />
          )}
        </div>
      );
    });

  return (
    <div
      ref={wordsListRef}
      className={classNames.wordsList}
      style={{ height: `calc(100vh - ${offsetFromTop}px)` }}
    >
      <div className={classNames.tabs}>{tabEelements}</div>
      <div className={classes('container', classNames.wordsListContainer)}>
        <div className={classes('container', classNames.wordsListItems)}>
          {wordsListItems}
        </div>
      </div>
    </div>
  );
};

export default WordsList;
