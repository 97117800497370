import { useEffect, useState } from 'react';
import classNames from './EditContainer.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UseLevel from '@/hooks/UseLevel';
import commonUtils from '@/utils/common';
import { Transition } from 'react-transition-group';
import { StudentsSkillsProgress } from '@/types/progress';

import TheButton from '@/components/TheButton/TheButton';
import LevelBySkillSelect from '@/components/LevelBySkillSelect/LevelBySkillSelect';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import rightArrow from '@/assets/svg/right-arrow.svg';
import diamond from '@/assets/svg/diamond.svg';
import infoIcon from '@/assets/svg/info.svg';

interface EditContainerProps {
  level: string;
  interfaceLanguage: string;
  nativeLanguage: string;
  currentLevelByskill?: StudentsSkillsProgress;
  studentName: string;
  emitCloseEdit?: () => void;
  emitSaveEdit?: (
    level: string,
    language: string,
    selectedLevelsBySkills: StudentsSkillsProgress | null
  ) => void;
}

const EditContainer = ({
  level,
  interfaceLanguage,
  nativeLanguage,
  currentLevelByskill,
  studentName,
  emitCloseEdit,
  emitSaveEdit,
}: EditContainerProps) => {
  const { t } = useTranslation();
  const { levels, languages } = useAppSelector(authSelector);

  const [selectedLevel, setSelectedLevel] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [selectedLevelsBySkills, setSelectedLevelsBySkills] =
    useState<StudentsSkillsProgress | null>(null);

  const [showLevelsDropdown, setShowLevelsDropdown] = useState(false);
  const [showLanguagesDropdown, setShowLanguagesDropdown] = useState(false);
  const [currentSkillDropdown, setCurrentSkillDropdown] = useState('');

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const levelArrowSrc = showLevelsDropdown ? upArrow : downArrow;
  const languageArrowSrc = showLanguagesDropdown ? downArrow : rightArrow;

  const { getLevelText } = UseLevel();
  const selectedLevelText = selectedLevel ? getLevelText(+selectedLevel) : null;

  useEffect(() => {
    if (level) {
      setSelectedLevel(level);
    }

    if (interfaceLanguage) {
      setSelectedLanguage(interfaceLanguage);
    }

    if (currentLevelByskill) {
      setSelectedLevelsBySkills(currentLevelByskill);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showLevelsDropdown) {
        return;
      }

      onCloseLevelsDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showLevelsDropdown]);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showLanguagesDropdown) {
        return;
      }

      onCloseLanguagesDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showLanguagesDropdown]);

  const resetFields = () => {
    setSelectedLevel('');
    setSelectedLanguage('');
    setSelectedLevelsBySkills(null);

    setShowLevelsDropdown(false);
    setShowLanguagesDropdown(false);
    setShowConfirmationModal(false);
    setCurrentSkillDropdown('');
  };

  const onShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const onSave = () => {
    setShowConfirmationModal(false);
    emitSaveEdit?.(selectedLevel, selectedLanguage, selectedLevelsBySkills);
  };

  const onCancel = () => {
    resetFields();
    emitCloseEdit?.();
  };

  const onToggleLevelsDropdown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowLevelsDropdown((prevState) => !prevState);
  };

  const onCloseLevelsDropdown = () => {
    setShowLevelsDropdown(false);
  };

  const onSelectedLevel = (
    e: React.MouseEvent<HTMLElement>,
    levelId: number
  ) => {
    e.stopPropagation();
    onCloseLevelsDropdown();
    setSelectedLevel(levelId.toString());
  };

  const onToggleLanguagesDropdown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowLanguagesDropdown((prevState) => !prevState);
  };

  const onCloseLanguagesDropdown = () => {
    setShowLanguagesDropdown(false);
  };

  const onSelectLanguage = (
    e: React.MouseEvent<HTMLElement>,
    language: string
  ) => {
    e.stopPropagation();
    onCloseLanguagesDropdown();
    setSelectedLanguage(language);
  };

  const onSelectLevelBySkill = (skill: string, level: number) => {
    setSelectedLevelsBySkills((prevState) => {
      if (prevState) {
        const updatedState = { ...prevState };

        updatedState[skill] = level;

        return updatedState;
      }

      return null;
    });
  };

  const onDisplayDropdown = (skill: string) => {
    setCurrentSkillDropdown(skill);
  };

  const levelOptions = levels.map((_level) => {
    const levelText = getLevelText(_level.id);

    return (
      <span
        key={_level.id}
        onClick={($event) => onSelectedLevel($event, _level.id)}
      >{`${_level.id} | ${levelText}`}</span>
    );
  });

  const levelsDropdown = showLevelsDropdown && (
    <div className={classNames.dropdown}>{levelOptions}</div>
  );

  const languageOptions = ['en', nativeLanguage].map((language) => {
    return (
      <div key={language} className={classNames.option}>
        <SVG src={commonUtils.getLanguageIcon(language)} />
        <span onClick={($event) => onSelectLanguage($event, language)}>
          {t(language)}
        </span>
      </div>
    );
  });

  const languagesDropdown = showLanguagesDropdown && (
    <div className={classNames.dropdown}>{languageOptions}</div>
  );

  const interfaceLanguageSelect = (
    <div
      className={classes(classNames.selectContainer, classNames.languageSelect)}
    >
      <div className={classNames.select} onClick={onToggleLanguagesDropdown}>
        <span className={classNames.label}>{t('interfaceLanguage')}</span>
        <div className={classNames.inputWrapper}>
          <div className={classNames.input}>
            <SVG src={commonUtils.getLanguageIcon(selectedLanguage)} />
            <span>{t(selectedLanguage)}</span>
          </div>
          <SVG className={classNames.arrow} src={languageArrowSrc} />
        </div>
      </div>
      {languagesDropdown}
    </div>
  );

  const generalLevelSelect = (
    <div className={classNames.selectContainer}>
      {/* <div className={classNames.select} onClick={onToggleLevelsDropdown}> */}
      <div className={classNames.select}>
        <span className={classNames.label}>{t('generalLevel')}</span>
        <div className={classNames.inputWrapper}>
          <div className={classNames.input}>
            <SVG src={diamond} />
            <span>
              {level
                ? `${selectedLevel} | ${selectedLevelText}`
                : t('levelTestIncomplete')}
            </span>
          </div>
          {/* <SVG className={classNames.arrow} src={languageArrowSrc} /> */}
        </div>
      </div>
    </div>
  );

  const levelsBySkillsSelects =
    selectedLevelsBySkills &&
    Object.entries(selectedLevelsBySkills).map((entry, index) => {
      const [key, value] = entry;

      return (
        <LevelBySkillSelect
          key={key}
          className={classes({ [classNames.levelBySkillBottom]: index > 2 })}
          skill={key}
          selectedLevel={value}
          shouldClose={currentSkillDropdown !== key}
          emitSelect={onSelectLevelBySkill}
          emitOpen={onDisplayDropdown}
        />
      );
    });

  const skillsLevelSelects = (
    <div
      className={classes(
        classNames.selectContainer,
        classNames.skillsLevelSelect
      )}
    >
      <div className={classNames.select}>
        <span className={classNames.label}>{t('levelPerSkill')}</span>
        <div className={classNames.selectsContainer}>
          <div className={classNames.levelsBySkillsSelects}>
            {levelsBySkillsSelects}
          </div>
          <div className={classNames.info}>
            <SVG src={infoIcon} />
            <span>{t('yourChangesWillAffectTheGeneralLevel')}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const confirmationModal = (
    <Transition
      in={showConfirmationModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
            />
            <AppModal>
              <div className={classes(classNames.modal, `fade-${state}`)}>
                <h2>{t('saveChanges')}</h2>
                <span> {t('pleaseContact', { studentName })}</span>
                <div className={classNames.confirmationBtns}>
                  <TheButton
                    className={classNames.btn}
                    text={t('save')}
                    shouldRecolor={false}
                    emitOnClick={onSave}
                  />
                  <TheButton
                    className={classNames.btn}
                    text={t('cancel')}
                    emitOnClick={() => setShowConfirmationModal(false)}
                    plain
                  />
                </div>
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  return (
    <div className={classNames.editContainer}>
      {confirmationModal}
      <div className={classNames.selects}>
        {interfaceLanguageSelect}
        {generalLevelSelect}
        {skillsLevelSelects}
      </div>
      <div className={classNames.buttons}>
        <TheButton
          className="flex-1 text-base"
          text={t('cancel')}
          emitOnClick={onCancel}
          plain
        />
        <TheButton
          className="flex-1 text-base"
          text={t('save')}
          shouldRecolor={false}
          emitOnClick={onShowConfirmationModal}
        />
      </div>
    </div>
  );
};

export default EditContainer;
