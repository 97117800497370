import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { InitialLoginProps } from '@/types/login';
import AppInput from '../AppInput/AppInput';
import TheButton from '../TheButton/TheButton';
import AppLoaderCircle from '../AppLoaderCircle';

const InitialLogin = ({
  emitSubmit,
  goToResetPassword,
  loading,
  styles,
}: InitialLoginProps) => {
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(t('validation.email_required').toString()),
    password: Yup.string().required(
      t('validation.password_required').toString()
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: emitSubmit ?? (() => {}),
  });

  const onClear = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    name: string
  ) => {
    formik.setFieldValue(name, '');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    formik.setFieldValue(
      name,
      value
        .replace(/\s/g, '')
        .replace(/\u202B|\u202C/g, '')
        .split('')
        .join('')
    );
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <AppInput
          className={styles.formField}
          inputWrapperClassName={styles.appInputWrapper}
          id="email"
          name="email"
          label={t('email') ?? ''}
          placeholder={t('email') ?? ''}
          value={formik.values?.email?.trim()}
          error={formik.errors.email}
          allowClear={true}
          emitChange={handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />
        <AppInput
          className={styles.formField}
          inputWrapperClassName={styles.appInputWrapper}
          id="password"
          name="password"
          type="password"
          label={t('password') ?? ''}
          placeholder={t('password') ?? ''}
          value={formik.values?.password?.trim()}
          error={formik.errors.password}
          allowClear={true}
          emitChange={handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />

        {!loading && (
          <>
            <TheButton
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
              className={styles.submit}
              text={t('signIn')}
            />
            <a
              className="text-center cursor-pointer"
              onClick={goToResetPassword}
            >
              {t('forgotPassword')}
            </a>
          </>
        )}
        {loading && (
          <div className={styles.loadingWrapper}>
            <AppLoaderCircle />
            <div className="mt-3">{t('signYouIn')}</div>
          </div>
        )}
      </form>
    </>
  );
};

export default InitialLogin;
