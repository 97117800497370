import { useTranslation } from 'react-i18next';
import classNames from './AvatarSelection.module.scss';
import classes from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import { useDispatch } from 'react-redux';
import commonUtils from '@/utils/common';
import SVG from 'react-inlinesvg';

import TheButton from '../TheButton/TheButton';
import { userService } from '@/services';
import UseScreenSize from '@/hooks/UseScreenSize';

interface AvatarSelectionProps {
  className?: string;
  emitClose: () => void;
}

const AvatarSelection = ({ className, emitClose }: AvatarSelectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAppSelector(authSelector);
  const { isDesktop } = UseScreenSize();

  const avatarImages = commonUtils.getAvatarImages();
  const backgroundColors = commonUtils.getAvatarBackgroundColors();

  const [avatarName, setAvatarName] = useState(user?.avatar?.avatarName || '');
  const [avatarColor, setAvatarColor] = useState(
    user?.avatar?.avatarColor || backgroundColors[0]
  );

  const onSelectImg = (img: string) => {
    setAvatarName(img);
  };

  const onSelectBackgroundColor = (backgroundColor: string) => {
    setAvatarColor(backgroundColor);
  };

  const onConfirm = async () => {
    if (avatarName && avatarColor) {
      try {
        const response = await userService.updateUserAvatar(
          user,
          avatarName,
          avatarColor
        );
        if (response) {
          dispatch(
            authActions.setAvatar({
              avatarName,
              avatarColor,
            })
          );
          return emitClose();
        }
      } catch (error) {
        console.log(error);
      }
    }

    emitClose();
  };

  const secondButton = !user?.avatar && (
    <TheButton text={t('skip')} emitOnClick={emitClose} plain />
  );

  const imageOptions = Object.entries(avatarImages).map((entry) => {
    const [key, value] = entry;
    const isSelected = key === avatarName;

    return (
      <div
        key={key}
        className={classes(classNames.avatarImg, {
          [classNames.avatarImgSelected]: isSelected,
        })}
        style={{ backgroundColor: avatarColor }}
        onClick={() => onSelectImg(key)}
      >
        <SVG src={value} />
      </div>
    );
  });

  const backgroundColorOptions = backgroundColors.map((backgroundColor) => {
    const isSelected = backgroundColor === avatarColor;

    return (
      <button
        key={backgroundColor}
        className={classes(classNames.backgroundColor, {
          [classNames.backgroundColorSelected]: isSelected,
        })}
        style={{ backgroundColor }}
        onClick={() => onSelectBackgroundColor(backgroundColor)}
      ></button>
    );
  });

  const topContent = user?.avatar ? (
    <>
      <div className={classNames.profile}>
        <div
          className={classNames.avatar}
          style={{
            backgroundColor: avatarColor ?? user.avatar.avatarColor,
          }}
        >
          <SVG
            src={
              commonUtils.getAvatarImage(avatarName) ??
              commonUtils.getAvatarImage(user.avatar.avatarName) ??
              ''
            }
          />
        </div>
        <span
          className={classNames.fullname}
        >{`${user.firstName} ${user.lastName}`}</span>
      </div>
      <div className={classNames.chooseYourFavoriteAvatar}>
        {t('chooseYourFavoriteAvatar')}
      </div>
    </>
  ) : (
    <div className={classNames.welcome}>
      <h2>{`${t('welcome')}!`}</h2>
      <span>{t('readyToChooseYourAvatar')}</span>
    </div>
  );

  let confirmBtnText = t('chooseAvatar');

  if (user?.avatar?.avatarName) {
    confirmBtnText = isDesktop ? t('save') : t('update');
  }

  return (
    <div className={classes(classNames.avatarSelection, className)}>
      <div className={classNames.top}>{topContent}</div>
      <div
        className={classes(classNames.body, {
          // 'h-4/5': isDesktop && !user?.avatar?.avatarName,
          // 'h-3/5': isDesktop && user?.avatar?.avatarName,
        })}
      >
        <div className={classNames.avatarImages}>{imageOptions}</div>
        <div className={classNames.backgroundColors}>
          {backgroundColorOptions}
        </div>
      </div>
      <div className={classNames.bottom}>
        <TheButton
          className={classes({
            [classNames.updateBtn]: user?.avatar,
          })}
          text={confirmBtnText}
          disabled={!avatarName}
          emitOnClick={onConfirm}
          shouldRecolor={false}
        />
        {secondButton}
      </div>
    </div>
  );
};

export default AvatarSelection;
