import { gameSelector } from '@/store/reducers/game';
import QuizImageIndicator from '../QuizImageIndicator/QuizImageIndicator';
import classNames from './QuizContent.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';

interface QuizContentProps {
  className?: string;
  children: React.ReactNode;
  shouldDisplayImageIndicator?: boolean;
  shouldDisplayStars?: boolean;
  isTutor?: boolean;
}

const QuizContent = ({
  className,
  children,
  shouldDisplayImageIndicator = true,
  shouldDisplayStars = true,
  isTutor = false,
}: QuizContentProps) => {
  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);

  return (
    <div
      className={classes(classNames.quizContent, className, {
        [classNames.isTutor]: isTutor,
        [classNames.quizContentLevelTest]: isLevelTest,
      })}
    >
      {shouldDisplayImageIndicator && !isTutor && (
        <QuizImageIndicator
          className={classes(classNames.quizImageIndicator, {
            [classNames.quizImageIndicatorFinalAssessment]: isFinalAssessment,
          })}
          shouldDisplayStars={shouldDisplayStars}
          starsClassName={classNames.stars}
          skillClassName={classNames.skill}
        />
      )}
      {children}
    </div>
  );
};

export default QuizContent;
