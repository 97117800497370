import classes from 'classnames';
import { SkillItem, SkillTypes } from '@/types/skill';
import Skeleton from 'react-loading-skeleton';
import classNames from './Skill.module.scss';
import SVG from 'react-inlinesvg';

import { useTranslation } from 'react-i18next';
import commonUtils from '@/utils/common';
import { SkillSettingsItem } from '@/types/user';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseThemeColors from '@/hooks/UseThemeColors';
import UseLocalLang from '@/hooks/UseLocalLang';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useEffect, useRef, useState } from 'react';
import SmallDropDown from '@/components/SmallDropDown/SmallDropDown';
import arrowDown from '@/assets/svg/down-arrow24.svg';
import arrowUp from '@/assets/svg/up-arrow.svg';
import UserUtils from '@/utils/user';
interface SkillProps {
  shouldHighlight?: boolean;
  loading: boolean;
  actual?: number;
  total?: number;
  goToSkill: ({
    type,
    skill,
  }: {
    type: SkillTypes;
    skill: SkillSettingsItem;
  }) => void;
  hasProgress?: boolean;
  skill: SkillSettingsItem;
}

const Skill = ({
  type,
  loading,
  shouldHighlight,
  hasProgress = true,
  backgroundColor,
  textColor,
  progressAmount,
  progressColor,
  actual,
  total,
  skill,
  lastTimePlayed,
  goToSkill,
}: SkillProps & SkillItem) => {
  const { t } = useTranslation();
  const { color } = UseThemeColors();
  const { windowWidth, isDesktop } = UseScreenSize();
  const currentRoute = UseCurrentRoute();
  const isLocal = UseLocalLang();
  const { user } = useAppSelector(authSelector);
  const [dropDown, setDropDown] = useState(false);
  const isTeacher = UserUtils.isTeacher(user);
  const isTeacherDashboard = currentRoute == ROUTE_NAMES.MY_STUDENTS;
  const progressAmountElement = hasProgress ? (
    <span className={classNames.progress}>
      {actual}/{total}
    </span>
  ) : null;

  const progressBarElement = (
    <div className={classNames.progressBar}>
      {loading ? (
        <Skeleton />
      ) : (
        <div
          className={classNames.progressBarInner}
          style={{
            width: `${progressAmount}%`,
            backgroundColor: isTeacherDashboard
              ? color('blue-dark')
              : progressColor,
          }}
        ></div>
      )}
    </div>
  );
  const imageRenderer = loading ? (
    <Skeleton
      circle
      width="80px"
      height="80px"
      containerClassName={classNames.imageSkeleton}
    />
  ) : (
    <button
      className={classNames.skillCircle}
      style={
        isDesktop
          ? {
              background: `conic-gradient(${textColor} 0% ${progressAmount}%, ${backgroundColor} ${progressAmount}% 100%)`,
            }
          : {}
      }
    >
      <SVG
        src={commonUtils.skillImage(type)}
        style={{
          backgroundColor: backgroundColor,
        }}
      />
    </button>
  );

  const toggleLevelDropdown = (event: React.MouseEvent) => {
    if (!isTeacher) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    setDropDown((prev) => !prev);
  };

  const progressTextRenderer = loading ? (
    <Skeleton
      className={classNames.progressTextSkeletion}
      width="80px"
      height="14px"
    />
  ) : (
    <div className={classNames.text}>
      <span>{commonUtils.getName(skill, isLocal)}</span>
      {progressAmountElement}
    </div>
  );
  return (
    <div
      onClick={() => goToSkill({ type, skill })}
      className={classes(classNames.skill, {
        [classNames.skillPlain]: !shouldHighlight,
        [classNames.skillTeacherDashboard]: isTeacherDashboard,
      })}
      style={{
        backgroundColor: isDesktop
          ? '#FAFAFA'
          : shouldHighlight
          ? backgroundColor
          : '',
      }}
    >
      {imageRenderer}
      <div className={classNames.content}>
        <div className={classNames.topTextContainer}>
          <h1>{t(`skills.${skill.name?.toLowerCase()}`)}</h1>
          <h2>{progressAmount}%</h2>
        </div>
        {user?.currentLevelByskill && (
          <div className={classNames.levelContainer}>
            <button
              style={{ zIndex: 1000 }}
              className={classNames.levelButton}
              onClick={toggleLevelDropdown}
            >
              {!dropDown && (
                <h1
                  className={classNames.levelText}
                  style={{ color: textColor }}
                >
                  {t('level') +
                    ' ' +
                    user?.currentLevelByskill[
                      skill.name?.toLowerCase() as SkillTypes
                    ]}
                </h1>
              )}

              {isTeacher && <SVG src={arrowDown} />}
            </button>

            <SmallDropDown
              open={dropDown}
              currentLevel={
                user?.currentLevelByskill[
                  skill.name?.toLowerCase() as SkillTypes
                ]
              }
              skill={skill.name}
              setOpen={setDropDown}
            />
          </div>
        )}
      </div>

      {/* {progressTextRenderer} */}
      {!isDesktop && progressBarElement}
    </div>
  );
};

export default Skill;
