import commonUtils from '@/utils/common';
import classNames from './QuizImageIndicator.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';
import { TopicTypes } from '@/types/topic';

interface QuizImageIndicatorProps {
  className?: string;
  starsClassName?: string;
  skillClassName?: string;
  shouldDisplayStars?: boolean;
}

const QuizImageIndicator = ({
  className,
  starsClassName,
  skillClassName,
  shouldDisplayStars = true,
}: QuizImageIndicatorProps) => {
  const { selectedQuiz, selectedType, isInEndOfSkillTest } =
    useAppSelector(gameSelector);
  const achieivedStars = selectedQuiz?.userData?.cumulativeAchievedStars ?? 0;

  const starsObj =
    selectedQuiz && selectedQuiz.userData?.attempts && achieivedStars > 0
      ? commonUtils.getStarImage(achieivedStars)
      : null;

  let src = '';
  if (selectedQuiz || isInEndOfSkillTest) {
    src = commonUtils.skillImage(selectedType ?? SkillTypes.Grammar);
  }

  return (
    <div className={classes(classNames.quizImageIndicator, className)}>
      {shouldDisplayStars && starsObj && (
        <img
          className={classes(classNames.stars, starsClassName)}
          src={starsObj.img}
          alt="stars"
        />
      )}
      <SVG className={classes(classNames.skill, skillClassName)} src={src} />
    </div>
  );
};

export default QuizImageIndicator;
