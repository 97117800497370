import { RootState } from '@/types/common';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TeacherState } from '@/types/teacher-dashboard';
import { UserState } from '@/types/user';

const initialState: TeacherState = {
  students: [],
};

const teacher = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    setStudents: (state, { payload }: PayloadAction<UserState[]>) => {
      state.students = payload;
    },
    editStudentsGrade: (state, { payload }: PayloadAction<string>) => {
      state.students = state.students.map((student) => {
        student.grade = payload;

        return student;
      });
    },
    updateStudent: (
      state,
      { payload }: PayloadAction<{ userName: string; newStudent: UserState }>
    ) => {
      state.students = state.students.map((student) => {
        if (student.userName === payload.userName) {
          return {
            ...payload.newStudent,
          };
        } else {
          return student;
        }
      });
    },
  },
});

export const teacherActions = teacher.actions;

export const teacherSelector = (state: RootState) => state.teacher;

export default teacher.reducer;
