import classNames from './TutorPopUpKeepGoing.module.scss';
import classes from 'classnames';
import { PopUpKeepGoingProps } from '@/types/game';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useState } from 'react';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

import TheButton from '@/components/TheButton/TheButton';

import coinsRow from '@/assets/svg/coins-row.svg';
import keepGoing from '@/assets/svg/tutor/keepGoing.svg';
import close from '@/assets/svg/close.svg';
import { tutorActions } from '@/store/reducers/tutor';
import { useDispatch } from 'react-redux';
import { TriggeredBy } from '@/types/tutor';

const TutorPopUpKeepGoing = ({
  coins,
  questionsLeft,
  tip,
  className,
  emitOnClosePopUp,
}: PopUpKeepGoingProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktop, isMobile } = UseScreenSize();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const [shouldExpand, setShouldExpand] = useState(false);

  const onExpand = () => {
    setShouldExpand(true);
  };

  const onReduce = () => {
    setShouldExpand(false);
  };

  const titleElement = (
    <h3 className={classNames.title}>{t('keepGoing') + '!'}</h3>
  );

  const descriptionElement = (
    <div className={classNames.text}>
      <span>{t('almostThere')}</span>
      <span className={classNames.questionsLeft}>
        {t('questionsLeft')}: {questionsLeft}
      </span>
    </div>
  );

  const toggleTutor = () => {
    emitOnClosePopUp?.();
    dispatch(tutorActions.openTutor(TriggeredBy.KeepGoing));
  };

  const imgElement = (
    <div className={classNames.tutor}>
      <img src={keepGoing} />
      {isMobile && (
        <>
          <div className={classNames.title}>{t('tutorKeepGoing')}</div>
          <a onClick={toggleTutor}>{t('tutorPractice')}</a>
        </>
      )}
    </div>
  );

  const coinsTextElement = (
    <div className={classNames.coins}>
      <SVG src={coinsRow} />
      <span>
        +{coins} {t('coins')}
      </span>
    </div>
  );

  return (
    <div className={classes('container', classNames.popUpKeepGoing, className)}>
      {shouldExpand && isMobile && <div className={classNames.backdrop}></div>}
      {isDesktop && (
        <div className={classNames.desktopContent}>
          {imgElement}
          <div className={classNames.content}>
            {coinsTextElement}
            {titleElement}
            {descriptionElement}
          </div>
        </div>
      )}
      {isMobile && (
        <>
          {descriptionElement}
          {titleElement}
          {imgElement}
        </>
      )}
      <div
        className={classes(classNames.bottom, {
          [classNames.expanded]: shouldExpand,
          [classNames.bottomNoTip]: !tip,
        })}
      >
        {shouldExpand && (
          <div className={classNames.close} onClick={onReduce}>
            <SVG src={close} />
          </div>
        )}
        <div
          className={classes(classNames.tipAndButton, {
            [classNames.tipAndButtonMobile]: isMobile,
          })}
        >
          {isDesktop ? (
            <>
              <div className={classNames.content}>
                <p>{t('tutorPrivateTutorTip')}</p>
                <span>{t('tutorKeepGoing')}</span>
              </div>
              <a onClick={toggleTutor}>{t('tutorMoreTips')}</a>
              <TheButton
                shouldRecolor={false}
                className={classNames.button}
                text={t('nextQuestion')}
                emitOnClick={() => {
                  dispatch(
                    tutorActions.setSuggestedTriggered(TriggeredBy.KeepGoing)
                  );
                  emitOnClosePopUp?.();
                }}
                showArrow={true}
              />
            </>
          ) : (
            <>
              {coinsTextElement}
              <TheButton
                shouldRecolor={false}
                className={classNames.button}
                text={isDesktop ? t('continueQuiz') : t('tutorContinueQuiz')}
                emitOnClick={() => {
                  dispatch(
                    tutorActions.setSuggestedTriggered(TriggeredBy.KeepGoing)
                  );
                  emitOnClosePopUp?.();
                }}
                showArrow={true}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TutorPopUpKeepGoing;
