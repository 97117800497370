import { progressService } from '@/services';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { LevelProgress, SkillsOverview } from '@/types/progress';
import { AchievementOfTheWeek } from '@/types/user';
import { useEffect, useState } from 'react';

const UseMyProfile = () => {
  const { user, userProgress } = useAppSelector(authSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [skillsOverview, setSkillsOverview] = useState<SkillsOverview | null>(
    null
  );
  const [totalStars, setTotalStars] = useState(0);
  const [bestOfTheWeek, setBestOfTheWeek] =
    useState<AchievementOfTheWeek | null>(null);

  //doesn't exist anymore in the backend
  // const [currentLevelProgress, setCurrentLevelProgress] =
  //   useState<LevelProgress | null>(null);
  const [levelAchievements, setLevelAchievements] = useState<
    LevelProgress[] | null
  >(null);

  const progressEntries = userProgress ? Object.entries(userProgress) : [];

  const allActual = progressEntries.reduce(
    (acc, entry) => acc + (entry[1]?.actual ?? 0),
    0
  );

  const allTotal = progressEntries.reduce(
    (acc, entry) => acc + (entry[1]?.total ?? 0),
    0
  );

  const fetchProfileProgress = async () => {
    setIsLoading(true);
    try {
      const response = (await progressService.fetchProfileProgress(user))?.data;
      if (response) {
        setSkillsOverview(response.skillsOverview);
        setTotalStars(response.totalStars);
        //doesn't exist anymore in the backend
        // setCurrentLevelProgress(response.currentLevelProgress);
        setLevelAchievements(response.levelAchievement);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const fetchBestScoreOfTheWeek = async () => {
    setIsLoading(true);

    try {
      const score = (await progressService.fetchBestScoreOfTheWeek(user))?.data;

      if (score && score !== null) {
        setBestOfTheWeek(score);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!user?.level) {
      return;
    }

    fetchProfileProgress();
    fetchBestScoreOfTheWeek();
  }, [user?.level]);

  return {
    isLoading,
    allActual,
    allTotal,
    skillsOverview,
    //doesn't exist anymore in the backend
    // currentLevelProgress,
    totalStars,
    levelAchievements,
    bestOfTheWeek,
  };
};

export default UseMyProfile;
