import { GameTypeProps, MultiChoiceProps } from '@/types/game';
import UseScreenSize from '@/hooks/UseScreenSize';
import classes from 'classnames';
import classNames from './MultiChoiceTutor.module.scss';
import commonUtils from '@/utils/common';
import { useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';

import MultiChoiceTutorQuestion from './MultiChoiceTutorQuestion/MultiChoiceTutorQuestion';
import MultiChoiceTutorOptions from './MultiChoiceTutorOptions/MultiChoiceTutorOptions';
import { useDispatch } from 'react-redux';
import UseLocalLang from '@/hooks/UseLocalLang';
import { useTranslation } from 'react-i18next';

const MultiChoiceTutor = ({
  currentQuestion,
  options,
  correctAnswer,
  pickedAnswer,
  showAnswerInOptions,
  emitOnAnswer,
  emitOnNextQuestion,
  emitOnRetry,
  isTutor = false,
}: GameTypeProps & MultiChoiceProps) => {
  const localLang = UseLocalLang();
  const { isDesktop, isMobile } = UseScreenSize();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    selectedType,
    selectedQuiz,
    showOverlay,
    revealWord,
    isLevelTest,
    isInEndOfSkillTest,
    isFinalAssessment,
  } = useAppSelector(gameSelector);

  const onPickOption = (option: string) => {
    if (showOverlay === true) {
      return;
    }

    emitOnAnswer(option);
  };

  const handleRetry = () => {
    dispatch(gameActions.setPictureOverlay(false));
    dispatch(gameActions.resetPickedAnswer());

    emitOnRetry?.();
  };

  return (
    <div
      className={classes(classNames.multiChoice, {
        [classNames.multiChoiceFinalAssessment]: isFinalAssessment,
        [classNames.isTutor]: isTutor,
        [classNames.isMobile]: isMobile,
        [classNames.isDesktop]: isDesktop && !isTutor, // Ensure this doesn't apply when isTutor is true
        [classNames.isTutorIsDesktop]: isTutor && isDesktop, // This is a new class for the combined condition
      })}
    >
      {isDesktop && selectedQuiz && !isTutor && (
        <div className={classNames.instructionsContainer}>
          {isLevelTest && (
            <h2 className={classNames.testYourEnglishText}>
              {t('testYourEnglish')}
            </h2>
          )}
          <h2
            className={classes({
              [classNames['instructions-ltr']]: isFinalAssessment,
            })}
          >
            {commonUtils.getQuizInstructions(selectedQuiz, localLang)}
          </h2>
        </div>
      )}
      {revealWord && (
        <div className={classNames.revealWord}>{currentQuestion.question}</div>
      )}

      <MultiChoiceTutorQuestion
        question={currentQuestion}
        selectedType={selectedType}
        handleRetry={handleRetry}
        emitOnNextQuestion={emitOnNextQuestion}
        blankFill={pickedAnswer ? correctAnswer : ''}
        isTutor={isTutor}
      />
      <MultiChoiceTutorOptions
        pickedAnswer={pickedAnswer ?? ''}
        showAnswerInOptions={showAnswerInOptions}
        correctAnswer={correctAnswer}
        coins={currentQuestion.coins}
        options={options}
        onSelect={onPickOption}
        isTutor={isTutor}
      />
    </div>
  );
};

export default MultiChoiceTutor;
