import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TeacherDashboardState } from '@/types/teacher-dashboard';
import { RootState } from '@/types/common';
import { BaseGroupDetails, EditGroupDetails } from '@/types/register';
import teacherDashboardThunks from '../thunks/teacher-dashboard';

const defaultTeacherDashboardState: TeacherDashboardState = {
  isNew: false,
  selectedClassId: '',
  groups: {},
};

const teacherDashboard = createSlice({
  name: 'TeacherDashboard',
  initialState: defaultTeacherDashboardState,
  reducers: {
    onAdd: (state) => {
      state.isNew = true;
      state.selectedClassId = '';
    },
    onEdit: (state, { payload }: PayloadAction<string>) => {
      state.isNew = false;
      state.selectedClassId = payload;
    },
    onReset: (state) => {
      state.isNew = false;
      state.selectedClassId = '';
    },
    // Action to add a new group or edit an existing one
    addOrEditGroup: (state, { payload }: PayloadAction<EditGroupDetails>) => {
      state.groups[payload.groupId] = payload;
    },
    // Action to remove a group
    removeGroup: (state, { payload }: PayloadAction<string>) => {
      delete state.groups[payload];
    },
  },
  extraReducers: {
    [teacherDashboardThunks.getGroupDetails.fulfilled.toString()]: (
      state,
      {
        payload,
      }: PayloadAction<{ groupData: BaseGroupDetails; groupId: string }>
    ) => {
      state.groups[payload.groupId] = payload.groupData;
    },
    [teacherDashboardThunks.updateGroupDetails.fulfilled.toString()]: (
      state,
      {
        payload,
      }: PayloadAction<{ groupData: BaseGroupDetails; groupId: string }>
    ) => {
      state.groups[payload.groupId] = payload.groupData;
    },
    [teacherDashboardThunks.createGroup.fulfilled.toString()]: (
      state,
      {
        payload,
      }: PayloadAction<{ groupData: BaseGroupDetails; groupId: string }>
    ) => {
      state.groups[payload.groupId] = payload.groupData;
    },
  },
});

export const teacherDashboardActions = teacherDashboard.actions;

export const teacherDashboardSelector = (state: RootState) =>
  state.teacherDashboard;

export default teacherDashboard.reducer;
