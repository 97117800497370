import { RootState } from '@/types/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { progressService, skillService } from '@/services';
import { appSettingsActions } from '../reducers/settings';
import axios from 'axios';

const questionsThunk = {
  refreshTopicQuizQuestions: createAsyncThunk(
    'refreshTopicQuizQuestions',
    async (_, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const { selectedType, selectedTopic, selectedQuiz } = state.game;
        const user = state.auth.user;

        if (!user || !selectedTopic || !selectedType || !selectedQuiz) {
          return;
        }

        const questionsResponse = await skillService.fetchQuestions(
          selectedType,
          user,
          selectedTopic.id,
          selectedQuiz.id
        );

        const questions = questionsResponse?.data?.questions ?? [];

        const progress =
          (
            await progressService.fetchQuestionsProgress(
              selectedType,
              user,
              selectedTopic.id,
              selectedQuiz.id
            )
          )?.data ?? {};

        for (const question of questions) {
          const questionProgress = progress[question.questionId];

          if (questionProgress) {
            question.userData = questionProgress;
          }

          if (question.imagePath != null) {
            const response = await axios.get(question.imagePath, {
              headers: {
                Authorization: `Bearer ${state.auth.token}`,
              },
            });

            question.imageBase64 = response.data as string;
          }
        }

        dispatch(
          appSettingsActions.appendQuestions({
            topicId: selectedTopic.id,
            quizId: selectedQuiz.id,
            questions: questions,
          })
        );

        return questions;
      } catch (error) {
        throw error;
      }
    }
  ),
};

export default questionsThunk;
