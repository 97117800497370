import { progressService, userService } from '@/services';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { teacherActions, teacherSelector } from '@/store/reducers/teacher';
import {
  StudentProgressBySkill,
  StudentsSkillsProgress,
} from '@/types/progress';
import { SkillTypes } from '@/types/skill';
import { TeacherGroup, UserState } from '@/types/user';
import commonUtils from '@/utils/common';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

const UseTeacher = () => {
  const { user } = useAppSelector(authSelector);

  const classrooms = useMemo(
    () => (user?.teacherGroups ? [...user.teacherGroups] : []),
    [user?.teacherGroups]
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);

  const { students } = useAppSelector(teacherSelector);
  const [statistics, setStatistics] = useState<StudentsSkillsProgress | null>(
    null
  );

  const [showModal, setShowModal] = useState(false);
  const [showSkillData, setShowSkillData] = useState(false);
  const [showAssessments, setShowAssessments] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<UserState | null>(
    null
  );

  const [selectedSkill, setSelectedSkill] = useState<{
    value: SkillTypes;
    progress: StudentProgressBySkill;
  } | null>(null);

  const [showEdit, setShowEdit] = useState(false);

  const [currentClassroom, setCurrentClassroom] = useState<TeacherGroup>(
    classrooms[0]
  );

  const [searchValue, setSearchValue] = useState('');

  const fetchClassProgress = async (
    currentClassroomFromOnSelectFunction?: any
  ) => {
    if (!currentClassroom) {
      return;
    }

    setIsLoading(true);
    try {
      const response = (
        await progressService.fetchClassProgress(
          user,
          `${user?.organizationId}_${
            currentClassroomFromOnSelectFunction
              ? currentClassroomFromOnSelectFunction?.id
              : currentClassroom?.id
          }`
        )
      )?.data;

      if (response) {
        const mappedStudents = response.studentsList.map((student) => {
          const { completedQuizzes, totalQuizzes } =
            student.studentProgress ?? {};

          const quizzesProgress = commonUtils.calcPercentage(
            completedQuizzes ?? 0,
            totalQuizzes ?? 0
          );

          return {
            ...student,
            quizzesProgress,
          };
        });

        dispatch(teacherActions.setStudents(mappedStudents as UserState[]));
        setStatistics(response.studentsProgressByLevelThenSkill.All);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!students.length && !statistics) {
      fetchClassProgress();
    }
  }, []);

  const onShowModal = async (studentId: string) => {
    const student = students.find((i) => i.userName === studentId) ?? null;

    if (!student?.level) {
      setShowModal(true);
      setSelectedStudent(student);
      return;
    }

    setIsLoadingProgress(true);

    try {
      const response = (
        await progressService.fetchStudentProgress(user, studentId)
      )?.data;

      if (response) {
        const student = students.find((i) => i.userName === studentId);

        if (student) {
          const studentClone = { ...student };
          studentClone.streak = response.streak;
          studentClone.skillsOverview = response.skillsOverview;

          setShowModal(true);
          setSelectedStudent(studentClone);
        }
      }

      setIsLoadingProgress(false);
    } catch (error) {
      console.log(error);
      setIsLoadingProgress(false);
    }
  };

  const onHideModal = () => {
    setShowModal(false);
    setSelectedStudent(null);
    setShowSkillData(false);
    setSelectedSkill(null);
  };

  const onShowAssessments = () => {
    setShowAssessments(true);
  };

  const onShowSkillData = async (value: SkillTypes) => {
    if (!selectedStudent) {
      return;
    }

    setIsLoadingProgress(true);

    try {
      const { userName, level } = selectedStudent;
      if (userName && level) {
        const progress = (
          await progressService.fetchStudentProgressBySkill(
            user,
            userName,
            level,
            value,
            selectedStudent
          )
        )?.data;

        if (progress) {
          setShowSkillData(true);
          setSelectedSkill({ value, progress });
        }
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoadingProgress(false);
  };

  const onHideSkillData = () => {
    setShowSkillData(false);
    setSelectedSkill(null);
  };

  const onHideAssessment = () => {
    setShowAssessments(false);
  };

  const onSetCurrentClassroom = (classroom: TeacherGroup) => {
    if (currentClassroom?.id === classroom.id) {
      return;
    }
    setCurrentClassroom(classroom);
    fetchClassProgress(classroom);
  };

  const onShowEdit = (boolean?: boolean) => {
    const isBoolean = typeof boolean === 'boolean';
    setShowEdit(isBoolean ? boolean : (prevState) => !prevState);
  };

  const onCloseEdit = () => {
    setShowEdit(false);
  };

  const onSaveEdit = async (
    level: string,
    interfaceLanguage: string,
    selectedLevelsBySkills: StudentsSkillsProgress | null
  ) => {
    if (!selectedStudent) {
      return;
    }

    setIsLoadingProgress(true);

    try {
      if (selectedStudent.userName) {
        const response = (
          await userService.updateStudentSettings(
            user,
            selectedStudent.userName,
            +level,
            interfaceLanguage
          )
        )?.data;

        if (response) {
          const studentIndex = students.findIndex(
            (i) => i.userName === selectedStudent.userName
          );

          if (studentIndex >= 0) {
            students[studentIndex].level = +level;
            students[studentIndex].interfaceLanguage = interfaceLanguage;
          }

          setSelectedStudent((prevState) => {
            return prevState
              ? {
                  ...prevState,
                  level: +level,
                  interfaceLanguage,
                }
              : null;
          });
        }
      }
    } catch (error) {}

    await commonUtils.sleep(1000);

    try {
      if (selectedLevelsBySkills && selectedStudent.userName) {
        const arrayOfSkills = [];
        for (const key in selectedLevelsBySkills) {
          arrayOfSkills.push({
            skill: key,
            level: selectedLevelsBySkills[key],
          });
        }

        const response = await userService.changeStudentLevelBySkill(
          user,
          selectedStudent.userName,
          arrayOfSkills
        );

        if (response) {
          const studentNewLevel = response.data.data.userGeneralLevel;
          const updatedSelectedStudentWithNewCurrentLevelByskill = {
            ...selectedStudent,
            level: studentNewLevel,
            currentLevelByskill: selectedLevelsBySkills,
            interfaceLanguage: interfaceLanguage,
          };

          setSelectedStudent(updatedSelectedStudentWithNewCurrentLevelByskill);

          dispatch(
            teacherActions.updateStudent({
              userName: selectedStudent.userName,
              newStudent: updatedSelectedStudentWithNewCurrentLevelByskill,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoadingProgress(false);
  };

  return {
    isLoading,
    isLoadingProgress,
    students,
    classrooms,
    statistics,
    selectedStudent,
    showModal,
    currentClassroom,
    searchValue,
    showSkillData,
    showAssessments,
    selectedSkill,
    showEdit,
    onShowEdit,
    onCloseEdit,
    onSaveEdit,
    onShowModal,
    onHideModal,
    onSetCurrentClassroom,
    onShowSkillData,
    onShowAssessments,
    onHideSkillData,
    onHideAssessment,
    setSearchValue,
  };
};

export default UseTeacher;
