import { useTranslation } from 'react-i18next';
import classNames from './MyProgress.module.scss';
import classes from 'classnames';
import UseThemeColors from '@/hooks/UseThemeColors';
import { SkillTypes } from '@/types/skill';
import commonUtils from '@/utils/common';
import { SkillsOverview } from '@/types/progress';
import { authSelector } from '@/store/reducers/auth';
import { appSettingsSelector } from '@/store/reducers/settings';
import { gameActions } from '@/store/reducers/game';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';

import ProgressCard from '@/components/ProgressCard/ProgressCard';
import quizzesThunks from '@/store/thunks/quizzes';
import UseScreenSize from '@/hooks/UseScreenSize';

interface MyProgressProps {
  className?: string;
  skillsOverview: SkillsOverview | null;
}

const MyProgress = ({ className, skillsOverview }: MyProgressProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { color } = UseThemeColors();
  const { isMobile } = UseScreenSize();

  const { comingUpQuizzes, skillSettings, user } = useAppSelector(authSelector);
  const { topics, quizzes } = useAppSelector(appSettingsSelector);

  const onNextQuiz = async (type: SkillTypes) => {
    if (type === SkillTypes.Vocabulary) {
      if (isMobile) {
        navigate('/dashboard/skill/vocabulary');
      } else {
        const skill =
          skillSettings.find((i) => i.name?.toLocaleLowerCase() === type) ??
          null;

        dispatch(gameActions.setSelectedSkill({ type, skill }));
        navigate(`/dashboard/games?skill=${skill?.name?.toLowerCase()}`);
      }

      return;
    }

    const quizByType = comingUpQuizzes.find((i) => i.skill === type) ?? null;

    const skill =
      skillSettings.find((i) => i.name?.toLocaleLowerCase() === type) ?? null;
    const topic =
      topics[type].find((i) => i.id === quizByType?.topicId) ?? null;

    dispatch(gameActions.setSelectedSkill({ type, skill }));
    dispatch(gameActions.setSelectedTopic(topic));

    const topicQuizzes = topic
      ? quizzes[topic.id] ??
        (await dispatch(quizzesThunks.refreshTopicQuizzes()).unwrap())
      : [];

    const selectedQuiz = topic
      ? topicQuizzes.find((i) => i.id === quizByType?.quizId) ?? null
      : null;

    dispatch(gameActions.setSelectedQuiz(selectedQuiz));
    navigate('/dashboard/quiz');
  };

  const progressCards = skillsOverview
    ? Object.entries(skillsOverview).map((entry) => {
        const [key, value] = entry;

        const { completedTopics, totalTopics, completedQuizzes, totalQuizzes } =
          value;

        const { trailColor, pathColor } =
          commonUtils.getProgressColors(key as SkillTypes) ?? {};

        const percentage =
          +commonUtils.calcPercentage(completedQuizzes, totalQuizzes) ?? 0;

        const topics = {
          actual: completedTopics ?? 0,
          total: totalTopics ?? 0,
        };

        const quizzes = {
          actual: completedQuizzes ?? 0,
          total: totalQuizzes ?? 0,
        };

        const showNextGameBtn = Boolean(
          comingUpQuizzes.some((i) => i.skill === (key as SkillTypes)) ?? null
        );

        return (
          <ProgressCard
            key={key}
            type={key as SkillTypes}
            percentage={percentage}
            topics={topics}
            quizzes={quizzes}
            user={user}
            trailColor={color(trailColor ?? '') ?? ''}
            pathColor={color(pathColor ?? '') ?? ''}
            text={t('nextQuiz')}
            showNextGameBtn={showNextGameBtn}
            emitClick={onNextQuiz}
          />
        );
      })
    : null;

  return (
    <div className={classes(classNames.myProgress, className)}>
      <h2>{t('myProgress')}</h2>
      <div className={classNames.progressCards}>{progressCards}</div>
    </div>
  );
};

export default MyProgress;
