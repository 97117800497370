import commonUtils from '@/utils/common';
import classNames from './QuizDetailsHeading.module.scss';
import classes from 'classnames';
import { SkillTypes } from '@/types/skill';
import { SkillSettingsItem } from '@/types/user';
import { Topic } from '@/types/topic';

import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import UseLocalLang from '@/hooks/UseLocalLang';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { gameSelector } from '@/store/reducers/game';
import UseScreenSize from '@/hooks/UseScreenSize';

interface QuizDetailsHeadingProps {
  selectedType?: SkillTypes | null;
  selectedSkill?: SkillSettingsItem | null;
  selectedTopic?: Topic | null;
  className?: string;

  isInGameWindow?: boolean;
}

const QuizDetailsHeading = ({
  selectedType,
  selectedSkill,
  selectedTopic,
  className,

  isInGameWindow = false,
}: QuizDetailsHeadingProps) => {
  const { isMobile } = UseScreenSize();
  const localLang = UseLocalLang();
  const { user } = useAppSelector(authSelector);
  const { t } = useTranslation();
  const { isInEndOfSkillTest, isFinalAssessment } =
    useAppSelector(gameSelector);

  let content = (
    <>
      <span
        className={classes({
          [classNames[`skill-${selectedType}`]]: true,
        })}
      >
        {selectedSkill ? commonUtils.getName(selectedSkill, localLang) : 'N/A'}
      </span>

      {(isInEndOfSkillTest && (!isInGameWindow || isMobile)) ||
      (isInGameWindow && !isInEndOfSkillTest) ? (
        <DetailSeperator />
      ) : (
        <></>
      )}

      {isInEndOfSkillTest && (!isInGameWindow || isMobile) ? (
        <span className={classNames.endOfSkillTopic}>
          {t('level') +
            ' ' +
            user?.currentLevelByskill?.[selectedType as SkillTypes]}
        </span>
      ) : (
        isInGameWindow &&
        selectedTopic &&
        !isInEndOfSkillTest && (
          <>
            {selectedTopic ? (
              <span className={classNames.topic}>
                {commonUtils.getName(selectedTopic, localLang)}
              </span>
            ) : (
              'N/A'
            )}
          </>
        )
      )}
    </>
  );

  if (isFinalAssessment) {
    content = (
      <span className={classNames.finalEnglishLevelTestText}>
        {t('finalEnglishLevelTest')}
      </span>
    );
  }

  return (
    <div className={classes(classNames.quizDetailsHeading, className)}>
      {content}
    </div>
  );
};

export default QuizDetailsHeading;
