import { SkillTypes } from '@/types/skill';
import classNames from './ProgressCard.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import SVG from 'react-inlinesvg';

import trophy from '@/assets/svg/trophy.svg';
import medal from '@/assets/svg/medal-gold.svg';
import UseSkills from '@/hooks/UseSkills';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import { UserState } from '@/types/user';

interface ProgressCardProps {
  type: SkillTypes;
  text: string;
  user?: UserState | null;
  pathColor: string;
  trailColor: string;
  percentage: number;
  topics: {
    actual: number;
    total: number;
  } | null;
  quizzes: {
    actual: number;
    total: number;
  } | null;
  showNextGameBtn?: boolean;
  emitClick: (type: SkillTypes) => void;
}

const ProgressCard = ({
  type,
  text,
  percentage,
  pathColor,
  trailColor,
  topics,
  quizzes,
  showNextGameBtn = true,
  emitClick,
  user,
}: ProgressCardProps) => {
  const { t } = useTranslation();
  const { getSkillName } = UseSkills();
  const currentRoute = UseCurrentRoute();
  const dividers = Array.from(Array(4)).map((divider, index) => {
    const left = `${20 * (index + 1)}%`;

    return (
      <div key={index} className={classNames.divider} style={{ left }}></div>
    );
  });
  const isOnProfile = currentRoute === ROUTE_NAMES.PROFILE;

  return (
    <div
      className={classes(classNames.progressCard, {
        [classNames.progressCardOnProfile]: isOnProfile,
      })}
    >
      <div className="flex items-center justify-between">
        <div className={classNames.text}>
          <div className="flex gap-1">
            <span className="font-bold opacity-80">{getSkillName(type)}</span>
            <span className="font-medium">{`${percentage}%`}</span>
          </div>
          <div>
            <h1>
              {t('level') + ' ' + user?.currentLevelByskill?.[type] ?? ''}
            </h1>
          </div>
        </div>
        <CircularProgressbar
          className={classNames.circularProgressBar}
          value={percentage}
          strokeWidth={12}
          styles={buildStyles({
            pathTransition: 'none',
            strokeLinecap: 'butt',
            pathColor,
            trailColor,
          })}
        />
      </div>
      <div className={classNames.details}>
        <div>
          {topics && (
            <div className={classNames.bottomTextContainer}>
              <div className={classNames.textContainer}>
                <SVG src={trophy} />
                <span>{`${topics.actual}/${topics.total} ${t('topics')}`}</span>
              </div>
              {quizzes && (
                <div className={classNames.textContainer}>
                  <SVG src={medal} />
                  <span>{`${quizzes.actual}/${quizzes.total} ${t(
                    'quizzes'
                  )}`}</span>
                </div>
              )}
            </div>
          )}
        </div>
        {/* <div className={`flex items-center ${isDesktop ? 'gap-2' : 'gap-40'}`}>
          {topics && (
            <div className={classNames.topics}>
              <SVG src={trophy} />
              <span>{`${topics.actual}/${topics.total} ${t('topics')}`}</span>
            </div>
          )}
          {quizzes && (
            <div className={classNames.quizzes}>
              <SVG src={medal} />
              <span>{`${quizzes.actual}/${quizzes.total} ${t(
                'quizzes'
              )}`}</span>
            </div>
          )}
        </div> */}
        <div
          className={classNames.progressBar}
          style={{ backgroundColor: trailColor }}
        >
          <div className={classNames.dividers}>{dividers}</div>
          <div
            className={classNames.bar}
            style={{ width: `${percentage}%`, backgroundColor: pathColor }}
          ></div>
        </div>
        {showNextGameBtn && (
          <button
            className={classNames.button}
            style={{ color: pathColor, backgroundColor: trailColor }}
            onClick={() => emitClick(type)}
          >
            {text}
          </button>
        )}

        {!showNextGameBtn && (
          <button
            className={classNames.button}
            style={{ color: pathColor, backgroundColor: trailColor }}
            onClick={() => emitClick(type)}
          >
            {t('goToSkill', { skill: getSkillName(type) })}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProgressCard;
