export const TRANSLATIONS_PT = {
  CodeMismatchException: "Código inválido",
  ExpiredCodeException: "Código inválido",
  IAlreadyHaveAnAccount: "Eu já tenho uma conta",
  LimitExceededException: "Limite de tentativas excedido. Tente novamente mais tarde.",
  UserNotConfirmedException: "Sua conta não foi verificada. Digite o código de verificação enviado para seu e-mail.",
  accountVerifiedCanLoginNow: "Sua conta foi verificada, você pode fazer login agora",
  account_settings: "Configurações da conta",
  achievmentsSection: {
    feedback: "Você mostrou algumas habilidades extraordinárias!",
    greatJob: "Ótimo trabalho!",
    "in": "Em",
    weeklyAchievement: "Conquista da semana"
  },
  addClass: "Criar classe",
  addNewClass: "Adicionar nova classe",
  advanceToTheNextLevel: "avançar para o próximo nível.",
  advanced: "Avançado",
  again: "novamente",
  all: "Todos",
  allDone: "Tudo feito!",
  allLevels: "Todos os níveis",
  allowIndividualAdvancement: "Permitir avanço para o próximo nível",
  almostFinish: "Quase lá!",
  almostHalfWayThere: "Você está quase na metade do caminho!",
  almostMadeIt: "Quase conseguiu",
  almostThere: "Você está quase lá...",
  almost_made_it: "Quase conseguiu",
  amazingJob: "Excelente trabalho!",
  amazing_job: "Excelente trabalho",
  anErrorOccurred: "Ocorreu um erro",
  anUnauthorizedRequestErrorOccurred: "Ocorreu um erro de solicitação não autorizado.",
  andBeginYour: "e comece sua jornada de aprendizado de inglês.",
  andInviteYourStudents: "e invite a sus estudiantes",
  andThenInvite: "e depois convide seus alunos para participarem dela",
  andYouCanImproveYouScores: "e melhorar suas pontuações.",
  and_got_all_the_stars: "e recebeu todas as estrelas",
  answeredIncorrectly: "respondeu incorretamente.",
  appName: "Britannica ELL",
  appTitle: "Britannica",
  app_banner: "Banner de aplicativo",
  ar: "árabe",
  areYouReadyForTheQuiz: "Você acha que está pronto para o jogo?",
  areYouSure: "Tem certeza?",
  asSoonAsTheyStart: "Assim que eles começarem a jornada de aprendizado, você poderá ver o progresso deles aqui.",
  assessment: "Avaliação",
  assessments: "Avaliações",
  assignments: "Atribuições",
  atLeastOneNumber: "Contém pelo menos 1 número",
  atLeastOneSpecial: "Contém pelo menos 1 caractere especial",
  atLeastOneUppercase: "Contém pelo menos 1 letra maiúscula",
  averageStudentLevel: "Nível médio do aluno",
  avgAttempts: "A média de tentativas",
  avgClassScores: "Média de notas da turma",
  avgFailureRate: "Taxa média de falhas",
  avgGameScores: "Média de notas dos jogos",
  avgPassRate: "Média de aprovação",
  avgQuizzes: "Média de quizzes concluídos por aluno",
  avgTimeSpent: "Média tempo gasto",
  back: "Voltar",
  backHome: "Início",
  backToHomePage: "Início",
  backToTopics: "Voltar aos tópicos",
  back_home: "Início",
  badgesSection: {
    link: "Ver tudo",
    title: "Minhas conquistas recentes."
  },
  britannicaEducationWith: "Britannica Education com",
  byClickingOn: "Ao clicar no nome do aluno, você verá o desempenho dele em cada habilidade e com que frequência utiliza o aplicativo. Você também pode decidir qual idioma de interface ele deve usar.",
  cancel: "Cancelar",
  cancelAnyway: "Cancelar de qualquer forma",
  changeLevel: "Alterar Nível",
  change_password: "Alterar senha",
  characters: "Caracteres ",
  checkInSpamMessage: "Não recebeu? Verifique sua <0>caixa de spam</0>.",
  checkInSpamMessageTeacher: "espanhol_apenas_não_alterar",
  choose: "Selecionar",
  chooseAClass: "Escolha uma classe",
  chooseAvatar: "Escolha o avatar",
  chooseDate: "Escolher Data",
  chooseGrade: "Escolha a série",
  chooseTheCorrectAnswer: "Escolha a resposta correta para completar a frase",
  chooseYourFavoriteAvatar: "Escolha seu avatar e cor favoritos",
  city: "Cidade ou distrito",
  "class": "Classe",
  classActivity: "Atividade de aula",
  classAvg: "Média da turma",
  classCreated: "A classe foi criada com sucesso!",
  classCreatedDescription: "Seus alunos receberam um e-mail com instruções sobre como redefinir a senha. Depois de fazer isso, eles começarão sua jornada de aprendizado de inglês.",
  className: "Nome da classe",
  classReport: "Relatório da Turma",
  classes: "Aulas",
  clickOnAStudent: "Clique em um aluno",
  clickToCopy: "Clique para copiar o link do convite",
  clickToTryAgain: "Clique para tentar novamente",
  clickToWatchAssessments: "Clique para ver as avaliações dos alunos!",
  coins: "Moedas",
  coinsWillBeLost: "e todas as moedas que você ganhou serão perdidas",
  complete: "Completo",
  confirmPassword: "Confirme a senha",
  contactOurTeam: "Em caso de dúvidas, entre em",
  contactOurTeamWithQuestions: "Entre em contato com nossa equipe em caso de dúvidas e preocupações.",
  contactUs: "Fale conosco",
  "continue": "Continuar",
  continueCreating: "Continuar a criar",
  continueOn: "Continue",
  continueOnWeb: "Continuar na web",
  continueQuiz: "Continuar o Jogo",
  continueToQuestions: "Continuar com as perguntas",
  continue_level_test: "Continuar Teste de nivelamento",
  copy: "Copiar",
  copyInvitationLink: "Copiar link do convite",
  copyLink: "Copiar link",
  copyLinkOnly: "Copiar somente o link",
  country: "País",
  create: "Criar",
  createClass: "Crear clase",
  createNewClass: "Criar nova classe",
  createYourClass: "Crie sua aula e convide seus alunos",
  createYourFirstClass: "Crie sua primeira aula",
  currentLevel: "Nível Atual",
  current_password: "Senha atual",
  customYourLearning: "Em breve, poderemos personalizar sua experiência de aprendizado!",
  dashboard: "Painel de Controle",
  date: "Data",
  dearSchoolAdmin: "Caro administrador da escola, o senhor foi",
  dearStudent: "Caro aluno, você foi convidado por ",
  dearTeacher: "Caro(a) professor(a), você foi convidado(a) a ingressar na",
  dearTeacherLevel: "Caro professor,",
  dearTeacherPleaseJoin: "Caro professor, cadastre-se em {{schoolName}} para poder criar suas aulas e começar a usar a plataforma de aprendizado de inglês da Britannica.",
  deleteClass: "Excluir turma",
  dictionary: "Dicionário",
  didYouMean: "Você quis dizer",
  didntQuiteHearThat: "Não ouvi bem isso. Tente novamente.",
  discoverAWorld: "Descubra um mundo de possibilidades para um aprendizado dinâmico e com ferramentas poderosas para aprimorar suas habilidades na língua inglesa.",
  doingGreat: "Você está indo muito bem!",
  done: "Concluído",
  dontKnow: "Não conheço",
  downloadAndView: "Baixe e visualize as",
  editClass: "Editar aula",
  email: "E-mail",
  emailAlreadyExists: "Já existe uma conta com o e-mail fornecido",
  emailExample: "exemplo@gmail.com",
  emailIsValidReceiveVerificationCode: "Se a conta de e-mail for válida, você receberá um código de verificação para continuar o processo de redefinição de senha.",
  emailVerification: "Verificação de e-mail",
  empowerEnrichAndInspire: "com o Britannica",
  en: "Inglês",
  endOfLevelAssessment: "Teste de conclusão de nível",
  english: "Inglês",
  englishLearningJourney: "jornada de aprendizado de inglês com a Britannica English.",
  englishLearningJourneyMorfix: "Jornada de aprendizado de inglês com o Morfixschool 2.0.",
  englishSkillsAssessment: "Avaliação de habilidades em inglês",
  englishSkillsAssessments: "Avaliações das habilidades em inglês",
  englishSkillsProgress: "Progresso das habilidades de inglês",
  enterCodeHere: "Digite o código aqui",
  enterNewPassword: "Insira a nova senha",
  enterVerificationCode: "Digite o código de verificação",
  enterVerificationCodeAndNewPassword: "Digite o código de verificação e a nova senha",
  es: "Espanhol",
  exampleEmail: "exemplo@gmail.com",
  exampleSentences: "Exemplos de Frases",
  examples: "exemplos",
  excellent: "Excelente",
  exit: "Saída",
  extra: "Adicionais",
  extraCoins: "Moedas Extras",
  failed_quiz_message_again: "Você ainda não passou nesse jogo, portanto não há moedas extras nem estrelas.",
  failed_quiz_message_first: "Infelizmente, você não passou, portanto não receberá nenhuma moeda por esse jogo.",
  failed_quiz_message_passed: "Você não foi aprovado. Não se preocupe, sua pontuação anterior foi salva.",
  failureRate: "Taxa de falha",
  fewMoreSeconds: "apenas mais alguns segundos",
  fieldIsMandatory: "Este campo é obrigatório",
  filter: "Filtro",
  finalAssessment: "Avaliação final",
  finalEnglishLevelTest: "Teste final de nível de inglês",
  firstname: "Nome",
  focus: "Descubra o seu ritmo",
  "for": "para",
  forgotPassword: "Esqueci minha senha",
  foundation: "Base",
  friday: "Sexta-feira",
  fridayShort: "Sex",
  from: "De",
  fullVersionAvilableOnlyOnDesktop: "Versão completa disponível apenas no desktop",
  generalLevel: "Nível geral",
  generateClassReport: "Gerar relatório de classe",
  generateReport: "Gerar relatório",
  getApp: "Instalar o app",
  getFullExperience: "Tenha a experiência completa com o aplicativo Britannica English",
  getStarted: "Iniciar",
  gettingStarted: "Introdução",
  giveFeedback: "Fale conosco",
  goAheadAndShareThisLinkWithYourStudentsNow: "Compartilhe este link com seus alunos agora.",
  goAheadJoinMyClass: "Olá. Participe da minha aula usando este link",
  goBackToSkills: "Voltar para habilidades",
  goToLevelTest: "Ir para o teste de nivelamento",
  goToSkill: "Ir para {{skill}}",
  go_back_btn: "Botão Voltar",
  good: "Bom",
  goodJob: "Bom trabalho!",
  goodLuck: "Boa sorte!",
  gotAllStars: "e recebeu todas as estrelas",
  gotThis: "Entendi!",
  grade: "Série",
  grade_1: "1º EFAI",
  grade_10: "1ª EM",
  grade_10th: "1ª EM",
  grade_11: "2ª EM",
  grade_11th: "2ª EM",
  grade_12: "3ª EM",
  grade_12th: "3ª EM",
  grade_1st: "1º EFAI",
  grade_2: "2º EFAI",
  grade_2nd: "2º EFAI",
  grade_3: "3º EFAI",
  grade_3rd: "3º EFAI",
  grade_4: "4º EFAI",
  grade_4th: "4º EFAI",
  grade_5: "5º EFAI",
  grade_5th: "5º EFAI",
  grade_6: "6º EFAF",
  grade_6th: "6º EFAF",
  grade_7: "7º EFAF",
  grade_7th: "7º EFAF",
  grade_8: "8º EFAF",
  grade_8th: "8º EFAF",
  grade_9: "9º EFAF",
  grade_9th: "9º EFAF",
  grammar_tip: "Dica de Gramática",
  great: "Ótimo!",
  greatJob: "Ótimo trabalho!",
  he: "hebraico",
  hello: "Olá",
  helloWorld: "Olá, Mundo!",
  hi: "Olá",
  hints: "Dicas",
  hints_options: {
    add_more_time: "Adicionar tempo no relógio",
    hear_the_english_word: "Ouça a palavra em inglês",
    remove_a_letter: "Remover uma letra",
    remove_one_answer: "Remover uma resposta",
    see_the_translation: "Veja a tradução",
    see_the_word: "Veja a palavra",
    show_a_letter: "Mostrar uma letra"
  },
  homepage: "Página inicial",
  howsYourEnglish: "Como está seu inglês?",
  iAgreeTo: "Eu concordo com os",
  id: "Identificação",
  illDoItLater: "Farei isso mais tarde",
  improveYourRecentGames: "Melhore seus jogos recentes",
  improveYourRecentVocabularyQuizzes: "Melhore seus jogos de vocabulário recentes",
  inAllGames: "Em todos os jogos",
  in_order_to_proceed_ask_teacher: "Para passar para o próximo nível, seu professor deve permitir que você o faça.",
  in_order_to_start: "Para iniciar sua jornada de aprendizado, precisamos",
  incorrect: "Incorreto!",
  incorrect_answer: "incorreto.",
  initialLevelSetTest: "Teste inicial de nivelamento",
  initialLevelTest: "Teste inicial de nivelamento",
  instructionalMaterial: "Material Instrucional",
  interfaceLanguage: "Idioma da Interface",
  interface_language: "Idioma da Interface",
  intermediate: "Intermediário",
  invalidCode: "Código inválido",
  invalidEmail: "E-mail inválido",
  invalidField: "Campo inválido",
  invalidFirstname: "Nome inválido",
  invalidLastname: "Sobrenome inválido",
  invalidPassword: "Senha inválida",
  invalidUsernameOrPassword: "Nome de usuário ou senha inválidos.",
  invitationLink: "Link do convite",
  invitationLinkWontChange: "Não se preocupe, o link do convite não será alterado",
  inviteYourStudents: "Convide seus alunos",
  inviteYourTeachers: "Convide seus professores para criarem suas aulas.",
  invitedToJoin: "convidado a participar do Britannica English.",
  invitedToJoinMorfix: "convidado a se juntar ao Morfixschool 2.0.",
  itLooksLikeYouReallyKnowTheMaterial: "Parece que você realmente conhece o material e está avançando bem. Concentre-se em concluir todas as habilidades e continue progredindo.",
  itsTime: "É hora de escolher se você está pronto para o jogo ou não,",
  joinHere: "Registre-se aqui:",
  joinNow: "Cadastre-se agora",
  keepGoing: "Continuar",
  keepPlaying: "Continue jogando",
  keepUpTheGoodWork: "Muito bom, continue assim.",
  know: "conheço",
  know_your_level: "saiba seu nível de inglês.",
  language: "Idioma",
  language_of_study: "Idioma de estudo",
  languages: {
    ar: "Árabe",
    en: "Inglês",
    es: "Espanhol",
    he: "Hebraico",
    pt: "Português"
  },
  lastname: "Sobrenome",
  leave_level_test_and_sign_out: "Sair do teste de nivelamento e sair?",
  letsAssess: "Vamos avaliar seu nível atual de inglês com um breve quiz para que possamos oferecer a melhor experiência de aprendizado. Pode levar de 5 a 15 minutos com base no seu desempenho. Você está pronto?",
  letsBegin: "Vamos começar",
  letsFinish: "Vamos terminar",
  letsGo: "Vamos lá!",
  letsGoFinalAssessment: "Vamos lá",
  letsKeepLearning: "Vamos continuar aprendendo!",
  letsSee: "Vamos ver o quanto você conhece seu vocabulário.",
  letsSeeIfYouReady: "Vamos ver se você está pronto para",
  level: "Nível",
  levelAchievement: "Conquista de nível",
  levelPerSkill: "Nível por habilidade",
  levelTest: "Teste de nivelamento",
  levelTestIncomplete: "Teste de nivelamento incompleto",
  linkCopied: "Link copiado!",
  listen_tip: "Dica de escuta",
  listeningTapToFinish: "Escuta. Toque para finalizar",
  loading_spinner: "Carregando",
  loginFromDifferent: "Faça login em uma conta diferente",
  logout: "Sair",
  looksLikeYouOnARoll: "Parece que você está indo bem",
  melingoCalculateYourLevel: "Estamos calculando seu nível ...",
  menu_button: "Botão de menu",
  message: "Mensagem",
  monday: "Segunda-feira",
  mondayShort: "Seg",
  moreTime: "mais tempo",
  moreTimes: "mais vezes",
  mustFirstCreateClass: "Para que seus alunos comecem a usar a plataforma, você deve primeiro criar uma turma e depois convidá-los a participar.",
  myProfile: "Meu perfil",
  myProgress: "Frequência do aluno",
  myRecentTranslations: "Minhas traduções recentes",
  needHelp: "Precisa de ajuda?",
  new_password: "Nova senha",
  new_password_verification: "Digite novamente a nova senha",
  next: "Avançar",
  nextQuestion: "Próxima pergunta",
  nextQuiz: "Próximo jogo",
  nextUpInYourLearningPath: "A seguir em sua trilha de aprendizagem",
  nextWord: "Próxima palavra",
  next_button: "Botão Avançar",
  noCoins: "Sem moedas",
  noDataToDisplay: "Não há dados para exibição.",
  noExtraCoins: "Sem moedas extras",
  noExtraCoinsThisTime: "portanto, não há moedas extras dessa vez",
  noResultsFor: "Nenhum resultado para {{word}}",
  none: "Nenhum",
  notBad: "Nada mal",
  notMadeIt: "Parece que você não conseguiu dessa vez. Você pode",
  notMadeItInTime: "Parece que você não conseguiu desta vez. Vamos passar para a próxima pergunta!",
  ofThisSite: "deste site.",
  ok: "Ok",
  okLetsBegin: "Ok, vamos começar!",
  okLetsGo: "Ok, vamos lá!",
  oneMoreMistake: "Um erro a mais encerrará o jogo",
  onlyNumbersAllowed: "Somente números são permitidos",
  oops: "Opa!",
  orClickHereToSendAgain: "Ou <0> clique aqui </0> para reenviar o código.",
  orClickHereToSendAgainTeacher: "espanhol_apenas_não_alterar",
  outOf: "de",
  overallProgress: "Progresso geral",
  passRate: "Taxa de aprovação",
  password: "Senha",
  passwordMinimumLength: "Tamanho mínimo de 8 caracteres",
  password_changed_successfully: "Senha alterada com sucesso",
  passwordsDontMatch: "As senhas não correspondem.",
  passwords_must_match: "As senhas devem ser iguais",
  pause_game: "Pausar jogo",
  paussed: "Pausado",
  perGame: "Por jogo",
  perLevel: "Por nível",
  perSkill: "Por habilidade",
  phone: "Telefone",
  phraseOfTheDay: "Frase do dia",
  phrasesFor: "Frases para",
  pictureOfTheDay: "Imagem do dia",
  play_sound: "Reproduzir Som",
  pleaseChooseYourOwnPassword: "Por favor escolha sua própria senha",
  pleaseCompleteTheFollowingTest: "Por favor, faça o teste a seguir. Não tenha pressa, leia as instruções e dê o seu melhor.",
  pleaseContact: "Entre em contato {{studentName}} diretamente e informe que eles devem sair e entrar novamente para ver as alterações.",
  pleaseContactYourSchoolManagerForGuidance: "Entre em contato com o gestor da sua escola para receber orientações.",
  pleaseEnter: "Por favor, digite",
  pleaseEnterTheFollowing: "Insira, as seguintes informações para dar início à sua",
  pleaseEnterYourDetails: "Insira seus dados",
  pleaseSelectYourAccount: "Selecione sua conta ou faça login em outra conta",
  pleaseTakeAMoment: "Por favor, reserve um momento para registrar sua escola.",
  pleaseTryAgainLaterWeApologize: "Tente novamente mais tarde. Pedimos desculpas pelo inconveniente.",
  pleaseWriteOnlyLetters: "Por favor, escreva apenas letras",
  powerFulWords: "nos capacitar, enriquecer e inspirar",
  practice: "Praticar",
  practiceAgain: "Praticar novamente",
  practiceProperPronunciation: "Pratique a pronúncia correta.",
  practiceYourVocabulary: "Pratique seu vocabulário",
  practice_makes_perfect: "A prática leva à perfeição!",
  preferences: "Preferências",
  premiumSection: {
    description: "Aqui você pode ver informações importantes ou recursos do administrador da escola ou da Britannica.",
    title: "Uma mensagem para os professores",
    unlock: "Leia mais"
  },
  previous_button: "Botão Anterior",
  processing: "Processamento",
  proficiency: "Proficiência",
  profile: "Perfil",
  progress: "Progresso",
  pt: "Português",
  qs: "Perguntas",
  question: "Pergunta",
  questions: "Perguntas",
  questionsAndConcerns: "perguntas e preocupações.",
  questionsLeft: "mais perguntas restantes",
  quiz: "Jogo",
  quizzes: "jogos",
  quizzesCompleted: "Jogos concluídos",
  quizzesSection: {
    comingUpQuizzesTitle: "Próximos jogos",
    lastQuizzesSubTitle: "Melhorar de 1/2 estrela para 3 estrelas",
    lastQuizzesTitle: "Melhore seus jogos recentes"
  },
  ran_out_of_time: "Parece que seu tempo acabou novamente. Esta pergunta será marcada como",
  readMore: "Leia mais",
  readTheText: "Leia o texto e responda às perguntas",
  read_tip: "Dica de leitura",
  readyToChooseYourAvatar: "Pronto para escolher seu avatar?",
  receiveTipsAndUpdates: "Gostaria de receber periodicamente dicas de produtos e atualizações da Britannica",
  receiveTipsAndUpdatesMorfix: "Gostaria de receber periodicamente dicas de produtos e atualizações do Morfixschool 2.0",
  recentLogin: "Login recente",
  rememberThis: "Lembra disso?",
  remind_teacher_to_move_up: "Lembre ao seu professor para que você suba de nível.",
  requirements: "Requisitos",
  resendVerificationCode: "Não recebeu o código? Enviar código novamente",
  resultForTheTranslation: "resultado para a tradução de",
  results: "Resultados",
  resultsForTheTranslation: "resultados para a tradução de",
  resume: "Retomar",
  reward: "Recompensa",
  saturday: "Sábado",
  saturdayShort: "Sáb",
  save: "Salvar",
  saveChanges: "Salvar alterações?",
  schoolName: "Nome da escola",
  score: "Pontuação",
  searchForAStudent: "Procurar um aluno",
  searchForStudent: "Pesquisar por aluno",
  selectSkill: "Selecione a habilidade",
  selfPracticeProgress: "progresso do aluno",
  selfPracticeStreak: "Frequência do aluno",
  send: "Enviar",
  sendNow: "Enviar agora",
  settings: {
    about_me: "Sobre mim",
    about_the_product: "Sobre o produto",
    add_students: {
      add_student: "Adicionar novo aluno",
      add_students: "Adicionar novos alunos",
      add_students_manually: "Adicionar alunos manualmente",
      button_next: "Avançar",
      click_to_browse: "clique aqui para pesquisar",
      create_students: "Criar novos alunos",
      download: "Fazer download",
      download_sample: "de um modelo de arquivo Excel (xlsx).",
      drag_here: "Arraste os arquivos e solte-os aqui ou",
      email_will_be_send: "Um e-mail será enviado",
      enroll_students: "Inscrever alunos",
      errors: {
        bad_formatting: "O arquivo não possui o formato correto. <br/> Verifique seus dados e faça as correções necessárias",
        email_in_use: "Este endereço de e-mail já foi utilizado",
        email_incorrect: "Insira um e-mail válido.",
        file_empty: "O arquivo que você está tentando enviar está vazio",
        file_too_large: "O arquivo é muito grande. O tamanho máximo permitido é de 256KB.",
        first_name_incorrect: "Nome contém caracteres inválidos.",
        first_name_missing: "É necessário inserir o nome.",
        last_name_incorrect: "Sobrenome contém caracteres inválidos.",
        last_name_missing: "É necessário inserir o sobrenome.",
        students_not_registered: "Alguns alunos não foram inscritos",
        wrong_format: "O formato do arquivo está incorreto. <br/> Certifique-se de que foi selecionado um arquivo Excel (xlsx)."
      },
      invite_students: "Inscrever alunos",
      preview: "Pré-visualização",
      success_description: "Foi enviado um e-mail aos seus alunos contendo instruções sobre como redefinir suas senhas. Quando terminarem, eles poderão dar início às suas jornadas de aprendizado de inglês.",
      success_title: "A classe foi criada com sucesso!",
      upload_description: "Quando o arquivo for carregado, seus alunos serão inscritos e receberão uma senha temporária em seus e-mails e poderão fazer login no Britannica English.",
      upload_file: "Envie os arquivos Excel (xlsx) de seus alunos",
      upload_instruction: "Faça download e visualize o modelo.",
      upload_successful: "Seu arquivo foi enviado com sucesso"
    },
    change_password: "Alterar senha",
    "class": "Minha classe",
    current_level: "Nível Atual",
    grade: "Grau",
    home: "Início",
    language: "Idioma",
    logout: "Sair",
    my_classes: "Minhas classes",
    my_games: "Meus jogos",
    my_students: "Meus alunos",
    native_language: "Meu idioma nativo",
    privacy_policy: "Política de Privacidade",
    profile: "Perfil",
    school_id: "Minha escola",
    security: "Segurança",
    sound: "Som",
    title: "Configurações",
    username: "Meu nome"
  },
  showMore: "mostrar mais",
  signIn: "Faça o login",
  signYouIn: "Conectando você ...",
  sign_out: "Sair",
  sincerely: "Atenciosamente, {{name}}",
  sixDigits: "6 dígitos",
  sixDigitsAtLeast: "O código deve ter pelo menos 6 dígitos",
  skills: {
    grammar: "Gramática",
    listen: "Escuta",
    read: "Leitura",
    speak: "Pronúncia",
    spelling: "Ortografia",
    vocabulary: "Vocabulário"
  },
  skillsTestTryAgain: "Você pode tentar novamente agora ou voltar para os jogos anteriores desse nível e praticar mais.",
  skip: "Pular",
  sorryThisLinkIsNoLongerValid: "Desculpe, o link não é mais válido",
  sortBy: "Ordenar por:",
  speakNow: "Fale agora",
  speak_tip: "Dica de pronúncia",
  spelling_tip: "Dica de ortografia",
  stars: "Estrelas",
  start: "Iniciar",
  startLevelTest: "Iniciar teste de nivelamento",
  startNextQuiz: "Iniciar o próximo quiz",
  startQuiz: "Iniciar jogo",
  startTheNextLevel: "Comece o próximo nível",
  startTheNextQuiz: "Iniciar o próximo jogo",
  startingReward: "Recompensa inicial",
  state: "Estado",
  studentActivity: "Atividade do aluno",
  studentInstructions: "Instruções para estudantes",
  studentLevels: "Níveis de alunos",
  studentName: "Nome do aluno",
  studentReport: "Relatório do aluno",
  students: "Alunos",
  studentsAreNotPermittedToAdvanceToTheNextLevel: "Os alunos não têm permissão para avançar para o próximo nível. Se você decidir alterar o nível do aluno com base no desempenho dele, poderá fazê-lo entrando no perfil do aluno e alterando manualmente o nível para cada habilidade.",
  study_language: "Idioma de estudo",
  subject: "Assunto",
  submit: "Enviar",
  suggested_languages: "Idiomas sugeridos",
  sunday: "Domingo",
  sundayShort: "Dom",
  synonyms: "Sinônimos",
  tapToSpeak: "Toque para falar",
  tapToTranslate: "Toque para traduzir...",
  teacherEndLevelTest: "Você e seus alunos iniciarão as habilidades de pronúncia e ortografia no nível iniciante. Isso fará com que o nível geral pareça um pouco mais baixo. Você pode ajustar manualmente o nível de cada aluno em cada habilidade acessando seu perfil em seu painel, enquanto você pode acessar todos os jogos em todos os níveis.",
  teacherInstructions: "Instruções para professores",
  teacherLetsBeginn: "Vamos começar!",
  teacherName: "Nome do professor",
  teachers: "Professores",
  teachersThatHaveRegistered: "Professores que se inscreveram",
  termsAndConditions: "Termos e Condições",
  testYourEnglish: "Teste seu nível de inglês",
  thankYou: "Obrigado!",
  thatsTheWay: "É isso aí!",
  thats_the_way: "É isso aí!",
  theGamesIn: "os jogos em",
  theWordWasntPronouncedCorrectly: "A palavra ou frase não foi pronunciada corretamente.",
  theWordsWerentPronouncedCorrectly: "As palavras não foram pronunciadas corretamente.",
  thereAre: "Existem",
  thereIs: "Há",
  thisStudentHasNot: "Este aluno ainda não concluiu o teste de nivelamento. Assim que ele iniciar a jornada, você poderá acompanhar seu progresso aqui.",
  thisTestIsDesignedToAssessYourStudentsInTheirGeneralEnglishLevel: "Quando os alunos tiverem concluído todas as atividades de habilidade em seu nível, eles poderão receber um teste de conclusão de nível que avaliará sua proficiência em tudo o que aprenderam. Agende o teste selecionando uma data no campo a seguir.",
  thisTestWillHelpYouSeeHowMuchEnglishYouHaveLearnedSoFar: "Este teste irá ajudar a avaliar o seu progresso de aprendizado de inglês até o momento. Ele inclui gramática, vocabulário, leitura, escuta e pronúncia. Após o teste, você pode continuar jogando e aprendendo.",
  thisWillHelpYouUnderstandTheMaterialBetter: "Isso ajudará você a entender melhor o material",
  thursday: "Quinta-feira",
  thursdayShort: "Qui ",
  thwWordYouEntered: "A palavra que você digitou não está no dicionário. Tente pesquisar uma palavra diferente",
  timeIsUp: "Tempo esgotado!",
  tip: "Dica!",
  tipsAndTricks: "Dicas e sugestões",
  to: "Para",
  toImproveYourVocabulary: "Para melhorar seu vocabulário",
  toJoin: "para se juntar",
  toNextQuestion: "para a próxima pergunta",
  toViewItsOverallLearningProgress: "para visualizar seu progresso geral de aprendizado",
  toViewTheirIndividualProgress: "para visualizar seu progresso individual",
  toViewTheirOverallProgress: "para visualizar seu progresso geral",
  todayQuestion: "A pergunta de hoje",
  topics: "Tópicos",
  totalCoins: "Total de moedas",
  totalHintsUsed: "Total de dicas usadas",
  totalQuizzes: "Total de jogos",
  totalWords: "Total de palavras",
  trasnlateAWordOrPhrase: "Traduza uma palavra ou frase em inglês ou em {{nativeLanguage}}...",
  tries: "tentativas",
  tryAgain: "Tente novamente",
  tryAgainBtn: "Tente mais uma vez",
  tryAgainLater: "Tente novamente mais tarde!",
  tryAgainOneMore: "Tente novamente ou siga em frente",
  tryAgainOrKeepGoing: "Tente novamente ou continue",
  tryBetter: "A prática leva à perfeição!",
  tuesday: "Terça-feira",
  tuesdayShort: "Ter ",
  understandingYourEnglishLevel: "Estamos começando a entender seu nível de inglês.",
  unfortunately: "Infelizmente, você não passou, portanto não receberá nenhuma moeda por esse jogo.",
  university: "Universidade",
  update: "Atualização",
  uponRegistrationStudentsWillReceiveAnInitialLevelTest: "Quando os alunos fizerem o login pela primeira vez, eles receberão um teste de nivelamento. Depois de concluído, sua trilha de aprendizagem individual será definida com base no nível de proficiência em cada habilidade. Se estiver desativado, o aluno começará no Nível 1.",
  useTheCoins: "Use as moedas que você ganhou para obter dicas",
  userStreak: {
    subTitle: "Pratique todos os dias para se tornar um MESTRE",
    title: "mantenha a frequência"
  },
  username: "Nome de usuário",
  usingYourMicrophone: "Usando seu microfone, fale devagar e com clareza e certifique-se de que não haja barulho de fundo.",
  validation: {
    code_required: "O código é necessário",
    confirm_password_required: "É necessário confirmar a senha",
    email_required: "Email obrigatório",
    password_required: "A senha é necessária",
    username_required: "O nome de usuário é obrigatório"
  },
  validationCodeInvalid: "Código de validação inválido",
  verificationCode: "Código de Verificação",
  verificationCodeSent: "Um código de verificação foi enviado para",
  viewAll: "Ver tudo",
  viewAnswerChoices: "Exibir opções de resposta",
  viewDetails: "Ver detalhes",
  viewProfile: "Ver perfil",
  vocabularyMistakes: "Erros de vocabulário",
  vocabularyPractice: "Prática de vocabulário",
  vocabulary_tip: "Dica de vocabulário",
  weAreSorry: "Desculpe",
  weCouldNotProduce: "Não foi possível produzir o relatório no momento.",
  weRecommendThatYouGoBackToYourPreviousGames: "Recomendamos que você volte aos jogos e jogue-os novamente.",
  weStillDontHaveAll: "Ainda não temos todas as informações necessárias ou há uma falha temporária.",
  weWantYourFeedback: "Olá {{firstname}}, queremos o seu feedback!",
  we_want_to_know: "Queremos saber seu nível",
  wednesday: "Quarta-feira",
  wednesdayShort: "Qua",
  welcome: "Bem-vindo(a)",
  welcomeBack: "Bem-vindo(a) de volta",
  welcomeConfetti: "Bem-vindo(a)!",
  welcomeTo: "Bem-vindo(a)",
  wellDone: "Muito bem",
  whatDidYouLearnAtThisLevel: "O que você aprendeu neste nível?",
  whenStudentsWillHaveCompletedAParticularSkill: "Quando os alunos concluírem todas as atividades de habilidades no nível designado, eles serão avaliados com um teste relacionado ao que aprenderam. Uma pontuação de 80% ou mais será considerada como proficiência. Se um aluno não passar no teste, será solicitado que o faça novamente.",
  whenYourStudentsReceiveAPassingGrade: "Quando os alunos alcançarem um índice mínimo de aprovação na avaliação das habilidades em inglês, eles poderão avançar para o próximo nível da habilidade em questão. A desativação desse recurso impedirá que os alunos avancem para o próximo nível.",
  with_a_score_of: "com uma pontuação de",
  wordOfTheDay: "Palavra do dia",
  words: "Aprenda inglês",
  wordsDictionary: "Palavras",
  wordsList: "Lista de palavras",
  workHard: "Dê o seu melhor para obter todas as estrelas.",
  work_hard_to_get_all_stars: "Dê o seu melhor para obter todas as estrelas",
  wow: "Uau",
  wow_you_got_a_score_of: "UAU, você obteve uma pontuação de {{score}}",
  writeClassNameHere: "Escreva o nome da classe aqui",
  writeHere: "Escreva aqui",
  wrongAnswer: "Resposta errada!",
  youAlmostThere: "Você está quase lá...",
  youAreOnYourWay: "Você está no caminho certo!",
  youCanCreateMore: "Você pode criar mais de uma classe",
  youCanPractice: "Você pode praticar palavras por meio de jogos ou usando cartões de memória e uma lista.",
  youCanTryAssessment: "Agora, você tem a oportunidade de realizar a avaliação do nível {{level}}, a mesma que seus alunos realizarão antes de progredirem para o próximo estágio.",
  youCompletedTheTest: "Você completou o teste",
  youDidNotPass: "Você não passou",
  youGot: "Você tem",
  youMayNowShare: "Agora você pode compartilhá-lo com seus",
  youPassedAll: "Você passou em todos os",
  youPassedAllTheGamesIn: "Você passou em todos os jogos do",
  youPassedTheQuiz: "Você passou no jogo com uma pontuação de",
  youPracticed: "Você praticou. Você estudou",
  youStill: "Você ainda não passou neste jogo, portanto não há moedas extras nem estrelas.",
  youWillLose: "Você vai perder",
  you_can_now_practice_level: "Agora você pode praticar o nível {{level}}",
  you_did_it: "Você conseguiu!",
  you_got_a_score_of: "Você obteve uma pontuação de {{score}}",
  you_passed_assessment: "Você foi aprovado na avaliação",
  you_passed_quiz: "Você passou no jogo",
  your: "Suas",
  yourChangesWillAffectTheGeneralLevel: "Suas alterações afetarão o nível geral",
  yourClasses: "Suas classes",
  yourMessageHasBeenSentSuccessfully: "Sua mensagem foi enviada com sucesso. Obrigado por compartilhar seu valioso feedback conosco!",
  yourMessageHere: "Sua mensagem aqui",
  yourPasswordHere: "Sua senha aqui",
  yourPhoneHere: "Seu telefone aqui",
  yourSchoolHasBeenCreated: "Sua escola foi criada com sucesso",
  yourStartingLevel: "Seu Nível Inicial",
  yourStreak: "minha frequência",
  yourStudentQuiz: "Este é o quiz que seus alunos realizarão quando entrarem no aplicativo pela primeira vez. Sinta-se à vontade para respondê-lo agora ou mais tarde.",
  yourStudentsHaveNotJoined: "Seus alunos ainda não ingressaram na aula",
  yourStudentsHaveNotJoinedYet: "Seus alunos ainda não ingressaram na aula",
  yourStudentsInThisClassWillNotTakeTheInitialLevelTest: "Os alunos dessa classe não farão o teste inicial de nivelamento após o cadastro. Todos começarão no Nível 1 em todas as habilidades de inglês.",
  yourStudentsWillNotReceiveAFinalExam: "Seus alunos não receberão um exame final.",
  yourSubjectHere: "Seu assunto aqui"
};
