import classNames from './DictionaryModal.module.scss';
import classes from 'classnames';
import UseDictionary from '@/hooks/UseDictionary';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';

import DictionarySearch from '../DictionarySearch/DictionarySearch';
import AutoCompleteResults from '../AutoCompleteResults/AutoCompleteResults';
import RecentTranslations from '../RecentTranslations/RecentTranslations';
import DictionaryCards from '../DictionaryCards/DictionaryCards';
import DictionaryPhrases from '../DictionaryPhrases/DictionaryPhrases';
import PictureOfTheDay from '../PictureOfTheDay/PictureOfTheDay';

import close from '@/assets/svg/close.svg';
import DictionaryOptions from '../DictionaryOptions/DictionaryOptions';
import DictionaryQuestion from '../DictionaryQuestion/DictionaryQuestion';
import TheButton from '@/components/TheButton/TheButton';
import { useTranslation } from 'react-i18next';
import AppLoaderCircle from '@/components/AppLoaderCircle';

interface DictionaryModalProps {
  className?: string;
  emitClose: () => void;
}

const DictionaryModal = ({ className, emitClose }: DictionaryModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const {
    searchValue,
    selectedWord,
    recentTranslations,
    autoCompleteResults,
    suggestions,
    cards,
    phrases,
    pictureOfTheDay,
    options,
    question,
    isSearching,
    onInputChange,
    onSearch,
    onClear,
    onSelectOption,
    onValueSearch,
  } = UseDictionary();

  const topic = 'Animals';

  const onClick = (result: string) => {
    onSelectOption(result);
  };

  // let content = autoCompleteResults.length ? (
  //   <AutoCompleteResults
  //     searchValue={searchValue}
  //     results={autoCompleteResults}
  //     suggestions={suggestions}
  //     emitClick={onClick}
  //   />
  // ) : (
  //   <RecentTranslations translations={recentTranslations} emitClick={onClick} />
  // );

  let content = null;

  if (isSearching) {
    content = <AppLoaderCircle className={classNames.loader} />;
  }

  if (recentTranslations.length && !isSearching) {
    content = (
      <RecentTranslations
        translations={recentTranslations}
        emitClick={onClick}
      />
    );
  }

  if (selectedWord) {
    content = (
      <>
        <DictionaryCards
          selectedWord={selectedWord}
          cards={cards}
          onValueSearch={onValueSearch}
        />
        {phrases?.length > 0 && (
          <div className={classNames.contentInner}>
            <DictionaryPhrases
              onValueSearch={onValueSearch}
              selectedWord={selectedWord}
              phrases={phrases}
            />
            {/* <DictionaryQuestion {...question} />
          <PictureOfTheDay {...pictureOfTheDay} />
        <DictionaryOptions options={options} /> */}
          </div>
        )}
        {/* <TheButton
          className={classNames.vocabularyPractice}
          description={t('vocabularyPractice') + ':' ?? ''}
          descriptionClassName={classNames.description}
          text={topic}
          showArrow={true}
          shouldRecolor={false}
        /> */}
      </>
    );
  }

  return (
    <div className={classes(classNames.dictionaryModal, className)}>
      <button className={classNames.close} onClick={emitClose}>
        <SVG src={close} />
      </button>
      <DictionarySearch
        className={classNames.dictionarySearch}
        showHeading={true}
        showMagnifyingGlass={isMobile && !searchValue}
        value={searchValue}
        emitChange={onInputChange}
        emitClear={onClear}
        emitKeyPress={onSearch}
        inGame={false}
      />
      <div
        className={classes(classNames.content, {
          [classNames.contentResults]: selectedWord,
        })}
      >
        {content}
      </div>
    </div>
  );
};

export default DictionaryModal;
