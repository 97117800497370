import heMorfix from '@/assets/images/heMorfix.png';
import enMorfix from '@/assets/images/enMorfix.png';
import classNames from './AppMorfixLogo.module.scss';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import { useSelector } from 'react-redux';
import classes from 'classnames';
type AppMorfixLogoProps = {
  showBackGround?: boolean;
  width?: number;
  height?: number;
};
const AppMorfixLogo = ({
  showBackGround = true,
  width,
  height,
}: AppMorfixLogoProps) => {
  const localInterfaceLanguage = localStorage.getItem('interface-language');
  return (
    <div
      style={{ width, height }}
      className={classes(classNames.container, {
        [classNames.background]: !showBackGround,
      })}
    >
      <img
        style={{ width, height }}
        src={localInterfaceLanguage === 'he' ? heMorfix : enMorfix}
      />
    </div>
  );
};

export default AppMorfixLogo;
