import UseSkill from './UseSkill';
import commonUtils from '@/utils/common';
import { useEffect } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { gameActions } from '@/store/reducers/game';

import Navbar from '@/components/Navbar/Navbar';
import SkillPageHeader from '@/components/SkillPage/SkillPageHeader/SkillPageHeader';
import SkillTopics from '@/components/SkillPage/SkillTopics/SkillTopics';

const SkillView = () => {
  const { type, topics, isLoading, groupedTopics } = UseSkill();
  const { isDesktop } = UseScreenSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDesktop) {
      dispatch(gameActions.resetAllSelected());
      navigate('/dashboard/games');
    }
  }, [isDesktop]);

  return (
    <>
      <Navbar decreaseOpacity={false} />
      <SkillPageHeader
        topicsAmount={topics.length}
        topicsCompleted={commonUtils.topicsCompleted(topics)}
        quizzesAmount={commonUtils.quizzesAmount(topics)}
        quizzesCompleted={commonUtils.quizzesCompletedCount(topics)}
        type={type}
        loading={isLoading}
      />
      <SkillTopics
        loading={isLoading}
        groupedTopics={groupedTopics}
        topics={topics}
      />
    </>
  );
};

export default SkillView;
