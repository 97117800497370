import { useTranslation } from 'react-i18next';
import classNames from './LevelTestSummary.module.scss';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { gameActions } from '@/store/reducers/game';
import UseLevel from '@/hooks/UseLevel';

import TheButton from '@/components/TheButton/TheButton';

import badge from '@/assets/svg/badge.svg';
import coinsRow from '@/assets/svg/coins-row.svg';
import complete from '@/assets/svg/quiz-complete.svg';
import { levelTestActions } from '@/store/reducers/level-test';
import { SkillTypes } from '@/types/skill';
import { authActions, authSelector } from '@/store/reducers/auth';
import { useAppSelector } from '@/store';
import UserUtils from '@/utils/user';
import UseUserProgress from '@/hooks/UseUserProgress';

interface LevelTestSummaryProps {
  firstname?: string;
  level?: number;
  coins?: number;
}

const LevelTestSummary = ({
  firstname,
  level,
  coins,
}: LevelTestSummaryProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { skillSettings } = useAppSelector(authSelector);
  const { user } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);
  const { loadUserProgress } = UseUserProgress();

  const { getLevelText } = UseLevel();
  const levelText = getLevelText(level);

  const onConfirm = async () => {
    dispatch(gameActions.resetGame());
    const skill = skillSettings.find(
      (i) => i.name?.toLocaleLowerCase() === SkillTypes.Grammar
    );
    dispatch(
      gameActions.setSelectedSkill({
        type: SkillTypes.Grammar,
        skill: skill,
      })
    );
    dispatch(levelTestActions.reset());
    if (isTeacher) {
      dispatch(levelTestActions.setIsFinishLevelTest(false));
    }
    await loadUserProgress();
    navigate('/dashboard/games');
  };
  const teacherView = <></>;

  return (
    <div className={classNames.levelTestSummary}>
      <div className={classNames.top}>
        <div className={classNames.badge}>
          {!isTeacher && (
            <>
              <SVG src={badge} />
              <span>{level}</span>
            </>
          )}
        </div>
        <div
          className={classNames.startingLevel}
          style={{ backgroundImage: `url(${complete})` }}
        >
          {isTeacher ? (
            <>
              <h2>{t('wellDone')}</h2>
            </>
          ) : (
            <>
              <span>{t('yourStartingLevel')}</span>
              <h2>{t(levelText ?? '')}</h2>
            </>
          )}
        </div>
      </div>
      <div className={classNames.body}>
        <h2>
          {isTeacher
            ? t('youCompletedTheTest')
            : `${t('goodJob')} ${firstname}`}
        </h2>
        <div className={classNames.reward}>
          <span>{isTeacher ? t('dearTeacherLevel') : t('startingReward')}</span>
          {isTeacher ? (
            <>
              <span>{t('teacherEndLevelTest')}</span>
              <span>{t('goodLuck')}</span>
            </>
          ) : (
            <>
              <SVG src={coinsRow} />
              <span className={classNames.coins}>{`${coins} ${t(
                'coins'
              )}`}</span>
            </>
          )}
        </div>
        <TheButton
          className={classNames.button}
          text={isTeacher ? t('done') : t('okLetsGo')}
          emitOnClick={onConfirm}
          showArrow={true}
        />
      </div>
    </div>
  );
};

export default LevelTestSummary;
