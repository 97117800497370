import classNames from './Rating.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';

import star from '@/assets/svg/star.svg';
import starEmpty from '@/assets/svg/star-empty.svg';

interface RatingProps {
  className?: string;
  achievedStars: number;
}

const Rating = ({ className, achievedStars }: RatingProps) => {
  const achievedStarsItems = Array(3)
    .fill({})
    .map((_, index) => {
      if (achievedStars >= index + 1) {
        return <SVG key={index} src={star} />;
      }

      return <SVG key={index} src={starEmpty} />;
    });

  return (
    <div className={classes(classNames.rating, className)}>
      {achievedStarsItems}
    </div>
  );
};

export default Rating;
