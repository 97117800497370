import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { LanguageSettingsItem } from '@/types/user';

const UseLanguages = () => {
  const { languages } = useAppSelector(authSelector);

  const getLanguageByCode = (code: string) => {
    return languages.find((lang) => lang.learningCode === code);
  };

  const getMotherLanguage = (lang: LanguageSettingsItem, code: string) => {
    return lang.localLanguages.find((lang) => lang.code === code);
  };

  return { getLanguageByCode, getMotherLanguage };
};

export default UseLanguages;
