import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  userService,
  progressService,
  skillService,
} from '@/services';
import { RootState } from '@/types/common';
import commonUtils from '@/utils/common';

const userThunks = {
  userInfo: createAsyncThunk('user/me', async (_, { getState }) => {
    try {
      const state = getState() as RootState;

      const user = await authService.me(
        `${state.auth.nativeLanguage}-${state.auth.studyingLanguage}`,
        state.auth.userId
      );

      return user;
    } catch (error) {
      throw error;
    }
  }),
  fetchSkills: createAsyncThunk('skill/settings', async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const settings = await userService.fetchSkillSettings(state.auth.user);

      return settings;
    } catch (error) {
      throw error;
    }
  }),
  fetchSkillsProgress: createAsyncThunk(
    'skill/progress',
    async (_, { getState }) => {
      try {
        const state = getState() as RootState;
        const progress = await progressService.fetchSkillsProgress(
          state.auth.user
        );

        return progress;
      } catch (error) {
        throw error;
      }
    }
  ),
  userSkipLevelTest: createAsyncThunk(
    'skill/skip-level-test',
    async (_, { getState }) => {
      try {
        const state = getState() as RootState;
        await skillService.userSkipLevelTest(state.auth.user);
      } catch (error) {
        throw error;
      }
    }
  ),
  fetchLevels: createAsyncThunk('user/level', async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const levels = await userService.fetchLevelSettings(state.auth.user);
      return levels;
    } catch (e) {
      console.log(e);
    }
  }),
  fetchLanguages: createAsyncThunk('user/language', async (_, { getState }) => {
    try {
      const languages = await userService.fetchLanguageSettings();
      return languages;
    } catch (e) {
      console.log(e);
    }
  }),
  fetchImprovableRecentQuizzes: createAsyncThunk(
    'user/improvableRecentQuizzes',
    async (_, { getState }) => {
      try {
        const state = getState() as RootState;
        const improvableRecentQuizzes =
          await progressService.fetchImprovableRecentQuizzes(state.auth.user);
        return improvableRecentQuizzes;
      } catch (e) {
        console.log(e);
      }
    }
  ),
  fetchComingUpQuizzes: createAsyncThunk(
    'user/fetchComingUpQuizzes',
    async (_, { getState }) => {
      try {
        const state = getState() as RootState;
        const comingUpQuizzes = await progressService.fetchComingUpQuizzes(
          state.auth.user
        );
        return comingUpQuizzes;
      } catch (e) {
        console.log(e);
      }
    }
  ),
  setLanguageSettings: createAsyncThunk(
    'user/languageSettings',
    async (interfaceLanguage: string, { getState }) => {
      try {
        const state = getState() as RootState;

        await userService.setUserLanguageSettings(
          state.auth.user,
          interfaceLanguage
        );
      } catch (e) {
        console.log(e);
      }
    }
  ),
  changeTeacherSkillLevel: createAsyncThunk(
    'user/teacherSkillLevel',
    async (requestBody: { skill: string; level: number }, { getState }) => {
      const { skill, level } = requestBody;
      try {
        const state = getState() as RootState;
        await userService.changeTeacherSkillLevel(
          state.auth.user,
          skill,
          level
        );
      } catch (e) {
        console.log(e);
      }
    }
  ),
  signOut: createAsyncThunk('user/signout', async (_, { getState }) => {
    try {
      const state = getState() as RootState;

      await userService.setUserLogOut(state.auth.user);
    } catch (e) {
      console.log(e);
    }
  }),
};

export default userThunks;
