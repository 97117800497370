import classNames from './TutorQuizSummary.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import commonUtils from '@/utils/common';
import { useAppSelector } from '@/store';
import { PopUpSummaryProps } from '@/types/game';
import { useEffect, useRef, useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';

import QuizTip from '../../QuizTip/QuizTip';
import TheButton from '@/components/TheButton/TheButton';

import complete from '@/assets/svg/quiz-complete.svg';
import failed from '@/assets/svg/quiz-failed.svg';
import coinsRow from '@/assets/svg/coins-row.svg';
import coinsRowFailed from '@/assets/svg/coins-row-failed.svg';
import close from '@/assets/svg/close.svg';
import GameUtils from '@/utils/gameUtils';
import UseLocalLang from '@/hooks/UseLocalLang';
import TutorTooltip from '../TutorTooltip/TutorTooltip';
import { useAppDispatch } from '@/store';
import { tutorActions } from '@/store/reducers/tutor';
import { TriggeredBy } from '@/types/tutor';

interface QuizSummaryProps {
  shouldExpand: boolean;
  emitShouldExpand: (payload: boolean) => void;
}

const TutorQuizSummary = ({
  selectedQuiz,
  coins,
  tip,
  userData,
  correctAnswers,
  totalQuestions,
  shouldExpand,
  hasNextQuiz,
  emitShouldExpand,
  emitOnClosePopUp,
  emitOnExit,
}: PopUpSummaryProps & QuizSummaryProps) => {
  const { t } = useTranslation();
  const { selectedType } = useAppSelector(gameSelector);
  const { isDesktop, isMobile } = UseScreenSize();
  const [offsetFromBottom, setOffsetFromBottom] = useState(0);
  const summaryRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const localLang = UseLocalLang();

  const score = +commonUtils.calcPercentage(
    correctAnswers ?? 0,
    totalQuestions ?? 0
  );

  const starsAmount = GameUtils.getStarsAmount(score);

  const isFailed = starsAmount == 0;
  const quizAttempts = userData?.attempts ?? 0;
  const previousQuizStars = userData?.cumulativeAchievedStars ?? 0;
  const isQuizPassedBefore = userData?.quizPassed ?? false;
  const isFirstTime = quizAttempts <= 0;
  const isPassedAfterPreviousFailed =
    !isFirstTime && !isQuizPassedBefore && !isFailed;
  const isPassedTwoStarsNoIncrease =
    previousQuizStars < 3 && starsAmount < previousQuizStars;

  const getMainTitle = () => {
    if (isPassedAfterPreviousFailed) {
      return 'you_did_it';
    }

    if (previousQuizStars < 2 && starsAmount === 2) {
      return 'amazing_job';
    }

    if (
      (previousQuizStars < 3 && starsAmount === 3) ||
      (previousQuizStars === 3 && starsAmount === 3)
    ) {
      return 'thats_the_way';
    }

    if (isPassedTwoStarsNoIncrease) {
      return 'almost_made_it';
    }

    return 'practice_makes_perfect';
  };

  const hasFailed = isFailed;

  const onExpand = () => {
    emitShouldExpand(true);
  };

  const onReduce = () => {
    emitShouldExpand(false);
  };

  const bottomHeight = bottomRef.current?.clientHeight;

  useEffect(() => {
    dispatch(tutorActions.unmuteNotifications());
  }, []);

  useEffect(() => {
    const offsetTop = summaryRef.current?.getBoundingClientRect().top;

    if (bottomHeight && offsetTop && isMobile) {
      setOffsetFromBottom(bottomHeight + offsetTop);
    }
  }, [bottomHeight, isMobile]);

  const extraCoins = false;

  let descriptionText = t('unfortunately');

  const getFailedText = () => {
    if (quizAttempts > 0 && !isQuizPassedBefore) {
      return 'failed_quiz_message_again';
    }

    if (isQuizPassedBefore && isFailed) {
      return 'failed_quiz_message_passed';
    }

    return 'failed_quiz_message_first';
  };

  const scoreText = `<span style="font-weight: bold">${score}%</span>`;

  const getSubTitle = () => {
    if (isFailed) {
      return `${t(getFailedText())}`;
    }
    if (isPassedAfterPreviousFailed) {
      return `${t('you_passed_quiz')}\n ${t('with_a_score_of')} ${score}%`;
    }
    if (previousQuizStars == 1 && starsAmount == 2) {
      return `${t('you_got_a_score_of')} ${scoreText} <br/>${t(
        'work_hard_to_get_all_stars'
      )}`;
    }
    if (previousQuizStars < 3 && starsAmount == 3) {
      return `${t('wow_you_got_a_score_of')} ${scoreText} <br/>${t(
        'and_got_all_the_stars'
      )}`;
    }
    return `${t('you_got_a_score_of')} ${scoreText} <br/>${t(
      'and_got_all_the_stars'
    )}`;
  };

  descriptionText = getSubTitle();
  const coinsText =
    hasFailed && !!coins
      ? t('noExtraCoins')
      : `+${coins} ${userData && extraCoins ? t('extraCoins') : t('coins')}`;

  const setSummaryHeight = () => {
    if (selectedType !== SkillTypes.Spelling && tip) {
      return `calc(100% - ${offsetFromBottom}px)`;
    }
    return '60vh';
  };

  const handleOpenTutor = () => {
    emitOnClosePopUp?.();
    dispatch(tutorActions.openTutor(TriggeredBy.Summary));
  };

  return (
    <div className={classNames.quizSummary}>
      <div className={classNames.images}>
        <img
          className={classNames.stars}
          src={commonUtils.getStarImage(starsAmount)?.img ?? ''}
          alt="stars"
        />
        <div
          className={classNames.result}
          style={{ backgroundImage: `url(${hasFailed ? failed : complete})` }}
        >
          <div className={classNames.text}>
            <span>
              {selectedQuiz
                ? commonUtils.getName(selectedQuiz, localLang)
                : 'N/A'}
            </span>
            <h3>{hasFailed ? `${t('oops')}` : t('complete')}</h3>
          </div>
        </div>
      </div>
      <div
        ref={summaryRef}
        className={classes(classNames.summary)}
        style={{ height: setSummaryHeight() }}
      >
        <h3 className={classNames.title}>{t(getMainTitle())}</h3>
        <div
          className={classNames.description}
          dangerouslySetInnerHTML={{ __html: descriptionText }}
        ></div>
        {(userData || !hasFailed) && (
          <div
            className={classes(classNames.reward, {
              [classNames.rewardMobile]: isMobile,
            })}
          >
            {isDesktop && !hasFailed && (
              <span className={classNames.rewardText}>{t('reward')}</span>
            )}
            <SVG src={!isFailed ? coinsRow : coinsRowFailed} />
            <span>{coinsText}</span>
          </div>
        )}

        {isMobile && isFailed && (
          <a onClick={() => emitOnClosePopUp?.(hasFailed)}>
            {hasNextQuiz && !hasFailed ? t('nextQuiz') : t('tryAgain')}
          </a>
        )}
      </div>
      <div
        ref={bottomRef}
        className={classes(classNames.bottom, {
          [classNames.expanded]: shouldExpand,
          [classNames.bottomNoTip]: !tip,
        })}
      >
        {shouldExpand && (
          <div className={classNames.close} onClick={onReduce}>
            <SVG src={close} />
          </div>
        )}

        {selectedType === SkillTypes.Grammar ? (
          <TutorTooltip score={score} />
        ) : (
          tip && (
            <QuizTip
              tip={tip ?? ''}
              plain
              shouldExpand={shouldExpand}
              isSummary={true}
              emitOnExpand={onExpand}
            />
          )
        )}

        {shouldExpand && (
          <TheButton
            className={classes(classNames.button, {
              [classNames.buttonFailed]: hasFailed,
            })}
            text={
              hasNextQuiz && !hasFailed ? t('startTheNextQuiz') : t('gotThis')
            }
            showArrow={!hasFailed}
            emitOnClick={() => emitOnClosePopUp?.(hasFailed)}
          />
        )}
        {isDesktop && (
          <div className={classNames.buttons}>
            <TheButton
              className="flex-1"
              plain
              text={t(isFailed ? 'tryAgain' : 'tutorPracticeWithMe')}
              emitOnClick={isFailed ? emitOnExit : handleOpenTutor}
            />
            <TheButton
              className={classNames.buttonTwo}
              text={
                hasFailed ? t('tutorPracticeWithMe') : t('startTheNextQuiz')
              }
              showArrow
              emitOnClick={
                hasFailed
                  ? handleOpenTutor
                  : () => emitOnClosePopUp?.(hasFailed)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TutorQuizSummary;
