import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from './TutorAssistPopupMobile.module.scss';
import classes from 'classnames';
import tutorIcon from '@/assets/svg/tutor/chat/tutor.svg';
import MuteIcon from '@/assets/svg/tutor/assistPopup/mute.svg';
import CloseIcon from '@/assets/svg/tutor/chat/close_chat.svg';
import CrossIcon from '@/assets/svg/tutor/assistPopup/cross.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { tutorActions, tutorSelector } from '@/store/reducers/tutor';
import TheButton from '@/components/TheButton/TheButton';
import SVG from 'react-inlinesvg';
import { TriggeredBy } from '@/types/tutor';

type TutorAssistPopupMobile = {
  emitClose: () => void;
};

const TutorAssistPopupMobile: React.FC<TutorAssistPopupMobile> = ({
  emitClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { currentQuestionIndex, gameQuestions, pickedAnswer } =
    useAppSelector(gameSelector);

  const { openTutorMuteConfirmPopup } = tutorActions;

  const currentQuestion = useMemo(() => {
    return gameQuestions[currentQuestionIndex];
  }, [currentQuestionIndex, gameQuestions]);

  const handleGotIt = () => {
    dispatch(gameActions.goToNextQuestion({}));
    emitClose();
  };

  const handleExplainMore = () => {
    dispatch(tutorActions.closeTutorAssist());
    dispatch(tutorActions.openTutor(TriggeredBy.AssistPopup));
  };

  const handleMute = () => dispatch(openTutorMuteConfirmPopup());

  return (
    <div className={classNames.assistPopup}>
      <div className={classNames.header}>
        <div className={classNames.seperator} />
        <div className={classNames.buttons}>
          <img src={MuteIcon} alt="mute" onClick={handleMute} />
          <img src={CloseIcon} alt="Close" onClick={handleGotIt} />
        </div>
      </div>
      <div className={classNames.body}>
        <div className={classNames.incorrectAnswer}>
          <SVG className={classNames.icon} src={CrossIcon} />
          <p>{pickedAnswer}</p>
        </div>
        <div className={classNames.context}>
          <p>{`Correct answer is "${currentQuestion.answer}"`}</p>
          <span>{currentQuestion.tip}</span>
        </div>
        <TheButton
          showArrow
          icon={tutorIcon}
          shouldRecolor={true}
          text={t('tutorExplainMore')}
          emitOnClick={handleExplainMore}
          className={classNames.theButton}
        />
        <TheButton
          plain
          text={t('tutorGotIt')}
          emitOnClick={() => {
            dispatch(
              tutorActions.setSuggestedTriggered(TriggeredBy.AssistPopup)
            );
            handleGotIt();
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(TutorAssistPopupMobile);
