import { useTranslation } from 'react-i18next';
import classNames from './TutorMobile.module.scss';
import classes from 'classnames';
import TutorIcon from '@/assets/svg/tutor/tutorDashboard.svg';
import MelingoLogo from '@/assets/svg/tutor/melingo.svg';
import MobileMenuIcon from '@/assets/svg/menu.svg';
import TutorEvents from '../TutorEvents/TutorEvents';
import { useAppDispatch, useAppSelector } from '@/store';
import { tutorSelector } from '@/store/reducers/tutor';
import TutorChatBody from '../../TutorDialogueManager/TutorChatBody/TutorChatBody';
import { Card } from '@/types/tutor';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useDrawer } from '@/context/DrawerContext';
import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';

interface TutorMobile {
  handleExitConfirmPopup: () => void;
  data: [string, Card[]][];
}

const TutorMobile = ({ handleExitConfirmPopup, data }: TutorMobile) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile, isDesktop } = UseScreenSize();
  const drawer = useDrawer();
  const navigate = useNavigate();
  const { showTutor } = useAppSelector(tutorSelector);

  const toggleMenu = () => {
    if (isMobile) {
      drawer.openMobileDrawer();
      return;
    }

    drawer.openDrawer();
  };

  const onGoBack = () => {
    return navigate('/dashboard/games');
  };

  const content = (
    <div className={classNames.dashboard}>
      <div
        className={classes(classNames.header, {
          [classNames.isMobile]: isMobile,
        })}
      >
        <div
          className={classes(classNames.banner, {
            [classNames.justifyCenter]: showTutor,
          })}
        >
          {showTutor ? (
            <div className={classNames.tutor}>
              <img src={TutorIcon} alt="tutorIcon" />
              <div className={classes(classNames.back)}>
                <span onClick={handleExitConfirmPopup}>{t('tutorBack')}</span>
              </div>
              <p>{t('tutorPrivate')}</p>
            </div>
          ) : (
            <>
              <div className={classNames.navigation}>
                <img src={MelingoLogo} alt="logo" onClick={onGoBack} />

                <button
                  onClick={toggleMenu}
                  className={classNames.menuIcon}
                  aria-label={t('menu_button').toString()}
                >
                  <SVG src={MobileMenuIcon} />
                </button>
              </div>
              <div className={classNames.content}>
                <img src={TutorIcon} alt="tutorIcon" />
                <p>{t('tutorWelcomeTitle')}</p>
                <span>{t('tutorBannerTitle')}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={classes(classNames.body, {
          [classNames.isDashMobile]: isMobile && !showTutor,
        })}
      >
        {showTutor ? (
          <TutorChatBody isDashboard />
        ) : (
          data.map(([key, value]) => (
            <TutorEvents key={key} title={t(`tutor_${key}`)} data={value} />
          ))
        )}
      </div>
    </div>
  );

  return (
    <div
      className={classes(classNames.myTutorDesktop, {
        [classNames.isShowTutor]: showTutor,
      })}
    >
      {content}
    </div>
  );
};

export default TutorMobile;
