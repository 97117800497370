import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/types/common';
import {
  FinalAssessmentProgress,
  FinalAssessmentQuestionResult,
  FinalAssessmentResponse,
  FinalAssessmentState,
} from '@/types/final-assessment';
import finalAssessmentThunks from '../thunks/final-assessment';
import GameUtils from '@/utils/gameUtils';
import commonUtils from '@/utils/common';

const defaultFinalAssessmentState: FinalAssessmentState = {
  quizzes: null,
  progress: null,
  currentQuizIndex: 0,
};

const finalAssessment = createSlice({
  name: 'FinalAssessment',
  initialState: defaultFinalAssessmentState,
  reducers: {
    increaseCurrentQuizIndex: (state) => {
      state.currentQuizIndex = state.currentQuizIndex + 1;
    },
    updateProgress: (
      state,
      {
        payload,
      }: PayloadAction<{
        questions: FinalAssessmentQuestionResult[];
        correctAnswers: number;
        wrongAnswers: number;
        questionAnswered: number;
      }>
    ) => {
      const { questions, correctAnswers, wrongAnswers, questionAnswered } =
        payload;

      const currentQuestions = state.progress?.questions ?? [];
      const currentCorrectAnswers = state.progress?.correctAnswers ?? 0;
      const currentWrongAnswers = state.progress?.wrongAnswers ?? 0;
      const currentQuestionAnswered = state.progress?.questionAnswered ?? 0;

      state.progress = {
        ...state.progress,
        questions: [...currentQuestions, ...questions],
        correctAnswers: currentCorrectAnswers + correctAnswers,
        wrongAnswers: currentWrongAnswers + wrongAnswers,
        questionAnswered: currentQuestionAnswered + questionAnswered,
      } as FinalAssessmentProgress;
    },
    finishProgress: (state) => {
      const score = commonUtils.calcPercentage(
        state.progress?.correctAnswers ?? 0,
        state.progress?.questionAnswered ?? 0
      );
      const isFailed = score < 60;
      const finishTime = GameUtils.formatDate(new Date());

      state.progress = {
        ...state.progress,
        score,
        isFailed,
        finishTime,
      } as FinalAssessmentProgress;
    },

    reset: (state) => {
      state.quizzes = null;
      state.progress = null;
      state.currentQuizIndex = 0;
    },
  },
  extraReducers: {
    [finalAssessmentThunks.fetchFinalAssessment.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<FinalAssessmentResponse>
    ) => {
      state.quizzes = payload;

      const startTime = GameUtils.formatDate(new Date());

      state.progress = {
        questions: [],
        correctAnswers: 0,
        wrongAnswers: 0,
        isFailed: false,
        questionAnswered: 0,
        score: 0,
        startTime,
        finishTime: '',
      };
    },
    [finalAssessmentThunks.fetchFinalAssessment.rejected.toString()]: (
      state
    ) => {
      state.quizzes = null;
    },
  },
});

export const finalAssessmentActions = finalAssessment.actions;

export const finalAssessmentSelector = (state: RootState) =>
  state.finalAssessment;

export default finalAssessment.reducer;
