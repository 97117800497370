import classNames from './ViewToggler.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { View } from '@/types/common';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';

interface ViewTogglerProps {
  className?: string;
  viewTabs: View[];
  emitSelectView: (viewTab: View) => void;
}

const ViewToggler = ({
  className,
  viewTabs,
  emitSelectView,
}: ViewTogglerProps) => {
  const { t } = useTranslation();
  const currentRoute = UseCurrentRoute();

  const onSetCurrentView = (viewTab: View) => {
    emitSelectView(viewTab);
  };

  const viewTogglers = viewTabs.map((viewTab) => {
    const { name, text, icon } = viewTab;
    const isSelected = viewTab.name === currentRoute;

    return (
      <div
        key={name}
        className={classes(classNames.toggler, {
          [classNames.togglerSelected]: isSelected,
        })}
        onClick={() => onSetCurrentView(viewTab)}
      >
        <SVG className={classNames.icon} src={icon ?? ''} />
        <span className={classNames.text}>{t(`${text}`)}</span>
      </div>
    );
  });

  return (
    <div className={classes(classNames.viewToggler, className)}>
      {viewTogglers}
    </div>
  );
};

export default ViewToggler;
