import {
  PopUpFocusProps,
  PopUpHintsProps,
  PopUpKeepGoingProps,
  PopUpPauseProps,
  PopUpSummaryProps,
  PopUpTimeIsUpProps,
  PopUpTypes,
  PopUpWordsListProps,
} from '@/types/game';

import PopUpKeepGoing from './PopUpKeepGoing/PopUpKeepGoing';
import PopUpFocus from './PopUpFocus/PopUpFocus';
import PopUpTimeIsUp from './PopUpTimeIsUp/PopUpTimeIsUp';
import PopUpPause from './PopUpPause/PopUpPause';
import PopUpSummary from './PopUpSummary/PopUpSummary';
import PopUpHints from './PopUpHints/PopUpHints';
import PopUpWordsList from './PopUpWordsList/PopUpWordsList';
import PopUpEndOfSkillSummary from './PopUpEndOfSkillSummary/PopUpEndOfSkillSummary';
import PopUpFinalAssessmentSummary from './PopUpFinalAssessmentSummary/PopUpFinalAssessmentSummary';
import TutorPopUpKeepGoing from './TutorPopUpKeepGoing/TutorPopUpKeepGoing';
import TutorPopUpFocus from './TutorPopUpFocus/TutorPopUpFocus';

const popUps = [
  {
    type: PopUpTypes.KeepGoing,
    component: PopUpKeepGoing,
  },
  {
    type: PopUpTypes.TutorKeepGoing,
    component: TutorPopUpKeepGoing,
  },
  {
    type: PopUpTypes.Focus,
    component: PopUpFocus,
  },
  {
    type: PopUpTypes.TutorFocus,
    component: TutorPopUpFocus,
  },
  {
    type: PopUpTypes.TimeIsUp,
    component: PopUpTimeIsUp,
  },
  {
    type: PopUpTypes.Pause,
    component: PopUpPause,
  },
  {
    type: PopUpTypes.Summary,
    component: PopUpSummary,
  },
  {
    type: PopUpTypes.EndOfSkillSummary,
    component: PopUpEndOfSkillSummary,
  },
  {
    type: PopUpTypes.FinalAssessmentSummary,
    component: PopUpFinalAssessmentSummary,
  },
  {
    type: PopUpTypes.Hints,
    component: PopUpHints,
  },
  {
    type: PopUpTypes.WordsList,
    component: PopUpWordsList,
  },
];

interface QuizPopUpProps {
  type: PopUpTypes | null;
  className: string;
}

const QuizPopUp = (
  props: QuizPopUpProps &
    (
      | PopUpKeepGoingProps
      | PopUpFocusProps
      | PopUpTimeIsUpProps
      | PopUpPauseProps
      | PopUpSummaryProps
      | PopUpHintsProps
      | PopUpWordsListProps
    )
) => {
  let popUpTypeToFind: any;

  popUpTypeToFind = props?.type;

  const popUpObj = popUps.find((popUp) => popUp.type === popUpTypeToFind);

  const ComponentToRender = popUpObj && popUpObj.component;

  return ComponentToRender ? <ComponentToRender {...props} /> : null;
};

export default QuizPopUp;
