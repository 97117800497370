import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import UseRegistration from './UseRegistration';
import SVG from 'react-inlinesvg';
import { RegistrationStepType } from '@/types/registration';

import logo from '@/assets/svg/logo_new.svg';
import welcomeDesktop from '@/assets/svg/welcome-desktop.svg';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import AppMorfixLogo from '@/components/AppMorfixLogo/AppMorfixLogo';

const RegistrationView = () => {
  const { t } = useTranslation();
  const { isDesktop } = UseScreenSize();
  const { nativeLanguage } = useAppSelector(authSelector);
  const showMorfixLogo = nativeLanguage === 'he';
  const {
    isLoading,
    role,
    currentStep,
    stepProps,
    RegistrationComponent,
    onNextStep,
  } = UseRegistration();

  const isWelcomeStep =
    currentStep === RegistrationStepType.REGISTRATION_WELCOME;

  const isVerificationCodeStep =
    currentStep === RegistrationStepType.VERIFY_CODE;

  const showWelcomeBackground =
    role === 'student' && isDesktop && isWelcomeStep;

  let component = RegistrationComponent ? (
    <RegistrationComponent {...stepProps()} />
  ) : null;

  if (isWelcomeStep) {
    component =
      RegistrationComponent && !isLoading ? (
        <RegistrationComponent {...stepProps()} />
      ) : null;
  }

  const formHeader = !isWelcomeStep && (
    <div className="registration-view-form-header">
      {showMorfixLogo ? (
        <AppMorfixLogo width={340} height={60} showBackGround={false} />
      ) : (
        <>
          <SVG src={logo} />
          <h2>Britannica English</h2>
        </>
      )}
      {!isVerificationCodeStep && <span>{t('pleaseEnterYourDetails')}</span>}
    </div>
  );

  return (
    <div className="registration-view h-full">
      {showWelcomeBackground && (
        <SVG className="registration-view-student" src={welcomeDesktop} />
      )}
      <div
        className={classes('registration-view-content', {
          'registration-view-form-content': !isWelcomeStep,
        })}
      >
        {isLoading && (
          <div className="registration-view-loader-wrapper">
            <AppLoaderCircle />
          </div>
        )}
        {formHeader}
        {component}
      </div>
    </div>
  );
};

export default RegistrationView;
