import classNames from './QuizzesModalHeader.module.scss';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import commonUtils from '@/utils/common';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import Skeleton from 'react-loading-skeleton';

import close from '@/assets/svg/close.svg';
import star from '@/assets/svg/star.svg';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import React, { useState } from 'react';
import UseLocalLang from '@/hooks/UseLocalLang';

interface QuizzesModalHeader {
  loadingQuizzes: boolean;
  emitOnClose: () => void;
}

const QuizzesModalHeader = ({
  loadingQuizzes,
  emitOnClose,
}: QuizzesModalHeader) => {
  const { t } = useTranslation();
  const { selectedTopic } = useAppSelector(gameSelector);
  const [topic] = useState(selectedTopic);
  const localLang = UseLocalLang();

  const onCloseModal = () => {
    if (loadingQuizzes) {
      return;
    }

    emitOnClose();
  };

  return (
    <div className={classNames.quizzesModalHeader}>
      <button className={classNames.close} onClick={onCloseModal}>
        <SVG src={close} />
      </button>
      <div className={classNames.info}>
        <h2>{topic ? commonUtils.getName(topic, localLang) : 'N/A'}</h2>
        <div className={classNames.amounts}>
          <span className={classNames.quizzes}>
            {topic?.userData?.quizzesCompleted ?? 0}/{topic?.quizzesCount}{' '}
            {t('quizzes')}
          </span>
          <div className={classNames.separator}></div>
          <div className={classNames.stars}>
            <SVG src={star} />
            <span>
              {topic?.userData?.receivedStars ?? 0}/
              {commonUtils.totalStars(topic)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(QuizzesModalHeader);
