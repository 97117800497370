import React from 'react';
import classNames from './CognitoVerify.module.scss';
import classes from 'classnames';
import { CognitoVerificationCodeProps } from '@/types/registration';
import AppInput from '@/components/AppInput/AppInput';
import TheButton from '@/components/TheButton/TheButton';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

const CognitoVerify = ({
  verificationCodeField,
  role,
  email,
  resendVerificationCode,
  emitSubmit,
}: CognitoVerificationCodeProps) => {
  const { t } = useTranslation();
  const { interfaceLanguage } = useAppSelector(authSelector);

  const isSpanish = interfaceLanguage === 'es';
  const isTeacher = role === 'teacher';

  return (
    <form className={classNames.cognitoVerify} onSubmit={emitSubmit}>
      <div className={classNames.top}>
        <h1>{t('emailVerification')}</h1>
        <h2>
          {t('verificationCodeSent')}: {email}.
        </h2>
        <div className={classNames.fields}>
          {verificationCodeField && (
            <AppInput
              onChange={verificationCodeField?.emitChange}
              shouldTranslate
              maxLength={6}
              key={verificationCodeField?.id}
              className={classes(classNames.field, {
                [classNames.fieldError]: verificationCodeField?.error,
              })}
              inputWrapperClassName={classNames.inputWrapperClassName}
              {...verificationCodeField}
              label={
                isSpanish && isTeacher
                  ? t('enterCodeHereTeacher').toString()
                  : verificationCodeField.label
              }
            />
          )}
        </div>
        <div className={classNames.description}>
          <div className={classNames.partOne}>
            <Trans
              i18nKey={
                isSpanish && isTeacher
                  ? 'checkInSpamMessageTeacher'
                  : 'checkInSpamMessage'
              }
            >
              <span className="font-semibold"></span>
            </Trans>
          </div>
          <div className={classNames.partTwo}>
            <Trans
              i18nKey={
                isSpanish && isTeacher
                  ? 'orClickHereToSendAgainTeacher'
                  : 'orClickHereToSendAgain'
              }
            >
              <a
                onClick={resendVerificationCode}
                className={classNames.resend}
              ></a>
            </Trans>
          </div>
        </div>
      </div>
      <TheButton
        className={classNames.submitBtn}
        disabled={
          !verificationCodeField?.value ||
          verificationCodeField?.value.length < 6
        }
        text={t('next')}
        type="submit"
      />
    </form>
  );
};

export default CognitoVerify;
