import classNames from './DictionaryDesktop.module.scss';
import classes from 'classnames';

import DictionarySearch from '../DictionarySearch/DictionarySearch';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import { useEffect, useState } from 'react';
import AutoCompleteResults from '../AutoCompleteResults/AutoCompleteResults';
import RecentTranslations from '../RecentTranslations/RecentTranslations';

interface DictionaryDesktopProps {
  className?: string;
  searchValue: string;
  recentTranslations: string[];
  results: string[];
  suggestions: string[];
  onInputChange: (value: string) => void;
  onClear: () => void;
  onSelectOption: (option: string) => void;
  onSearch: (value: string) => void;
}

const DictionaryDesktop = ({
  className,
  searchValue,
  onInputChange,
  onClear,
  onSelectOption,
  onSearch,
}: DictionaryDesktopProps) => {
  const currentRoute = UseCurrentRoute();
  const isMyStudentsPage = currentRoute === ROUTE_NAMES.MY_STUDENTS;

  const [shouldExpand, setShouldExpand] = useState(false);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!shouldExpand) {
        return;
      }

      onClose();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [shouldExpand]);

  useEffect(() => {
    setShouldExpand(() => Boolean(searchValue));
  }, [searchValue]);

  const onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setShouldExpand((prevState) => (searchValue ? true : !prevState));
  };

  const onClose = () => {
    setShouldExpand(false);
  };

  const onResultClick = (result: string) => {
    onSelectOption(result);
  };

  return (
    <div className={classes(classNames.dictionaryDesktop, className)}>
      <DictionarySearch
        showHeading={true}
        className={classNames.dictionarySearch}
        value={searchValue}
        shouldExpand={shouldExpand && !searchValue}
        emitChange={onInputChange}
        emitClear={onClear}
        emitKeyPress={onSearch}
        emitClick={onClick}
        inGame={false}
      />
      {/* {shouldExpand && !searchValue && (
        <div className={classNames.results}>
          <SectionSeperator className="mb-8" />
          {!searchValue && (
            <RecentTranslations
              translations={recentTranslations}
              emitClick={onResultClick}
            />
          )}
          {results.length ? (
            <AutoCompleteResults
              searchValue={searchValue}
              results={results}
              suggestions={suggestions}
              emitClick={onResultClick}
            />
          ) : (
            <RecentTranslations
              translations={recentTranslations}
              emitClick={onResultClick}
            />
          )}
        </div>
      )} */}
    </div>
  );
};

export default DictionaryDesktop;
