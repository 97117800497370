import { PopUpFocusProps } from '@/types/game';
import classNames from './TutorPopUpFocus.module.scss';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import classes from 'classnames';
import { useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

import TheButton from '@/components/TheButton/TheButton';

import close from '@/assets/svg/close.svg';
import focus from '@/assets/svg/tutor/focus.svg';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { useDispatch } from 'react-redux';
import { tutorActions, tutorSelector } from '@/store/reducers/tutor';
import { TriggeredBy } from '@/types/tutor';

const TutorPopUpFocus = ({
  tip,
  className,
  inCorrectAnswers,
  totalQuestions,
  emitOnClosePopUp,
}: PopUpFocusProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = UseScreenSize();
  const { isTutorNeeded } = useAppSelector(tutorSelector);

  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const [shouldExpand, setShouldExpand] = useState(false);

  const onExpand = () => {
    if (isDesktop) {
      return;
    }
    setShouldExpand(true);
  };

  const onReduce = () => {
    setShouldExpand(false);
  };

  const space = ' ';

  const titleElement = <h3>{t('focus')}</h3>;

  const descriptionElement = (
    <div className={classNames.text}>
      {isTutorNeeded ? (
        t('tutorPracticeWithMe')
      ) : (
        <>
          <div className={classNames.partOne}>
            <span className={classNames.oneMoreMistake}>
              {t('oneMoreMistake')}
            </span>
          </div>
          <span>{t('coinsWillBeLost')}</span>
        </>
      )}
    </div>
  );

  const toggleTutor = () => {
    emitOnClosePopUp?.();
    dispatch(tutorActions.openTutor(TriggeredBy.Focus));
  };

  const imgElement = (
    <div className={classNames.tutor}>
      <img src={focus} alt="focus" />
      {isMobile && (
        <>
          <div className={classNames.title}>
            {t('oneMoreMistake')} {t('coinsWillBeLost')}
          </div>
          <span>{t('tutorLetsPractice')}</span>
          <TheButton
            className={classNames.button}
            text={t('tutorClickToPractice')}
            emitOnClick={toggleTutor}
            showArrow={true}
          />
        </>
      )}
    </div>
  );

  const questionsTextElement = (
    <div className={classNames.questions}>
      <div>
        <span className={classNames.amount}>
          {inCorrectAnswers + space}
          {t('outOf') + space}
          {totalQuestions + space}
        </span>
        <span>{t('questions')}</span>
      </div>
      <span>{t('answeredIncorrectly')}</span>
    </div>
  );

  return (
    <div className={classes('container', classNames.popUpFocus, className)}>
      {shouldExpand && isMobile && <div className={classNames.backdrop}></div>}
      {isDesktop && (
        <div className={classNames.desktopContent}>
          {imgElement}
          <div className={classNames.content}>
            {titleElement}
            {questionsTextElement}
            {descriptionElement}
          </div>
        </div>
      )}
      {isMobile && (
        <>
          {questionsTextElement}
          {titleElement}
          {imgElement}
        </>
      )}
      <div
        className={classes(classNames.bottom, {
          [classNames.expanded]: shouldExpand,
          [classNames.bottomNoTip]: !tip,
        })}
      >
        {shouldExpand && (
          <div className={classNames.close} onClick={onReduce}>
            <SVG src={close} />
          </div>
        )}
        <div
          className={classes(classNames.tipAndButton, {
            [classNames.tipAndButtonMobile]: isMobile,
          })}
        >
          {isDesktop ? (
            <div className={classNames.practice}>
              <div className={classNames.practice_content}>
                <p>{t('tutorPracticeIsRecommended')}</p>
                <span>
                  {isTutorNeeded
                    ? t('tutorPracticeWithMe')
                    : `${t('oneMoreMistake')} ${t('coinsWillBeLost')}`}
                </span>
              </div>

              <div className={classNames.link}>
                <a
                  onClick={() => {
                    dispatch(
                      tutorActions.setSuggestedTriggered(TriggeredBy.Focus)
                    );
                    emitOnClosePopUp?.();
                  }}
                >
                  {t('tutorContinue')}
                </a>
                <TheButton
                  shouldRecolor={false}
                  className={classNames.button}
                  text={t('tutorPracticeWithMe')}
                  emitOnClick={toggleTutor}
                  showArrow={true}
                />
              </div>
            </div>
          ) : (
            <a onClick={() => emitOnClosePopUp?.()}>{t('tutorContinue')}</a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TutorPopUpFocus;
