import React, { useState, useCallback, FocusEvent } from 'react';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';

import { StudentData, StudentValidationError } from '@/types/student-data';
import ValidateStudent from '@/utils/studentDataValidationUtils';
import classNames from '../StudentsTable.module.scss';
import binIcon from '@/assets/svg/bin.svg';
import saveIcon from '@/assets/svg/check-plain.svg';

interface AddStudentRowProps {
    setStudentsData: React.Dispatch<React.SetStateAction<StudentData[]>>;
    setShowNewStudentInput: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddStudentRow: React.FC<AddStudentRowProps> = ({
    setStudentsData,
    setShowNewStudentInput,
}) => {
    const { t } = useTranslation();
    const [newStudent, setNewStudent] = useState<StudentData>({ firstName: '', lastName: '', email: '' });
    const [validationErrors, setValidationErrors] = useState<StudentValidationError[]>([]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewStudent(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleAddNewStudent = useCallback(() => {
        const errors = ValidateStudent(newStudent);
        if (errors.length) {
            setValidationErrors(errors);
            return;
        }

        setStudentsData(prev => [...prev, newStudent]);
        resetNewStudent();
    }, [newStudent, setStudentsData]);

    const resetNewStudent = useCallback(() => {
        setNewStudent({ firstName: '', lastName: '', email: '' });
        setShowNewStudentInput(false);
        setValidationErrors([]);
    }, [setShowNewStudentInput]);

    const handleCloseAddNewStudent = useCallback(() => {
        resetNewStudent();
    }, [resetNewStudent]);

    const findErrorMessage = useCallback((field: string): string | undefined => {
        const error = validationErrors.find(error => error.field === field);
        return error ? error.message : undefined;
    }, [validationErrors]);

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const errors = ValidateStudent(newStudent);
        setValidationErrors(errors);
    };

    return (
        <tr className={`${classNames.studentRow} ${validationErrors.length > 0 ? classNames.errorRow : ''}`}>
            {['firstName', 'lastName', 'email'].map(field => (
                <td key={field}>
                    <input
                        type={field === 'email' ? 'email' : 'text'}
                        name={field}
                        value={newStudent[field]}
                        onBlur={handleBlur}
                        className={findErrorMessage(field) ? classNames.errorInput : ''}
                        onChange={handleChange}
                    />
                    {findErrorMessage(field) && (
                       <span className={classNames.rowError}>{t(`settings.add_students.errors.${findErrorMessage(field)}`)}</span>
                    )}
                </td>
            ))}
            <td className={classNames.actionIcons}>
                <button className={classNames.saveSvg} onClick={handleAddNewStudent}>
                    <SVG src={saveIcon} />
                </button>
                <button className={classNames.buttonSvg} onClick={handleCloseAddNewStudent}>
                    <SVG src={binIcon} className={classNames.closingBtn}/>
                </button>
            </td>
        </tr>
    );
};

export default AddStudentRow;