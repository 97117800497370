import config from '@/config';
import { EndQuizBody, GameTypes } from '@/types/game';
import { StudentsSkillsProgress } from '@/types/progress';
import {
  ClassProgressResponse,
  StudentProgressResponse,
  LevelTestResultsResponse,
  PostQuizProgressResponse,
  QuizzesProgressResponse,
  SkillsProgressResponse,
  TopicsProgressResponse,
  ProfileProgressResponse,
  StudentProgressBySkillResponse,
  ImprovableRecentQuizzesResponse,
  ComingUpQuizzesResponse,
} from '@/types/response';
import { ProgressService } from '@/types/services';
import { SkillTypes } from '@/types/skill';
import { UserState } from '@/types/user';
import axiosInstance from '@/utils/api';
import UserUtils from '@/utils/user';

export const progressService: ProgressService = {
  fetchSkillsProgress: async (user: UserState | null) => {
    const resp: SkillsProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/progress/skills/${user?.id}`
    );

    return resp;
  },
  fetchTopicsProgress: async (skill: SkillTypes, user: UserState | null) => {
    const resp: TopicsProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/progress/topics/${user?.id}/${skill}/${UserUtils.levelByLanguage(
        user,
        skill
      )}`
    );

    return resp;
  },
  fetchQuizProgress: async (
    skill: SkillTypes,
    user: UserState | null,
    topicId: number
  ) => {
    const resp: QuizzesProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/progress/quizzes/${user?.id}/${skill}/${topicId}`
    );
    return resp;
  },
  fetchQuestionsProgress: async (
    skill: SkillTypes,
    user: UserState | null,
    topicId: number,
    quizId: number
  ) => {
    const resp: QuizzesProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/progress/questions/${user?.id}/${skill}/${topicId}/${quizId}`
    );

    return resp;
  },
  // sendLevelTestData: async (user: UserState | null, progress: EndQuizBody) => {
  //   const resp: LevelTestResultsResponse | null = await axiosInstance.post(
  //     `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
  //       user
  //     )}/calculate-user-level/${user?.id}`,
  //     progress
  //   );

  //   return resp;
  // },
  sendQuizProgressData: async (
    user: UserState | null,
    skillName: string,
    topicId: number,
    quizId: number,
    gameType: GameTypes,
    progress: any
  ) => {
    const inPractice = gameType === GameTypes.Practice;

    const skillLevel = UserUtils.levelByLanguage(user, skillName);

    const resp: PostQuizProgressResponse | null = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(user)}/progress/${
        inPractice ? 'practice' : 'game'
      }/${
        user?.id
      }/${skillLevel}/${skillName.toLowerCase()}/${topicId}/${quizId}`,
      progress
    );

    return resp;
  },
  fetchClassProgress: async (user: UserState | null, classroom: string) => {
    const resp: ClassProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/class-progress/${user?.id}/${classroom}`
    );

    return resp;
  },
  fetchStudentProgress: async (user: UserState | null, studentId: string) => {
    const resp: StudentProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/class-progress/${
        user?.id
      }/student-metadata/${studentId}`
    );

    return resp;
  },
  fetchStudentProgressBySkill: async (
    user: UserState | null,
    studentId: string,
    studentLevel: number,
    skill: SkillTypes,
    selectedStudent: UserState | null
  ) => {
    const resp: StudentProgressBySkillResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/class-progress/${
        user?.id
      }/student-scores-in-skill/${studentId}/${skill}/${UserUtils.levelByLanguage(
        selectedStudent,
        skill
      )}`
    );

    return resp;
  },
  fetchProfileProgress: async (user: UserState | null) => {
    const resp: ProfileProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/user/profile/${user?.id}`
    );

    return resp;
  },
  fetchImprovableRecentQuizzes: async (user: UserState | null) => {
    const resp: ImprovableRecentQuizzesResponse | null =
      await axiosInstance.get(
        `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
          user
        )}/user/learning-hub/get-improve-last-quiz/${user?.id}`
      );

    return resp;
  },
  fetchBestScoreOfTheWeek: async (user: UserState | null) => {
    const resp: any | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/user/learning-hub/get-best-score-of-the-week/${user?.id}`
    );

    return resp;
  },
  fetchComingUpQuizzes: async (user: UserState | null) => {
    const resp: ComingUpQuizzesResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/user/learning-hub/get-coming-up-quizzes/${user?.id}`
    );

    return resp;
  },
};
