import { RootState } from '@/types/common';
import { Topic } from '@/types/topic';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { appSettingsActions } from '../reducers/settings';
import { SkillTypes } from '@/types/skill';
import { progressService, skillService } from '@/services';

const topicsThunks = {
  refreshSkillTopics: createAsyncThunk(
    'refreshSkillTopics',
    async (_, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const { selectedType } = state.game;
        const user = state.auth.user;

        if (!selectedType) {
          return;
        }

        const _existTopics = structuredClone(
          state.settings.topics[selectedType]
        );

        const topicsProgress =
          (await progressService.fetchTopicsProgress(selectedType, user))
            ?.data ?? {};

        for (const topic of _existTopics) {
          const topicProgress = topicsProgress[topic.id.toString()];

          if (topicProgress) {
            topic.userData = { ...topicProgress };
          }
        }

        dispatch(
          appSettingsActions.apppendSkillWithTopics({
            skill: selectedType,
            topics: _existTopics,
          })
        );

        return _existTopics;
      } catch (error) {
        console.log(error);

        throw error;
      }
    }
  ),
  prefetchSkillTopics: createAsyncThunk(
    'prefetchSkillTopics',
    async (_, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const user = state.auth.user;
        const { isLevelTest } = state.game;
        const topics: Record<string, Topic[]> = {};
        const _existTopics = structuredClone(state.settings.topics);

        dispatch(appSettingsActions.toggleTopicsLoading());

        for (const skill of Object.values(SkillTypes)) {
          if (isLevelTest) {
            continue;
          }

          const topicsList = _existTopics[skill]
            ? _existTopics[skill]
            : (await skillService.fetchTopics(skill, user))?.data ?? [];

          const topicsProgress =
            (await progressService.fetchTopicsProgress(skill, user))?.data ??
            {};

          for (const topic of topicsList) {
            const topicProgress = topicsProgress[topic.id.toString()];

            if (topicProgress) {
              topic.userData = topicProgress;
            }
          }

          topics[skill] = topicsList;
        }

        dispatch(appSettingsActions.populateTopics(topics));

        return topics;
      } catch (error) {
        throw error;
      }
    }
  ),
};

export default topicsThunks;
