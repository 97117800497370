import { useEffect, useState } from 'react';

let recognition: any = null;

if ('webkitSpeechRecognition' in window) {
  recognition = new webkitSpeechRecognition();
  recognition.continuos = true;
  recognition.lang = 'en-US';
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;
}

const UseSpeechRecognition = () => {
  const [text, setText] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const [isUserSpeechEmpty, setIsUserSpeechEmpty] = useState(false);
  useEffect(() => {
    if (!recognition) {
      return;
    }

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      setText(event.results[0][0].transcript);
      setIsUserSpeechEmpty(false);
    };

    recognition.onspeechend = (event: SpeechRecognitionEvent) => {
      recognition.stop();
    };
  }, []);

  useEffect(() => {
    try {
      isListening ? recognition.start() : recognition.stop();
    } catch (e) {}
  }, [isListening]);

  const onToggle = () => {
    if (disableToggle) {
      return;
    }

    if (!isListening) {
      setDisableToggle(true);

      setTimeout(() => {
        setDisableToggle(false);
      }, 1000);
    }

    if (isListening && !text) {
      setIsUserSpeechEmpty(true);
      console.log(`failed attempt: ${text}`);
    }

    setIsListening((prevState) => !prevState);
  };

  const onReset = () => {
    setText('');
    recognition.abort();
  };

  return {
    text,
    isListening,
    onToggle,
    onReset,
    hasRecognitionSupport: !!recognition,
    isUserSpeechEmpty,
    setIsUserSpeechEmpty,
    setIsListening,
  };
};

export default UseSpeechRecognition;
