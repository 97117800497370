import config from '@/config';
import { cognitoService } from '@/services/cognito';
import axios, { type AxiosError } from 'axios';
import commonUtils from './common';
import store from '@/store';
import { authActions } from '@/store/reducers/auth';

const tutorAxiosInstance = axios.create({
  baseURL: config.tutorUrl,
  withCredentials: false,
});

// axiosInstance.interceptors.request.use(async (config) => {
//   if (
//     !!localStorage.getItem('token') &&
//     commonUtils.shouldTokenRefresh(localStorage.getItem('token') ?? '')
//   ) {
//     console.log('refresh');
//     await cognitoService.refreshUserSession();

//     store.dispatch(authActions.setToken(localStorage.getItem('token') ?? ''));
//   }

//   if (!!localStorage.getItem('token')) {
//     config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
//   }

//   return config;
// });

tutorAxiosInstance.interceptors.response.use(
  (response) => {
    // Check if the response status code is 204
    if (response.status === 204) {
      throw new Error('No Content');
    }

    return response;
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default tutorAxiosInstance;
