import classNames from './GettingStartedInstructions.module.scss';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import classes from 'classnames';
import chat from '@/assets/svg/chat.svg';
import rightArrow from '@/assets/svg/right-arrow.svg';
import { useDrawer } from '@/context/DrawerContext';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import download from '@/assets/svg/download.svg';
import UseScreenSize from '@/hooks/UseScreenSize';

const GettingStartedInstructions = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { nativeLanguage } = useAppSelector(authSelector);
  const { isDesktop } = UseScreenSize();

  const isInterfaceLangHE = interfaceLanguage?.code === 'he';

  const isNativeLanguageHEAndInterfaceEN =
    nativeLanguage === 'he' && interfaceLanguage?.code === 'en';

  const instructions = [
    {
      title: t('teacherInstructions'),
      description: t('downloadAndView'),
      pdf: `/pdf/${nativeLanguage}_teacher.pdf`,
    },
    {
      title: t('studentInstructions'),
      description: t('downloadAndView'),
      pdf: `/pdf/${nativeLanguage}_student.pdf`,
    },
  ];

  const isRtl = interfaceLanguage?.direction === 'rtl';

  const instructionsElements = instructions.map((instruction, index) => (
    <a
      href={instruction.pdf}
      target="_blank"
      key={index}
      className={classNames.instruction}
    >
      <SVG src={download} />
      <span>{instruction.description + ' '}</span>
      <span className="font-bold">{instruction.title}</span>
    </a>
  ));
  return (
    <div className={classNames.gettingStartedInstructions}>
      {isDesktop && <h2>{t('gettingStarted')}</h2>}
      {isDesktop && (
        <div className={classNames.instructions}>{instructionsElements}</div>
      )}
      <div className={classNames.help}>
        <SVG
          className={classes(classNames.chat, {
            [classNames.chatSvgRtl]: isRtl,
          })}
          src={chat}
        />
        <h2>{t('needHelp')}</h2>
        <p>
          {isNativeLanguageHEAndInterfaceEN ? (
            <span>{t('contactOurTeamWithQuestions')}</span>
          ) : (
            <>
              <span>{t('contactOurTeam')}</span>
              <span>{t('britannicaEducationWith')}</span>
              {!isInterfaceLangHE && <span>{t('questionsAndConcerns')}</span>}
            </>
          )}
        </p>
        <button
          className={classNames.feedbackBtn}
          onClick={drawer.openContactUsDrawer}
        >
          {t('giveFeedback')}
          <SVG
            className={classes(classNames.arrow, {
              [classNames.arrowSvgRtl]: isRtl,
            })}
            src={rightArrow}
          />
        </button>
      </div>
    </div>
  );
};

export default GettingStartedInstructions;
