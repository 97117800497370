import { useAppDispatch, useAppSelector } from '@/store';
import { teacherDashboardSelector } from '@/store/reducers/teacher-dashboard';
import teacherDashboardThunks from '@/store/thunks/teacher-dashboard';
import { BaseGroupDetails } from '@/types/register';
import { useState, useEffect } from 'react';

const useGroupDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSavingGroup, setIsSavingGroup] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { groups } = useAppSelector(teacherDashboardSelector);
  const dispatch = useAppDispatch();

  const fetchGroupDetails = async (groupId: string) => {
    setIsLoading(true);

    try {
      const details = await dispatch(
        teacherDashboardThunks.getGroupDetails(groupId)
      ).unwrap();

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setIsLoading(false);

      return details;
    } catch (error) {
      setError('Failed to fetch group details');
      setIsLoading(false);
    }
  };

  const saveGroupDetails = async (
    groupId: string,
    groupDetails: BaseGroupDetails
  ) => {
    setIsSavingGroup(true);
    try {
      await dispatch(
        teacherDashboardThunks.updateGroupDetails({ groupId, groupDetails })
      ).unwrap();

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setIsSavingGroup(false);
    } catch (error) {
      setError('Failed to save group details');
      setIsSavingGroup(false);
    }
  };

  const createGroup = async (groupDetails: BaseGroupDetails) => {
    setIsSavingGroup(true);

    try {
      const group = await dispatch(
        teacherDashboardThunks.createGroup(groupDetails)
      ).unwrap();

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setIsSavingGroup(false);

      return group;
    } catch (error) {
      setError('Failed to save group details');
      setIsSavingGroup(false);
    }
  };

  return {
    isLoadingGroups: isLoading,
    isSavingGroup,
    errorLoadingGroups: error,
    fetchGroupDetails,
    saveGroupDetails,
    createGroup,
    groups,
  };
};

export default useGroupDetails;
