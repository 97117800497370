// External library imports
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import classes from 'classnames';

// Redux and state management
import { useAppSelector } from '@/store';
import { tutorSelector } from '@/store/reducers/tutor';

// Hooks
import UseScreenSize from '@/hooks/UseScreenSize';

// Components and assets
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import TutorDialogueManager from '../TutorDialogueManager/TutorDialogueManager';
import TutorAssistPopupDesktop from './TutorAssistPopup/TutorAssistPopupDesktop/TutorAssistPopupDesktop';
import TutorAssistPopupMobile from './TutorAssistPopup/TutorAssistPopupMobile/TutorAssistPopupMobile';
import TutorMuteConfirmPopupDesktop from './TutorAssistPopup/TutorMuteConfirmPopup/TutorMuteConfirmPopupDesktop/TutorMuteConfirmPopupDesktop';
import tutorIcon from '@/assets/svg/tutor/chat/tutor.svg';
import arrowIcon from '@/assets/svg/dictionary-btn.svg';
import classNames from './TutorChatInterface.module.scss';
import TutorMuteConfirmPopupMobile from './TutorAssistPopup/TutorMuteConfirmPopup/TutorMuteConfirmPopupMobile/TutorMuteConfirmPopupMobile';

// Types
type TutorChatInterfaceProps = {
  isFixed?: boolean;
  emitOpen: () => void;
  emitClose: () => void;
};

// TutorChatInterface component
const TutorChatInterface: React.FC<TutorChatInterfaceProps> = ({
  isFixed = true,
  emitOpen,
  emitClose,
}) => {
  const { t } = useTranslation();
  const { showTutor, showAssistPopup, showTutorMuteConfirmPopup } =
    useAppSelector(tutorSelector);
  const { isMobile, isDesktop } = UseScreenSize();

  // Render the appropriate modal content based on state
  const renderModalContent = () => {
    if (showTutor) return <TutorDialogueManager emitClose={emitClose} />;
    if (showAssistPopup)
      return isMobile ? (
        <TutorAssistPopupMobile emitClose={emitClose} />
      ) : (
        <TutorAssistPopupDesktop emitClose={emitClose} />
      );
    if (showTutorMuteConfirmPopup)
      return isMobile ? (
        <TutorMuteConfirmPopupMobile emitClose={emitClose} />
      ) : (
        <TutorMuteConfirmPopupDesktop emitClose={emitClose} />
      );
  };

  const renderMobileFooter = () => {
    return isMobile ? (
      <div
        id="tutor-btn"
        onClick={emitOpen}
        className={classes(classNames.tutorToggler, {
          [classNames.fixed]: isFixed,
        })}
      >
        <div className={classNames.title}>
          <img src={tutorIcon} alt="Tutor Icon" />
          <span>{t('tutorPrivate')}</span>

          <div className={classNames.arrowIcon}>
            <img src={arrowIcon} alt="Arrow Icon" />
          </div>
        </div>
      </div>
    ) : null;
  };

  return (
    <div className={classNames.chatInterface}>
      {renderMobileFooter()}
      <Transition
        in={showTutor || showAssistPopup || showTutorMuteConfirmPopup}
        timeout={400}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <>
            {(showTutor || showTutorMuteConfirmPopup) && isDesktop && (
              <AppBackdrop
                className={classes(classNames.backdrop, `backdrop-${state}`)}
                emitClose={emitClose}
              />
            )}
            <AppModal>{renderModalContent()}</AppModal>
          </>
        )}
      </Transition>
    </div>
  );
};

export default TutorChatInterface;
