import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppSettings, RootState } from '@/types/common';
import { Topic } from '@/types/topic';
import { SkillTypes } from '@/types/skill';
import { Quiz } from '@/types/quiz';
import { Question } from '@/types/question';

const defaultAppSettings: AppSettings = {
  topics: {},
  questions: {},
  quizzes: {},
  topicsLoading: false,
};

const appSettings = createSlice({
  name: 'appSettings',
  initialState: defaultAppSettings,
  reducers: {
    populateTopics: (
      state,
      { payload }: PayloadAction<Record<string, Topic[]>>
    ) => {
      state.topics = payload;
      state.topicsLoading = false;
    },
    apppendSkillWithTopics: (
      state,
      { payload }: PayloadAction<{ skill: SkillTypes; topics: Topic[] }>
    ) => {
      state.topics[payload.skill] = payload.topics;
    },
    toggleTopicsLoading: (state) => {
      state.topicsLoading = !state.topicsLoading;
    },
    clearAppSettings: (state) => {
      state.topics = {};
      state.quizzes = {};
      state.questions = {};
    },
    appendQuizzesWithData: (
      state,
      { payload }: PayloadAction<{ topicId: number; quizzes: Quiz[] }>
    ) => {
      state.quizzes = { ...state.quizzes, [payload.topicId]: payload.quizzes };
    },
    clearTopicQuizzes: (
      state,
      { payload }: PayloadAction<{ topicId: number }>
    ) => {
      const { [payload.topicId]: removedQuizzes, ...remainingQuizzes } =
        state.quizzes;

      state.quizzes = remainingQuizzes;
    },
    appendQuestions: (
      state,
      {
        payload,
      }: PayloadAction<{
        topicId: number;
        quizId: number;
        questions: Question[];
      }>
    ) => {
      state.questions = {
        ...state.questions,
        [`${payload.topicId}-${payload.quizId}`]: payload.questions,
      };
    },
    clearQuestionsProgress: (
      state,
      { payload }: PayloadAction<{ topicId: number; quizId: number }>
    ) => {
      const {
        [`${payload.topicId}-${payload.quizId}`]: removedQuestions,
        ...remainingQuestions
      } = state.questions;

      state.questions = remainingQuestions;
    },
  },
});

export const appSettingsActions = appSettings.actions;

export const appSettingsSelector = (state: RootState) => state.settings;

export default appSettings.reducer;
