const env = import.meta.env;

export default {
  apiUrl: env.VITE_API_URL,
  tutorUrl: env.VITE_API_TUTOR_URL,
  apiVersion: env.VITE_API_VERSION,
  appName: env.VITE_APP_NAME,
  awsUserPoolId: env.VITE_AWS_COGNITO_USER_POOL_ID,
  awsCognitoClientId: env.VITE_AWS_COGNITO_CLIENT_ID,
  countryStateCityAPIKey: env.VITE_COUNTRY_STATE_CITY_API_KEY,
  starsPerQuiz: 3,
  topicsPerSection: 3,
  topicsPerSectionDesktop: 5,
};
