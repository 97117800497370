import classes from 'classnames';
import React, { MutableRefObject, useEffect, useState } from 'react';
import classNames from './RoadmapSection.module.scss';
import { SkillTypes } from '@/types/skill';
import { Topic } from '@/types/topic';
import commonUtils from '@/utils/common';
import { useDispatch } from 'react-redux';
import { gameActions } from '@/store/reducers/game';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { useTranslation } from 'react-i18next';
import { authSelector } from '@/store/reducers/auth';
import { SkillSettingsItem } from '@/types/user';
import useQuizzesModal from '@/hooks/UseQuizzesModal';
import UseLocalLang from '@/hooks/UseLocalLang';

import QuizzesModal from '@/components/SkillPage/QuizzesModal/QuizzesModal';
import RoadmapCarousel from '@/components/RoadmapCarousel/RoadmapCarousel';
import TheButton from '../TheButton/TheButton';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useNavigate } from 'react-router-dom';
import quizzesThunks from '@/store/thunks/quizzes';
import { appSettingsSelector } from '@/store/reducers/settings';
import UserUtils from '@/utils/user';

interface RoadmapSectionProps {
  topics: Topic[];
  loading: boolean;
  groupedTopics: Topic[][];
  mapRef?: MutableRefObject<HTMLDivElement | null>;
}

const RoadmapSection = ({
  topics,
  groupedTopics,
  mapRef,
  loading,
}: RoadmapSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localLang = UseLocalLang();

  const { skillSettings, comingUpQuizzes, user } = useAppSelector(authSelector);
  const { selectedType } = useAppSelector(gameSelector);
  const { shouldDisplayModal, onCloseModal, onOpenModal } = useQuizzesModal();
  const [showStartNextQuiz, setShowStartNextQuiz] = useState(false);
  const isStudent = UserUtils.isStudent(user);
  const { quizzes, topics: topicsFromSelector } =
    useAppSelector(appSettingsSelector);

  useEffect(() => {
    if (comingUpQuizzes && topicsFromSelector[selectedType as string]) {
      const quizByType =
        comingUpQuizzes.find((i) => i.skill === selectedType) ?? null;
      const topic =
        topicsFromSelector[selectedType as string].find(
          (i) => i.id === quizByType?.topicId
        ) ?? null;

      setShowStartNextQuiz(Boolean(topic));
    }
  }, [selectedType, quizzes]);
  const onSelectTab = (type: SkillTypes, skill: SkillSettingsItem) => {
    dispatch(
      gameActions.setSelectedSkill({
        type,
        skill,
      })
    );
  };

  const tabsElements = skillSettings.map((skill) => {
    const tab = commonUtils.skillByName(skill.name ?? '');
    const isActive = selectedType === tab;

    return (
      <div
        key={skill.id}
        className={classes(classNames.tab, {
          [classNames.tabActive]: isActive,
        })}
        onClick={() => onSelectTab(tab, skill)}
      >
        <span>{commonUtils.getName(skill, localLang)}</span>
        {isActive && <div className={classNames.stripe}></div>}
      </div>
    );
  });
  const { isMobile } = UseScreenSize();
  const navigate = useNavigate();

  const onNextQuiz = async (type: SkillTypes | null) => {
    if (type) {
      if (type === SkillTypes.Vocabulary) {
        if (isMobile) {
          navigate('/dashboard/skill/vocabulary');
        } else {
          const skill =
            skillSettings.find((i) => i.name?.toLocaleLowerCase() === type) ??
            null;

          dispatch(gameActions.setSelectedSkill({ type, skill }));
          navigate(`/dashboard/games?skill=${skill?.name?.toLowerCase()}`);
        }

        return;
      }

      const quizByType = comingUpQuizzes.find((i) => i.skill === type) ?? null;
      const skill =
        skillSettings.find((i) => i.name?.toLocaleLowerCase() === type) ?? null;
      const topic =
        topicsFromSelector[type].find((i) => i.id === quizByType?.topicId) ??
        null;

      dispatch(gameActions.setSelectedSkill({ type, skill }));
      dispatch(gameActions.setSelectedTopic(topic));

      const topicQuizzes = topic
        ? quizzes[topic.id] ??
          (await dispatch(quizzesThunks.refreshTopicQuizzes() as any).unwrap())
        : [];

      const selectedQuiz = topic
        ? topicQuizzes.find((i) => i.id === quizByType?.quizId) ?? null
        : null;

      dispatch(gameActions.setSelectedQuiz(selectedQuiz));
      navigate('/dashboard/quiz');
    } else {
      return;
    }
  };

  return (
    <div
      className={classNames.roadmapSection}
      ref={mapRef !== null ? mapRef : null}
    >
      <div className={classNames.header}>
        <div className={classNames.tabs}>{tabsElements}</div>
        {isStudent &&
          selectedType !== SkillTypes.Vocabulary &&
          showStartNextQuiz && (
            <TheButton
              showArrow
              text={t('startNextQuiz')}
              className={classNames.nextQuizButton}
              emitOnClick={() => onNextQuiz(selectedType)}
            />
          )}
      </div>
      <RoadmapCarousel
        topics={topics.slice().sort((a, b) => a.order - b.order)}
        loading={loading}
        groupedTopics={groupedTopics}
        emitOpenModal={(topicId) => onOpenModal(topicId)}
      />
      <QuizzesModal
        shouldDisplayModal={shouldDisplayModal}
        onCloseModal={onCloseModal}
      />
    </div>
  );
};

export default React.memo(RoadmapSection);
