export const TRANSLATIONS_ES = {
  CodeMismatchException: "Código no válido",
  ExpiredCodeException: "Código inválido",
  IAlreadyHaveAnAccount: "Ya tengo una cuenta",
  LimitExceededException: "Se ha superado el límite de intentos. Vuelva a intentarlo más tarde.",
  UserNotConfirmedException: "Su cuenta no está verificada. Por favor, introduzca el código de verificación enviado a su correo electrónico.",
  accountVerifiedCanLoginNow: "Su cuenta ha sido verificada, ya puede iniciar sesión",
  account_settings: "Configuración de la cuenta",
  achievmentsSection: {
    feedback: "¡Has demostrado unas habilidades extraordinarias!",
    greatJob: "¡Gran trabajo!",
    "in": "En",
    weeklyAchievement: "Logro de la semana"
  },
  addClass: "Crear nueva clase",
  addNewClass: "Añadir nueva clase",
  advanceToTheNextLevel: "avanzar al siguiente nivel",
  advanced: "Avanzado",
  again: "de nuevo",
  all: "Todos",
  allDone: "¡Ya está!",
  allLevels: "Todos los niveles",
  allowIndividualAdvancement: "Permitir el avance de nivel",
  almostFinish: "¡Ya falta poco!",
  almostHalfWayThere: "¡Estás casi a mitad de camino!",
  almostMadeIt: "Casi lo logras",
  almostThere: "Ya casi terminas...",
  almost_made_it: "Casi lo logras",
  amazingJob: "¡Increíble trabajo!",
  amazing_job: "¡Increíble trabajo!",
  anErrorOccurred: "Se ha producido un error...",
  anUnauthorizedRequestErrorOccurred: "Se ha producido un error de solicitud no autorizada.",
  andBeginYour: "y comenzar tu viaje de aprendizaje de inglés.",
  andInviteYourStudents: "e invite a sus estudiantes",
  andThenInvite: "y luego invite a sus estudiantes a unirse a ella",
  andYouCanImproveYouScores: "y podrás mejorar tus puntuaciones.",
  and_got_all_the_stars: "y conseguiste todas las estrellas",
  answeredIncorrectly: "contestado incorrectamente.",
  appName: "Britannica ELL",
  appTitle: "Britannica",
  app_banner: "Banner de la aplicación",
  ar: "عربيه",
  areYouReadyForTheQuiz: "¿Crees que estás preparado/a para el juego?",
  areYouSure: "¿Está seguro/a?",
  asSoonAsTheyStart: "En cuanto ellos inicien su viaje, podrá ver sus progresos aquí.",
  assessment: "Evaluación",
  assessments: "Evaluaciones",
  assignments: "Tareas",
  atLeastOneNumber: "Debe contener al menos 1 número",
  atLeastOneSpecial: "Debe contener al menos 1 carácter especial",
  atLeastOneUppercase: "Debe contener al menos 1 letra mayúscula",
  averageStudentLevel: "Nivel promedio de los estudiantes",
  avgAttempts: "Promedio de intentos",
  avgClassScores: "Puntuación promedio de la clase",
  avgFailureRate: "Tasa promedio de fallas",
  avgGameScores: "Puntuaciones promedio de los juegos",
  avgPassRate: "Tasa promedio de aprobación",
  avgQuizzes: "Promedio de pruebas completadas por estudiante",
  avgTimeSpent: "Tiempo promedio empleado",
  back: "Atrás",
  backHome: "Volver a Inicio",
  backToHomePage: "Volver a Inicio",
  backToTopics: "Volver a los temas",
  back_home: "Volver a la página de inicio",
  badgesSection: {
    link: "Ver todo",
    title: "Mis insignias recientes"
  },
  britannicaEducationWith: "Britannica Education con",
  byClickingOn: "Al hacer clic en el nombre del estudiante, verá su desempeño en cada habilidad y la frecuencia con la que utiliza la aplicación. También puede decidir qué idioma de la interfaz deben utilizar los estudiantes.",
  cancel: "Cancelar",
  cancelAnyway: "Cancelar de todas maneras",
  changeLevel: "Cambiar nivel",
  change_password: "Cambiar la contraseña",
  characters: "Caracteres",
  checkInSpamMessage: "¿No lo has recibido? Revisa tu <0>carpeta de correo no deseado</0>.",
  checkInSpamMessageTeacher: "¿No lo ha recibido? Revise su <0>carpeta de correo no deseado</0>.",
  choose: "Elegir",
  chooseAClass: "Elija una clase",
  chooseAvatar: "Elegir avatar",
  chooseDate: "Elegir fecha",
  chooseGrade: "Elegir el grado",
  chooseTheCorrectAnswer: "Elija la respuesta correcta para completar la frase",
  chooseYourFavoriteAvatar: "Elige tu avatar y color favoritos",
  city: "Ciudad o distrito",
  "class": "Clase",
  classActivity: "Actividad de la clase",
  classAvg: "Promedio de la clase",
  classCreated: "¡La clase ha sido creada con éxito!",
  classCreatedDescription: "A sus alumnos se les ha enviado un correo electrónico con instrucciones sobre cómo restablecer su contraseña. Una vez que lo hayan hecho, comenzarán su viaje de aprendizaje del inglés.",
  className: "Nombre de la clase",
  classReport: "Informe de clase",
  classes: "Clases",
  clickOnAStudent: "Haga clic en un estudiante",
  clickToCopy: "Haga clic para copiar el enlace de su invitación",
  clickToTryAgain: "Haz clic para intentar de nuevo",
  clickToWatchAssessments: "¡Haga clic para ver las evaluaciones de los estudiantes!",
  coins: "Monedas",
  coinsWillBeLost: "y todas las monedas que ganaste se perderán.",
  complete: "Completo",
  confirmPassword: "Confirmar la contraseña",
  contactOurTeam: "Póngase en contacto con nuestro equipo en",
  contactOurTeamWithQuestions: "Póngase en contacto con nuestro equipo si tiene preguntas o dudas.",
  contactUs: "Contáctenos",
  "continue": "Continuar",
  continueCreating: "Continuar creando",
  continueOn: "Continuar",
  continueOnWeb: "Continuar en la web",
  continueQuiz: "Continuar el juego",
  continueToQuestions: "Continuar con las preguntas",
  continue_level_test: "Continuar la prueba de nivel",
  copy: "Copiar",
  copyInvitationLink: "Copiar enlace de la invitación",
  copyLink: "Copiar el enlace",
  copyLinkOnly: "Copiar solo enlace",
  country: "País",
  create: "Crear",
  createClass: "Crear clase",
  createNewClass: "Crear nueva clase",
  createYourClass: "Cree su clase e invite a sus estudiantes",
  createYourFirstClass: "Cree su primera clase",
  currentLevel: "Nivel actual",
  current_password: "Contraseña actual",
  customYourLearning: "¡Pronto podremos personalizar tu experiencia de aprendizaje!",
  dashboard: "Panel de control",
  date: "Fecha",
  dearSchoolAdmin: "Estimado administrador de la escuela, usted ha sido",
  dearStudent: "Estimado estudiante, has sido invitado por",
  dearTeacher: "Estimado/a docente, ha sido invitado/a a unirse a",
  dearTeacherLevel: "Querido profesor,",
  dearTeacherPleaseJoin: "Estimado docente, por favor únase a {{schoolName}} para que pueda crear sus clases y empezar a utilizar la plataforma de aprendizaje de inglés de Britannica.",
  deleteClass: "Eliminar clase",
  dictionary: "Diccionario",
  didYouMean: "Quizás quisiste decir",
  didntQuiteHearThat: "No lo he oído bien. Inténtalo de nuevo.",
  discoverAWorld: "Descubre un mundo de rutas de aprendizaje dinámicas y herrramientas sólidas para elevar tu nivel de inglés.",
  doingGreat: "Lo está haciendo bien.",
  done: "Listo",
  dontKnow: "No lo sé",
  downloadAndView: "Descargar y ver",
  editClass: "Editar clase",
  email: "Correo electrónico",
  emailAlreadyExists: "Ya existe una cuenta con el correo electrónico indicado",
  emailExample: "correo@gmail.com",
  emailIsValidReceiveVerificationCode: "Si la cuenta de correo electrónico es válida, recibirás un código de verificación para continuar con el proceso de restablecimiento de la contraseña.",
  emailVerification: "Verificación del correo electrónico",
  empowerEnrichAndInspire: "con Britannica",
  en: "Inglés",
  endOfLevelAssessment: "Evaluación de fin de nivel",
  english: "Inglés",
  englishLearningJourney: " experiencia única de enseñanza de inglés con Britannica English.",
  englishLearningJourneyMorfix: "English-learning journey with Morfixschool 2.0.",
  englishSkillsAssessment: "Evaluación de las habilidades de inglés",
  englishSkillsAssessments: "Evaluaciones de habilidades de inglés",
  englishSkillsProgress: "Progreso de las habilidades del inglés",
  enterCodeHere: "Ingresa el código aquí",
  enterNewPassword: "Ingresa la nueva contraseña",
  enterVerificationCode: "Ingrese el código de verificación",
  enterVerificationCodeAndNewPassword: "Ingrese el código de verificación y la nueva contraseña",
  es: "Español",
  exampleEmail: "ejemplo@gmail.com",
  exampleSentences: "Ejemplos de oraciones",
  examples: "ejemplos",
  excellent: "Excelente",
  exit: "Salir",
  extra: "Extra",
  extraCoins: "Monedas extra",
  failed_quiz_message_again: "Aún no has pasado este juego, así que no hay monedas extra ni estrellas.",
  failed_quiz_message_first: "Lamentablemente, no pasaste, por lo que no recibirás monedas por este juego.",
  failed_quiz_message_passed: "No pasaste. No te preocupes, tu puntuación anterior ha sido guardada.",
  failureRate: "Tasa de fallos",
  fewMoreSeconds: "solo unos segundos más",
  fieldIsMandatory: "Este campo es obligatorio",
  filter: "Filtrar",
  finalAssessment: "Evaluación final",
  finalEnglishLevelTest: "Prueba final de nivel de inglés",
  firstname: "Primer nombre",
  focus: "Encuentra tu flujo",
  "for": "para",
  forgotPassword: "Olvidé mi contraseña",
  foundation: "Básico",
  friday: "viernes",
  fridayShort: "vie",
  from: "De",
  fullVersionAvilableOnlyOnDesktop: "Versión completa disponible solo en computadora de escritorio",
  generalLevel: "Nivel general",
  generateClassReport: "Generar informe de clase",
  generateReport: "Generar informe",
  getApp: "Descargar aplicación",
  getFullExperience: "Disfruta de la experiencia completa con la aplicación Britannica English",
  getStarted: "Empezar",
  gettingStarted: "Cómo empezar",
  giveFeedback: "Contáctenos",
  goAheadAndShareThisLinkWithYourStudentsNow: "Continúe y comparta este enlace con los estudiantes",
  goAheadJoinMyClass: "Hola. Únete a mi clase usando este enlace",
  goBackToSkills: "Volver a las habilidades",
  goToLevelTest: "Ir a la prueba de nivel",
  goToSkill: "Ir a {{skill}}",
  go_back_btn: "Botón Volver",
  good: "Bien",
  goodJob: "¡Buen trabajo!",
  goodLuck: "¡Buena suerte!",
  gotAllStars: "y conseguiste todas las estrellas",
  gotThis: "¡Lo tengo!",
  grade: "Grado",
  grade_1: "1º",
  grade_10: "10º",
  grade_10th: "10º",
  grade_11: "11º",
  grade_11th: "11º",
  grade_12: "12º",
  grade_12th: "12º",
  grade_1st: "1º",
  grade_2: "2º",
  grade_2nd: "2º",
  grade_3: "3º",
  grade_3rd: "3º",
  grade_4: "4º",
  grade_4th: "4º",
  grade_5: "5º",
  grade_5th: "5º",
  grade_6: "6º",
  grade_6th: "6º",
  grade_7: "7º",
  grade_7th: "7º",
  grade_8: "8º",
  grade_8th: "8º",
  grade_9: "9º",
  grade_9th: "9º",
  grammar_tip: "Consejo de gramática",
  great: "¡Estupendo!",
  greatJob: "¡Gran trabajo!",
  he: "עברית",
  hello: "Hola ",
  helloWorld: "hello world2 spanish",
  hi: "Hola",
  hints: "Pistas",
  hints_options: {
    add_more_time: "Añadir tiempo al reloj",
    hear_the_english_word: "Escuchar la palabra en inglés",
    remove_a_letter: "Eliminar una letra",
    remove_one_answer: "Eliminar una respuesta",
    see_the_translation: "Ver la traducción",
    see_the_word: "Ver la palabra",
    show_a_letter: "Mostrar una letra"
  },
  homepage: "Página de inicio",
  howsYourEnglish: "¿Cómo va tu inglés?",
  iAgreeTo: "Estoy de acuerdo con los",
  id: "ID",
  illDoItLater: "Lo haré más tarde",
  improveYourRecentGames: "Mejora tus juegos recientes",
  improveYourRecentVocabularyQuizzes: "Mejora tus juegos de vocabulario recientes",
  inAllGames: "En todos los juegos",
  in_order_to_proceed_ask_teacher: "Para pasar al siguiente nivel, tu profesor debe permitirte hacerlo.",
  in_order_to_start: "Para comenzar tu viaje de aprendizaje, necesitamos",
  incorrect: "¡Incorrecto!",
  incorrect_answer: "incorrecto.",
  initialLevelSetTest: "Prueba de nivel inicial",
  initialLevelTest: "Prueba de nivel inicial",
  instructionalMaterial: "Material didáctico",
  interfaceLanguage: "Idioma de la interfaz",
  interface_language: "Idioma de la interfaz",
  intermediate: "Intermedio",
  invalidCode: "Código no válido",
  invalidEmail: "Correo electrónico no válido",
  invalidField: "Campo no válido",
  invalidFirstname: "Nombre no válido",
  invalidLastname: "Apellido no válido",
  invalidPassword: "Contraseña no válida.",
  invalidUsernameOrPassword: "Nombre de usuario o contraseña no válidos",
  invitationLink: "Enlace de invitación",
  invitationLinkWontChange: "No te preocupes, el enlace de invitación no cambiará",
  inviteYourStudents: "Invite a sus estudiantes",
  inviteYourTeachers: "Invite a sus profesores a crear sus clases.",
  invitedToJoin: "invitado a unirse a Britannica English.",
  invitedToJoinMorfix: "invited to join Morfixschool 2.0.",
  itLooksLikeYouReallyKnowTheMaterial: "Parece que realmente conoces el material y estás avanzando muy bien. Concéntrate en completar todas las habilidades y seguirás avanzando.",
  itsTime: "Es hora de elegir si estás preparado para el juego o no,",
  joinHere: "Únete aquí:",
  joinNow: "Únase ahora",
  keepGoing: "¡Sigue adelante!",
  keepPlaying: "¡Sigue jugando!",
  keepUpTheGoodWork: "Sigue con el buen trabajo",
  know: "Lo sé",
  know_your_level: "saber tu nivel de inglés.",
  language: "Idioma",
  language_of_study: "Idioma de estudio",
  languages: {
    ar: "Árabe",
    en: "Inglés",
    es: "Español",
    he: "Hebreo",
    pt: "Portugués"
  },
  lastname: "Apellido",
  leave_level_test_and_sign_out: "¿Dejar la prueba de nivel y cerrar sesión?",
  letsAssess: "Vamos a evaluar tu nivel actual de inglés con un juego corto para que podamos ofrecerte la mejor experiencia de aprendizaje.",
  letsBegin: "Comencemos",
  letsFinish: "Terminemos",
  letsGo: "Let's Go",
  letsGoFinalAssessment: "Vamos",
  letsKeepLearning: "¡sigamos aprendiendo!",
  letsSee: "Veamos qué tan bien conoces tu vocabulario.",
  letsSeeIfYouReady: "Veamos si estás listo/a para",
  level: "Nivel",
  levelAchievement: "Nivel de logro",
  levelPerSkill: "Nivel por habilidad",
  levelTest: "Prueba de nivel",
  levelTestIncomplete: "Prueba de nivel incompleta",
  linkCopied: "¡Enlace copiado!",
  listen_tip: "Consejo de escuchar",
  listeningTapToFinish: "Escuchando. Pulsa para terminar",
  loading_spinner: "Indicador de carga",
  loginFromDifferent: "Iniciar sesión desde otra cuenta",
  logout: "Cerrar sesión",
  looksLikeYouOnARoll: "Parece que estás en buena racha",
  melingoCalculateYourLevel: "Estamos calculando tu nivel...",
  menu_button: "Botón de menú",
  message: "Mensaje",
  monday: "lunes",
  mondayShort: "lun",
  moreTime: "más tiempo",
  moreTimes: "más veces",
  mustFirstCreateClass: "Para que sus estudiantes comiencen a utilizar la plataforma, primero debe crear una clase y luego invitarlos a unirse.",
  myProfile: "Mi perfil",
  myProgress: "Mi progreso",
  myRecentTranslations: "Mis traducciones recientes",
  needHelp: "¿Necesita ayuda?",
  new_password: "Nueva contraseña",
  new_password_verification: "Reingresar la nueva contraseña",
  next: "Siguiente",
  nextQuestion: "Siguiente pregunta",
  nextQuiz: "Siguiente juego",
  nextUpInYourLearningPath: "Próximo paso en tu ruta de aprendizaje",
  nextWord: "Siguiente palabra",
  next_button: "Botón Siguiente",
  noCoins: "Sin monedas",
  noDataToDisplay: "No hay datos para mostrar",
  noExtraCoins: "Sin monedas extra",
  noExtraCoinsThisTime: "así que esta vez no hay monedas extra",
  noResultsFor: "No hay resultados para {{word}}",
  none: "Ninguno",
  notBad: "¡Nada mal!",
  notMadeIt: "Parece que esta vez no lo has conseguido. Puedes",
  notMadeItInTime: "Parece que esta vez no lo has conseguido. Pasamos a la siguiente pregunta.",
  ofThisSite: "de este sitio.",
  ok: "OK",
  okLetsBegin: "OK, ¡comencemos!",
  okLetsGo: "OK, ¡vamos!",
  oneMoreMistake: "Un error más acabará el juego,",
  onlyNumbersAllowed: "Solo se permiten números",
  oops: "Oops",
  orClickHereToSendAgain: "O <0>haz clic aquí</0> para volver a enviar el código.",
  orClickHereToSendAgainTeacher: "O <0>haga clic aquí</0> para volver a enviar el código.",
  outOf: "de",
  overallProgress: "Progreso general",
  passRate: "Tasa de aprobación",
  password: "Contraseña",
  passwordMinimumLength: "Longitud mínima de 8 caracteres.",
  password_changed_successfully: "La contraseña se ha cambiado correctamente",
  passwordsDontMatch: "Las contraseñas no coinciden",
  passwords_must_match: "Las contraseñas deben coincidir",
  pause_game: "Pausar el juego",
  paussed: "en pausa",
  perGame: "Por juego",
  perLevel: "Por nivel",
  perSkill: "Por habilidad",
  phone: "Teléfono",
  phraseOfTheDay: "Frase del día",
  phrasesFor: "Frases para",
  pictureOfTheDay: "Imagen del día",
  play_sound: "Reproducir sonido",
  pleaseChooseYourOwnPassword: "Por favor, elige tu propia contraseña",
  pleaseCompleteTheFollowingTest: "Por favor, completa la siguiente prueba. Tómate tu tiempo, lee las instrucciones y hazla lo mejor que puedas.",
  pleaseContact: "Por favor, contacta directamente a {{studentName}} y dile que debe cerrar sesión y luego iniciarla de nuevo para ver los cambios.",
  pleaseContactYourSchoolManagerForGuidance: "Por favor, póngase en contacto con el administrador de su institución educativa para la orientación correspondiente.",
  pleaseEnter: "Por favor, ingrese",
  pleaseEnterTheFollowing: "Por favor, complete la siguiente información para comenzar su",
  pleaseEnterYourDetails: "Por favor, ingrese sus datos",
  pleaseSelectYourAccount: "Por favor, selecciona tu cuenta o inicia sesión desde otra",
  pleaseTakeAMoment: "Por favor, tómese un momento para registrar su escuela.",
  pleaseTryAgainLaterWeApologize: "Por favor, inténtalo de nuevo más tarde. Disculpa las molestias.",
  pleaseWriteOnlyLetters: "Por favor, escribe solo cartas",
  powerFulWords: "empower, enrich, and inspire us",
  practice: "Practica",
  practiceAgain: "Practica otra vez",
  practiceProperPronunciation: "Practica la pronunciación correcta.",
  practiceYourVocabulary: "Practica tu vocabulario",
  practice_makes_perfect: "¡La práctica hace al maestro!",
  preferences: "Preferencias",
  premiumSection: {
    description: "Aquí puede ver información importante o recursos del administrador de su escuela o de Britannica.",
    title: "Un mensaje para los profesores",
    unlock: "Leer más"
  },
  previous_button: "Botón Anterior",
  processing: "Procesando",
  proficiency: "Competencia",
  profile: "Perfil",
  progress: "Progreso",
  pt: "Portugués",
  qs: "Qs",
  question: "Pregunta",
  questions: "Preguntas",
  questionsAndConcerns: "sus preguntas y comentarios.",
  questionsLeft: "quedan más preguntas",
  quiz: "Juego",
  quizzes: "Juegos",
  quizzesCompleted: "Juegos completados",
  quizzesSection: {
    comingUpQuizzesTitle: "Próximos juegos",
    lastQuizzesSubTitle: "Mejorar de 1/2 estrella a 3 estrellas",
    lastQuizzesTitle: "Mejora tus últimos juegos"
  },
  ran_out_of_time: "Parece que se te acabó el tiempo otra vez. Esta pregunta se marcará como",
  readMore: "Leer más",
  readTheText: "Lee el texto y contesta las preguntas",
  read_tip: "Consejo de leer",
  readyToChooseYourAvatar: "¿Listo/a para elegir tu avatar?",
  receiveTipsAndUpdates: "Me gustaría recibir consejos sobre productos y actualizaciones de Britannica de vez en cuando",
  receiveTipsAndUpdatesMorfix: "I'd like to receive product tips and updates from Morfixschool 2.0 from time to time",
  recentLogin: "Inicio de sesión reciente",
  rememberThis: "¿Recuerdas esto?",
  remind_teacher_to_move_up: "Recuérdale a tu profesor que te avance al siguiente nivel.",
  requirements: "Requisitos",
  resendVerificationCode: "¿No lo ha recibido? Volver a enviar el código",
  resultForTheTranslation: "resultado de la traducción de",
  results: "Resultados",
  resultsForTheTranslation: "resultados para la traducción de",
  resume: "Reanudar",
  reward: "Recompensa",
  saturday: "sábado",
  saturdayShort: "sáb",
  save: "Guardar",
  saveChanges: "¿Guardar cambios?",
  schoolName: "Nombre de la escuela",
  score: "Puntuación",
  searchForAStudent: "Buscar un estudiante",
  searchForStudent: "Buscar por estudiante",
  selectSkill: "Seleccionar habilidad",
  selfPracticeProgress: "Progreso de la autopráctica",
  selfPracticeStreak: "Racha de la autopráctica",
  send: "Enviar",
  sendNow: "Enviar ahora",
  settings: {
    about_me: "Sobre mí",
    about_the_product: "Acerca del producto",
    add_students: {
      add_student: "Agregar nuevo estudiante",
      add_students: "Add new students",
      add_students_manually: "Agregar estudiantes manualmente",
      button_next: "Siguiente",
      click_to_browse: "haga clic para navegar",
      create_students: "Crear nuevos estudiantes",
      download: "Descargar",
      download_sample: "un archivo Excel (xlsx) de modelo.",
      drag_here: "Arrastre aquí los archivos o ",
      email_will_be_send: "Se enviará un correo electrónico",
      enroll_students: "Inscribir estudiantes",
      errors: {
        bad_formatting: "El archivo no tiene el formato correcto. <br/> Por favor, revise los datos y haga las correcciones necesarias.",
        email_in_use: "Esta dirección de correo electrónico ya está registrada",
        email_incorrect: "Por favor, escriba una dirección de correo electrónico válida",
        file_empty: "El archivo que estás intentando subir está vacío",
        file_too_large: "El archivo es demasiado grande. El tamaño máximo permitido es de 256KB.",
        first_name_incorrect: "El nombre contiene caracteres no válidos.",
        first_name_missing: "Es necesario ingresar un nombre.",
        last_name_incorrect: "El apellido contiene caracteres no válidos.",
        last_name_missing: "Es necesario ingresar un apellido.",
        students_not_registered: "Algunos estudiantes no se registraron.",
        wrong_format: "El formato del archivo es incorrecto. <br/> Asegúrese de subir un archivo Excel (xlsx)."
      },
      invite_students: "Inscribir estudiantes",
      preview: "Vista preliminar",
      success_description: "Your students have been sent an email with instructions on resetting their password. Once they've done that, they'll begin their English learning journey.",
      success_title: "The class has been created successfully!",
      upload_description: "Once the file has been uploaded successfully, your students will be registered and receive a temporary password in their email and can log in to Britannica English.",
      upload_file: "Suba el archivo Excel (xlsx) de sus estudiantes",
      upload_instruction: "Download and view the template for guidance.",
      upload_successful: "Su archivo se ha subido correctamente."
    },
    change_password: "Cambiar contraseña",
    "class": "Mi clase",
    current_level: "Nivel actual",
    grade: "Grado",
    home: "Inicio",
    language: "Idioma",
    logout: "Cerrar sesión",
    my_classes: "Mis clases",
    my_games: "Mis juegos",
    my_students: "Mis estudiantes",
    native_language: "Mi lengua materna",
    privacy_policy: "Política de privacidad",
    profile: "Perfil",
    school_id: "Mi escuela",
    security: "Seguridad",
    sound: "Sonido",
    title: "Ajustes",
    username: "Mi nombre"
  },
  showMore: "mostrar más",
  signIn: "Iniciar sesión",
  signYouIn: "Iniciando sesión…",
  sign_out: "Cerrar sesión",
  sincerely: "Atentamente, {{name}}",
  sixDigits: "6 dígitos",
  sixDigitsAtLeast: "El código debe tener al menos 6 dígitos",
  skills: {
    grammar: "Gramática",
    listen: "Escuchar",
    read: "Leer",
    speak: "Hablar",
    spelling: "Ortografía",
    vocabulary: "Vocabulario"
  },
  skillsTestTryAgain: "Puedes volver a intentarlo ahora o volver a los juegos anteriores de este nivel y practicar un poco más.",
  skip: "Saltar",
  sorryThisLinkIsNoLongerValid: "Lo sentimos, este enlace ya no es válido.",
  sortBy: "Ordenar por:",
  speakNow: "Habla ahora",
  speak_tip: "Consejo de hablar",
  spelling_tip: "Consejo de ortografía",
  stars: "Estrellas",
  start: "Comenzar",
  startLevelTest: "Prueba de nivel de inicio",
  startNextQuiz: "Comenzar el siguiente juego",
  startQuiz: "Iniciar el juego",
  startTheNextLevel: "Comience el siguiente nivel",
  startTheNextQuiz: "Comenzar el siguiente juego",
  startingReward: "Recompensa inicial",
  state: "Estado",
  studentActivity: "Actividad del estudiante",
  studentInstructions: "Instrucciones para los estudiantes",
  studentLevels: "Niveles de los estudiantes",
  studentName: "Nombre del estudiante",
  studentReport: "Informe del estudiante",
  students: "Estudiantes",
  studentsAreNotPermittedToAdvanceToTheNextLevel: "No se permite a los estudiantes avanzar al siguiente nivel. Si decide cambiar el nivel del estudiante en función de su desempeño, puede hacerlo entrando en el perfil del estudiante y cambiando manualmente su nivel para cada habilidad.",
  study_language: "Idioma de estudio",
  subject: "Asunto",
  submit: "Enviar",
  suggested_languages: "Idiomas sugeridos",
  sunday: "domingo",
  sundayShort: "dom",
  synonyms: "Sinónimos",
  tapToSpeak: "Pulsa para hablar",
  tapToTranslate: "Pulsa para traducir...",
  teacherEndLevelTest: "Usted y sus alumnos comenzarán a hablar y deletrear en el nivel principiante. Esto hará que el nivel general parezca ligeramente más bajo. Puedes ajustar manualmente el nivel de cada estudiante en cada habilidad accediendo a su perfil en tu panel, mientras que puedes acceder a todos los juegos en todos los niveles.",
  teacherInstructions: "Instrucciones para los docentes",
  teacherLetsBeginn: "¡Comencemos!",
  teacherName: "Nombre del profesor/a",
  teachers: "Profesores",
  teachersThatHaveRegistered: "Profesores que se han registrado",
  termsAndConditions: "Términos y condiciones",
  testYourEnglish: "Pon a prueba tu nivel de inglés",
  thankYou: "¡Gracias!",
  thatsTheWay: "¡Así se hace!",
  thats_the_way: "¡Así se hace!",
  theGamesIn: "los juegos de",
  theWordWasntPronouncedCorrectly: "La palabra o frase no se ha pronunciado correctamente.",
  theWordsWerentPronouncedCorrectly: "Las palabras no se pronunciaron correctamente.",
  thereAre: "Hay",
  thereIs: "Hay",
  thisStudentHasNot: "Este estudiante aún no ha completado la prueba de nivel. Una vez que hayan comenzado su viaje, verá su progreso aquí.",
  thisTestIsDesignedToAssessYourStudentsInTheirGeneralEnglishLevel: "Cuando los estudiantes hayan completado todas las actividades de las habilidades de su nivel, podrán recibir una prueba de fin de nivel que evaluará su competencia en todo lo que han aprendido. Programe esta prueba utilizando el indicador de fecha que aparece a continuación.",
  thisTestWillHelpYouSeeHowMuchEnglishYouHaveLearnedSoFar: "Esta prueba te ayudará a saber cuánto inglés has aprendido hasta ahora. Incluye gramática, vocabulario, lectura, comprensión auditiva (listening) y expresión oral (speaking). Después de la prueba, puedes seguir jugando y continuar aprendiendo.",
  thisWillHelpYouUnderstandTheMaterialBetter: "Esto te ayudará a comprender mejor el material",
  thursday: "jueves",
  thursdayShort: "jue",
  thwWordYouEntered: "La palabra que has ingresado no está en el diccionario. Intenta buscar otra palabra",
  timeIsUp: "¡Se acabó el tiempo!",
  tip: "¡Consejo!",
  tipsAndTricks: "Consejos y trucos",
  to: "Para",
  toImproveYourVocabulary: "Para mejorar tu vocabulario,",
  toJoin: "a unirte a",
  toNextQuestion: "a la siguiente pregunta",
  toViewItsOverallLearningProgress: "para ver su progreso general de aprendizaje",
  toViewTheirIndividualProgress: "para ver su progreso individual",
  toViewTheirOverallProgress: "para ver su progreso general",
  todayQuestion: "La pregunta de hoy",
  topics: "Temas",
  totalCoins: "Total de monedas",
  totalHintsUsed: "Total de pistas utilizadas",
  totalQuizzes: "Total de juegos",
  totalWords: "Total de palabras",
  trasnlateAWordOrPhrase: "Traduce una palabra o frase en inglés o en {{nativeLanguage}}...",
  tries: "intentos",
  tryAgain: "Intenta de nuevo",
  tryAgainBtn: "Intenta una vez más",
  tryAgainLater: "Vuelva a intentarlo más tarde.",
  tryAgainOneMore: "intentarlo una vez más o pasar",
  tryAgainOrKeepGoing: "Inténtalo de nuevo o continúa",
  tryBetter: "¡La práctica hace al maestro!",
  tuesday: "martes",
  tuesdayShort: "mar",
  understandingYourEnglishLevel: "Estamos empezando a entender tu nivel de inglés.",
  unfortunately: "Lamentablemente, no pasaste, por lo que no recibirás monedas por este juego.",
  university: "Universidad",
  update: "Actualización",
  uponRegistrationStudentsWillReceiveAnInitialLevelTest: "Cuando los estudiantes inicien sesión por primera vez, recibirán una prueba de nivel. Una vez completada, su ruta de aprendizaje individual se establecerá en función de su nivel de competencia en cada habilidad. Si la opción está desactivada, todos los estudiantes comenzarán en el Nivel 1.",
  useTheCoins: "Usa las monedas que has ganado para obtener pistas.",
  userStreak: {
    subTitle: "Practica cada día para convertirte en un MAESTRO",
    title: "¡Sigue adelante!"
  },
  username: "Nombre de usuario",
  usingYourMicrophone: "Utilizando el micrófono, habla despacio y con claridad y asegúrate de que no haya ruido de fondo.",
  validation: {
    code_required: "Se requiere código",
    confirm_password_required: "Es necesario confirmar la contraseña.",
    email_required: "Correo electrónico obligatorio",
    password_required: "Se requiere una contraseña",
    username_required: "El nombre de usuario es obligatorio"
  },
  validationCodeInvalid: "El código de validación no es válido",
  verificationCode: "Código de verificación",
  verificationCodeSent: "Se ha enviado un código de verificación a",
  viewAll: "Ver todo",
  viewAnswerChoices: "Ver opciones de respuesta",
  viewDetails: "Ver detalles",
  viewProfile: "Ver perfil",
  vocabularyMistakes: "Errores de vocabulario",
  vocabularyPractice: "Práctica de vocabulario",
  vocabulary_tip: "Consejo de vocabulario",
  weAreSorry: "Lo sentimos,",
  weCouldNotProduce: "No hemos podido elaborar el informe por el momento.",
  weRecommendThatYouGoBackToYourPreviousGames: "Te recomendamos que vuelvas a tus juegos anteriores y los hagas de nuevo.",
  weStillDontHaveAll: "Todavía no tenemos toda la información necesaria o hay un fallo temporal.",
  weWantYourFeedback: "Hola, {{firstname}}, ¡queremos tus comentarios!\n",
  we_want_to_know: "Queremos saber tu nivel",
  wednesday: "miércoles",
  wednesdayShort: "mié",
  welcome: "¡Le damos la bienvenida",
  welcomeBack: "Te damos la bienvenida de nuevo",
  welcomeConfetti: "¡Le damos la bienvenida",
  welcomeTo: "Le damos la bienvenida a",
  wellDone: "Bien hecho",
  whatDidYouLearnAtThisLevel: "¿Qué has aprendido en este nivel?",
  whenStudentsWillHaveCompletedAParticularSkill: "Cuando los estudiantes completen todas las actividades de habilidad en su nivel asignado, serán evaluados sobre lo que han aprendido. Una calificación de 80% o superior se considerará competente. Si el/la estudiante no supera la prueba, tendrá la oportunidad de volver a realizarla hasta aprobarla.",
  whenYourStudentsReceiveAPassingGrade: "Cuando los estudiantes reciben una calificación de aprobado en la Evaluación de habilidades de inglés, se les permitirá avanzar al siguiente nivel para esa habilidad. Si se desactiva esta opción, los estudiantes no podrán pasar al siguiente nivel.",
  with_a_score_of: "con una puntuación de",
  wordOfTheDay: "Palabra del día",
  words: "Aprende inglés",
  wordsDictionary: "Palabras",
  wordsList: "Lista de palabras",
  workHard: "Trabaja duro para conseguir todas las estrellas.",
  work_hard_to_get_all_stars: "Trabaja duro para conseguir todas las estrellas",
  wow: "WOW",
  wow_you_got_a_score_of: "WOW, tienes una puntuación de {{score}}",
  writeClassNameHere: "Escribe el nombre aquí",
  writeHere: "Escribe aquí",
  wrongAnswer: "¡Respuesta incorrecta!",
  youAlmostThere: "¡Ya casi está!",
  youAreOnYourWay: "¡Estás en camino!",
  youCanCreateMore: "Puede crear más de una clase",
  youCanPractice: "puedes practicar palabras mediante juegos o utilizando tarjetas y una lista.",
  youCanTryAssessment: "Ahora puede probar la evaluación de nivel {{level}} que sus estudiantes recibirán antes de que puedan avanzar al siguiente nivel. ",
  youCompletedTheTest: "Has completado la prueba",
  youDidNotPass: "No pasaste",
  youGot: "Tienes",
  youMayNowShare: "Ya puede compartir este enlace con sus ",
  youPassedAll: "Has pasado todo",
  youPassedAllTheGamesIn: "Has pasado todos los juegos en",
  youPassedTheQuiz: "Has superado el juego con una puntuación de",
  youPracticed: "Has practicado. Has estudiado",
  youStill: "Todavía no has pasado este juego, así que no hay monedas ni estrellas extra.",
  youWillLose: "Vas a perder",
  you_can_now_practice_level: "Ahora puedes practicar el Nivel {{level}}",
  you_did_it: "¡Lo lograste!",
  you_got_a_score_of: "Tienes una puntuación de {{score}}",
  you_passed_assessment: "Pasaste la evaluación",
  you_passed_quiz: "Pasaste el juego",
  your: "Sus",
  yourChangesWillAffectTheGeneralLevel: "Tus cambios afectarán al nivel general",
  yourClasses: "Sus clases",
  yourMessageHasBeenSentSuccessfully: "Tu mensaje se ha enviado correctamente. ¡Gracias por tomarte el tiempo para enviarnos tus valiosos comentarios!",
  yourMessageHere: "Tu mensaje aquí",
  yourPasswordHere: "Tu contraseña aquí",
  yourPhoneHere: "Tu teléfono aquí",
  yourSchoolHasBeenCreated: "Su escuela se ha creado correctamente",
  yourStartingLevel: "Tu nivel inicial",
  yourStreak: "Tu avance",
  yourStudentQuiz: "Este es la prueba que sus estudiantes realizarán cuando ingresen a la aplicación por primera vez. Pruébela ahora o puede intentarlo más tarde.",
  yourStudentsHaveNotJoined: "Sus estudiantes aún no se han unido a la clase",
  yourStudentsHaveNotJoinedYet: "Sus estudiantes aún no se han unido a la clase",
  yourStudentsInThisClassWillNotTakeTheInitialLevelTest: "Sus estudiantes en esta clase no realizarán la prueba de nivel inicial al registrarse. Todos empezarán en el Nivel 1 para todas las habilidades de inglés.",
  yourStudentsWillNotReceiveAFinalExam: "Sus estudiantes no recibirán un examen final.",
  yourSubjectHere: "Tu asunto aquí"
};
