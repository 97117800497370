import config from '@/config';
import {
  TopicsResponse,
  QuizzesResponse,
  QuizProgressResponse,
  LevelTestQuestionsResponse,
  NewLevelTestQuestionsResponse,
} from '@/types/response';
import { SkillService } from '@/types/services';
import axiosInstance from '@/utils/api';
import { SkillTypes } from '@/types/skill';
import UserUtils from '@/utils/user';
import { UserState } from '@/types/user';

export const skillService: SkillService = {
  fetchTopics: async (skill: SkillTypes, user: UserState | null) => {
    const resp: TopicsResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/${skill.toLowerCase()}/${UserUtils.levelByLanguage(user, skill)}`
    );

    return resp;
  },
  fetchQuizzes: async (skill: SkillTypes, user: UserState, topicId: number) => {
    const resp: QuizzesResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/${skill.toLowerCase()}/${UserUtils.levelByLanguage(
        user,
        skill
      )}/${topicId}`
    );

    return resp;
  },
  fetchQuestions: async (
    skill: SkillTypes,
    user: UserState,
    topicId: number,
    quizId: number
  ) => {
    const resp: QuizProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/${skill.toLowerCase()}/${UserUtils.levelByLanguage(
        user,
        skill
      )}/${topicId}/${quizId}`
    );
    return resp;
  },
  fetchTestQuestions: async (user) => {
    const resp: LevelTestQuestionsResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/level-test-questions`
    );
    return resp;
  },
  fetchNewLevelTestQuestions: async (user) => {
    const resp = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/level-test/questions/${user?.id}`
    );
    return resp.data;
  },
  fetchEndOfSkillTest: async (user, skill, skillLevel) => {
    const resp = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/end-of-level-in-skill/${skill}/${skillLevel}`
    );

    // return {
    //   questions: [
    //     {
    //       questionId: 10200,
    //       question: 'There ___ no school today.',
    //       answer: 'is',
    //       distractors: 'are, am',
    //       order: 1,
    //       tip: null,
    //       tipLocal: null,
    //       coins: 0,
    //     },
    //     {
    //       questionId: 10201,
    //       question: 'There ___ rabbits in my garden.',
    //       answer: 'are',
    //       distractors: 'is, am',
    //       order: 2,
    //       tip: null,
    //       tipLocal: null,
    //       coins: 0,
    //     },
    //     {
    //       questionId: 10202,
    //       question: '___ like coffee?',
    //       answer: 'Do you',
    //       distractors: 'Does you, Are you',
    //       order: 3,
    //       tip: null,
    //       tipLocal: null,
    //       coins: 0,
    //     },
    //     {
    //       questionId: 10203,
    //       question: '___ there a bank near here?',
    //       answer: 'Is',
    //       distractors: 'Am, are',
    //       order: 4,
    //       tip: null,
    //       tipLocal: null,
    //       coins: 0,
    //     },
    //     {
    //       questionId: 10204,
    //       question: 'Whose bags ___ these?',
    //       answer: 'are',
    //       distractors: 'am, is',
    //       order: 5,
    //       tip: null,
    //       tipLocal: null,
    //       coins: 0,
    //     },
    //   ],
    //   quiz: {
    //     id: 1100,
    //     gameType: 1,
    //     nameLocal: 'End level test',
    //     name: 'end level test',
    //     description: '',
    //     descriptionLocal: '',
    //     order: 1,
    //     instructionsLocal: 'Escolha a melhor resposta.',
    //     instructions:
    //       'Read the sentences/ questions and choose the correct option for the blanks. Then, check your answers.',
    //     urlName: 'end_level_test',
    //     isFree: false,
    //     numberOfQuestions: 25,
    //     lastUpdate: '',
    //     created: '23/01/2024 15:02:26.873',
    //     isLocked: false,
    //     timer: 0,
    //     cardsLogic: null,
    //   },
    // };

    return resp.data.data;
  },
  postEndOfSkillTest: async (user, skill, skillLevel, result) => {
    const resp = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/end-of-level-in-skill/${skill}/${skillLevel}/${user?.id}`,
      result
    );

    return resp.data.data;
  },
  userSkipLevelTest: async (user) => {
    await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/level-test/user-skip-level-test/${user?.id}`
    );
  },
  postUserLevelTestResult: async (user, result) => {
    const resp = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/level-test/user-result/${user?.id}`,
      { levelTestBySkillUserResult: result }
    );
    return resp.data.data;
  },
  fetchFinalAssessment: async (user) => {
    const resp = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/final-assessment/questions/${user?.level}`
    );

    return resp.data.data;
  },
  postFinalAssessment: async (user, result) => {
    const resp = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/final-assessment/${user?.level}/${user?.id}`,
      result
    );

    return resp.data.data;
  },
};
