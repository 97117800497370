import { useTranslation } from 'react-i18next';
import classNames from './TutorEventCard.module.scss';
import TutorPencil from '@/assets/svg/tutor/pencilIcon.svg';
import Star from '@/assets/svg/tutor/FullStar.svg';
import StarEmpty from '@/assets/svg/tutor/EmptyStar.svg';
import Arrow from '@/assets/svg/tutor/arrow.svg';
import { Card } from '@/types/tutor';
import { useState } from 'react';
import classes from 'classnames';
import UseScreenSize from '@/hooks/UseScreenSize';

import useTutor from '@/hooks/useTutor';

interface TutorEventCard {
  data: Card;
}

const TutorEventCard = ({ data }: TutorEventCard) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = UseScreenSize();
  const { isCardLoading, handleCardClick } = useTutor();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return  (
    <div
      className={classes(classNames.eventCard, {
        [classNames.isMobile]: isMobile,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleCardClick(data)}
    >
      {isHovered && isDesktop && (
        <div className={classNames.blackOverlay}>
          <div className={classNames.textOverlay}>
            <p> {t('tutorLetsStart')}</p>
            <img src={Arrow} alt="arrow" />
          </div>
        </div>
      )}

      <div
        className={classes(classNames.header, {
          [classNames.isMobile]: isMobile,
        })}
      >
        <img
          className={classes(classNames.icon, {
            [classNames.iconMobile]: isMobile,
            [classNames.iconDesktop]: isDesktop,
          })}
          src={TutorPencil}
          alt="pencil"
        />
        {!data?.is_new && (
          <div className={classNames.newTag}>
            <p>{t('tutorNewtag')}</p>
          </div>
        )}
      </div>
      <div
        className={classes(classNames.body, {
          [classNames.isMobile]: isMobile,
        })}
      >
        <div
          className={classes(classNames.skill, {
            [classNames.isMobile]: isMobile,
          })}
        >
          <span>{data?.skill}</span>
          <p className={classNames.pipe}>|</p>
          <p>{data?.topic_name}</p>
        </div>
        <div
          className={classes(classNames.title, {
            [classNames.isMobile]: isMobile,
          })}
        >
          <p>{data?.quiz_name}</p>
        </div>
        <div
          className={classes(classNames.text, {
            [classNames.isMobile]: isMobile,
          })}
        >
          <p>{data?.description}</p>
        </div>
      </div>
      {data?.timestamp_last_time_played && (
        <div
          className={classes(classNames.footer, {
            [classNames.isMobile]: isMobile,
          })}
        >
          {/* <div
            className={classes(classNames.timeStamp, {
              [classNames.isMobile]: isMobile,
            })}
          >
            <p>{timeAgo(data?.timestamp_last_time_played)}</p>
          </div> */}
          {renderStarRating(data?.star_rating)}
        </div>
      )}
    </div>
  );
};

export default TutorEventCard;

/**
 * Converts a timestamp string to a human-readable relative time format.
 *
 * @param {string} [timestamp] - The timestamp string in the format "yyyy-mm-dd hh:mm".
 * @returns {string} A string representing how much time has passed since the given timestamp.
 */
function timeAgo(timestamp?: string): string {
  // Check for undefined or null timestamp
  if (!timestamp) {
    return 'Invalid Timestamp';
  }

  // Ensure the timestamp is trimmed to remove any leading/trailing whitespace
  const trimmedTimestamp = timestamp.trim();

  // Split the timestamp into date and time parts
  const [datePart, timePart] = trimmedTimestamp.split(' ');
  const [day, month, year] = datePart.split('-');
  const [hour, minute] = timePart.split(':');

  // Pad the day and month with a leading zero if they are single digit
  const paddedDay = day.padStart(2, '0');
  const paddedMonth = month.padStart(2, '0');

  // Construct a Date object from the timestamp components
  const quizDate = new Date(
    `${year}-${paddedMonth}-${paddedDay}T${hour}:${minute}:00`
  );

  // Calculate the time difference in milliseconds
  const timeDifference = new Date().getTime() - quizDate.getTime();

  // Convert the time difference to days
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Determine the relative time string based on the days difference
  if (daysAgo === 0) {
    return 'Today';
  } else if (daysAgo === 1) {
    return '1 day ago';
  } else {
    return `${daysAgo} days ago`;
  }
}

function renderStarRating(rating: string) {
  const { isMobile, isDesktop } = UseScreenSize();

  // Split the rating string into numerator and denominator
  const [numerator, denominator = '3'] = rating.split('/');

  // Convert the numerator and denominator to numbers
  const numeratorValue = parseInt(numerator, 10);
  const denominatorValue = parseInt(denominator, 10);

  // Calculate the number of filled and empty stars
  const filledStars = Array(numeratorValue).fill(null);
  const emptyStars = Array(denominatorValue - numeratorValue).fill(null);

  // Render the stars
  return (
    <div
      className={classes(classNames.rating, {
        [classNames.isMobile]: isMobile,
      })}
    >
      {filledStars.map((_, index) => (
        <img key={index} className={classNames.star} src={Star} alt="star" />
      ))}
      {emptyStars.map((_, index) => (
        <img
          key={index}
          className={classNames.emptyStar}
          src={StarEmpty}
          alt="empty star"
        />
      ))}
    </div>
  );
}
