import React from 'react';
import SkillsStatistics from './SkillsStatistics/SkillsStatistics';
import StudentsCards from './StudentsCards/StudentsCards';
import { Transition } from 'react-transition-group';
import AppBackdrop from '../AppBackdrop';
import AppModal from '../AppModal';
import SVG from 'react-inlinesvg';
import UserData from './UserData/UserData';
import { LocalLanguage, TeacherGroup, UserState } from '@/types/user';
import {
  StudentProgressBySkill,
  StudentsSkillsProgress,
} from '@/types/progress';
import { SkillTypes } from '@/types/skill';
import classNames from './StudentsData.module.scss';
import classes from 'classnames';
import UseScreenSize from '@/hooks/UseScreenSize';
import close from '@/assets/svg/close.svg';
import { useTranslation } from 'react-i18next';

interface StudentsContentProps {
  students: UserState[];
  isLoadingProgress?: boolean;
  statistics: StudentsSkillsProgress | null;
  selectedStudent: UserState | null;
  showModal: boolean;
  searchValue: string;
  showSkillData?: boolean;
  showAssessments: boolean;
  selectedSkill?: {
    value: SkillTypes;
    progress: StudentProgressBySkill;
  } | null;
  showEdit?: boolean;
  interfaceLanguage: LocalLanguage | undefined;
  currentClassroom: TeacherGroup;
  onShowSkillData?: (skill: SkillTypes) => void;
  onHideSkillData?: () => void;
  onShowAssessments: () => void;
  onHideAssessment: () => void;
  onCloseEdit?: () => void;
  onShowEdit?: () => void;
  onSaveEdit?: (
    level: string,
    language: string,
    selectedLevelsBySkills: StudentsSkillsProgress | null
  ) => void;
  setSearchValue: (value: string) => void;
  onShowDataModal: (studentId: string) => void;
  onHideDataModal: () => void;
}

const StudentsContent = ({
  statistics,
  students,
  searchValue,
  showModal,
  interfaceLanguage,
  selectedStudent,
  showEdit,
  showAssessments,
  isLoadingProgress,
  showSkillData,
  selectedSkill,
  currentClassroom,
  setSearchValue,
  onShowDataModal,
  onHideDataModal,
  onHideSkillData,
  onShowSkillData,
  onShowAssessments,
  onHideAssessment,
  onShowEdit,
  onCloseEdit,
  onSaveEdit,
}: StudentsContentProps) => {
  const { isMobile } = UseScreenSize();
  const { t } = useTranslation();
  return (
    <>
      {/* <SkillsStatistics statistics={statistics} /> */}
      <StudentsCards
        students={students}
        searchValue={searchValue}
        currentClassroom={currentClassroom}
        emitShowDataModal={onShowDataModal}
        setSearchValue={setSearchValue}
      />
      <Transition
        in={showModal}
        timeout={400}
        mountOnEnter
        unmountOnExit
        children={(state) => (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
              emitClose={onHideDataModal}
            />
            <AppModal>
              {isMobile ? (
                <div
                  className={classes(classNames.viewBlocker, `fade-${state}`)}
                >
                  <button
                    className={classNames.close}
                    onClick={onHideDataModal}
                  >
                    <SVG src={close} />
                  </button>
                  <button className={classNames.text} onClick={onHideDataModal}>
                    {t('fullVersionAvilableOnlyOnDesktop')}
                  </button>
                </div>
              ) : (
                <div
                  className={classes(
                    classNames.modal,
                    `slide-horizontal-${interfaceLanguage?.direction}-${state}`
                  )}
                >
                  <button
                    className={classNames.close}
                    onClick={onHideDataModal}
                  >
                    <SVG src={close} />
                  </button>
                  {selectedStudent && (
                    <UserData
                      {...selectedStudent}
                      showEdit={showEdit}
                      isLoadingProgress={isLoadingProgress}
                      selectedStudent={selectedStudent}
                      showSkillData={showSkillData}
                      selectedSkill={selectedSkill}
                      showAssessments={showAssessments}
                      emitShowSkillData={onShowSkillData}
                      emitHideSkillData={onHideSkillData}
                      emitShowAssessments={onShowAssessments}
                      emitHideAssessment={onHideAssessment}
                      emitShowEdit={onShowEdit}
                      emitCloseEdit={onCloseEdit}
                      emitSaveEdit={onSaveEdit}
                    />
                  )}
                </div>
              )}
            </AppModal>
          </>
        )}
      />
    </>
  );
};

export default StudentsContent;
