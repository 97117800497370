import React, { useCallback, useEffect, useState } from 'react';
import classNames from './Practice.module.scss';
import { PracticeProps } from '@/types/game';
import { Question } from '@/types/question';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useTranslation } from 'react-i18next';

import PracticeCard from './PracticeCard/PracticeCard';
import WordKnowledgeControls from '@/components/QuizPage/WordKnowledgeControls/WordKnowledgeControls';
import TheButton from '@/components/TheButton/TheButton';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';

const Practice = ({
  currentQuestion,
  practiceCards,
  emitOnSwipe,
  emitOnNextQuestion,
  emitOnAknowledge,
}: PracticeProps) => {
  const { t } = useTranslation();
  const { isDesktop } = UseScreenSize();
  const [slicedPracticeCards, setSlicedPracticeCards] = useState<Question[]>(
    []
  );

  useEffect(() => {
    document.body.style.overflowX = 'hidden';

    return () => {
      document.body.style.overflowX = '';
    };
  }, []);

  useEffect(() => {
    if (practiceCards) {
      setSlicedPracticeCards(() => practiceCards.slice(0, 3));
    }
  }, [practiceCards]);

  const onSwipe = useCallback(async (questionId: number) => {
    emitOnSwipe?.(questionId);
    emitOnNextQuestion?.();
  }, []);

  const onNextWord = () => {
    emitOnSwipe?.(currentQuestion.questionId);
    emitOnNextQuestion?.();
  };

  const practiceCardsElements = slicedPracticeCards
    .slice(0, 3)
    .map((practiceCard, index) => (
      <PracticeCard
        key={practiceCard.questionId}
        question={practiceCard}
        index={index}
        emitOnSwipe={onSwipe}
      />
    ));

  return (
    <div className={classNames.practice}>
      {isDesktop && (
        <div className="flex items-cetner justify-between">
          <h2>{t('practiceYourVocabulary')}</h2>
          <div className="flex flex-1 justify-between items-center gap-8">
            <WordKnowledgeControls
              question={currentQuestion}
              emitOnAknowledge={emitOnAknowledge}
            />
            <DetailSeperator className={classNames.seperator} />
            <TheButton
              className={classNames.button}
              text={t('nextWord')}
              emitOnClick={onNextWord}
              showArrow={true}
            />
          </div>
        </div>
      )}
      <div className={classNames.practiceCards}>{practiceCardsElements}</div>
    </div>
  );
};

export default React.memo(Practice);
