import { useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { GameStatus, GameTypes } from '@/types/game';

// This hook is responsible for handling the timer tick of our current game state
// It will start the timeout once the game status is PLAYING and the current timer ref is NULL
// Every second the timer will dispatch an action to decrement the current time left in the store
const UseQuizTimer = () => {
  const {
    gameStatus,
    gameType,
    isLevelTest,
    selectedTopic,
    isInEndOfSkillTest,
    isFinalAssessment,
  } = useAppSelector(gameSelector);
  const dispatch = useAppDispatch();
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    // Check if the game status now is PLAYING and the current timer ref is NULL

    if (
      gameStatus === GameStatus.PLAYING &&
      !isInEndOfSkillTest &&
      !isLevelTest &&
      !isFinalAssessment &&
      !timeoutRef.current
    ) {
      timeoutRef.current = window.setInterval(() => {
        // Dispatch an action to decrement the current time left in the store
        dispatch(gameActions.updateGameTime());
      }, 1000);
    } else if (gameStatus === GameStatus.ENDED && timeoutRef.current) {
      window.clearInterval(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [gameStatus, gameType]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        window.clearInterval(timeoutRef.current);
      }
    };
  }, []);

  return { timeoutRef };
};

export default UseQuizTimer;
