import React from 'react';
import classNames from './MyClassesView.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import UseMyClasses from './UseMyClasses';

import TheButton from '@/components/TheButton/TheButton';
import StudentsTable from '@/components/StudentsTable/StudentsTable';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import GradesFilter from '@/components/GradesFilter/GradesFilter';
import YourClasses from '@/components/YourClasses/YourClasses';
import ClassEditor from '@/components/ClassEditor/ClassEditor';
import NoClasses from '@/components/NoClasses/NoClasses';
import { Transition } from 'react-transition-group';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import CsvDropzone from '@/components/CsvDropzone/CsvDropzone';

const MyClassesView = () => {
  const { t } = useTranslation();

  const {
    selectedFilter,
    selectedFilterText,
    classConfig,
    allClassrooms,
    filterdClassrooms,
    onFilter,
    createOrSaveClass,
    onCreateClass,
    onEditClass,
    loadingGroupDetails,
    isSavingGroup,
    showClassCreatedModal,
    onCloseClassCreatedModal,
    showCopyModal,
    setShowCopyModal,
    onCloseCopyModal,
    onCloseCancelModal,
    onShowCopyModal,
    showCancelModal,
    setShowCancelModal,
    onShowCancelModal,
    onContinue,
    user,
    studentsData,
    setStudentsData,
    onCsvStudentsCreate,
    studentsDataResponse,
    isCsvLoading,
    isCsvSending,
    setIsCsvLoading,
    csvFilesAdded,
    setCsvFilesAdded,
    showUploadCsvModal,
    showStudentsTableModal,
    onCloseUploadCsvModal,
    onShowStudentsTableModal,
    onCloseStudentsTableModal,
    onBackStudentsTableModal,
    disableSubmitButton, 
    setDisableSubmitButton,
    showNewStudentInput,
    setShowNewStudentInput,
    classStudentsDataResponse
  } = UseMyClasses();

  const classUploadCsvModal = (
    <Transition
      in={showUploadCsvModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
            />
            <AppModal>
              <div
                className={classes(
                  classNames.modal,
                  classNames.fileUploadModal,
                  `fade-${state}`
                )}
              >
                <h2>{t('settings.add_students.enroll_students')}</h2>
                <span className={classNames.description}>
                  {t('settings.add_students.upload_file')}
                </span>
                <div className={classNames.descriptionWrapper}>
                  {(!csvFilesAdded && !isCsvLoading) ? (
                    <>
                      <span className={classNames.description}>
                        {t('settings.add_students.upload_instruction')}
                      </span>
                      <span className={classNames.sample}>
                        <a href="/csv/sample.xlsx" target="_blank" className={classNames.downloadLink}>{t('settings.add_students.download')}</a> {t('settings.add_students.download_sample')}
                      </span>
                    </>
                  ) : (
                    <span className={classNames.description}>
                      {t('settings.add_students.upload_description')}
                    </span>
                  )}
                </div>
                <CsvDropzone
                  studentsData={studentsData}
                  setStudentsData={setStudentsData}
                  isCsvLoading={isCsvLoading}
                  setIsCsvLoading={setIsCsvLoading}
                  setCsvFilesAdded={setCsvFilesAdded}
                />
                <div className={`${classNames.buttons} ${classNames.buttonsUpload}`}>
                  <TheButton
                    className={classNames.addManuallyBtn}
                    text={t('settings.add_students.add_students_manually')}
                    plain
                    emitOnClick={onCloseUploadCsvModal}
                  />
                  <TheButton
                    className={classNames.sendBtn}
                    text={t('settings.add_students.button_next')}
                    type="submit"
                    disabled={!studentsData.length || isCsvLoading}
                    shouldRecolor={false}
                    emitOnClick={onShowStudentsTableModal}
                  />
                </div>
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const classStudentsTableModal = (
    <Transition
      in={showStudentsTableModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
            />
            <AppModal>
              <div
                className={classes(
                  classNames.modal,
                  classNames.studentsDataModal,
                  classNames.fileUploadModal,
                  `fade-${state}`
                )}
              >
                <h2>{t('settings.add_students.preview')}</h2>
                <span className={classNames.description}>
                  {t('settings.add_students.create_students')}
                </span>
                <StudentsTable
                  studentsData={studentsData}
                  setStudentsData={setStudentsData}
                  studentsDataResponse={studentsDataResponse}
                  allowActions={true}
                  allowNewRows={true}
                  disableSubmitButton={disableSubmitButton}
                  setDisableSubmitButton={setDisableSubmitButton}
                  showNewStudentInput={showNewStudentInput}
                  setShowNewStudentInput={setShowNewStudentInput}
                  isModal={true}
                />
                {isCsvSending ? (
                  <div className={classNames.loader}>
                    <AppLoaderCircle />
                  </div>
                ) : studentsData.length > 0 ? (
                  <div className={classNames.buttons}>
                    <TheButton
                      className={classNames.cancelBtn}
                      text={t('back')}
                      plain
                      emitOnClick={onBackStudentsTableModal}
                    />
                    <TheButton
                      className={classNames.sendBtn}
                      text={t('settings.add_students.enroll_students')}
                      type="submit"
                      shouldRecolor={false}
                      disabled={!studentsData.length || disableSubmitButton || showNewStudentInput}
                      emitOnClick={onCsvStudentsCreate}
                    />
                  </div>
                ) : studentsDataResponse ? (
                  <div>
                    <TheButton
                      className={`${classNames.sendBtn} ${classNames.button}`}
                      text={t('done')}
                      type="submit"
                      shouldRecolor={false}
                      emitOnClick={onCloseStudentsTableModal}
                    />
                  </div>
                ) : null
                }
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );




  const classCreatedModal = (
    <Transition
      in={showClassCreatedModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
            />
            <AppModal>
              <div
                className={classes(
                  classNames.modal,
                  classNames.copyModal,
                  `fade-${state}`
                )}
              >
                <h2>{t('classCreated')}</h2>
                <span className={classNames.description}>
                  {t('classCreatedDescription')}
                </span>
                <TheButton
                  className={classNames.btn}
                  text={t('done')}
                  shouldRecolor={false}
                  emitOnClick={onCloseClassCreatedModal}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const cancelModal = (
    <Transition
      in={showCancelModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
            />
            <AppModal>
              <div
                className={classes(
                  classNames.modal,
                  classNames.copyModal,
                  `fade-${state}`
                )}
              >
                <h2>{t('areYouSure')}</h2>
                <span className={classNames.description}>
                  {t('mustFirstCreateClass')}
                </span>
                <TheButton
                  className={classNames.btn}
                  text={t('continueCreating')}
                  shouldRecolor={false}
                  emitOnClick={onContinue}
                />
                <TheButton
                  className={classNames.cancelBtn}
                  text={t('cancelAnyway')}
                  shouldRecolor={false}
                  emitOnClick={onCloseCancelModal}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const linkCopiedModal = (
    <Transition
      in={showCopyModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
            />
            <AppModal>
              <div
                className={classes(
                  classNames.modal,
                  classNames.copyModal,
                  `fade-${state}`
                )}
              >
                <h2>{t('linkCopied')}</h2>
                <span className={classNames.description}>
                  {t('goAheadAndShareThisLinkWithYourStudentsNow')}
                </span>
                <TheButton
                  className={classNames.btn}
                  text={t('done')}
                  shouldRecolor={false}
                  emitOnClick={onCloseCopyModal}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  return (
    <div
      className={classes(classNames.myClassesView, {
        [classNames.noClasses]: !user?.teacherGroups?.length,
      })}
    >
      {classUploadCsvModal}
      {classStudentsTableModal}
      {classCreatedModal}
      {cancelModal}
      {linkCopiedModal}
      <div id="my_classes_header" className={classNames.header}>
        <h2>{t('settings.my_classes')}</h2>
        <GradesFilter
          selectedFilter={selectedFilter}
          selectedFilterText={selectedFilterText}
          emitFilter={onFilter}
        />
      </div>
      <div className={classNames.body}>
        <YourClasses
          className={classNames.yourClasses}
          classConfig={classConfig}
          filterdClassrooms={filterdClassrooms}
          selectedFilter={selectedFilter}
          onCreateClass={onCreateClass}
          onEditClass={onEditClass}
        />
        {classConfig && !loadingGroupDetails ? (
          <ClassEditor
            className={classNames.classEditor}
            classConfig={classConfig}
            createOrSaveClass={createOrSaveClass}
            loading={isSavingGroup}
            showCopyModal={showCopyModal}
            onShowCopyModal={onShowCopyModal}
            setShowCopyModal={setShowCopyModal}
            showCancelModal={showCancelModal}
            onShowCancelModal={onShowCancelModal}
            setShowCancelModal={setShowCancelModal}
            classStudentsDataResponse={classStudentsDataResponse}
          />
        ) : (
          <NoClasses
            className={classNames.noClasses}
            emitCreateClass={onCreateClass}
            loading={loadingGroupDetails}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(MyClassesView);
