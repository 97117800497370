import { useMemo } from 'react';
import classNames from './SkillData.module.scss';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { UserState } from '@/types/user';
import { StudentProgressBySkill } from '@/types/progress';
import { SkillTypes } from '@/types/skill';

import TopicAccordion from './TopicAccordion/TopicAccordion';

import trophy from '@/assets/svg/trophy.svg';
import medal from '@/assets/svg/medal-gold.svg';
import star from '@/assets/svg/star.svg';
import VocabularyMistakes from './VocabularyMistakes/VocabularyMistakes';

interface SkillDataProps {
  selectedStudent?: UserState | null;
  selectedSkill: { value: SkillTypes; progress: StudentProgressBySkill } | null;
}

const SkillData = ({ selectedSkill, selectedStudent }: SkillDataProps) => {
  const { t } = useTranslation();

  const skill = useMemo(
    () =>
      selectedStudent && selectedSkill
        ? Object.entries(selectedStudent.skillsOverview ?? {}).find(
            (entry) => entry[0] === selectedSkill.value
          )
        : null,
    [selectedStudent, selectedSkill]
  );

  const accordionItems = selectedSkill
    ? selectedSkill.progress
        .sort((curr, next) => curr.topicOrder - next.topicOrder)
        .map((topic, index) => {
          const { topicName, completedQuizzes, totalQuizzes, quizzesList } =
            topic;
          return (
            <TopicAccordion
              key={index}
              name={topicName}
              actual={completedQuizzes}
              total={totalQuizzes}
              quizzes={quizzesList}
            />
          );
        })
    : null;

  return (
    <div className={classNames.skillData}>
      <div className={classNames.details}>
        <div className={classNames.box}>
          <SVG src={trophy} />
          <span>{`${skill?.[1].completedTopics}/${skill?.[1].totalTopics} ${t(
            'topics'
          )}`}</span>
        </div>
        <div className={classNames.box}>
          <SVG src={medal} />
          <span>{`${skill?.[1].completedQuizzes}/${skill?.[1].totalQuizzes} ${t(
            'quizzes'
          )}`}</span>
        </div>
        <div className={classNames.box}>
          <SVG src={star} />
          <span>{`${skill?.[1].achievedStars}/${skill?.[1].totalStars} ${t(
            'stars'
          )}`}</span>
        </div>
      </div>
      <div className={classNames.accordion}>
        {/** need to remove the false */}
        {false && selectedSkill?.value === 'vocabulary' && (
          <VocabularyMistakes />
        )}
        {accordionItems}
      </div>
    </div>
  );
};

export default SkillData;
