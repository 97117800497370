import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';

import styles from './TutorMessage.module.scss';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { useAppDispatch, useAppSelector } from '@/store';
import { tutorActions, tutorSelector } from '@/store/reducers/tutor';
import { GameTypes } from '@/types/game';
import QuizQuestion from '@/components/QuizPage/QuizQuestion/QuizQuestion';
import { Question } from '@/types/question';
import CorrectBanner from '@/assets/svg/tutor/chat/correct_answer.svg';
import InCorrectBanner from '@/assets/svg/tutor/chat/incorrect_answer.svg';
import CorrectBannerMobile from '@/assets/svg/tutor/chat/correct_answer_mobile.svg';
import InCorrectBannerMobile from '@/assets/svg/tutor/chat/incorrect_answer_mobile.svg';

import { gameSelector } from '@/store/reducers/game';
import UseScreenSize from '@/hooks/UseScreenSize';
import {
  TutorSender,
  TutorMessage as TutorMessageTypes,
  MessageTypes,
} from '@/types/tutor';
import useTutor from '@/hooks/useTutor';

// Component's props definition
export interface TutorMessage {
  messageIndex: number;
  payload: TutorMessageTypes;
  sender: TutorSender;
}

const TutorMessage: React.FC<TutorMessage> = ({
  messageIndex,
  payload,
  sender,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { selectedTopic } = useAppSelector(gameSelector);

  const { exerciseHelp, setTutorExerciseFeedback } = useTutor();

  const { showTutor, dashBoard } = useAppSelector(tutorSelector);

  const {
    title,
    text,
    loading,
    hasSeen,
    exercise,
    answeredCorrectly,
    pickedAnswer,
    type,
  } = payload;

  // Determining if the text is from user or tutor
  const isUser = sender === TutorSender.user;
  const isTutor = sender === TutorSender.tutor;

  const [displayedMessage, setDisplayedMessage] = useState<string | null>('');

  // Function to type out each character in the text
  const typeCharacter = useCallback(
    (index: number, trimmedMessage: string) => {
      // Check if the current index is less than the length of the message
      if (index < trimmedMessage.length) {
        // Get the current character from the message based on the index
        let nextChar = trimmedMessage.charAt(index);
        // Set the next index to the current index + 1
        let nextIndex = index + 1;

        // Check if the current character is the start of an HTML tag
        if (nextChar === '<') {
          // Find the end index of the HTML tag (where '>' is found)
          const tagEndIndex = trimmedMessage.indexOf('>', index);
          if (tagEndIndex !== -1) {
            // Extract the entire HTML tag from the message
            nextChar = trimmedMessage.slice(index, tagEndIndex + 1);
            // Update the next index to be the position after the end of the tag
            nextIndex = tagEndIndex + 1;
          }
        }

        // Update the displayed message by adding the next character (or entire HTML tag)
        setDisplayedMessage((prev) => prev + nextChar);
        // Set a timeout to recursively call the function, creating a typing effect
        setTimeout(() => typeCharacter(nextIndex, trimmedMessage), 20);
      } else {
        // Once the entire message is displayed, call the dispatch function
        dispatch(tutorActions.updateHasSeen(messageIndex));
      }
    },
    [tutorActions.updateHasSeen, messageIndex]
  );

  // Effect for handling text typing animation
  useEffect(() => {
    if (text === undefined) {
      setDisplayedMessage('');
      return;
    }

    if (typeof text === 'string') {
      const trimmedMessage = text.trim();

      if (trimmedMessage && isTutor && !loading && !hasSeen) {
        setDisplayedMessage('');
        typeCharacter(0, trimmedMessage);
      } else {
        setDisplayedMessage(trimmedMessage);
      }
    } else if (exercise) {
      setDisplayedMessage(null);
    }
  }, [text, isTutor, loading, hasSeen, typeCharacter]);

  const hasAnswer =
    payload.hasOwnProperty('answeredCorrectly') &&
    payload.answeredCorrectly !== undefined;

  // Determine the banner type based on the correctness of the answer and device type.
  const getBannerType = (isCorrect: boolean, isDashboard: boolean) => {
    if (isCorrect) {
      return isDashboard ? CorrectBanner : CorrectBannerMobile;
    } else {
      return isDashboard ? InCorrectBanner : InCorrectBannerMobile;
    }
  };

  const answerBanner = getBannerType(!!answeredCorrectly, !!dashBoard.data);

  const isExercise = type === MessageTypes.Exercise;

  const handleHelp = () => exerciseHelp(exercise as Question);

  return (
    <div
      key={messageIndex}
      className={classNames({
        [styles.userMessage]: isUser,
        [styles.message]: isTutor,
      })}
    >
      {loading ? (
        <ThreeDots
          height="30"
          width="30"
          radius="9"
          color="#08263F"
          ariaLabel="three-dots-loading"
          visible={true}
          wrapperClass={styles.marginCenter}
        />
      ) : (
        <div className={styles.container}>
          {hasAnswer && (
            <div
              className={classNames({
                [styles.answerBanner]: true,
              })}
            >
              <img src={answerBanner} alt="banner" />
            </div>
          )}
          <div
            className={classNames(styles.header, styles.unsetPadding, {
              [styles.incorrect]: hasAnswer && !answeredCorrectly,
            })}
          >
            {(title || isExercise) && (
              <p>{isExercise ? selectedTopic?.name : title}</p>
            )}
            <span
              dangerouslySetInnerHTML={{ __html: displayedMessage || '' }}
            />
          </div>
          {isExercise && (
            <div className={styles.quiz}>
              <QuizQuestion
                currentQuestion={exercise ?? null}
                emitOnAnswer={setTutorExerciseFeedback}
                isTutor={showTutor}
                tutorPickedAnswer={pickedAnswer}
                showTutorAnswerInOptions={!!pickedAnswer}
                questionGameType={GameTypes.Multichoice}
              />
              <a className={styles.quizFooter} onClick={handleHelp}>
                {t('tutorMoreHelp')}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(TutorMessage);
