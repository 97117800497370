import { useTranslation } from 'react-i18next';
import classNames from './DataContainer.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { SkillTypes } from '@/types/skill';
import commonUtils from '@/utils/common';
import UseThemeColors from '@/hooks/UseThemeColors';
import { SkillsOverview, StudentProgress } from '@/types/progress';
import { Streak, UserState } from '@/types/user';

import UserStreak from '@/components/UserStreak/UserStreak';
import ProgressCard from '@/components/ProgressCard/ProgressCard';

import school from '@/assets/svg/school.svg';
import diamond from '@/assets/svg/diamond.svg';
import world from '@/assets/svg/world.svg';
import Assessments from '../Assessments/Assessments';
import AssessmentsButton from '../Assessments/AssessmentsButton/AssessmentsButton';

interface DataContainerProps {
  level: string;
  levelText: string;
  groupName?: string | null;
  language?: string;
  progress: StudentProgress;
  streak?: Streak[] | null;
  skillsOverview?: SkillsOverview | null;
  selectedStudent: UserState | null | undefined;
  emitShowSkillData?: (skill: SkillTypes) => void;
  emitShowAssessments: () => void;
}

const DataContainer = ({
  groupName,
  level,
  levelText,
  language,
  progress,
  streak,
  skillsOverview,
  selectedStudent,
  emitShowSkillData,
  emitShowAssessments,
}: DataContainerProps) => {
  const { t } = useTranslation();
  const { color } = UseThemeColors();
  const onViewSkillDetails = (type: SkillTypes) => {
    emitShowSkillData?.(type);
  };

  const progressCards = skillsOverview
    ? Object.entries(skillsOverview).map((entry) => {
        const [key, value] = entry;

        const { completedTopics, totalTopics, completedQuizzes, totalQuizzes } =
          value;

        const skillProgress =
          Object.entries(progress.currentProgressBySkill ?? {}).find(
            ([skill]) => key === skill
          )?.[1] ?? null;

        const percentage = skillProgress
          ? +commonUtils.calcPercentage(
              skillProgress.actual,
              skillProgress.total
            )
          : 0;

        const { trailColor, pathColor } =
          commonUtils.getProgressColors(key as SkillTypes) ?? {};

        const topics = {
          actual: completedTopics,
          total: totalTopics,
        };

        const quizzes = {
          actual: completedQuizzes,
          total: totalQuizzes,
        };

        return (
          <ProgressCard
            key={key}
            type={key as SkillTypes}
            percentage={percentage}
            topics={topics}
            quizzes={quizzes}
            user={selectedStudent}
            trailColor={color(trailColor ?? '') ?? ''}
            pathColor={color(pathColor ?? '') ?? ''}
            text={t('viewDetails')}
            emitClick={onViewSkillDetails}
          />
        );
      })
    : null;

  return (
    <div className={classNames.dataContainer}>
      <div className={classNames.info}>
        <div className={classNames.box}>
          <span>{t('schoolName')}</span>
          <div className={classNames.text}>
            <SVG src={school} />
            <span>{groupName}</span>
          </div>
        </div>
        {Number(level) > 0 && (
          <div className={classNames.box}>
            <span>{t('level')}</span>
            <div className={classNames.text}>
              {level ? <SVG src={diamond} /> : null}
              <span>{`${level} | ${levelText}`}</span>
            </div>
          </div>
        )}
        <div className={classNames.box}>
          <span>{t('interfaceLanguage')}</span>
          <div className={classNames.text}>
            <SVG src={world} />
            <span>{language}</span>
          </div>
        </div>
      </div>
      {streak && (
        <UserStreak streak={streak} title={t('selfPracticeStreak') ?? ''} />
      )}
      {/**todo need remove false */}
      {false && <AssessmentsButton onClick={emitShowAssessments} />}
      <div
        className={classes(classNames.progress, {
          [classNames.noProgress]: !skillsOverview,
        })}
      >
        {skillsOverview ? (
          <>
            <h2>{t('selfPracticeProgress')}</h2>
            <div className={classNames.progressCards}>{progressCards}</div>
          </>
        ) : (
          <span>{t('thisStudentHasNot')}</span>
        )}
      </div>
    </div>
  );
};

export default DataContainer;
