import { SpeakPreparationProps } from '@/types/game';
import { useRef, useState } from 'react';
import classNames from './EndOfSkillTestPreparation.module.scss';
import classes from 'classnames';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import commonUtils from '@/utils/common';

import TheButton from '@/components/TheButton/TheButton';
import QuizDetailsHeading from '../../QuizDetailsHeading/QuizDetailsHeading';

import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useNavigate } from 'react-router-dom';
import { SkillTypes } from '@/types/skill';
import { gameActions } from '@/store/reducers/game';
import UserUtils from '@/utils/user';

const EndOfSkillTestPreparation = ({
  selectedType,
  selectedSkill,
  selectedTopic,
  tip,
  emitOnStartQuiz,
}: SpeakPreparationProps) => {
  const { t } = useTranslation();
  const { isDesktop } = UseScreenSize();
  const { user } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);
  const isStudent = UserUtils.isStudent(user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const levelBySkill = user?.currentLevelByskill?.[selectedType as SkillTypes];

  const onGoBack = () => {
    dispatch(gameActions.setIsLevelTest(false));
    return navigate('/dashboard/games');
  };

  const description = (
    <div className={classNames.description}>
      {isStudent && (
        <>
          <span className="font-bold">
            {t('hi') + ' ' + user?.firstName + ',' + ' '}
          </span>
          <span>{t('youPassedAllTheGamesIn') + ' '}</span>
          <span className="font-bold">
            {t(`skills.${selectedType}`) + '.' + ' '}
          </span>
        </>
      )}
      {isTeacher ? (
        <>
          <br />
          <span>{t('youCanTryAssessment', { level: levelBySkill })}</span>
        </>
      ) : (
        <>
          <span>{t('letsSeeIfYouReady') + ' '}</span>
          <span>{t('advanceToTheNextLevel')}</span>
        </>
      )}
    </div>
  );

  const buttons = (
    <>
      {isDesktop ? (
        <>
          <TheButton
            className={classNames.backBtn}
            plain
            text={t('illDoItLater')}
            emitOnClick={onGoBack}
          />
          <TheButton
            className={classNames.button}
            text={t('letsGoFinalAssessment')}
            showArrow={isDesktop}
            emitOnClick={emitOnStartQuiz}
          />
        </>
      ) : (
        <>
          <TheButton
            className={classNames.button}
            text={t('start')}
            showArrow={isDesktop}
            emitOnClick={emitOnStartQuiz}
          />
          <TheButton
            className={classNames.backBtn}
            plain
            text={t('illDoItLater')}
            emitOnClick={onGoBack}
          />
        </>
      )}
    </>
  );
  return (
    <div className={classNames.endOfSkillTestPreparation}>
      <div className={classNames.heading}>
        <QuizDetailsHeading
          selectedType={selectedType}
          selectedSkill={selectedSkill}
          selectedTopic={selectedTopic}
        />
        <h1 className={classNames.title}>
          {t('level') +
            ' ' +
            user?.currentLevelByskill?.[selectedType as SkillTypes] +
            ' ' +
            t('assessment')}
        </h1>
      </div>
      <div className={classNames.svgContainer}>
        <SVG src={selectedType ? commonUtils.skillImage(selectedType) : ''} />
      </div>
      <div className={classNames.descriptionContainer}>{description}</div>
      <div className={classNames.buttons}>{buttons}</div>
    </div>
  );
};

export default EndOfSkillTestPreparation;
