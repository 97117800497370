import { skillService } from '@/services';
import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { appSettingsSelector } from '@/store/reducers/settings';
import {
  fetchTutorProcess,
  tutorActions,
  tutorSelector,
} from '@/store/reducers/tutor';
import questionsThunk from '@/store/thunks/questions';
import quizzesThunks from '@/store/thunks/quizzes';
import topicsThunks from '@/store/thunks/topics';
import { GameTypes } from '@/types/game';
import { Question } from '@/types/question';
import { Quiz } from '@/types/quiz';
import { SkillTypes } from '@/types/skill';
import { Topic, TopicTypes } from '@/types/topic';
import {
  Card,
  MessageTypes,
  TriggeredBy,
  TutorElement,
  TutorGroupMessages,
  TutorMessage,
  TutorSender,
} from '@/types/tutor';
import GameUtils from '@/utils/gameUtils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// This is the time in seconds to open the shift popup
const TIME_IN_SEC_TO_OPEN_SHIFT_POPUP = 1.5;

// This is the time in seconds to prepare the quiz
const TIME_IN_SEC_TO_PREPARE_THE_QUIZ = 4;

// This hook is used to handle the tutor's messages and the user's interaction with the tutor
const useTutor = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { messages, dashBoard, question_response } =
    useAppSelector(tutorSelector);
  const { selectedQuiz, selectedTopic, pickedAnswer } =
    useAppSelector(gameSelector);
  const { questions } = useAppSelector(appSettingsSelector);
  const { skillSettings } = useAppSelector(authSelector);

  const [path, setPath] = useState('');
  const [isCardLoading, setIsCardLoading] = useState(false);

  // This function is used to handle the exit confirm popup when the user wants to navigate away from the tutor
  const handleExitConfirmPopup = () =>
    dispatch(tutorActions.openTutorExitConfirmPopup());

  // This function is used to handle the exit confirm popup when the user wants to navigate away from the tutor
  const handleCloseExitPopUpTutor = () =>
    dispatch(tutorActions.closeTutorExitConfirmPopup());

  // This function is used to handle the exit confirm popup when the user wants to navigate away from the tutor
  const handleNavigateAwayTutor = () => {
    dispatch(tutorActions.closeTutorExitConfirmPopup());
    dispatch(tutorActions.resetMessages());
    dispatch(tutorActions.closeTutor());
    dispatch(tutorActions.resetDashboard());
    navigate(path);
  };

  const dashBoardData = useMemo(
    () => Object.entries<Card[]>(dashBoard.data || {}),
    [dashBoard.data]
  );

  // This function is used to set the user's answer to the tutor's question
  // and then the tutor will give feedback based on the user's answer
  // This function is called when the user clicks on an answer
  // The user's answer is passed as an argument to this function
  // The user's answer is then set to the tutor's question
  // The tutor will then give feedback based on the user's answer
  const setTutorExerciseFeedback = (userAnswer: string) => {
    console.log('userAnswer', userAnswer);

    // Check if there are any messages in the state.
    if (messages.length === 0) return;

    // Identify the index of the last group of messages.
    const lastGroupMessageIndex = messages.length - 1;

    // Make a copy of the last group of messages to avoid direct state mutation.
    const lastGroupMessage = { ...messages[lastGroupMessageIndex] };

    // Validate that the message index is within a valid range.
    if (lastGroupMessage.message.length === 0) return;

    const lastMessageIndex = lastGroupMessage.message.length - 1;

    // Directly reference the last message for mutation.
    const lastMessage = { ...lastGroupMessage.message[lastMessageIndex] };

    // Assign the user's answer to the pickedAnswer property of the last message.
    lastMessage.pickedAnswer = userAnswer;
    const exercise = lastMessage.exercise;
    // Update the last message in the last group of messages with the user's answer.
    dispatch(
      tutorActions.updatePickedAnswer({
        userAnswer, // The user's answer
        lastGroupMessageIndex, // The index of the last group of messages
        lastMessageIndex, // The index of the last message
      })
    );
    if (!exercise) return;
    const answeredCorrectly = exercise.answer === userAnswer;
    dispatch(
      tutorActions.setQuestionResponse({
        id: exercise?.id || 0,
        isCorrect: exercise?.answer === userAnswer,
        answer: userAnswer,
        responseTime: 0,
        wrongAnswer: answeredCorrectly ? '' : userAnswer,
        distractorLevel: 0,
        hint_used: null,
      })
    );
    // Check if the last message is an exercise

    // Validate that the last message is an exercise and the exercise is valid.

    // Determine if the user's answer is correct by comparing with the exercise's answer

    // Form the correct sentence by replacing '~' with the correct answer
    const correctSentence = exercise.question.replace('~', exercise.answer);

    // Create a tutor message based on the user's answer and the exercise
    const tutorMessage = createExerciseFeedbackMessage(userAnswer, exercise);

    // Create a tutor group with the tutor message
    let tutorGroupMessage: TutorGroupMessages;

    // Check if the user's answer is correct
    if (answeredCorrectly) {
      const feedbackMessage = createTutorMessage(
        'tutorFeedback', // Placeholder value, replace as needed.
        false // Do not emphasize in React
      );

      // Create a tutor group with the tutor message and the feedback message
      tutorGroupMessage = createTutorGroup([
        tutorMessage, // The user's answer
        // feedbackMessage // The feedback message
      ]);
    } else {
      // Create a tutor message based on the exercise's tip
      const explanationMessage = createExerciseFollowUpMessage(
        GameUtils.getTipPerDistractor(exercise,userAnswer), // Formatted message
        // exercise.tip || '', // Formatted message
        'tutorLetMeExplain' // Title
      );
      // Create a tutor message based on the correct sentence
      const correctMessage = createExerciseFollowUpMessage(
        `"${exercise.answer}"`, // Formatted message
        'tutorCorrectSentence' // Title
      );
      // Create a tutor message based on the follow-up message
      const followUpMessage = createTutorMessage(
        'tutorWasHelpful', // Placeholder value, replace as needed.
        false // Do not emphasize in React
      );

      // Create a tutor group with the tutor messages
      tutorGroupMessage = createTutorGroup([
        tutorMessage, // The user's answer
        correctMessage, // The correct sentence
        explanationMessage, // The explanation
        // followUpMessage, // The follow-up message
      ]);
    }

    // Handle the tutor group
    handleUserAndTutorGroups(tutorGroupMessage);
  };

  // This function is used to give the user more help on the exercise
  const exerciseHelp = (exercise: Question) => {
    // Create and dispatch user and tutor messages
    createAndDispatchMessages(
      'tutorMoreHelp', // User message key
      'tutorHowHelp' // Tutor message key
    );

    const moreHelpElement = [
      {
        name: t('tutorMoreHelp') as string,
        value: { text: exercise.tip || '' },
      } as TutorElement,
      {
        name: t('tutorMoreExplanation') as string,
        value: { text: t('tutorMoreExplanation') as string },
      } as TutorElement,
      {
        name: t('tutorGiveExample') as string,
        value: { text: t('tutorGiveExample') as string },
      } as TutorElement,
      {
        name: t('tutorCorrectAnswer') as string,
        value: {
          text: t('tutorCorrectAnswerIsShort', {
            answer: exercise.answer,
          }) as string,
        },
      } as TutorElement,
    ];
    // Dispatch the new elements
    dispatch(
      // Set the new elements in the tutor state
      tutorActions.setNewElements(moreHelpElement)
    );
  };

  /**
   * Handles click events on cards, setting the selected skill and topic, and retrieving related quizzes.
   *
   * @param {Card} data - The data of the clicked card.
   */
  const handleCardClick = async (data: Card) => {
    setIsCardLoading(true);

    try {
      // Find the skill matching the card's skill name, defaulting to null if not found.
      const skill = skillSettings.find((i) => i.name === data.skill) ?? null;

      const selectedSkill = {
        type: data.skill.toLocaleLowerCase() as SkillTypes,
        skill: skill,
      };

      // Set the selected skill in the game state.
      dispatch(gameActions.setSelectedSkill(selectedSkill));

      const topic: Topic = {
        id: +data.topic_id, // Convert to number
        nameLocal: null, // Set to null
        name: data.topic_name, // Set to the card's topic name
        order: 1, // Set to 1
        quizzesCount: 1, // Set to 1
        topicType: TopicTypes.NormalTopic, // Set to normal topic
      };

      // Set the selected topic in the game state.
      dispatch(gameActions.setSelectedTopic(topic));

      // Retrieve the quizzes for the selected topic.
      const topicQuizzes = await fetchTopicQuizzes();

      // Find the selected quiz by ID, defaulting to null if not found.
      const selectedQuiz =
        (topicQuizzes as Quiz[]).find((i) => i.id === parseInt(data.quiz_id)) ??
        null;

      // Set the selected quiz in the game state.
      dispatch(gameActions.setSelectedQuiz(selectedQuiz));

      // Open the tutor interface.
    } catch (error) {
      console.error('Error handling card click:', error);
      // Handle errors appropriately here.
    } finally {
      // Ensure loading state is reset whether the operation succeeds or fails.
      setIsCardLoading(false);

      dispatch(tutorActions.openTutor(TriggeredBy.HomePage));
    }
  };

  /**
   * Fetches quizzes for the selected topic.
   *
   * @returns {Promise<Quiz[]>}
   * @throws {Error} - An error if fetching quizzes fails.
   *
   */
  async function fetchTopicQuizzes() {
    try {
      return await dispatch(quizzesThunks.refreshTopicQuizzes())
        .unwrap()
        .then((response) => {
          return response;
        });
    } catch (error) {
      console.error('Error fetching topic quizzes:', error);
      return [];
    } finally {
      setIsCardLoading(false);
    }
  }

  /**
   * Handles the user's choice and triggers appropriate actions based on the choice type.
   *
   * @param {TutorElement} userChoice - The user's choice object.
   */
  const setUserChoice = async (userChoice: TutorElement, isInGame: boolean) => {
    // Determine if the user's choice is a shift
    const isShift = userChoice.type === MessageTypes.Shift;

    // Process the user's choice regardless of its type
    processUserChoice(
      userChoice, // The user's choice
      isShift // Whether the user's choice is a shift
    );

    // Perform different actions based on the choice type
    if (isShift) {
      // Open the shift popup after a Y-second delay
      setTimeout(
        () => dispatch(tutorActions.openShiftPopup()),
        TIME_IN_SEC_TO_OPEN_SHIFT_POPUP * 1000
      );

      // Fetch or retrieve questions based on the selected topic and quiz
      const questionsKey = `${selectedTopic?.id}-${selectedQuiz?.id}`;

      // Fetch or retrieve questions based on the selected topic and quiz
      const _questions =
        questions[questionsKey] ??
        (await dispatch(questionsThunk.refreshTopicQuizQuestions()).unwrap());

      // Perform game actions after a X-second delay
      setTimeout(async () => {
        // Reset the game state and start the game with the selected quiz and questions
        dispatch(gameActions.resetGame());
        // Start the game with the selected quiz and questions
        dispatch(
          gameActions.startGame({
            gameType: selectedQuiz?.gameType as GameTypes,
            questions: _questions as Question[],
          })
        );
        // Close the tutor interface
        dispatch(tutorActions.closeTutor());

        // Reset tutor messages
        dispatch(tutorActions.resetMessages());

        // Reset dashboard data
        dispatch(tutorActions.resetDashboard());

        // Navigate to the quiz after a X-second delay
        navigate('/dashboard/quiz');
      }, TIME_IN_SEC_TO_PREPARE_THE_QUIZ * 1000);
    } else {
      // Update user choice for non-shift types
      console.log('userChoice', pickedAnswer);
      dispatch(
        tutorActions.updateUserChoice({
          content_id: userChoice.id ?? 0,
          feedback: null,
          question_respons: question_response,
        })
      );
    }

    dispatch(fetchTutorProcess({}));
  };

  // This is used to determine if the user has seen all messages and if the last message is not an exercise or shift
  const isShowOptions = useMemo(() => {
    // Check if there are any messages in the state.
    const lastGroupMessage = messages[messages.length - 1];

    if (lastGroupMessage?.sender === TutorSender.tutor) {
      // Check if all messages have been seen
      const allMessagesSeen = lastGroupMessage.message.every(
        (msg) => msg.hasSeen
      );

      // Check if any message is an exercise or shift
      const anyMessageIsExerciseOrShift = lastGroupMessage.message.some(
        (msg) =>
          msg.type === MessageTypes.Exercise || msg.type === MessageTypes.Shift
      );

      return allMessagesSeen && !anyMessageIsExerciseOrShift;
    }

    return false;
  }, [messages]);

  /**
   * Handles the creation and dispatching of user and tutor groups.
   *
   * @param {TutorGroupMessages} userGroup - The user group to be dispatched.
   * @param {TutorGroupMessages} tutorGroup - The tutor group to be dispatched.
   */
  function handleUserAndTutorGroups(
    userGroup?: TutorGroupMessages,
    tutorGroup?: TutorGroupMessages
  ) {
    // Dispatch user and tutor groups if they exist
    if (userGroup) dispatch(tutorActions.setGroupMessages(userGroup));
    if (tutorGroup) dispatch(tutorActions.setGroupMessages(tutorGroup));
  }

  /**
   * Creates and dispatches user and tutor messages.
   *
   * @param {string} userMessageKey - The key for the user message text.
   * @param {string} tutorMessageKey - The key for the tutor message text.
   */
  const createAndDispatchMessages = (
    userMessageKey: string,
    tutorMessageKey: string
  ) => {
    // Create user and tutor messages
    const userMessage = createUserMessage(userMessageKey);
    const tutorMessage = createTutorMessage(
      tutorMessageKey, // Placeholder value, replace as needed.
      false // Do not emphasize in React
    );

    // Create user and tutor groups
    const userGroup = createUserGroup(userMessage);
    const tutorGroup = createTutorGroup(tutorMessage);

    // Handle both user and tutor groups
    handleUserAndTutorGroups(userGroup, tutorGroup);
  };

  /**
   * Adds formatted text to the given string.
   *
   * @param {string} text - The original text.
   * @returns {string} - The text with additional formatted emphasis.
   */
  function addFormattedText(text: string): string {
    return `${t(text as string)} (Emphasized in React)`;
  }

  // Create a user message based on the given text
  const createUserMessage = (text: string): TutorMessage => {
    return {
      text: t(text) as string, // Formatted message
      hasSeen: true, // Has been seen
      loading: false, // Not loading
      type: MessageTypes.Response, // Message type
    };
  };

  // Create a tutor message based on the given text
  const createTutorMessage = (
    text: string,
    emphasizedInReact: boolean = true,
    hasSeen: boolean = false,
    loading: boolean = false,
    type: MessageTypes = MessageTypes.Response,
    exercise: Question | null = null,
    title: string | null | undefined = null,
    answeredCorrectly: boolean | undefined = undefined
  ): TutorMessage => {
    return {
      title: title
        ? emphasizedInReact
          ? addFormattedText(title)
          : (t(title) as string)
        : null, // Title
      text: emphasizedInReact ? addFormattedText(text) : (t(text) as string), // Formatted message
      hasSeen, // Has been seen
      loading, // Not loading
      type, // Message type
      exercise, // Exercise object
      answeredCorrectly, // Answered correctly
    };
  };

  // Create a tutor message based on the user's answer
  const createExerciseFeedbackMessage = (
    userAnswer: string,
    exercise: Question
  ): TutorMessage => {
    const answeredCorrectly = exercise.answer === userAnswer;
    const updatedQuestion = exercise.question.replace('~', userAnswer);

    // Determine the correct title key based on whether the answer is correct
    const titleKey = answeredCorrectly
      ? 'tutorCorrectTitle'
      : 'tutorInCorrectTitle';

    // Assuming t() can handle replacements correctly and returns a string
    const messageKey = answeredCorrectly
      ? 'tutorCorrectBody'
      : 'tutorInCorrectBody';
    const message = t(messageKey, { answer: updatedQuestion });

    // Here, ensure that the title is processed to a string correctly
    // If t() returns an object for some reason, you might need to adjust this part
    const title = t(titleKey); // Make sure this is a string

    return createTutorMessage(
      answeredCorrectly ? message : '',
      false, // Do not emphasize in React
      true, // Has been seen
      false, // Not loading
      MessageTypes.ExerciseAnswer, // Message type
      null, // No exercise object
      title, // Title, ensure this is always a string
      answeredCorrectly // Answered correctly
    );
  };

  // Create a tutor message based on the given exercise
  const createExerciseMessage = (exercise: Question): TutorMessage => {
    return createTutorMessage(
      'tutorChooseAnswer', // Placeholder value, replace as needed.
      false, // Emphasize in React
      true, // Has been seen
      false, // Not loading
      MessageTypes.Exercise, // Exercise type
      exercise // Exercise object
    );
  };

  // Create a tutor message based on the given text
  const createExerciseFollowUpMessage = (
    text: string,
    title: string
  ): TutorMessage => {
    return createTutorMessage(
      text, // Placeholder value, replace as needed.
      false, // Emphasize in React
      true, // Has been seen
      false, // Not loading
      MessageTypes.ExerciseAnswer, // Exercise answer type
      null, // No exercise object
      title // Title
    );
  };

  /**
   * Processes the user choice, generates tutor messages and groups,
   * and then handles these groups.
   * This function is called when the user makes a choice.
   * It processes the user's choice and dispatches the appropriate actions.
   * It also handles the user and tutor groups if they exist and are valid.
   * It also processes the conditional user choice and dispatches the appropriate actions.
   * It also creates a tutor group with the tutor message and includes thumbs if needed.
   * It also creates a user group with the user message.
   *
   * @param {TutorElement} userChoice - The user's choice object.
   * @param {boolean} isShift - Whether the user's choice is a shift.
   */
  function processUserChoice(userChoice: TutorElement, isShift: boolean) {
    // Create a user group with the user message
    const userGroup = createUserGroup(createUserMessage(userChoice.name));

    let tutorMessage; // Placeholder value, replace as needed.

    // Check if user choice is an exercise
    if (userChoice.type === MessageTypes.Exercise) {
      const exercise: Question = userChoice.value.question as Question;

      // Assuming createExerciseMessage is a function that takes an exercise object and returns a message
      tutorMessage = createExerciseMessage(exercise);
    } else {
      // Create a tutor message based on the user's choice for other types
      tutorMessage = createTutorMessage(
        userChoice.value.text as string, // Placeholder value, replace as needed.
        false, // Do not emphasize in React
        false, // Has been seen
        false, // Loading
        isShift ? MessageTypes.Shift : MessageTypes.Response // Message type
      );
    }

    // Create a tutor group with the tutor message and include thumbs if needed
    const tutorGroup = createTutorGroup(
      tutorMessage, // The tutor message
      userChoice.include_thumbs || false // Include thumbs if needed
    );

    // Process the conditional user choice and dispatch the appropriate actions
    processConditionalUserChoice(
      userChoice, // The user's choice
      tutorGroup // The tutor group
    );

    // Handle both user and tutor groups if they exist and are valid
    handleUserAndTutorGroups(
      userGroup, // The user group
      tutorGroup // The tutor group
    );
  }

  /**
   * Processes the conditional user choice and dispatches the appropriate actions.
   *
   * @param {TutorElement} userChoice - The user's choice object.
   * @param {TutorGroupMessages} tutorGroup - The tutor group to be updated.
   */
  function processConditionalUserChoice(
    userChoice: TutorElement,
    tutorGroup: TutorGroupMessages
  ) {
    // Check if the userChoice is conditional
    const isConditional = userChoice.wasClear !== undefined;

    if (isConditional) {
      const wasClearTrueValue = userChoice.wasClear?.true[0];
      const wasClearFalseValue = userChoice.wasClear?.false[0];

      // Append 'Was that clear?' message with response options.
      if (wasClearTrueValue && wasClearFalseValue) {
        tutorGroup.message.push(createTutorMessage('tutorWasClear'));

        return dispatch(
          tutorActions.setNewElements([
            {
              name: addFormattedText('tutorNoMoreHelp'),
              value: wasClearFalseValue.value,
              type: wasClearFalseValue.type,
            },
            {
              name: addFormattedText('tutorYesGoBack'),
              value: wasClearTrueValue.value,
              type: wasClearTrueValue.type,
            },
          ])
        );
      }
    }
  }

  return {
    setPath,
    handleCloseExitPopUpTutor,
    handleNavigateAwayTutor,
    handleExitConfirmPopup,
    isLoadingDashBaord: dashBoard.isLoading,
    dashBoardData,
    exerciseHelp,
    setUserChoice,
    isCardLoading,
    handleCardClick,
    setTutorExerciseFeedback,
    isShowOptions,
  };
};

export default useTutor;

/**
 * Generates a timestamp string based on the current date and time in 12-hour format.
 *
 * @returns {string} - The formatted timestamp as a string.
 */
export function calculateTimeStamp(): string {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const isPM = hours >= 12;
  const formattedHours = hours % 12 || 12; // Adjust for 12-hour clock format

  return `${formattedHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${isPM ? 'PM' : 'AM'}`;
}

/**
 * Creates a tutor group message.
 *
 * @param {TutorMessage} messages - The tutor messages.
 * @param {boolean} includeThumbs - Whether to include thumbs in the message.
 * @returns {TutorGroupMessages} - The tutor group message.
 */
export function createTutorGroup(
  messages: TutorMessage | TutorMessage[],
  includeThumbs: boolean = false
): TutorGroupMessages {
  return {
    sender: TutorSender.tutor,
    message: Array.isArray(messages) ? messages : [messages],
    timeStamp: calculateTimeStamp(),
    includeThumbs,
  };
}

/**
 * Creates a user group message.
 *
 * @param {TutorMessage} messages - The user messages.
 * @param {string} timeStamp - The timestamp of the message.
 * @returns {TutorGroupMessages} - The user group message.
 */
export function createUserGroup(
  messages: TutorMessage,
  timeStamp: string = calculateTimeStamp()
): TutorGroupMessages {
  return {
    sender: TutorSender.user,
    message: [messages],
    timeStamp,
    includeThumbs: false,
  };
}

/**
 * Validates whether a given value adheres to the `Question` type structure.
 *
 * @param {any} value - The value to be checked.
 * @returns {boolean} - Returns true if the value is a valid `Question`, otherwise false.
 */
function isValidQuestion(value: any): value is Question {
  return (
    value &&
    typeof value === 'object' &&
    'questionId' in value &&
    'question' in value &&
    'answer' in value
    // Include other necessary properties if needed
  );
}
