import { useNavigate } from 'react-router-dom';
import { progressService, skillService } from '@/services';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import { gameActions, gameSelector } from '@/store/reducers/game';
import commonUtils from '@/utils/common';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UseQuizTimer from '@/hooks/UseQuizTimer';

import {
  GameStatus,
  GameTypes,
  HintsType,
  PopUpEndOfSkillSummaryProps,
  PopUpFinalAssessmentSummaryProps,
  PopUpFocusProps,
  PopUpHintsProps,
  PopUpKeepGoingProps,
  PopUpPauseProps,
  PopUpSummaryProps,
  PopUpTimeIsUpProps,
  PopUpTypes,
  PopUpWordsListProps,
  QuizQuestionResult,
} from '@/types/game';
import GameUtils from '@/utils/gameUtils';
import UseTopicQuizzes from '@/hooks/UseTopicQuizzes';
import { SkillTypes } from '@/types/skill';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseSkill from '../skill/UseSkill';
import { levelTestSelector } from '@/store/reducers/level-test';
import { Quiz } from '@/types/quiz';
import { Question } from '@/types/question';
import {
  appSettingsActions,
  appSettingsSelector,
} from '@/store/reducers/settings';
import questionsThunk from '@/store/thunks/questions';
import topicsThunks from '@/store/thunks/topics';
import quizzesThunks from '@/store/thunks/quizzes';
import UserUtils from '@/utils/user';
import UseUserProgress from '@/hooks/UseUserProgress';
import UseLevelTestGame from '@/hooks/UseLevelTestGame';
import userThunks from '@/store/thunks/user';
import { TopicTypes } from '@/types/topic';
import { endOfSkillActions } from '@/store/reducers/end-of-skill';
import useEndOfSkillTest from '@/hooks/UseEndOfSkillTest';
import {
  finalAssessmentActions,
  finalAssessmentSelector,
} from '@/store/reducers/final-assessment';

import {
  // fetchTutorActivity,
  fetchTutorActivity,
  shouldInitiateTutor,
  tutorActions,
  tutorSelector,
} from '@/store/reducers/tutor';
import { TutorActivityType } from '@/types/tutor';

const UseQuiz = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user, skillSettings } = useAppSelector(authSelector);
  const { loadUserProgress } = UseUserProgress();
  const {
    selectedType,
    selectedTopic,
    selectedSkill,
    selectedQuiz,
    gameStatus,
    timesUpShown,
    focusShown,
    keepGoingShown,
    correctAnswers,
    total_correctAnswers,
    inCorrectAnswers,
    currentQuestionIndex,
    currentReadQuestionIndex,
    gameQuestions,
    coins,
    selectedHint,
    pickedAnswer,
    currentTime,
    isMuted,
    allowRetry,
    gameType,
    showPreQuiz,
    showAnswerInOptions,
    questionResultData,
    quizQuestionResultData,
    total_inCorrectAnswers,
    totalQuizTime,
    visitFocus,
    visitKeepGoing,
    hintsData,
  } = useAppSelector(gameSelector);

  const { questions, quizzes } = useAppSelector(appSettingsSelector);

  const {
    quizzes: levelTestQuizzes,
    progress: levelTestProgress,
    currentQuizIndex: levelTestQuizIndex,
    currentOverAllQuizNumber,
  } = useAppSelector(levelTestSelector);

  const {
    quizzes: finalAssessmentQuizzes,
    progress: finalAssessmentProgress,
    currentQuizIndex: finalAssessmentQuizIndex,
  } = useAppSelector(finalAssessmentSelector);

  const { startLevelTestGame } = UseLevelTestGame();
  const { handleStartEndOfSkillTest } = useEndOfSkillTest();

  const { mute, isTutorNeeded, showTutor } = useAppSelector(tutorSelector);

  UseQuizTimer();

  const { sortedQuizzes } = UseTopicQuizzes();

  const navigate = useNavigate();
  const { isDesktop, isMobile } = UseScreenSize();
  const [loadQuizProgress, setLoadQuizProgress] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [shouldDisplayPopUp, setShouldDisplayPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState<PopUpTypes | null>(null);
  const [popUpProps, setPopUpProps] = useState<
    | PopUpKeepGoingProps
    | PopUpFocusProps
    | PopUpTimeIsUpProps
    | PopUpPauseProps
    | PopUpSummaryProps
    | PopUpHintsProps
    | PopUpWordsListProps
    | PopUpEndOfSkillSummaryProps
    | PopUpFinalAssessmentSummaryProps
    | null
  >(null);

  const [isFinalAssessmentFinished, setIsFinalAssessmentFinished] =
    useState(false);

  const currentCoins = user?.achievements?.coins;

  const { topics } = UseSkill();

  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);

  const isInEndOfSkillTest =
    selectedTopic?.topicType === TopicTypes.EndOfSkillTest;

  const isRead = selectedType === SkillTypes.Read;
  const isSpeak = selectedType === SkillTypes.Speak;
  const isGrammar = selectedType === SkillTypes.Grammar;

  const ignoreFocusTypes = [GameTypes.ClosedSpelling, GameTypes.Pronunciation];

  //if the current quiz number is 10, 20, 30, show keep going modal
  const levelTestShowKeepGoing = [10, 20, 30].includes(
    currentOverAllQuizNumber
  );

  //Check the current quiz number and show keep going modal
  useEffect(() => {
    if (levelTestShowKeepGoing) {
      showKeepGoing();
    }
  }, [currentOverAllQuizNumber]);

  const getNextTopic = () => {
    const currentTopicIndex = topics.findIndex(
      (topic) => topic.id === selectedTopic?.id
    );

    if (currentTopicIndex >= topics.length - 1) {
      return null;
    }

    return topics[currentTopicIndex + 1];
  };

  const getTopicQuizzes = async (topicId: number): Promise<Quiz[]> => {
    const topicQuizzes =
      quizzes[topicId] ??
      (await dispatch(quizzesThunks.refreshTopicQuizzes(topicId)).unwrap());

    const _sortedQuizzes = commonUtils.sortByKey([...topicQuizzes], 'order');

    return _sortedQuizzes;
  };

  const getNextTopicQuiz = async () => {
    const currentQuizIndex = sortedQuizzes.findIndex(
      (quiz) => quiz.id === selectedQuiz?.id
    );

    const nextQuiz = sortedQuizzes[currentQuizIndex + 1];

    // Go to next topic and first quiz
    if (currentQuizIndex >= sortedQuizzes.length - 1) {
      const nextTopic = getNextTopic();

      if (
        !nextTopic ||
        !nextTopic.id ||
        nextTopic.topicType === TopicTypes.EndOfSkillTest
      ) {
        return null;
      }

      const nextTopicQuizzes = await getTopicQuizzes(nextTopic.id);

      return { topic: nextTopic, quiz: nextTopicQuizzes[0] };
    }

    return { topic: selectedTopic, quiz: nextQuiz };
  };

  const showFocusModal = () => {
    // Mark the focus modal as shown. This prevents it from being shown again in the same session.
    dispatch(gameActions.setFocusShown());
    // Hide the focus indicator, as we're now showing the modal.
    dispatch(gameActions.toggleShowFocus(false));

    // Define the properties for the focus modal.
    const props = {
      totalQuestions: gameQuestions.length, // Total number of questions in the game.
      inCorrectAnswers, // Number of incorrect answers given by the user.
      canClickOutside: false, // Prevents closing the modal by clicking outside it.
      emitOnClosePopUp: async () => {
        // Function to execute when the modal is closed.
        onClosePopUp(); // Custom function to handle modal closure.
        await commonUtils.sleep(400); // Wait for 400ms for a smoother user experience.

        // Proceed to the next question after the modal is closed.
        !showTutor && goToNextQuestion(true);
      },
    } as PopUpFocusProps;

    // Check if the current skill type is 'Grammar'.
    const isGrammarSkill = selectedType === SkillTypes.Grammar;

    // Determine the type of pop-up to display based on whether the skill is Grammar.
    const type = isGrammarSkill ? PopUpTypes.TutorFocus : PopUpTypes.Focus;

    // Display the appropriate pop-up with the defined properties.
    displayPopUp(type, props);

    // Reset the counters for correct and incorrect answers.
    dispatch(gameActions.resetCorrectAnswers());
    dispatch(gameActions.resetIncorrectAnswers());
  };

  const showKeepGoing = () => {
    dispatch(gameActions.setKeepGoingShown());
    dispatch(gameActions.toggleShowKeepGoing(false));

    const questionsLeft = gameQuestions.slice(currentQuestionIndex + 1).length;

    const props = {
      questionsLeft,
      coins,
      canClickOutside: false,
      emitOnClosePopUp: async () => {
        onClosePopUp();
        await commonUtils.sleep(400);
        !isLevelTest && goToNextQuestion(true);
      },
    } as PopUpKeepGoingProps;

    const isGrammarSkill = selectedType === SkillTypes.Grammar;

    const type =
      isGrammarSkill && !isLevelTest
        ? PopUpTypes.TutorKeepGoing
        : PopUpTypes.KeepGoing;

    displayPopUp(type, props);

    if (!isLevelTest) {
      dispatch(gameActions.resetCorrectAnswers());
      dispatch(gameActions.resetIncorrectAnswers());
    }
  };

  const fetchQuizProgress = async (topicId: number, quizId: number) => {
    if (
      selectedType &&
      !isLevelTest &&
      selectedQuiz &&
      user &&
      selectedTopic &&
      !isInEndOfSkillTest &&
      !isFinalAssessment &&
      quizId
    ) {
      // === TUTOR ===

      // === TUTOR ====

      try {
        setLoadQuizProgress(true);

        let _questions =
          questions[`${topicId}-${quizId}`] ??
          (await dispatch(questionsThunk.refreshTopicQuizQuestions()).unwrap());

        if (isRead) {
          dispatch(gameActions.resetGame());

          _questions = _questions.map((question) => {
            return {
              ...question,
              pickedAnswer: '',
              options: GameUtils.getPossibleAnswers(
                question,
                GameTypes.Multichoice,
                SkillTypes.Read
              ),
            };
          });
        }

        const whitelist = [
          SkillTypes.Vocabulary,
          SkillTypes.Read,
          SkillTypes.Speak,
        ];

        dispatch(gameActions.resetGame());
        dispatch(gameActions.setShowPreQuiz(whitelist.includes(selectedType)));

        dispatch(
          gameActions.startGame({
            gameType: selectedQuiz.gameType ?? 1,
            questions: _questions,
          })
        );

        if (
          isGrammar &&
          !isLevelTest &&
          !isFinalAssessment &&
          !isInEndOfSkillTest
        ) {
          dispatch(
            fetchTutorActivity({ activity_type: TutorActivityType.path })
          );
        }

        setLoadQuizProgress(false);
      } catch (error) {
        console.log(error);
        setLoadQuizProgress(false);
        setLoadError(true);
        commonUtils.showToast(t('anErrorOccurred'));
      }
    }
  };

  const onReset = () => {
    dispatch(gameActions.setInEndOfSkillTest(false));
    dispatch(endOfSkillActions.resetCurrentReadQuizIndex());
    dispatch(tutorActions.resetMessages());
    dispatch(gameActions.setSelectedQuiz(null));
    dispatch(gameActions.resetGame());
  };

  const currentQuestion = useMemo(() => {
    return gameQuestions[currentQuestionIndex];
  }, [currentQuestionIndex, gameQuestions]);

  const checkAnswer = async () => {
    let isAnswerCorrect = pickedAnswer == currentQuestion.answer;
    const isListen = selectedType == SkillTypes.Listen;

    if (pickedAnswer && gameType === GameTypes.Pronunciation) {
      const lowerCaseQuestion = currentQuestion.question?.toLowerCase();
      const lowerCasePickedAnswer = pickedAnswer.toLocaleLowerCase();

      const isSimilar = commonUtils.isSimilar(
        lowerCaseQuestion,
        lowerCasePickedAnswer
      );

      isAnswerCorrect = isSimilar;
    }

    if (
      (gameType !== GameTypes.ClosedSpelling &&
        gameType !== GameTypes.Pronunciation &&
        (!isListen || isAnswerCorrect || !allowRetry)) ||
      (isListen && (isLevelTest || isFinalAssessment))
    ) {
      await commonUtils.sleep(1500); // Wait before checking
    }

    if (!selectedQuiz && !isInEndOfSkillTest) {
      return;
    }

    // Initialize flags for determining whether to show specific modals.
    let visitFocus = false;
    let visitKeepGoing = false;

    const displayFocusAfterX = GameUtils.displayFocusAfterX(
      selectedQuiz as Quiz
    );
    const displayKeepGoingAfterX = GameUtils.displayKeepGoingAfterX(
      selectedQuiz as Quiz
    );

    // Check if action modals should be displayed based on the game questions.
    const displayActionModals = GameUtils.shouldDisplayActionModals(
      gameQuestions ?? []
    );

    // Determine if the 'Focus' modal should be shown.
    // Check if the number of incorrect answers meets the threshold for showing the 'Focus' modal.
    if (
      displayActionModals &&
      displayFocusAfterX !== null &&
      displayFocusAfterX > 0 &&
      isTutorNeeded &&
      inCorrectAnswers >= displayFocusAfterX
    ) {
      visitFocus = true; // Set the flag to show the 'Focus' modal.
    }

    // Determine if the 'Keep Going' modal should be shown.
    if (
      displayActionModals &&
      displayKeepGoingAfterX != null &&
      displayKeepGoingAfterX > 0 &&
      correctAnswers >= displayKeepGoingAfterX // Check if the number of correct answers meets the threshold for showing the 'Keep Going' modal.
    ) {
      visitKeepGoing = true; // Set the flag to show the 'Keep Going' modal.
    }

    // Resolve potential conflict between the two modals.
    if (visitKeepGoing && visitFocus) {
      visitKeepGoing = false; // If both 'Focus' and 'Keep Going' modals are flagged to be shown, prioritize the 'Focus' modal.
    }

    // Check if the conditions to potentially show the Focus Modal are met.
    if (
      visitFocus && // Check if the game logic dictates that the focus modal should be shown.
      !focusShown && // Ensure the focus modal has not already been shown.
      !GameUtils.isOneQuestionLeft(currentQuestionIndex, gameQuestions) // Ensure it's not the last question in the game.
    ) {
      // If the current game type should ignore the focus modal or if the current skill type is 'Listen'
      // and the user is allowed to retry, then just toggle the focus visibility flag without showing the modal.
      if (
        ignoreFocusTypes.includes(gameType!) ||
        (selectedType === SkillTypes.Listen && allowRetry)
      ) {
        dispatch(gameActions.toggleShowFocus(true));
      } else {
        // If the above conditions aren't met, show the focus modal.
        showFocusModal();
        return; // Exit the function to avoid further processing.
      }
    } else if (
      // Check if the focus modal has been shown and if the total incorrect answers
      // exceed the threshold defined for displaying the focus modal.
      focusShown &&
      total_inCorrectAnswers > (displayFocusAfterX ?? 0)
    ) {
      dispatch(gameActions.endGame()); // End the game if the above condition is met.
      return; // Exit the function to stop further processing.
    }

    if (
      visitKeepGoing &&
      !keepGoingShown &&
      !GameUtils.isOneQuestionLeft(currentQuestionIndex, gameQuestions)
    ) {
      if (ignoreFocusTypes.includes(gameType!) || (isListen && allowRetry)) {
        dispatch(gameActions.toggleShowKeepGoing(true));
      } else {
        showKeepGoing();
        return;
      }
    }

    if (
      isListen &&
      !isAnswerCorrect &&
      (isInEndOfSkillTest || isFinalAssessment)
    ) {
      await commonUtils.sleep(1500);
    }

    if (
      isListen &&
      !isAnswerCorrect &&
      !isLevelTest &&
      !isInEndOfSkillTest &&
      !isFinalAssessment &&
      allowRetry
    ) {
      dispatch(gameActions.setPictureOverlay(true));
      return;
    }

    dispatch(gameActions.setCheckAnswerCompleted(true));

    // ==== TUTOR ===

    const score = +commonUtils.calcPercentage(
      correctAnswers ?? 0,
      total_correctAnswers + total_inCorrectAnswers ?? 0
    );
    const payload = {
      Session_questions: quizQuestionResultData.map((question, index) => ({
        questionId: question.questionId,
        isCorrect: question.isCorrect,
        questionLevel: 0, // Todo: get question level
        isTimesUp: question.isTimesUp,
        responseTime: question.responseTime,
        wrongAnswer: question.wrongAnswer,
        wrongAnswerLevel: 0, // Todo: get wrong answer level
        isHintUsed: hintsData[index]?.hintUsageCount > 0,
      })),
      // displayFocusAfterX: displayFocusAfterX ?? null,
      quizId: selectedQuiz?.id,
      gameType: selectedQuiz?.gameType,
      userLevel: user?.level,
      averageScore: score,
      quizName: selectedQuiz?.name,
      correctAnswers: total_correctAnswers,
      wrongAnswers: total_inCorrectAnswers,
      // tutorUsage: tutorUsage, // Todo: get tutor usage
      hintUsageCount: hintsData.reduce((sum, data) => {
        const hintUsageCount = data.hintUsageCount || 0;
        return sum + hintUsageCount;
      }, 0),
      attemptsQuiz: selectedQuiz?.userData?.attempts ?? 0,
    };

    // ==== TUTOR ===

    if (
      selectedType == SkillTypes.Spelling ||
      selectedType == SkillTypes.Speak
    ) {
      return;
    }

    const isLast = currentQuestionIndex === gameQuestions.length - 1;

    if (isLevelTest && isRead) {
      return;
    } else if (isRead && isLast) {
      return;
    }

    if (!isAnswerCorrect && mute) {
      dispatch(tutorActions.tutorMuteOn());
    }

    let _isTutorNeeded = isTutorNeeded;

    if (
      !_isTutorNeeded &&
      isGrammar &&
      !isLevelTest &&
      !isFinalAssessment &&
      !isInEndOfSkillTest
    ) {
      _isTutorNeeded = await dispatch(shouldInitiateTutor(payload)).unwrap();

      if (_isTutorNeeded) {
        showFocusModal();
        return;
      }
    }

    if (!isAnswerCorrect && !mute) {
      dispatch(tutorActions.openTutorAssist());
    }

    if (
      isGrammar &&
      !isLevelTest &&
      !isFinalAssessment &&
      !isInEndOfSkillTest &&
      !isAnswerCorrect
    ) {
      return;
    }

    // if (isAnswerCorrect) goToNextQuestion();
    goToNextQuestion();
  };

  const displayPopUp = (
    type: PopUpTypes,
    props:
      | PopUpKeepGoingProps
      | PopUpFocusProps
      | PopUpTimeIsUpProps
      | PopUpSummaryProps
  ) => {
    setPopUpType(type);
    setPopUpProps(props);
    setShouldDisplayPopUp(true);
  };

  const onMute = () => {
    dispatch(gameActions.toggleGameMute());
  };

  const onPause = async () => {
    if (
      gameStatus === GameStatus.ENDED ||
      gameStatus === GameStatus.TIME_ENDED ||
      gameStatus === GameStatus.SHOW_HINTS ||
      gameStatus === GameStatus.SHOW_TUTOR ||
      gameStatus === GameStatus.PREPARING
    ) {
      return;
    }

    if (
      gameStatus === GameStatus.PLAYING ||
      gameStatus === GameStatus.QUESTION_ANSWERED ||
      gameStatus === GameStatus.CHECK_ANSWER
    ) {
      dispatch(
        gameActions.onPause({
          simple:
            gameStatus === GameStatus.QUESTION_ANSWERED ||
            gameStatus === GameStatus.CHECK_ANSWER,
        })
      );
    } else {
      dispatch(gameActions.onPlay());
    }
  };

  const onClosePopUp = async () => {
    setShouldDisplayPopUp(false);
    setPopUpType(null);
    setPopUpProps(null);

    if (
      popUpType === PopUpTypes.Pause ||
      popUpType === PopUpTypes.Hints ||
      popUpType === PopUpTypes.WordsList
    ) {
      await commonUtils.sleep(400);
      dispatch(gameActions.onPlay());
    }
  };

  const closeHintPopup = async () => {
    onClosePopUp();
    dispatch(gameActions.setSelectedHint({ hint: null, soundPath: '' }));
    dispatch(gameActions.onPlay());
  };

  const addCoins = (coins: number) => {
    const currentCoins = user?.achievements.coins ?? 0;

    dispatch(authActions.setUserCoins(currentCoins + coins));
  };

  const onHintClick = async (hint: HintsType) => {
    const isHearEnglishWord = hint === HintsType.HEAR_ENGLISH_WORD;

    let soundPath = '';

    if (isHearEnglishWord) {
      const response = await GameUtils.getSoundBase64FromUrl(
        gameQuestions[currentQuestionIndex].dictionaryDetails?.soundPath ?? ''
      );

      soundPath = response;
    }

    addCoins(user?.role === 'teacher' ? -0 : -5);

    dispatch(gameActions.setSelectedHint({ hint, soundPath }));

    closeHintPopup();
  };

  const goToNextQuestion = (force = false) => {
    if (!force) {
      console.log(
        'Go To Next Question - Visit Focus or Tutor Needed',
        visitFocus
      );

      if (visitFocus) {
        showFocusModal();
        return;
      }
      if (visitKeepGoing && !visitFocus) {
        showKeepGoing();
        return;
      }
    }

    dispatch(
      gameActions.goToNextQuestion({
        quizzesLength: levelTestQuizzes?.length ?? 0,
        levelTestQuizIndex: levelTestQuizIndex,
      })
    );
  };

  const toggleCheckAnswer = async (answer: string) => {
    dispatch(
      gameActions.handlePickedAnswer({
        answer,
      })
    );

    // === TUTOR ===
    if (
      isGrammar &&
      !isLevelTest &&
      !isFinalAssessment &&
      !isInEndOfSkillTest
    ) {
      dispatch(fetchTutorActivity({ activity_type: TutorActivityType.path }));
    }
    // === TUTOR ====

    if (gameType === GameTypes.Multichoice) {
      let showAnswer = true;

      if (
        selectedType === SkillTypes.Listen &&
        allowRetry &&
        !isLevelTest &&
        !isInEndOfSkillTest &&
        !isFinalAssessment
      ) {
        showAnswer = false;
      }

      if (showAnswer) {
        await commonUtils.sleep(500);
        dispatch(gameActions.setShowAnswerInOptions());
      }
    }
  };

  const buildEndGameBody = () => {
    const scoreEarned = +commonUtils.calcPercentage(
      total_correctAnswers ?? 0,
      gameQuestions.length ?? 0
    );

    const stars = GameUtils.getStarsAmount(scoreEarned);
    const quizQuestionResultDataIds = quizQuestionResultData.map(
      (question) => question.questionId
    );
    const inPractice = gameType === GameTypes.Practice;

    let remainingQuestionBuild: QuizQuestionResult[] = [];

    const topicQuizzes = selectedTopic ? quizzes[selectedTopic.id] : [];

    const isLastQuizInTopic =
      selectedQuiz?.id === topicQuizzes[topicQuizzes.length - 1]?.id;

    const isLastTopic = selectedTopic?.id === topics[topics.length - 1].id;
    const isLastQuizInTheLastTopic = isLastTopic && isLastQuizInTopic;

    if (inPractice) {
      let practiceQuestions: any = [];

      gameQuestions.map((question) => {
        if (question.userPractice?.isKnown !== null) {
          practiceQuestions.push({
            questionId: question.questionId,
            isKnown: question.userData?.practiceMode?.isKnown,
          });
        }
      });

      const practiceContent = {
        knownEntriesCount: gameQuestions.filter(
          (question) => question.userData?.practiceMode?.isKnown === true
        ).length,
        unKnownEntriesCount: gameQuestions.filter(
          (question) => question.userData?.practiceMode?.isKnown === false
        ).length,
        startTime: GameUtils.formatDate(totalQuizTime),
        finishTime: GameUtils.formatDate(new Date()),
        questions: practiceQuestions,
        quizId: selectedQuiz?.id,
      };

      return {
        practice: {
          ...practiceContent,
        },
      };
    } else {
      const remainingQuestion: Question[] = gameQuestions.filter(
        (question) => !quizQuestionResultDataIds.includes(question.questionId)
      );

      if (remainingQuestion.length > 0) {
        remainingQuestion.forEach((question, index) => {
          remainingQuestionBuild.push({
            questionId: question.questionId,
            isCorrect: false,
            isTimesUp: true,
            wrongAnswer: null,
            responseTime: 0,
            achievedCoins: 0,
            coinsUsed: hintsData[index].coinsUsed,
            hintUsageCount: hintsData[index].hintUsageCount,
          });
        });
      }
      const content = {
        questions: [...quizQuestionResultData, ...remainingQuestionBuild],
        quizId: selectedQuiz?.id,
        gameType: GameTypes.Multichoice,
        correctAnswers: total_correctAnswers,
        wrongAnswers: total_inCorrectAnswers,
        isFailed: stars === 0,
        achievedStars: stars,
        achievedCoins: coins,
        coinsUsed: hintsData
          .map((data) => data.coinsUsed)
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }),
        hintUsageCount: hintsData
          .map((data) => data.hintUsageCount)
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }),
        questionAnswered: total_correctAnswers + total_inCorrectAnswers,
        score: scoreEarned,
        startTime: GameUtils.formatDate(totalQuizTime),
        finishTime: GameUtils.formatDate(new Date()),
        isLastQuizInTopic,
        isLastQuizInTheLastTopic,
      };

      return {
        endOfQuiz: {
          ...content,
        },
      };
    }
  };
  const reactToGameStatus = async () => {
    if (gameStatus === GameStatus.CHECK_ANSWER) {
      await checkAnswer();
    } else if (gameStatus === GameStatus.ENDED) {
      if (isLevelTest && selectedQuiz) {
        startLevelTestGame(levelTestQuizIndex + 1);
        return;
      }
      if (isInEndOfSkillTest) {
        const percentage = commonUtils.calcPercentage(
          total_correctAnswers,
          quizQuestionResultData.length
        );

        const result = {
          questions: quizQuestionResultData.map((question) => {
            return {
              questionId: question.questionId,
              isCorrect: question.isCorrect,
              wrongAnswer: question.wrongAnswer,
              responseTime: question.responseTime,
            };
          }),
          level: user?.currentLevelByskill?.[selectedType as SkillTypes],
          skill: selectedType,
          correctAnswers: total_correctAnswers,
          wrongAnswers: total_inCorrectAnswers,
          isFailed: percentage < 80,
          questionAnswered: quizQuestionResultData.length,
          score: percentage,
          startTime: GameUtils.formatDate(totalQuizTime),
          finishTime: GameUtils.formatDate(new Date()),
        };

        let finalAssessmentResults = null;

        if (selectedType && user?.currentLevelByskill) {
          finalAssessmentResults = await skillService.postEndOfSkillTest(
            user,
            selectedType,
            user?.currentLevelByskill?.[selectedType as SkillTypes],
            result
          );
        }

        const _selectedSkill = selectedSkill;
        const _selectedTopic = selectedTopic;
        const _selectedQuiz = selectedQuiz;

        const props: PopUpEndOfSkillSummaryProps = {
          selectedSkill,
          selectedTopic,
          selectedQuiz,
          correctAnswers: total_correctAnswers,
          totalQuestions: quizQuestionResultData.length,
          coins,
          userData: _selectedQuiz?.userData,
          assessmentResult: finalAssessmentResults,
          emitOnClosePopUp: async (hasFailed: Boolean) => {
            onClosePopUp();
            await commonUtils.sleep(400);

            if (hasFailed && _selectedQuiz && _selectedTopic) {
              handleStartEndOfSkillTest(_selectedTopic);
            }
          },
          emitOnExit: onExitSummaryTest,
          emitOnQuit: onQuit,
        } as PopUpEndOfSkillSummaryProps;

        displayPopUp(PopUpTypes.EndOfSkillSummary, props);
        return;
      } else if (isFinalAssessment) {
        const currentLevel =
          user?.currentLevelByskill?.[selectedType as SkillTypes] ?? 1;

        const newProgress = {
          questions: GameUtils.generateFinalAssessmentQuestionsResults(
            selectedType!,
            currentLevel,
            quizQuestionResultData
          ),
          correctAnswers: total_correctAnswers,
          wrongAnswers: total_inCorrectAnswers,
          questionAnswered: quizQuestionResultData.length,
        };

        const nextFinalAssessmentQuiz =
          finalAssessmentQuizzes?.[finalAssessmentQuizIndex + 1];

        // SAVE QUIZ RESULTS:
        dispatch(finalAssessmentActions.updateProgress(newProgress));

        if (nextFinalAssessmentQuiz) {
          dispatch(finalAssessmentActions.increaseCurrentQuizIndex());

          if (!isSpeak) {
            await commonUtils.sleep(1000);
          }

          dispatch(gameActions.resetGame());

          const type = nextFinalAssessmentQuiz.quiz.skill as SkillTypes;
          const skill = skillSettings.find(
            (i) => i.name?.toLocaleLowerCase() === SkillTypes.Grammar
          );

          dispatch(gameActions.setSelectedSkill({ type, skill }));
          dispatch(gameActions.setSelectedQuiz(nextFinalAssessmentQuiz.quiz));
          dispatch(
            gameActions.startGame({
              gameType: nextFinalAssessmentQuiz.quiz.gameType,
              questions: nextFinalAssessmentQuiz.questions,
            })
          );

          return;
        }

        dispatch(finalAssessmentActions.finishProgress());
        setIsFinalAssessmentFinished(true);

        return;
      }

      const isTeacher = UserUtils.isTeacher(user);

      const _selectedSkill = selectedSkill;
      const _selectedTopic = selectedTopic;
      const _selectedQuiz = selectedQuiz;

      dispatch(authActions.refreshUserProgress());

      dispatch(
        appSettingsActions.clearTopicQuizzes({
          topicId: Number(selectedTopic?.id),
        })
      );

      dispatch(
        appSettingsActions.clearQuestionsProgress({
          topicId: Number(selectedTopic?.id),
          quizId: Number(selectedQuiz?.id),
        })
      );

      try {
        const quizData = buildEndGameBody();

        const progressData = await progressService.sendQuizProgressData(
          user,
          _selectedSkill?.name ?? '',
          _selectedTopic?.id!,
          _selectedQuiz!.id,
          gameType!,
          quizData
        );

        const streak = progressData?.data.data.streak ?? [];

        // Update new streak response
        dispatch(authActions.setStreak(streak));

        await loadUserProgress();
        await dispatch(userThunks.fetchComingUpQuizzes()).unwrap();

        // Refresh skill topics

        await dispatch(topicsThunks.refreshSkillTopics()).unwrap();
        await dispatch(quizzesThunks.refreshTopicQuizzes()).unwrap();
      } catch (error) {
        console.log(error);
      }

      const nextTopicQuiz = await getNextTopicQuiz();

      const props = {
        selectedSkill,
        selectedTopic,
        selectedQuiz,
        correctAnswers: total_correctAnswers,
        totalQuestions: gameQuestions.length,
        coins,
        hasNextQuiz: !!nextTopicQuiz,
        userData: _selectedQuiz?.userData,
        emitOnClosePopUp: async (hasFailed: Boolean) => {
          onClosePopUp();
          await commonUtils.sleep(400);
          if (hasFailed && _selectedQuiz && _selectedTopic) {
            dispatch(gameActions.resetGame());

            if (isRead) {
              dispatch(gameActions.setShowPreQuiz(true));
            }

            fetchQuizProgress(_selectedTopic.id, _selectedQuiz.id);
          } else {
            if (nextTopicQuiz) {
              onReset();
              dispatch(gameActions.setSelectedQuiz(nextTopicQuiz.quiz));
              dispatch(gameActions.setSelectedTopic(nextTopicQuiz.topic));

              fetchQuizProgress(
                nextTopicQuiz?.topic?.id ?? 0,
                nextTopicQuiz.quiz.id
              );
              await commonUtils.sleep(1000);
            } else {
              navigate('/dashboard/games');
            }
          }
        },
        emitOnExit: onExit,
        emitOnQuit: onQuit,
        emitOnAknowledge: onAknowledge,
        emitOnEndOfSkillTest: async () => {
          const nextTopic = getNextTopic();

          if (nextTopic) {
            await handleStartEndOfSkillTest(nextTopic, false);

            onClosePopUp();
          }
        },
        emitStartQuizFromPractice: () => {
          onClosePopUp();
          dispatch(gameActions.resetGame());
          if (_selectedQuiz) {
            dispatch(
              gameActions.startGame({
                gameType: _selectedQuiz.gameType,
                questions: gameQuestions,
              })
            );
          }
        },
        emitPracticeAgain: () => {
          onClosePopUp();
          dispatch(gameActions.resetGame());

          if (_selectedQuiz) {
            dispatch(
              gameActions.startGame({
                gameType: +selectedQuiz.gameType,
                questions: gameQuestions,
              })
            );
          }
          dispatch(gameActions.setPracticeGame());
        },
      } as PopUpSummaryProps;

      addCoins(coins);

      displayPopUp(PopUpTypes.Summary, props);
    } else if (gameStatus === GameStatus.TIME_ENDED) {
      dispatch(gameActions.setRevealTranslation(false));
      // if (selectedType === SkillTypes.Listen && allowRetry) {
      //   dispatch(gameActions.setPictureOverlay(true));
      //   return;
      // }

      // here
      if (!timesUpShown) {
        dispatch(gameActions.setTimesUpShown());
      }

      // Show times up modal

      const props = {
        emitOnClosePopUp: async () => {
          onClosePopUp();
          onDisableRetry();
        },
        isLastQuestion: GameUtils.isLastQuestion(
          gameQuestions,
          currentQuestionIndex
        ),
        allowRetry: allowRetry,
        emitOnNextQuestion: async () => {
          console.log('next question');
          // dispatch(gameActions.incrementIncorrectAnswers());
          onClosePopUp();
          await commonUtils.sleep(400);

          goToNextQuestion();
        },
      } as PopUpTimeIsUpProps;

      console.log(1);
      displayPopUp(PopUpTypes.TimeIsUp, props);
    } else if (
      gameStatus === GameStatus.PAUSED ||
      gameStatus === GameStatus.PAUSED_SIMPLE
    ) {
      const props = {
        currentQuestion: currentQuestionIndex,
        totalQuestions: gameQuestions.length,
        coins,
        emitOnClosePopUp: async () => {
          onClosePopUp();
          await commonUtils.sleep(400);

          dispatch(gameActions.onPlay());
        },
        emitOnQuit: onExit,
      } as PopUpPauseProps;
      displayPopUp(PopUpTypes.Pause, props);
    } else if (gameStatus === GameStatus.SHOW_HINTS) {
      const props = {
        coins: currentCoins,
        emitOnSelectHint: onHintClick,
        emitOnClosePopUp: async () => {
          onClosePopUp();
          await commonUtils.sleep(400);
          dispatch(gameActions.onPlay());
        },
      } as PopUpHintsProps;

      displayPopUp(PopUpTypes.Hints, props);
    } else if (gameStatus === GameStatus.SHOW_WORDS_LIST) {
      const props = {
        emitOnClosePopUp: async () => {
          onClosePopUp();
          await commonUtils.sleep(400);
          dispatch(gameActions.onPlay());
        },
        emitOnAknowledge: onAknowledge,
      } as PopUpWordsListProps;

      displayPopUp(PopUpTypes.WordsList, props);
    }
  };

  const onDisableRetry = async () => {
    dispatch(gameActions.disableRetry());
    await commonUtils.sleep(400);

    dispatch(gameActions.resetTimer());

    // Deduct total incorrect answers and incorrect answers
    // Remove question result data from array
    // Because they are retrying.
    if (
      selectedType === SkillTypes.Spelling ||
      selectedType === SkillTypes.Listen ||
      selectedType === SkillTypes.Speak
    ) {
      dispatch(gameActions.resetCurrentQuestionState());
    }
  };

  const onQuit = async () => {
    onClosePopUp();
    dispatch(gameActions.resetAllSelected());
    onReset();
    navigate('/dashboard/games');
  };

  const onExit = async () => {
    onClosePopUp();
    onReset();

    if (popUpType === PopUpTypes.Pause && isDesktop) {
      navigate('/dashboard/games');
      return;
    }

    navigate(`/dashboard/skill/${selectedType}`);
  };

  const onExitSummaryTest = async () => {
    window.location.href = `/dashboard/skill/${selectedType}`;
  };

  const onStartQuiz = () => {
    dispatch(gameActions.setShowPreQuiz(false));
  };

  const onPractice = () => {
    onStartQuiz();

    if (user?.fromToLanguageParams) {
      dispatch(gameActions.setFromToLang(user?.fromToLanguageParams));
    }

    if (!loadQuizProgress) {
      dispatch(gameActions.setPracticeGame());
    }
  };

  const onAknowledge = (id: number, isKnown: boolean) => {
    dispatch(gameActions.setWordKnowledge({ id, isKnown }));
  };

  const onSwipe = (questionId: number) => {
    dispatch(gameActions.onSwipe(questionId));
  };

  const onLoadLevelTestQuiz = (quiz: Quiz) => {
    if (quiz.questions) {
      dispatch(gameActions.resetGame());
      dispatch(gameActions.setSelectedQuiz(quiz));
      dispatch(
        gameActions.startGame({
          gameType: quiz.gameType,
          questions: quiz.questions,
        })
      );
    }
  };

  useEffect(() => {
    reactToGameStatus();
  }, [gameStatus]);

  useEffect(() => {
    return () => {
      if (!isLevelTest) {
        dispatch(gameActions.resetGame());
      }
    };
  }, []);

  useEffect(() => {
    if (!selectedQuiz) {
      if ((isLevelTest && levelTestQuizzes) || isInEndOfSkillTest) {
        // const firstUnfinished = levelTestQuizzes.find(
        //   (quiz) => !quiz.isLocked && !quiz.isFinished
        // );

        // if (firstUnfinished) {
        //   onLoadLevelTestQuiz(firstUnfinished);
        // }

        return;
      }

      return navigate('/dashboard/games');
    }

    fetchQuizProgress(selectedTopic?.id ?? 0, selectedQuiz.id);
  }, []);

  useEffect(() => {
    if (
      gameStatus === GameStatus.PLAYING &&
      !currentTime &&
      !isLevelTest &&
      !isInEndOfSkillTest &&
      !isFinalAssessment
    ) {
      if (isRead) {
        dispatch(gameActions.endGame());
        return;
      }

      dispatch(gameActions.timeOutGame());
    }
  }, [gameStatus, currentTime]);

  useEffect(() => {
    const postFinalAssessment = async () => {
      const response = await skillService.postFinalAssessment(
        user,
        finalAssessmentProgress!
      );
      setIsFinalAssessmentFinished(false);

      if (response) {
        const props: PopUpFinalAssessmentSummaryProps = {
          emitOnClosePopUp: async () => {
            onClosePopUp();
            dispatch(gameActions.resetGame());
            dispatch(gameActions.setIsFinalAssessmentTest(false));
            dispatch(finalAssessmentActions.reset());
            dispatch(authActions.setIsUserCompletedFinalAssessment(true));

            navigate('/dashboard/games');
            return;
          },
        };

        displayPopUp(PopUpTypes.FinalAssessmentSummary, props);
      }
    };

    if (isFinalAssessment && isFinalAssessmentFinished) {
      postFinalAssessment();
    }
  }, [isFinalAssessment, isFinalAssessmentFinished]);
  // useEffect(() => {
  //   if (gameStatus !== GameStatus.PLAYING) return;
  //   // Check for condition to go to the next question
  //   const isLast = currentQuestionIndex === gameQuestions.length - 1;
  //   const answeredCorrectly = pickedAnswer == currentQuestion.answer;

  //   console.log(
  //     'Use Effect - go to next question',
  //     isTutorNeeded,
  //     isLast,
  //     answeredCorrectly,
  //     mute
  //   );
  //   if ((!isTutorNeeded || isLast) && !answeredCorrectly && !mute) {
  //     console.log(
  //       '(!isTutorNeeded || isLast) && !answeredCorrectly && !mute',
  //       (!isTutorNeeded || isLast) && !answeredCorrectly && !mute
  //     );
  //     goToNextQuestion();
  //   }
  // }, [
  //   dispatch,
  //   isTutorNeeded,
  //   currentQuestionIndex,
  //   gameQuestions,
  //   pickedAnswer,
  //   mute,
  //   gameStatus,
  // ]);

  return {
    loadError,
    loadQuizProgress,
    currentQuestion,
    selectedType,
    selectedTopic,
    selectedSkill,
    selectedQuiz,
    gameStatus,
    timesUpShown,
    focusShown,
    correctAnswers,
    inCorrectAnswers,
    currentQuestionIndex,
    gameQuestions,
    gameType,
    coins,
    selectedHint,
    pickedAnswer,
    currentTime,
    shouldDisplayPopUp,
    popUpType,
    popUpProps,
    isMuted,
    currentCoins,
    allowRetry,
    showPreQuiz,
    showAnswerInOptions,
    onClosePopUp,
    onPause,
    onMute,
    onStartQuiz,
    onPractice,
    onExit,
    onDisableRetry,
    onAknowledge,
    onSwipe,
    goToNextQuestion,
    toggleCheckAnswer,
    currentReadQuestionIndex,
  };
};

export default UseQuiz;
