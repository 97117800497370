import { useTranslation } from 'react-i18next';
import classNames from './MyProfile.module.scss';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

import AchievementsCarousel from './AchievementsCarousel/AchievementsCarousel';
import ProfileCard from './ProfileCard/ProfileCard';
import MyProfileProgress from './MyProfileProgress/MyProfileProgress';
import MyProgress from './MyProgress/MyProgress';
import GoBackBtn from '../GoBackBtn/GoBackBtn';
import UseMyProfile from '@/hooks/UseMyProfile';
import AppLoaderCircle from '../AppLoaderCircle';
import QuizzesList from '../QuizzesList/QuizzesList';

interface MyProfileDesktopProps {
  emitHideProfile: () => void;
}

const MyProfileDesktop = ({ emitHideProfile }: MyProfileDesktopProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const { improvableRecentQuizzes } = useAppSelector(authSelector);

  const { isLoading, allActual, allTotal, skillsOverview, levelAchievements } =
    UseMyProfile();

  const content = isLoading ? (
    <div className="h-full w-full flex justify-center">
      <AppLoaderCircle />
    </div>
  ) : (
    <>
      <div className="flex items-center justify-between">
        <h2 className={classNames.title}>{t('myProfile')}</h2>
        <GoBackBtn
          className={classNames.goBackBtn}
          showIcon={false}
          emitClick={emitHideProfile}
        />
      </div>
      <div className={classNames.partOne}>
        <ProfileCard
          className={classNames.profileCard}
          classroom={user?.groupName}
          level={user?.level}
          grade={user?.grade}
          school={user?.organizationName}
          country={user?.country}
          streak={user?.streak}
        />
        {/* <MyProfileProgress
          className={classNames.myProfileProgress}
          level={user?.level}
          allActual={allActual}
          allTotal={allTotal}
          currentLevelProgress={currentLevelProgress?.metadataProgress}
        /> */}
        <MyProgress
          className={classNames.myProgress}
          skillsOverview={skillsOverview}
        />
      </div>
      {/* <div className={classNames.partTwo}>
        {levelAchievements && (
          <AchievementsCarousel levelAchievements={levelAchievements} />
        )}
      </div> */}
    </>
  );

  return <div className={classNames.myProfileDesktop}>{content}</div>;
};

export default MyProfileDesktop;
