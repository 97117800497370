import { createContext, useEffect, useReducer } from 'react';

type ThemeMode = 'light' | 'dark';

type ThemeState = {
  themeMode: ThemeMode;
  computedStyle: CSSStyleDeclaration | null;
};

type ThemeAction = { type: 'TOGGLE_THEME_MODE' | 'INIT_THEME' };

export type ThemeContextType = ThemeState & {
  toggleThemeMode: () => void;
};

export const ThemeContext = createContext<ThemeContextType | null>(null);

const themeReducer = (state: ThemeState, action: ThemeAction): ThemeState => {
  switch (action.type) {
    case 'INIT_THEME':
      const mode = state.themeMode;
      document.documentElement.setAttribute('theme-mode', mode);

      return {
        ...state,
        themeMode: mode,
        computedStyle: getComputedStyle(document.documentElement),
      };
    case 'TOGGLE_THEME_MODE':
      const themeMode = state.themeMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme-mode', themeMode);
      document.documentElement.setAttribute('theme-mode', themeMode);

      return {
        ...state,
        themeMode,
        computedStyle: getComputedStyle(document.documentElement),
      };
    default:
      return state;
  }
};

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(themeReducer, {
    themeMode: (localStorage.getItem('theme-mode') as ThemeMode) ?? 'light',
    computedStyle: null,
  });

  const toggleThemeMode = () => {
    dispatch({ type: 'TOGGLE_THEME_MODE' });
  };

  useEffect(() => {
    dispatch({ type: 'INIT_THEME' });

    return () => {
      // Cleanup function to toggle back to 'light' theme on unmount
      localStorage.setItem('theme-mode', 'light');
      document.documentElement.setAttribute('theme-mode', 'light');
    };
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        themeMode: state.themeMode,
        toggleThemeMode,
        computedStyle: state.computedStyle,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
