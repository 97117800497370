import UseSpeechRecognition from '@/hooks/UseSpeechRecognition';
import classNames from './PronunciationMicrophone.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import React, { ReactElement, useEffect, useState } from 'react';
import { Question } from '@/types/question';
import commonUtils from '@/utils/common';
import { useDispatch } from 'react-redux';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { GameStatus } from '@/types/game';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';

import AppLoaderCircle from '@/components/AppLoaderCircle';
import AnswerReward from '../../AnswerReward/AnswerReward';

import microphone from '@/assets/svg/microphone.svg';
import check from '@/assets/svg/check.svg';
import wrong from '@/assets/svg/wrong.svg';
import arrow from '@/assets/svg/right-arrow-medium.svg';
import microphoneGray from '@/assets/svg/microphoneGray.svg';
import wrongmic from '@/assets/svg/wrongmic.svg';
import UserUtils from '@/utils/user';
interface PronunciationMicrophoneProps {
  currentQuestion: Question;
  pickedAnswer?: string;
  allowRetry?: boolean;
  emitOnRetry?: () => void;
  emitOnAnswer: (option: string) => void;
  emitOnNextQuestion?: () => void;
  checkIfIsListening: (isListening: boolean) => void;
}

const PronunciationMicrophone = ({
  currentQuestion,
  pickedAnswer,
  allowRetry,
  emitOnRetry,
  emitOnAnswer,
  emitOnNextQuestion,
  checkIfIsListening,
}: PronunciationMicrophoneProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const { user } = useAppSelector(authSelector);

  const isStudent = UserUtils.isStudent(user);

  const {
    text,
    isListening,
    onToggle,
    onReset,
    isUserSpeechEmpty,
    setIsUserSpeechEmpty,
    setIsListening,
  } = UseSpeechRecognition();

  const [showFeedback, setShowFeedback] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const questionTitle = currentQuestion.question?.toLowerCase();
  const questionTitleWordCount = questionTitle.split(' ').length;

  const { checkAnswerCompleted, isFinalAssessment } =
    useAppSelector(gameSelector);

  const onAnswer = () => {
    if (pickedAnswer) {
      // emitOnAnswer(pickedAnswer);
      emitOnNextQuestion?.();
      setShowSubmitBtn(false);
      setShowOverlay(false);
    }
  };

  const onRetry = () => {
    emitOnRetry?.();
    dispatch(gameActions.onPlay());
    setShowSubmitBtn(false);
    setShowOverlay(false);
  };

  const postCheckAnswer = async () => {
    if (isCorrect) {
      await commonUtils.sleep(1500);
      setShowFeedback(false);
      setShowSubmitBtn(false);
      emitOnNextQuestion?.();
      return;
    }

    await commonUtils.sleep(1500);
    setShowFeedback(false);
    setShowSubmitBtn(false);

    if (!isFinalAssessment) {
      setShowOverlay(true);
    }

    if (!allowRetry) {
      if (!isFinalAssessment) {
        await commonUtils.sleep(1500);
      }

      setShowSubmitBtn(false);
      setShowOverlay(false);
      emitOnNextQuestion?.();
    }
  };

  useEffect(() => {
    if (checkAnswerCompleted) {
      postCheckAnswer();
    }
  }, [checkAnswerCompleted]);

  const displayOverlay = async () => {
    if (pickedAnswer) {
      emitOnAnswer(pickedAnswer);
    }
  };

  useEffect(() => {
    if (showFeedback) {
      displayOverlay();
    }
  }, [showFeedback]);

  const setPickedAnswer = async () => {
    setIsProcessing(true);
    await commonUtils.sleep(1000);

    dispatch(gameActions.toggleGameStatus(GameStatus.QUESTION_ANSWERED));
    dispatch(gameActions.setPickedAnswer({ answer: text }));

    const lowerCaseText = text.toLocaleLowerCase();

    const isSimilar = commonUtils.isSimilar(questionTitle, lowerCaseText);

    setIsCorrect(isSimilar);
    onReset();
    setIsProcessing(false);
    setShowFeedback(true);
  };

  useEffect(() => {
    if (text && !isListening) {
      // setShowSubmitBtn(true);
      setPickedAnswer();
    }
    checkIfIsListening(isListening);
  }, [isListening, text]);

  const feedback = (
    <div
      className={classes(classNames.feedback, {
        [classNames.feedbackCorrect]: isCorrect,
        [classNames.feedbackIncorrect]: !isCorrect,
      })}
    >
      <span>{isCorrect ? t('goodJob') : t('incorrect')}</span>
      <div className={classNames.circle}>
        <SVG src={isCorrect ? check : wrong} />
      </div>
    </div>
  );

  const incorrectTextKey =
    questionTitleWordCount === 1
      ? 'theWordWasntPronouncedCorrectly'
      : 'theWordsWerentPronouncedCorrectly';

  const overlay = (
    <div className={classNames.overlay}>
      <div className={classNames.text}>
        <h2>{t('oops')}</h2>
        <span>{t(incorrectTextKey)}</span>
      </div>
      {allowRetry && (
        <div className={classNames.buttons}>
          <button className={classNames.retry} onClick={onRetry}>
            <span>1</span>
          </button>
          <button className={classNames.nextQuestion} onClick={onAnswer}>
            <span></span>
          </button>
        </div>
      )}
    </div>
  );

  const submitBtn = (
    <button className={classNames.submitBtn} onClick={setPickedAnswer}>
      <SVG src={arrow} />
    </button>
  );

  let body = (
    <button
      className={classes(classNames.speakButton, {
        [classNames.speakPulse]: isListening,
      })}
      onClick={onToggle}
    >
      <SVG src={microphone} />
    </button>
  );

  if (isProcessing) {
    body = <AppLoaderCircle className={classNames.loader} />;
  } else if (showFeedback) {
    body = feedback;
  } else if (showOverlay) {
    body = overlay;
  } else if (showSubmitBtn) {
    body = submitBtn;
  }

  let bottom: ReactElement<any, any> | null = !showOverlay ? (
    <span className={classNames.text}>
      {isListening ? t('listeningTapToFinish') : t('tapToSpeak')}
    </span>
  ) : null;

  if (isProcessing) {
    bottom = <span className={classNames.text}>{t('processing')}...</span>;
  } else if (showFeedback) {
    bottom =
      isStudent &&
      isCorrect &&
      currentQuestion.coins &&
      !currentQuestion.userData?.achievedCoins ? (
        <AnswerReward
          className={classNames.answerReward}
          coins={currentQuestion.coins}
        />
      ) : null;
  } else if (showSubmitBtn) {
    bottom = <span className={classNames.text}>{t('sendNow')}</span>;
  }

  if (isUserSpeechEmpty) {
    body = (
      <div className={classNames.pronunciationEmpty}>
        <div className={classNames.errorMessageContainer}>
          <SVG src={wrongmic} />
          <span>{t('didntQuiteHearThat')}</span>
        </div>

        <button
          onClick={() => {
            setIsUserSpeechEmpty(false);
            setIsListening(true);
          }}
        >
          <SVG src={microphoneGray} />
        </button>
      </div>
    );
    bottom = <span className={classNames.text}>{t('clickToTryAgain')}</span>;
  }
  return (
    <div className={classNames.pronunciationMicrophone}>
      <div className={classNames.body}>{body}</div>
      <div className={classNames.bottom}>{bottom}</div>
    </div>
  );
};

export default React.memo(PronunciationMicrophone);
