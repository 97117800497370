import classes from 'classnames';
import { Transition } from 'react-transition-group';
import classNames from './QuizzesModal.module.scss';
import { Quiz } from '@/types/quiz';
import commonUtils from '@/utils/common';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import UseTopicQuizzes from '@/hooks/UseTopicQuizzes';
import { useNavigate } from 'react-router-dom';
import UseSkills from '@/hooks/UseSkills';
import { SkillTypes } from '@/types/skill';

import AppBackdrop from '@/components/AppBackdrop';
import QuizzesModalHeader from './QuizzesModalHeader/QuizzesModalHeader';
import QuizBadge from '@/components/QuizBadge/QuizBadge';
import AppModal from '@/components/AppModal';
import QuizScore from '@/components/QuizScore/QuizScore';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import medalGold from '@/assets/svg/medal-gold.svg';
import lock from '@/assets/svg/lock.svg';
import Skeleton from 'react-loading-skeleton';
import UseLocalLang from '@/hooks/UseLocalLang';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';
import { Question } from '@/types/question';

interface QuizzesModalProps {
  shouldDisplayModal: boolean;
  onCloseModal: () => void;
}

const QuizzesModal = ({
  shouldDisplayModal,
  onCloseModal,
}: QuizzesModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const localLang = UseLocalLang();
  const { user } = useAppSelector(authSelector);

  const isTeacher = UserUtils.isTeacher(user);

  const { skillsAssets } = UseSkills();
  const { selectedType, selectedTopic } = useAppSelector(gameSelector);

  const skillAssets = selectedType && skillsAssets[selectedType];

  const { quizzesGroups, loadingQuizzes, quizzesPerPage, sortedQuizzes } =
    UseTopicQuizzes();

  const goToQuizPage = async (quiz: Quiz) => {
    const isLocked = commonUtils.isQuizLocked(quiz, sortedQuizzes);

    if (isLocked && selectedType !== SkillTypes.Vocabulary && !isTeacher) {
      return;
    }

    if (loadingQuizzes) {
      return;
    }

    dispatch(gameActions.setSelectedQuiz(quiz));
    navigate('/dashboard/quiz');
  };

  const getQuizzesGroup = () => {
    if (loadingQuizzes) {
      return commonUtils.groupBySize(
        Array(selectedTopic?.quizzesCount ?? 0).fill({}),
        quizzesPerPage
      );
    }

    return quizzesGroups;
  };

  const quizzesGroupsElements = getQuizzesGroup().map(
    (quizzesGroup, groupIndex) => {
      const quizzesElements = quizzesGroup.map(
        (quiz: Quiz, quizIndex: number) => {
          const index = groupIndex * quizzesPerPage + quizIndex;
          const isLocked =
            !isTeacher &&
            selectedType !== SkillTypes.Vocabulary &&
            commonUtils.isQuizLocked(quiz, sortedQuizzes);
          const isCompleted = commonUtils.isQuizCompleted(quiz);

          const achievedStars =
            (!isLocked && quiz.userData?.cumulativeAchievedStars) || 0;

          let badgeImgSrc = lock;

          if (!isLocked) {
            badgeImgSrc = !isCompleted ? skillAssets?.medal ?? '' : medalGold;
          }

          return (
            <div
              key={quizIndex}
              className={classes(
                classNames.quiz,
                {
                  [classNames.quizLocked]: isLocked,
                },
                !isLocked ? 'cursor-pointer' : ''
              )}
              onClick={() => goToQuizPage(quiz)}
            >
              {loadingQuizzes ? (
                <Skeleton width="45px" height="45px" circle />
              ) : (
                <QuizBadge
                  img={badgeImgSrc}
                  isLocked={isLocked}
                  index={index}
                  isSmall={true}
                />
              )}
              {loadingQuizzes ? (
                <Skeleton
                  width="70px"
                  style={{ position: 'relative', top: '-10px' }}
                />
              ) : (
                <>
                  <QuizScore
                    isLocked={isLocked}
                    achievedStars={achievedStars}
                  />
                  <span className={classNames.name}>
                    {commonUtils.getName(quiz, localLang)}
                  </span>
                </>
              )}
            </div>
          );
        }
      );

      return (
        <SwiperSlide key={groupIndex} className={classNames.quizzesGrid}>
          {quizzesElements}
        </SwiperSlide>
      );
    }
  );

  return (
    <>
      <Transition
        in={shouldDisplayModal}
        timeout={400}
        mountOnEnter
        unmountOnExit
        children={(state) => (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
              emitClose={onCloseModal}
            />
            <AppModal>
              <div
                className={classes(classNames.modal, `fade-${state}`)}
                style={{
                  backgroundColor: skillAssets?.containerBackgroundColor,
                  borderColor: skillAssets?.modalBorderColor,
                }}
              >
                <div
                  className={classNames.modalContentInner}
                  style={{
                    borderColor: skillAssets?.modalInnerBorderColor,
                  }}
                >
                  <QuizzesModalHeader
                    loadingQuizzes={loadingQuizzes}
                    emitOnClose={onCloseModal}
                  />
                  <div className={classNames.modalBody}>
                    <Swiper
                      modules={[Pagination]}
                      className={classNames.quizzesSwiper}
                      spaceBetween={10}
                      slidesPerView={1}
                      draggable={true}
                      pagination={{
                        clickable: true,
                        horizontalClass: classNames.swiperPaginationItem,
                        bulletClass: classes(classNames.bulletItem, {
                          [classNames[`bulletItem-${selectedType}`]]: true,
                        }),
                        bulletActiveClass: classes(
                          classNames.bulletItemActive,
                          {
                            [classNames[`bulletItemActive-${selectedType}`]]:
                              true,
                          }
                        ),
                      }}
                    >
                      {quizzesGroupsElements}
                    </Swiper>
                  </div>
                </div>
              </div>
            </AppModal>
          </>
        )}
      />
    </>
  );
};

export default QuizzesModal;
