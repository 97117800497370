import classNames from './UserData.module.scss';
import classes from 'classnames';
import commonUtils from '@/utils/common';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useMemo } from 'react';
import { SkillTypes } from '@/types/skill';
import { UserState } from '@/types/user';
import UseLevel from '@/hooks/UseLevel';
import UseSkills from '@/hooks/UseSkills';
import {
  StudentProgressBySkill,
  StudentsSkillsProgress,
} from '@/types/progress';
import UseThemeColors from '@/hooks/UseThemeColors';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import DataContainer from './DataContainer/DataContainer';
import EditContainer from './EditContainer/EditContainer';
import SkillData from './SkillData/SkillData';
import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';

import pencil from '@/assets/svg/pencil.svg';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import Assessments from './Assessments/Assessments';
import downLoadSvg from '../../../assets/svg/download.svg';
import GenerateReport from './GenerateReport/GenerateReport';
import { ReportTypes } from '../../../types/generate-report';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

interface UserDataProps {
  isLoadingProgress?: boolean;
  showEdit?: boolean;
  showSkillData?: boolean;
  selectedStudent?: UserState | null;
  showAssessments?: boolean;
  selectedSkill?: {
    value: SkillTypes;
    progress: StudentProgressBySkill;
  } | null;
  emitShowSkillData?: (skill: SkillTypes) => void;
  emitHideSkillData?: () => void;
  emitShowAssessments: () => void;
  emitHideAssessment: () => void;
  emitShowEdit?: () => void;
  emitSaveEdit?: (
    level: string,
    language: string,
    selectedLevelsBySkills: StudentsSkillsProgress | null
  ) => void;
  emitCloseEdit?: () => void;
}

const UserData = ({
  isLoadingProgress,
  level,
  firstName,
  lastName,
  grade,
  className,
  groupName,
  lang,
  interfaceLanguage,
  nativeLanguage,
  streak,
  skillsOverview,
  studentProgress,
  showSkillData,
  selectedSkill,
  selectedStudent,
  showAssessments,
  organizationName,
  showEdit,
  currentLevelByskill,
  emitShowSkillData,
  emitHideSkillData,
  emitShowAssessments,
  emitHideAssessment,
  emitShowEdit,
  emitCloseEdit,
  emitSaveEdit,
}: UserState & UserDataProps) => {
  const { t } = useTranslation();
  const { color } = UseThemeColors();
  const { getSkillName } = UseSkills();
  const { getLevelText } = UseLevel();
  const appInterface = useAppSelector(interfaceLanguageSelector);
  console.log(appInterface?.direction);
  const levelText = getLevelText(level);
  const colors = useMemo(
    () =>
      selectedSkill ? commonUtils.getProgressColors(selectedSkill.value) : null,
    [selectedSkill]
  );

  const skill = useMemo(
    () =>
      selectedStudent && selectedSkill
        ? Object.entries(skillsOverview ?? {}).find(
            (entry) => entry[0] === selectedSkill.value
          )
        : null,
    [selectedStudent, selectedSkill]
  );

  const actual = skill ? skill[1].completedQuizzes : 0;
  const total = skill ? skill[1].totalQuizzes : 0;
  const percentage = +commonUtils.calcPercentage(actual, total) ?? 0;

  const isUniversityGrade = grade.toLowerCase() === 'university';
  const isNoneGrade = grade.toLowerCase() === 'none';

  let gradeValue = null;

  if (!isNoneGrade) {
    if (isUniversityGrade) {
      gradeValue = t('university');
    } else {
      gradeValue =
        isNaN(+grade) || Number.isInteger(+grade)
          ? commonUtils.fixGradeSymbol(grade)
          : grade;
    }
  }

  const top = showSkillData ? (
    <>
      <GoBackBtn
        className={classNames.goBackBtn}
        emitClick={() => emitHideSkillData?.()}
      />
      <div className={classNames.skill}>
        <div
          className={classNames.indicator}
          style={{ backgroundColor: color(colors?.trailColor ?? '') }}
        >
          {selectedSkill && (
            <SVG
              className={classNames.img}
              src={commonUtils.skillImage(selectedSkill.value)}
            />
          )}
          {colors && (
            <CircularProgressbar
              className={classNames.progressBar}
              value={percentage}
              strokeWidth={12}
              styles={buildStyles({
                pathTransition: 'none',
                strokeLinecap: 'butt',
                trailColor: color(colors.trailColor),
                pathColor: color(colors.pathColor),
              })}
            />
          )}
        </div>
        <div className={classNames.texts}>
          <div className={classNames.nameAndPercentage}>
            {selectedSkill && (
              <span className={classNames.name}>
                {getSkillName(selectedSkill.value)}
              </span>
            )}
            <span className={classNames.percentage}>{`${percentage}%`}</span>
          </div>
          <div className={classNames.user}>
            <span>{`${selectedStudent?.firstName} ${selectedStudent?.lastName}`}</span>
            <DetailSeperator />
            <span>{`${t('level')} ${selectedStudent?.level}`}</span>
          </div>
        </div>
      </div>
    </>
  ) : false ? ( //todo need to return this to showAssessments instead of false
    <>
      <GoBackBtn emitClick={emitHideAssessment} />
    </>
  ) : (
    <>
      {/* <button
        onClick={handleGenerateStudentReport}
        className={classNames.generateReportContainer}
      >
        <SVG src={downLoadSvg} />
        <h2 className={classNames.generateReport}>{t('generateReport')}</h2>
      </button> */}
      <GenerateReport
        currentStudent={selectedStudent}
        reportType={ReportTypes.student}
      />
      <div className={classNames.profile}>
        <UserAvatar
          className={classNames.avatar}
          userFirstName={selectedStudent?.firstName ?? ''}
          allowEdit={false}
        />
        <h2>{`${firstName} ${lastName}`}</h2>
        <div className={classNames.details}>
          {grade && <span>{`${gradeValue} ${t('grade')}`}</span>}
          {className ? (
            <>
              <DetailSeperator className={classNames.seperator} />
              <span>{className}</span>
            </>
          ) : null}
        </div>
      </div>
      {Number(level) > 0 && (
        <button
          className={classes(classNames.edit, {
            [classNames.editActive]: showEdit,
            [classNames.editRtl]: appInterface?.direction === 'rtl',
          })}
          onClick={emitShowEdit}
        >
          <SVG src={pencil} />
        </button>
      )}
    </>
  );

  const body = showEdit ? (
    <EditContainer
      level={level?.toString() ?? ''}
      interfaceLanguage={interfaceLanguage}
      nativeLanguage={nativeLanguage}
      currentLevelByskill={currentLevelByskill}
      studentName={firstName}
      emitSaveEdit={emitSaveEdit}
      emitCloseEdit={emitCloseEdit}
    />
  ) : showSkillData && selectedSkill ? (
    <SkillData
      selectedSkill={selectedSkill}
      selectedStudent={selectedStudent}
    />
  ) : false ? ( //todo need to return this to showAssessments instead of false
    <Assessments student={selectedStudent} />
  ) : studentProgress ? (
    <DataContainer
      groupName={organizationName ?? 'N/A'}
      level={level?.toString() ?? ''}
      levelText={levelText ?? ''}
      language={t(interfaceLanguage) ?? 'N/A'}
      progress={studentProgress}
      streak={streak}
      skillsOverview={skillsOverview}
      emitShowSkillData={emitShowSkillData}
      emitShowAssessments={emitShowAssessments}
      selectedStudent={selectedStudent}
    />
  ) : null;

  const content = isLoadingProgress ? (
    <div className="h-full w-full flex items-center justify-center">
      <AppLoaderCircle />
    </div>
  ) : (
    <>
      <div className={classNames.top}>{top}</div>
      <div className={classNames.body}>{body}</div>
    </>
  );

  return <div className={classNames.userData}>{content}</div>;
};

export default UserData;
