import classNames from './MyProfile.module.scss';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { SkillTypes } from '@/types/skill';
import { Topic } from '@/types/topic';
import UseMyProfile from '@/hooks/UseMyProfile';

import Navbar from '../Navbar/Navbar';
import UserInfo from '../UserInfo/UserInfo';
import ProfileInfo from '../ProfileInfo/ProfileInfo';
import UserStreak from '../UserStreak/UserStreak';
import AchievementsCarousel from './AchievementsCarousel/AchievementsCarousel';
import ProfileProgress from '../ProfileProgress/ProfileProgress';
import BadgesSection from '../BadgesSection/BadgesSection';
import QuizzesSection from '../QuizzesSection/QuizzesSection';
import ProfileSkillsProgress from '../ProfileSkillsProgress/ProfileSkillsProgress';
import commonUtils from '@/utils/common';
import ProfileCard from './ProfileCard/ProfileCard';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import MyProgress from './MyProgress/MyProgress';

const MyProfileMobile = () => {
  const { user, userProgress } = useAppSelector(authSelector);
  const { allActual, allTotal, levelAchievements, skillsOverview } =
    UseMyProfile();
  const currentRoute = UseCurrentRoute();

  // const {
  //   completedTopics,
  //   totalTopics,
  //   completedQuizzes,
  //   totalQuizzes,
  //   achievedStars,
  //   totalStars,
  // } = currentLevelProgress?.metadataProgress ?? {};

  const comingUpQuizzes = [
    {
      skill: SkillTypes.Grammar,
      title: 'Quiz 3',
      topic: 'Verbs',
    },
    {
      skill: SkillTypes.Speak,
      title: 'Quiz 1',
      topic: 'About me',
    },
    {
      skill: SkillTypes.Spelling,
      title: 'Quiz 4',
      topic: 'Vowels',
    },
    {
      skill: SkillTypes.Read,
      title: 'Quiz 11',
      topic: 'Difficulty A',
    },
    {
      skill: SkillTypes.Listen,
      title: 'Quiz 5',
      topic: 'Difficulty C',
    },
  ];

  const badges = [
    {
      skill: SkillTypes.Grammar,
      topic: {
        name: 'Verbs and stuff',
        nameLocal: 'Verbs and stuff',
      } as Topic,
    },
    {
      skill: SkillTypes.Listen,
      topic: { name: 'Syntax', nameLocal: 'Syntax' } as Topic,
    },
    {
      skill: SkillTypes.Spelling,
      topic: { name: 'Vowels', nameLocal: 'Vowels' } as Topic,
    },
  ];

  return (
    <div className={classNames.myProfileMobile}>
      <Navbar
        showArrowIcon={currentRoute === ROUTE_NAMES.PROFILE}
        className={classNames.navbar}
      />
      {user && (
        <UserInfo
          fullname={`${user.firstName} ${user.lastName}`}
          profileImage={user.avatar?.avatarName}
          backgroundColor={user.avatar?.avatarColor}
          notificationsAmount={3}
          level={user.level}
          grade={user?.grade.toString()}
          badges={user.achievements?.badges}
          coins={user.achievements?.coins}
        />
      )}
      <div className="flex flex-col gap-4">
        {/* <ProfileInfo
          completedTopics={completedTopics}
          totalTopics={totalTopics}
          completedQuizzes={completedQuizzes}
          totalQuizzes={totalQuizzes}
          achievedStars={achievedStars}
          totalStars={totalStars}
        /> */}
        <ProfileCard
          className={classNames.profileCard}
          classroom={user?.groupName}
          level={user?.level}
          grade={user?.grade}
          school={user?.organizationName}
          country={user?.country}
          streak={user?.streak}
        />
        {user?.streak && <UserStreak showTitle={false} streak={user.streak} />}
      </div>
      {/* <div className={classNames.profileProgress}> */}
      {/* <ProfileProgress
          className="flex-1"
          showLevelInfo={false}
          level={user?.level}
          allActual={allActual}
          allTotal={allTotal}
          isMyProfile={true}
        /> */}
      <ProfileSkillsProgress progress={userProgress} />
      {/* </div> */}
      <MyProgress
        className={classNames.myProgress}
        skillsOverview={skillsOverview}
      />
      <QuizzesSection />
      {/* {levelAchievements && (
        <AchievementsCarousel levelAchievements={levelAchievements} />
      )} */}
      {/* <QuizzesSection comingUpQuizzes={comingUpQuizzes} /> */}
      {/* <BadgesSection badges={badges} /> */}
    </div>
  );
};

export default MyProfileMobile;
