import classNames from './TopicAccordion.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { StudentQuizzProgress } from '@/types/progress';
import commonUtils from '@/utils/common';
import { useEffect, useRef, useState } from 'react';

import Rating from '@/components/Rating/Rating';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';

import trophy from '@/assets/svg/trophy.svg';
import medal from '@/assets/svg/medal-gold.svg';
import star from '@/assets/svg/star.svg';
import clock from '@/assets/svg/clock.svg';
import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import pencil from '@/assets/svg/pencil.svg';

interface TopicAccordionProps {
  name: string;
  actual: number;
  total: number;
  quizzes: StudentQuizzProgress[];
}

const TopicAccordion = ({
  quizzes,
  actual,
  total,
  name,
}: TopicAccordionProps) => {
  const { t } = useTranslation();

  const [shouldExpand, setShouldExpand] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number | string>('');

  const topRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const topRefHeight = topRef.current?.clientHeight;
    const bodyRefHeight = bodyRef.current?.clientHeight;

    if (topRefHeight && bodyRefHeight) {
      setMaxHeight(() =>
        shouldExpand ? topRefHeight + bodyRefHeight : topRefHeight
      );
    }
  }, [shouldExpand]);

  const onToggle = () => {
    setShouldExpand((prevState) => !prevState);
  };

  const onEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  const quizzesList = quizzes
    .sort((curr, next) => curr.quizOrder - next.quizOrder)
    .map((quiz, index) => {
      const { quizName, achivedScore, achivedStars, numberOfQuestionsInQuiz } =
        quiz;
      const isLast = index === quizzes.length - 1;

      return (
        <div key={index} className={classNames.quiz}>
          <div className={classNames.content}>
            <span className={classNames.order}>{index + 1}.</span>
            <div className={classNames.partOne}>
              <span className={classNames.name}>{quizName}</span>
              <Rating
                className={classNames.rating}
                achievedStars={achivedStars}
              />
              <DetailSeperator className={classNames.seperator} />
              <span className={classNames.score}>{`${t(
                'score'
              )}: ${achivedScore}`}</span>
            </div>
            <div className={classNames.partTwo}>
              <span
                className={classNames.questions}
              >{`${numberOfQuestionsInQuiz} ${t('qs')}`}</span>
              {/* <DetailSeperator />
              <SVG
                className={classes(classNames.clock, {
                  [classNames.clockActive]: ,
                })}
                src={clock}
              /> */}
            </div>
          </div>
          {!isLast && <SectionSeperator className="my-2" />}
        </div>
      );
    });

  const percentage = commonUtils.calcPercentage(actual, total);

  const arrowSrc = shouldExpand ? upArrow : downArrow;

  return (
    <div className={classNames.topicAccordion} style={{ maxHeight }}>
      <div ref={topRef} className={classNames.top}>
        <div className="flex items-start flex-col gap-2">
          <div className={classNames.nameAndPercentage}>
            <span className="font-bold">{name + ' '}</span>
            <span>| {percentage}%</span>
          </div>
          <span className={classNames.totalQuizzes}>{`${actual}/${total} ${t(
            'totalQuizzes'
          )}`}</span>
        </div>
        <div className="flex flex-1 justify-between flex-col gap-2">
          <div className="self-end flex items-center gap-2">
            {/* <button className={classNames.pencil} onClick={onEdit}>
              <SVG src={pencil} />
            </button> */}
            <button className={classNames.arrow} onClick={onToggle}>
              <SVG src={arrowSrc} />
            </button>
          </div>
          <div className={classNames.progress}>
            <div
              className={classNames.bar}
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
        </div>
      </div>
      <div ref={bodyRef} className={classNames.body}>
        {quizzesList}
      </div>
    </div>
  );
};

export default TopicAccordion;
