import { Question } from '@/types/question';
import classNames from './ClosedSpellingOptions.module.scss';
import classes from 'classnames';
import { Block, ClosedSpellingProps } from '@/types/game';
interface ClosedSpellingOptionsProps {
  answerBlocks: Block[];
  optionBlocks: Block[];
  isCorrect: boolean;
  isFilled: boolean;
  emitOnSelect: (block: Block) => void;
  emitOnRemove: (block: Block) => void;
}

const ClosedSpellingOptions = ({
  answerBlocks,
  optionBlocks,
  isCorrect,
  isFilled,
  emitOnSelect,
  emitOnRemove,
}: ClosedSpellingOptionsProps) => {
  const answerBlocksElements = answerBlocks.map((block, index) => (
    <div
      key={index}
      className={classes(classNames.block, classNames.answerBlock, {
        [classNames.answerBlockFilled]: block?.value,
        [classNames.answerBlockCorrect]: isFilled && isCorrect,
        [classNames.answerBlockWrong]: isFilled && !isCorrect,
      })}
      onClick={() => emitOnRemove(block)}
    >
      {block?.value && block.isRemovable === true && !isFilled && (
        <button className={classNames.removeBtn}>
          <span></span>
        </button>
      )}
      <span>{block?.value}</span>
    </div>
  ));

  const optionsElements = optionBlocks.map((option) => {
    return (
      <div
        key={option.id}
        className={classes(classNames.block, classNames.optionBlock, {
          [classNames.optionBlockSelected]: option.isDisabled,
        })}
        onClick={() => (option.isDisabled ? null : emitOnSelect(option))}
      >
        <span>{option?.value}</span>
      </div>
    );
  });

  return (
    <div className={classNames.closedSpellingOptions}>
      <div className={classNames.answerBlocks}>{answerBlocksElements}</div>
      <div className={classNames.optionsBlocks}>{optionsElements}</div>
    </div>
  );
};

export default ClosedSpellingOptions;
