import classNames from './UserInfo.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

import Badge from './Badge/Badge';
import Detail from './Detail/Detail';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import UserAvatar from '@/components/UserAvatar/UserAvatar';

import blueStar from '@/assets/svg/star.svg';
import coin from '@/assets/svg/coin.svg';
import UseMyProfile from '@/hooks/UseMyProfile';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import UseLevel from '@/hooks/UseLevel';
import UseScreenSize from '@/hooks/UseScreenSize';
import commonUtils from '@/utils/common';

interface UserInfoProps {
  isTeacher?: boolean;
  isMobile?: boolean;
  fullname: string;
  profileImage?: string | null;
  backgroundColor?: string | null;
  notificationsAmount?: number;
  level?: number;
  grade?: string;
  coins?: number;
  badges?: number;
  classesAmount?: number;
  studentsAmount?: number;
  schoolName?: string;
}

const UserInfo = ({
  isTeacher = false,
  isMobile = false,
  profileImage,
  backgroundColor,
  fullname,
  notificationsAmount,
  level,
  grade,
  coins,
  badges,
  classesAmount,
  studentsAmount,
  schoolName,
}: UserInfoProps) => {
  const { t } = useTranslation();
  const currentRoute = UseCurrentRoute();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  //doesn't exist anymore in the backend
  const { totalStars } = UseMyProfile();
  const { getLevelText } = UseLevel();
  const levelText = getLevelText(level);
  const { isDesktop } = UseScreenSize();
  const isMobileAndRouteIsProfile =
    !isDesktop && currentRoute === ROUTE_NAMES.PROFILE;

  const isProfilePage = currentRoute === ROUTE_NAMES.PROFILE;

  const shouldDisplaySecondDetail = isTeacher || (!isTeacher && grade);

  const userGrade = grade ?? '';
  let gradeText = '';

  const isUniversityGrade = grade?.toLowerCase() === 'university';
  const isNoneGrade = grade?.toLowerCase() === 'none';

  if (isTeacher) {
    gradeText = studentsAmount?.toString() ?? '';
  } else if (!isNoneGrade) {
    if (isUniversityGrade) {
      gradeText = t('university');
    } else {
      gradeText =
        isNaN(+userGrade) || Number.isInteger(+userGrade)
          ? t(`grade_${commonUtils.fixGradeSymbol(userGrade)}`)
          : userGrade;
    }
  }

  return (
    <header
      className={cls(classNames.header, { [classNames.mobile]: isMobile })}
    >
      <UserAvatar
        className={classNames.profileImage}
        img={profileImage}
        backgroundColor={backgroundColor}
        userFirstName={fullname}
        allowEdit={isTeacher || isProfilePage}
        // notificationsAmount={notificationsAmount}
      />
      <div
        className={classes(classNames.info, [
          classNames[`info-${interfaceLanguage?.direction}`],
        ])}
      >
        <h2 className={classNames.fullname}>{fullname}</h2>

        {!isMobileAndRouteIsProfile && (
          <>
            {isTeacher && schoolName && <h1>{schoolName}</h1>}
            <div className={classNames.details}>
              <Detail
                topic={isTeacher ? t('classes') : t('level')}
                value={isTeacher ? classesAmount ?? 0 : levelText}
              />
              {gradeText && (
                <>
                  <DetailSeperator />
                  <Detail
                    topic={isTeacher ? t('students') : t('grade')}
                    value={gradeText}
                  />
                </>
              )}
            </div>
          </>
        )}
        {!isTeacher &&
          badges !== undefined &&
          coins !== undefined &&
          !isMobileAndRouteIsProfile && (
            <div className={classNames.badges}>
              <Badge type="stars" image={blueStar} amount={totalStars} />
              <Badge type="coins" image={coin} amount={coins} />
            </div>
          )}
      </div>
    </header>
  );
};

export default UserInfo;
