import { createAsyncThunk } from '@reduxjs/toolkit';
import { skillService } from '@/services';
import { RootState } from '@/types/common';
import commonUtils from '@/utils/common';
import { FinalAssessmentQuiz } from '@/types/final-assessment';
import { SkillTypes } from '@/types/skill';

const finalAssessmentThunks = {
  fetchFinalAssessment: createAsyncThunk(
    'skill/fetchFinalAssessment',
    async (_, { getState }) => {
      try {
        const state = getState() as RootState;
        const user = state.auth.user;

        if (user) {
          const quizzes = await skillService.fetchFinalAssessment(user);
          const sortedQuizzes: FinalAssessmentQuiz[] = commonUtils
            .sortByKey(quizzes ?? [], 'order')
            .map((quizData: FinalAssessmentQuiz) => {
              if (quizData.quiz.skill === SkillTypes.Read) {
                quizData.questions.map((question) => {
                  const options = [
                    ...question.distractors.split(','),
                    question.answer,
                  ];

                  question.options = commonUtils.shuffleArray(options ?? []);

                  return question;
                });
              }

              return quizData;
            });

          return sortedQuizzes;
        }
        return null;
      } catch (error) {
        throw error;
      }
    }
  ),
};

export default finalAssessmentThunks;
