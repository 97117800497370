import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_EN } from './en/translation';
import { TRANSLATIONS_PT } from './pt/translation';
import { TRANSLATIONS_ES } from './es/translation';
import { TRANSLATIONS_HE } from './he/translation';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    pt: {
      translation: TRANSLATIONS_PT,
    },
    es: {
      translation: TRANSLATIONS_ES,
    },
    he: {
      translation: TRANSLATIONS_HE,
    },
  },
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
