import { useTranslation } from 'react-i18next';
import classNames from './ActivityCircleChart.module.scss';
import {
  GenerateReportProps,
  ReportTypes,
} from '../../../../../types/generate-report';
import classes from 'classnames';
import SkillCircle from '../SkillCircle';

const ActivityCircleChart = ({
  reportType,
  reportContent,
}: GenerateReportProps) => {
  const { t } = useTranslation();
  const isStudentReport = reportType === ReportTypes.student;
  // if (reportContent?.activity) {
  //   const values = Object.values(reportContent?.activity);
  //   console.log(values);
  //   const sum = values.reduce((a, b) => a + b, 0);
  //   console.log(sum);
  //   const left = 100 - sum;
  //   const up = left / values.length;
  //   console.log(up);
  // }
  const skills = [
    'grammar',
    'vocabulary',
    'read',
    'listen',
    'speak',
    'spelling',
  ];
  return (
    <div className={classNames.studentActivityCard}>
      <h1>{isStudentReport ? t('studentActivity') : t('classActivity')}</h1>
      <h2>{t('perSkill')}</h2>

      <div className={classNames.circleContainer}>
        <div className={classNames.circle}>
          <SkillCircle skills={reportContent?.activity as any} />
        </div>
        <ul className={classNames.listContainer}>
          {reportContent &&
            skills.map((skill) => (
              <div className={classNames.skill}>
                <div
                  className={classes(classNames.skillColor, {
                    [classNames[
                      `skillColor-${skill[0].toUpperCase() + skill.slice(1)}`
                    ]]: true,
                  })}
                />
                <li>{t(`skills.${skill}`)}</li>
              </div>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ActivityCircleChart;
