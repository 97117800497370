import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AuthState,
  Streak,
  TeacherGroup,
  UserAvatar,
  UserState,
} from '@/types/user';
import {
  LanguageSettingsResponse,
  LevelSettingsResponse,
  LevelTestResultsResponse,
  MeResponse,
  SkillSettingsResponse,
  SkillsProgressResponse,
  ImprovableRecentQuizzesResponse,
  ComingUpQuizzesResponse,
  LevelTestResultResponse,
} from '@/types/response';
import { RootState } from '@/types/common';
import userThunks from '../thunks/user';
import { SkillsProgress } from '@/types/progress';
import { EditGroupDetails } from '@/types/register';
import ReactGA from 'react-ga4';
import { EventNames } from '@/types/analytics';
import commonUtils from '@/utils/common';

const defaultAuthState: AuthState = {
  appLoaded: false,
  loggedIn: false,
  user: null,
  userProgress: null,
  skillSettings: [],
  showWelcomeScreen: false,
  isNewlyRegistered: false,
  showAppRecommandationScreen: false,
  showAvatarSelection: false,
  isFromRegistration: false,
  displayDictionary: false,
  shouldStartFinalAssessment: false,
  token: localStorage.getItem('token'),
  idToken: localStorage.getItem('idToken'),
  userId: localStorage.getItem('userId'),
  nativeLanguage: localStorage.getItem('native-language') ?? 'pt',
  studyingLanguage: localStorage.getItem('studying-language') ?? 'en',
  interfaceLanguage: localStorage.getItem('interface-language'),
  levels: [],
  languages: [],
  improvableRecentQuizzes: [],
  comingUpQuizzes: [],
};

const auth = createSlice({
  name: 'Auth',
  initialState: defaultAuthState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setIdToken: (state, action: PayloadAction<string>) => {
      state.idToken = action.payload;
    },
    setStreak: (state, action: PayloadAction<Streak[]>) => {
      state.user = {
        ...(state.user as UserState),
        streak: action.payload,
      };
    },
    setUserLevel: (state, action: PayloadAction<number>) => {
      state.user = {
        ...(state.user as UserState),
        level: action.payload,
      };
    },
    setDictionaryDisplay: (state, action: PayloadAction<boolean>) => {
      state.displayDictionary = action.payload;
    },
    setNativeLanguage: (state, action: PayloadAction<string>) => {
      state.nativeLanguage = action.payload;
      localStorage.setItem('native-language', action.payload);
    },
    setInterfaceLanguage: (state, action: PayloadAction<string>) => {
      state.interfaceLanguage = action.payload;
      localStorage.setItem('interface-language', action.payload);

      state.user = {
        ...(state.user as UserState),
        interfaceLanguage: action.payload,
      };
    },
    setStudyingLanguage: (state, action: PayloadAction<string>) => {
      state.studyingLanguage = action.payload;
      localStorage.setItem('studying-language', action.payload);
    },
    signOut: (state) => {
      /// Track hint used event
      ReactGA.event(EventNames.Logout);

      state.loggedIn = false;
      state.user = null;
      state.token = null;
      state.userProgress = null;
      localStorage.removeItem('loggedIn');
      localStorage.removeItem('userId');
      localStorage.removeItem('token');
      localStorage.removeItem('idToken');
    },
    refreshUserProgress: (state) => {
      state.userProgress = null;
    },
    signIn: (
      state,
      action: PayloadAction<{ token: string; userId: string }>
    ) => {
      /// Track hint used event
      ReactGA.event(EventNames.Login);

      const { token, userId } = action.payload;

      state.token = token;
      state.userId = userId;
      state.showWelcomeScreen = true;
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
    },
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setUserProgress: (
      state,
      action: PayloadAction<{ userProgress: SkillsProgress }>
    ) => {
      state.userProgress = action.payload.userProgress;
    },
    appLoaded: (state) => {
      state.appLoaded = true;
    },
    setUserAfterLevelTest: (
      state,
      action: PayloadAction<LevelTestResultResponse>
    ) => {
      if (state.user !== null) {
        state.user.achievements.coins =
          action.payload.userLevel?.startingReward ?? 0;
        state.user.level = action.payload.userLevel?.userGeneralLevel;
        state.showAvatarSelection = state.user.avatar ? false : true;
        state.user.currentLevelByskill =
          action.payload.userLevel?.currentLevelByskill;

        /// Track level test completed event
        const levelEventMapping: { [key: number]: string } = {
          1: EventNames.Leveltest_result_1,
          2: EventNames.Leveltest_result_2,
          3: EventNames.Leveltest_result_3,
          4: EventNames.Leveltest_result_4,
          5: EventNames.Leveltest_result_5,
        };

        ReactGA.event(
          levelEventMapping[action.payload.userLevel?.userGeneralLevel ?? 1]
        );
      }
    },

    setShowWelcomeScreen: (state, action: PayloadAction<boolean>) => {
      state.showWelcomeScreen = action.payload;
    },
    setShowAppRecommandationScreen: (state, action: PayloadAction<boolean>) => {
      state.showAppRecommandationScreen = action.payload;
    },
    setIsNewlyRegistered: (state, action: PayloadAction<boolean>) => {
      state.isNewlyRegistered = action.payload;
    },
    setShowAvatarSelection: (state, action: PayloadAction<boolean>) => {
      state.showAvatarSelection = action.payload;
    },
    setAvatar: (state, action: PayloadAction<UserAvatar>) => {
      if (state.user) {
        state.user.avatar = action.payload;
      }
    },
    setUserCoins: (state, action: PayloadAction<number>) => {
      if (state.user && state.user.achievements) {
        state.user.achievements.coins = action.payload;
      }
    },
    setIsFromRegistration: (state, action: PayloadAction<boolean>) => {
      state.isFromRegistration = action.payload;
    },
    addNewClass: (state, action: PayloadAction<TeacherGroup>) => {
      state.user?.teacherGroups?.push(action.payload);
    },
    updateTeacherClass: (state, action: PayloadAction<EditGroupDetails>) => {
      if (state.user?.teacherGroups) {
        const { groupId: id, grade, groupName: name } = action.payload;

        const groupIndex = state.user.teacherGroups.findIndex(
          (i) => i.id === id
        );

        if (Number.isInteger(groupIndex)) {
          state.user.teacherGroups[groupIndex].name = name;
          state.user.teacherGroups[groupIndex].grade = grade;
        }
      }
    },
    setIsCreateOrgComplete: (state, action: PayloadAction<boolean>) => {
      if (state.user) {
        state.user.isCreateOrgComplete = action.payload;
      }
    },
    setIsUserCompletedFinalAssessment: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (state.user) {
        state.user.isUserCompletedFinalAssessment = action.payload;
      }
    },
    setShouldStartFinalAssessment: (state, action: PayloadAction<boolean>) => {
      if (state.user) {
        state.shouldStartFinalAssessment = action.payload;
      }
    },
    updatedClassroomStudentsAmount: (
      state,
      action: PayloadAction<{ id: string; amount: number }>
    ) => {
      const { id, amount } = action.payload;

      if (state.user?.teacherGroups) {
        const groupIndex = state.user.teacherGroups.findIndex(
          (i) => i.id === id
        );

        if (Number.isInteger(groupIndex)) {
          state.user.teacherGroups[groupIndex].totalStudents =
            (state.user.teacherGroups[groupIndex].totalStudents ?? 0) + amount;
        }
      }
    },
    // setUserToken: (state, action: PayloadAction<{ token: String }>) => {
    //   state.token = action.payload.token;
    // },
    // setUserId: (state, action: PayloadAction<{ userId: String }>) => {
    //   state.userId = action.payload.userId;
    // },
  },
  extraReducers: {
    // USER INFO EXTRA REDUCERS
    [userThunks.userInfo.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<MeResponse>
    ) => {
      let grade = null;

      const isUniversityGrade =
        payload.data.grade?.toLowerCase() === 'university';
      const isNoneGrade = payload.data.grade?.toLowerCase() === 'none';

      if (payload.data.grade) {
        if (isUniversityGrade || isNoneGrade) {
          grade = payload.data.grade;
        } else {
          grade = isNaN(+payload.data.grade)
            ? commonUtils.fixGradeSymbol(payload?.data.grade ?? '1')
            : payload?.data.grade;
        }
      }

      const user = {
        ...payload.data,
        grade,
      };

      const userId = state.userId?.toString();
      state.loggedIn = true;
      state.user = { ...(user as UserState), id: userId ?? '' };

      state.interfaceLanguage = user.interfaceLanguage;
      localStorage.setItem('interface-language', user.interfaceLanguage);
    },
    [userThunks.fetchLevels.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<LevelSettingsResponse>
    ) => {
      state.levels = payload.data;
    },
    [userThunks.fetchLanguages.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<LanguageSettingsResponse>
    ) => {
      state.languages = payload.data;
    },
    [userThunks.fetchImprovableRecentQuizzes.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ImprovableRecentQuizzesResponse>
    ) => {
      state.improvableRecentQuizzes = payload.data;
    },
    [userThunks.fetchComingUpQuizzes.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ComingUpQuizzesResponse>
    ) => {
      state.comingUpQuizzes = payload.data;
    },
    [userThunks.userInfo.rejected.toString()]: (state) => {
      state.user = null;
      state.loggedIn = false;
      state.appLoaded = true;

      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    },

    [userThunks.userSkipLevelTest.fulfilled.toString()]: (state) => {
      state.user = {
        ...(state.user as UserState),
        currentLevelByskill: {
          speak: 1,
          grammar: 1,
          listen: 1,
          read: 1,
          vocabulary: 1,
          spelling: 1,
        },
      };
    },

    // USER PROGRESS EXTRA REDUCERS
    [userThunks.fetchSkillsProgress.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<SkillsProgressResponse>
    ) => {
      state.userProgress = payload.data;
    },

    // USER SKILLS EXTRA REDUCERS
    [userThunks.fetchSkills.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<SkillSettingsResponse>
    ) => {
      state.skillSettings = payload.data;
    },
  },
});

export const authActions = auth.actions;

export const authSelector = (state: RootState) => state.auth;
export const skillsSelector = (state: RootState) => state.auth.skillSettings;
export const isLangLocalSelector = (state: RootState) => {
  const { interfaceLanguage, nativeLanguage } = state.auth;

  return interfaceLanguage === nativeLanguage;
};

export const interfaceLanguageSelector = (state: RootState) => {
  const { languages, interfaceLanguage, studyingLanguage } = state.auth;

  const language = languages.find(
    (lang) => lang.learningCode === studyingLanguage
  );

  if (!language) {
    return;
  }

  const interfaceLang = language.localLanguages.find(
    (lang) => lang.code === interfaceLanguage
  );

  return interfaceLang;
};

export const nativeLanguageSelector = (state: RootState) => {
  const { languages, nativeLanguage, studyingLanguage } = state.auth;

  const language = languages.find(
    (lang) => lang.learningCode === studyingLanguage
  );

  if (!language) {
    return;
  }

  const nativeLang = language.localLanguages.find(
    (lang) => lang.code === nativeLanguage
  );

  return nativeLang;
};

export const studyingLanguageSelector = (state: RootState) => {
  const { languages, studyingLanguage } = state.auth;

  const language = languages.find(
    (lang) => lang.learningCode === studyingLanguage
  );

  return language;
};

export default auth.reducer;
