import UseContactUs from '@/hooks/UseContactUs';
import classNames from './ContactUsDrawer.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useFormik } from 'formik';
import { useDrawer } from '@/context/DrawerContext';
import { useEffect } from 'react';
import { Transition } from 'react-transition-group';

import AppLoaderCircle from '../AppLoaderCircle';
import SectionSeperator from '../SectionSeperator/SectionSeperator';
import TheButton from '../TheButton/TheButton';
import AppBackdrop from '../AppBackdrop';
import AppModal from '../AppModal';
import AppInput from '../AppInput/AppInput';

const ContactUsDrawer = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const drawer = useDrawer();
  const { nativeLanguage } = useAppSelector(authSelector);
  const showMorfixLogo = nativeLanguage === 'he';
  const {
    isLoading,
    ContactUsSchema,
    InitialContactUsValues,
    showMessageSent,
    onCloseMessageSent,
    OnContactUsSubmitted,
  } = UseContactUs();

  const formik = useFormik({
    initialValues: InitialContactUsValues,
    validationSchema: ContactUsSchema,
    onSubmit: OnContactUsSubmitted,
  });

  const shouldDisable =
    !formik.values.subject || !formik.values.email || !formik.values.message;

  useEffect(() => {
    if (showMessageSent) {
      formik.resetForm();
    }
  }, [showMessageSent]);

  return (
    <div className={classNames.contactUsDrawer}>
      {isLoading ? (
        <AppLoaderCircle className="m-auto" />
      ) : (
        <>
          <Transition
            in={showMessageSent}
            timeout={400}
            mountOnEnter
            unmountOnExit
            children={(state) => {
              return (
                <>
                  <AppBackdrop
                    className={classes(
                      classNames.backdrop,
                      `backdrop-${state}`
                    )}
                    emitClose={onCloseMessageSent}
                  />
                  <AppModal>
                    <div
                      className={classes(
                        classNames.messageSent,
                        `fade-${state}`
                      )}
                    >
                      <h2>{t('thankYou')}</h2>
                      <span>{t('yourMessageHasBeenSentSuccessfully')}</span>
                      <TheButton
                        className={classNames.btn}
                        text={t('done')}
                        emitOnClick={onCloseMessageSent}
                        shouldRecolor={false}
                      />
                    </div>
                  </AppModal>
                </>
              );
            }}
          />
          <div className={classNames.top}>
            <h2>{t('contactUs')}</h2>
            <span>
              {t('weWantYourFeedback', { firstname: user?.firstName })}
            </span>
          </div>
          <SectionSeperator className="my-8" />
          <div className={classNames.body}>
            <form
              onSubmit={(event) =>
                shouldDisable ? null : formik.handleSubmit(event)
              }
            >
              <div className={classNames.fields}>
                <AppInput
                  className={classNames.field}
                  inputWrapperClassName={classNames.inputWrapperClassName}
                  id="subject"
                  name="subject"
                  label={t('subject') ?? ''}
                  isRequired
                  placeholder={t('yourSubjectHere') ?? ''}
                  value={formik.values.subject}
                  error={formik.errors.subject}
                  emitChange={formik.handleChange}
                />
                <AppInput
                  className={classNames.field}
                  inputWrapperClassName={classNames.inputWrapperClassName}
                  id="email"
                  name="email"
                  type="email"
                  label={t('email') ?? ''}
                  isRequired
                  placeholder={t('exampleEmail') ?? ''}
                  value={formik.values.email}
                  error={formik.errors.email}
                  emitChange={formik.handleChange}
                />
                <AppInput
                  className={classNames.field}
                  inputWrapperClassName={classNames.inputWrapperClassName}
                  id="phone"
                  name="phone"
                  label={t('phone') ?? ''}
                  placeholder={t('yourPhoneHere') ?? ''}
                  value={formik.values.phone}
                  error={formik.errors.phone}
                  emitChange={formik.handleChange}
                />
                <div className={classNames.messageField}>
                  <label htmlFor="message">{t('message')}</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder={t('yourMessageHere') ?? ''}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                  ></textarea>
                </div>
                <div className={classNames.tipAndUpdatesCheckbox}>
                  <input
                    id="should_receive_updates"
                    name="shouldReceiveUpdates"
                    type="checkbox"
                    checked={formik.values.shouldReceiveUpdates}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="should_receive_updates">
                    {showMorfixLogo
                      ? t('receiveTipsAndUpdatesMorfix')
                      : t('receiveTipsAndUpdates')}
                  </label>
                </div>
              </div>

              <div className={classNames.buttons}>
                <TheButton
                  className={classNames.cancelBtn}
                  text={t('cancel')}
                  plain
                  emitOnClick={drawer.closeContactUsDrawer}
                />
                <TheButton
                  className={classNames.sendBtn}
                  text={t('send')}
                  type="submit"
                  disabled={shouldDisable}
                  shouldRecolor={false}
                />
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactUsDrawer;
