import { TeacherGroup, UserState } from '@/types/user';

export enum COLORS {
  grammar = '#ff9f4c',
  read = '#973d9d',
  listen = '#e11560',
  vocabulary = '#145c99',
  spelling = '#2bae19',
  speak = '#05abf4',
}

export type GenerateReportProps = {
  reportType?: ReportTypes;
  currentClassroom?: TeacherGroup;
  currentStudent?: UserState | null;
  reportContent?: ReportContent | null;
};

export enum ReportTypes {
  class = 'class',
  student = 'student',
}

export type StudentLevelsValueObject = {
  startingLevel: number;
  currentLevel: number;
};
export type ClassLevelsValueObject = { groupCurrentAverageLevel: number };

export type Skills = {
  grammar: number;
  read: number;
  listen: number;
  vocabulary: number;
  spelling: number;
  speak: number;
};

export type ReportContent = {
  averageLevel: number;
  overallQuizProgress: number;
  quizPassRate: number;
  quizFailureRate: number;
  progressPerSkill: Record<
    keyof Skills,
    StudentLevelsValueObject | ClassLevelsValueObject
  >;
  averageScore: Skills;
  activity: Skills;
  averageAttempts: number;
  averageTimeSpent: string;
  totalHintsUsed: number;
};
