import { LocalizedType } from './common';
import { UserData } from './user';

export type Topic = LocalizedType & {
  id: number;
  order: number;
  quizzesCount: number;
  userData?: UserData | null;
  topicType: TopicTypes;
};

export enum TopicTypes {
  EndOfSkillTest = 3,
  NormalTopic = 1,
}

export type Road = {
  order: number;
  img: string;
};
