import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import classNames from './SmallDropDown.module.scss';
import SVG from 'react-inlinesvg';
import check from '@/assets/svg/check24.svg';
import { useTranslation } from 'react-i18next';
import { SkillTypes } from '@/types/skill';
import { useAppDispatch } from '@/store';
import userThunks from '@/store/thunks/user';
type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  currentLevel: number;
  skill: string | undefined;
};
const SmallDropDown = ({ open, setOpen, currentLevel, skill }: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);
  const items = [
    `${t('level')} 1`,
    `${t('level')} 2`,
    `${t('level')} 3`,
    `${t('level')} 4`,
    `${t('level')} 5`,
  ];
  const handlePickedLevel = async (e: React.MouseEvent, level: number) => {
    e.stopPropagation();

    setOpen(false);
    if (skill && level !== currentLevel) {
      await dispatch(
        userThunks.changeTeacherSkillLevel({
          skill: skill.toLowerCase(),
          level,
        })
      );
      localStorage.setItem('selected-skill', skill.toLocaleLowerCase());
      window.location.reload();
    }
  };

  return (
    <>
      {open && (
        <div ref={dropdownRef} className={classNames.container}>
          {items.map((item, index) => (
            <div
              onClick={(e) => handlePickedLevel(e, index + 1)}
              style={{
                borderBottom: index === 4 ? 'none' : '1px solid #08263F33',
              }}
              className={classNames.textContainer}
              key={index}
            >
              <button>{item}</button>
              {currentLevel === index + 1 && <SVG src={check} />}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SmallDropDown;
