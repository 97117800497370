import WithSettings, { WithSettingsProps } from '@/hocs/WithSettings';
import classNames from './PremiumSection.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';

import premiumImg1 from '@/assets/svg/premium-img-1.svg';
import premiumImg2 from '@/assets/svg/premium-img-2.svg';

interface PremiumSectionProps {
  className?: string;
}

const PremiumSection = ({
  t,
  className,
}: WithSettingsProps & PremiumSectionProps) => {
  const { user } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);

  const onUnlockPremium = () => {};

  const content = isTeacher ? (
    <>
      <h2>{t('tipsAndTricks')}</h2>
      <p>{t('byClickingOn')}</p>
    </>
  ) : (
    <>
      <h2>{t('premiumSection.title')}</h2>
      <p>{t('premiumSection.description')}</p>
      <button onClick={onUnlockPremium}>{t('premiumSection.unlock')}</button>
    </>
  );

  return (
    <div
      className={classes(
        classNames.section,
        isTeacher
          ? classNames.premiumSectionTeacherDashboard
          : classNames.premiumSection,
        className
      )}
    >
      <div className={classNames.content}>{content}</div>
      <SVG
        className={classNames.img}
        src={isTeacher ? premiumImg1 : premiumImg2}
      />
    </div>
  );
};

export default WithSettings(PremiumSection);
