import { Topic } from '@/types/topic';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';
import { useNavigate } from 'react-router-dom';
import GameUtils from '@/utils/gameUtils';
import endOfSkillThunks from '@/store/thunks/end-of-skill';
import {
  endOfSkillActions,
  endOfSkillSelector,
} from '@/store/reducers/end-of-skill';

const useEndOfSkillTest = () => {
  const { selectedType } = useAppSelector(gameSelector);
  const { quiz, prevTopic } = useAppSelector(endOfSkillSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleStartEndOfSkillTest = async (topic: Topic, toNavigate = true) => {
    let response;

    if (quiz && prevTopic?.id === topic.id) {
      response = quiz;
      dispatch(endOfSkillActions.resetCurrentReadQuizIndex());
    } else {
      const res = await dispatch(
        endOfSkillThunks.fetchEndOfSkillTest(topic)
      ).unwrap();
      response = res?.questions;
    }

    dispatch(gameActions.setInEndOfSkillTest(true));
    dispatch(gameActions.resetGame());

    if (selectedType === SkillTypes.Read) {
      if (response) {
        const firstQuiz = GameUtils.getQuizFromQuizzes(response?.quiz);

        dispatch(gameActions.setShowPreQuiz(true));
        dispatch(gameActions.setSelectedQuiz(firstQuiz));
        dispatch(gameActions.setSelectedTopic(topic));
        dispatch(
          gameActions.setSelectedSkill({
            type: SkillTypes.Read,
            skill: { name: 'Read' },
          })
        );
        dispatch(gameActions.setInEndOfSkillTest(true));
        dispatch(
          gameActions.startGame({
            gameType: GameUtils.skillTypeToGameType(selectedType as SkillTypes),
            questions: GameUtils.getQuestionsFromQuiz(
              response?.questions,
              firstQuiz?.id
            ),
          })
        );
      }

      if (toNavigate) {
        navigate('/dashboard/quiz');
      }

      return;
    }

    if (response) {
      dispatch(gameActions.setShowPreQuiz(true));
      dispatch(gameActions.setSelectedTopic(topic));
      dispatch(
        gameActions.setSelectedQuiz(
          GameUtils.getQuizFromQuizzes(response?.quiz)
        )
      );
      dispatch(
        gameActions.startGame({
          gameType: GameUtils.skillTypeToGameType(selectedType as SkillTypes),
          questions: GameUtils.getQuestionsFromQuiz(response?.questions),
        })
      );

      if (toNavigate) {
        navigate('/dashboard/quiz');
      }
    } else {
      return;
    }
  };

  return { handleStartEndOfSkillTest };
};

export default useEndOfSkillTest;
