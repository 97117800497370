import { SkillTypes } from '@/types/skill';
import classNames from './ProgressItem.module.scss';
import WithSettings, { WithSettingsProps } from '@/hocs/WithSettings';
import SkillIcon from '@/components/SkillIcon/SkillIcon';
import UseSkills from '@/hooks/UseSkills';

interface ProgressItemProps {
  skill: SkillTypes;
  actual?: number;
  total?: number;
}

const ProgressItem = ({
  t,
  skill,
  actual,
  total,
}: ProgressItemProps & WithSettingsProps) => {
  const { getSkillName } = UseSkills();

  return (
    <div className={classNames.progressItem}>
      <div className={classNames.header}>
        <SkillIcon className={classNames.skillIcon} skill={skill} />
        <span>{getSkillName(skill)}</span>
      </div>
      <span className={classNames.summary}>
        {`${actual}/${total} ${t('quizzes')}`}
      </span>
    </div>
  );
};

export default WithSettings(ProgressItem);
