import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { ConfirmVerificationCodeProps } from '@/types/login';
import AppInput from '../AppInput/AppInput';
import TheButton from '../TheButton/TheButton';
import AppLoaderCircle from '../AppLoaderCircle';

const VerifyRegistrationCode = ({
  emitSubmit,
  styles,
  loading,
}: ConfirmVerificationCodeProps) => {
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    code: Yup.string().required(t('validation.code_required').toString()),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: LoginSchema,
    onSubmit: emitSubmit ?? (() => {}),
  });

  const onClear = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    name: string
  ) => {
    formik.setFieldValue(name, '');
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <AppInput
          className={styles.formField}
          inputWrapperClassName={styles.appInputWrapper}
          id="code"
          name="code"
          label={t('verificationCode') ?? ''}
          maxLength={6}
          type="password"
          placeholder={t('enterVerificationCode') ?? ''}
          value={formik.values?.code?.trim()}
          error={formik.errors.code}
          allowClear={true}
          emitChange={formik.handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />

        {!loading && (
          <TheButton
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            className={styles.submit}
            text={t('signIn')}
          />
        )}
        {loading && (
          <div className={styles.loadingWrapper}>
            <AppLoaderCircle />
            <div className="mt-3">{t('signYouIn')}</div>
          </div>
        )}
      </form>
    </>
  );
};

export default VerifyRegistrationCode;
