import { useTranslation } from 'react-i18next';
import classNames from './AverageGameScores.module.scss';
import {
  GenerateReportProps,
  ReportTypes,
} from '../../../../../types/generate-report';
import classes from 'classnames';

const AverageGameScores = ({
  reportType,
  reportContent,
}: GenerateReportProps) => {
  const { t } = useTranslation();
  const isStudentReport = reportType === ReportTypes.student;

  const averageScoresXAxis = [100, 80, 60, 40, 20, 0];

  return (
    <div className={classNames.chartContainer}>
      <div>
        <h1>{isStudentReport ? t('avgGameScores') : t('avgClassScores')}</h1>
        <h5>{t('perSkill')}</h5>
      </div>
      <div className={classNames.chart}>
        <div className={classNames.xAxisValues}>
          {averageScoresXAxis.map((score) => (
            <h4 key={score}>{score}</h4>
          ))}
        </div>

        <div className={classNames.gameScoresChartContainer}>
          {reportContent &&
            Object.entries(reportContent.averageScore).map(([skill, score]) => (
              <div className={classNames.gameScoresBarsContainer}>
                <div
                  className={classes(classNames.bar, {
                    [classNames[
                      `bar-${skill[0].toUpperCase() + skill.slice(1)}`
                    ]]: true,
                    [classNames.background]: score === 0,
                  })}
                >
                  <div
                    style={{
                      height: `${100 - score}%`,
                    }}
                    className={classNames.filledBar}
                  />
                  {score !== 0 && (
                    <div className={classNames.number}>
                      <h2>{score}</h2>
                    </div>
                  )}
                </div>

                <div className={classNames.gameScoresXAxis}>
                  <h2>{t(`skills.${skill}`)}</h2>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AverageGameScores;
