import { RegistrationWelcomeProps } from '@/types/registration';
import classNames from './RegistrationWelcome.module.scss';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';

import { Link, useNavigate } from 'react-router-dom';
import TheButton from '@/components/TheButton/TheButton';

import backgroundOne from '@/assets/svg/registration_welcome_bg_1.svg';
import backgroundTwo from '@/assets/svg/registration_welcome_bg_2.svg';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import AppMorfixLogo from '@/components/AppMorfixLogo/AppMorfixLogo';

const RegistrationWelcome = ({
  role,
  organizationDetails,
  groupDetails,
  emitNextStep,
}: RegistrationWelcomeProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile, isDesktop } = UseScreenSize();
  const { nativeLanguage } = useAppSelector(authSelector);

  const showMorfixLogo = nativeLanguage === 'he';

  const onGoToLogin = () => {
    dispatch(authActions.setIsFromRegistration(true));
    navigate('/login');
  };

  const description =
    role === 'admin' ? (
      <>
        <span>{t('dearSchoolAdmin')}</span>
        <span>
          {showMorfixLogo ? t('invitedToJoinMorfix') : t('invitedToJoin')}
        </span>
        <span>{t('pleaseTakeAMoment')}</span>
      </>
    ) : role === 'teacher' ? (
      <>
        <span>
          {t('dearTeacher') + ` ${organizationDetails?.organizationName}.`}
        </span>
        <span>{t('pleaseEnterTheFollowing')}</span>
        <span>
          {showMorfixLogo
            ? t('englishLearningJourneyMorfix')
            : t('englishLearningJourney')}
        </span>
      </>
    ) : (
      <>
        <span>{t('dearStudent')}</span>
        <span>{`${groupDetails?.teacherName} ${t('toJoin')} ${
          groupDetails?.groupName
        }`}</span>
        <span>{t('andBeginYour')}</span>
      </>
    );

  const signInBtn = (
    <TheButton
      className={classNames.signInBtn}
      text={t('joinNow')}
      emitOnClick={emitNextStep}
    />
  );

  const alreadyHaveAccountBtn = (
    <TheButton
      plain
      className={classNames.link}
      text={t('IAlreadyHaveAnAccount')}
      emitOnClick={onGoToLogin}
    />
  );

  return (
    <div className={classNames.registrationWelcome}>
      <div className={classNames.body}>
        {isMobile && <SVG src={backgroundOne} className="w-full mb-2" />}
        <span className={classNames.welcomeText}>{t('welcomeTo')}</span>
        <div className={classNames.textContainer}>
          {showMorfixLogo ? (
            <AppMorfixLogo width={382} height={60} showBackGround={false} />
          ) : (
            <div className={classNames.title}>
              <span className={classNames.titleText}>Britannica</span>
              <span className={classNames.englishText}>English</span>
            </div>
          )}
        </div>

        <p>{description}</p>
        {isMobile && <SVG src={backgroundTwo} className="w-full mt-7" />}
        {isDesktop && (
          <>
            {signInBtn}
            {alreadyHaveAccountBtn}
          </>
        )}
      </div>
      {isMobile && (
        <>
          {signInBtn}
          {alreadyHaveAccountBtn}
        </>
      )}
    </div>
  );
};

export default RegistrationWelcome;
