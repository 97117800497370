import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';
import UseLocalLang from './UseLocalLang';
import { useTranslation } from 'react-i18next';

const UseLevel = () => {
  const { t } = useTranslation();
  const { levels } = useAppSelector(authSelector);
  const localLang = UseLocalLang();

  const getLevelText = (level?: number) => {
    const levelObj = levels.find((i) => i.id === level);

    // return levelObj ? commonUtils.getName(levelObj, localLang) : '';
    return levelObj ? t(levelObj.name?.toLowerCase() ?? '') : '';
  };

  return { getLevelText };
};

export default UseLevel;
