import { PersonalDetailsProps } from '@/types/registration';
import classNames from './PersonalDetails.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import UseScreenSize from '@/hooks/UseScreenSize';

import AppInput from '@/components/AppInput/AppInput';
import TheButton from '@/components/TheButton/TheButton';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

const PersonalDetails = ({
  role,
  fields,
  emitSubmit,
}: PersonalDetailsProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const localInterfaceLanguage = localStorage.getItem('interface-language');
  const lastNameFieldIndex =
    fields?.findIndex((field) => field.id === 'lastname') ?? 0;

  const firstnameAndLastnameFields = fields
    ?.slice(0, lastNameFieldIndex + 1)
    .map((field) => (
      <AppInput
        key={field.id}
        shouldTranslate
        className={classes(classNames.field, {
          [classNames.fieldError]: field.error,
        })}
        inputWrapperClassName={classNames.inputWrapperClassName}
        {...field}
      />
    ));

  const remainedFields = fields?.slice(lastNameFieldIndex + 1).map((field) => {
    if (field.id === 'terms_and_conditions') {
      return (
        <div key={field.id} className={classNames.termsAndConditions}>
          <AppInput
            shouldTranslate
            className={classNames.field}
            inputWrapperClassName={classNames.inputWrapperClassName}
            {...field}
          />
          <div
            className={classes(classNames.label, {
              [classNames.labelError]: field.error,
            })}
          >
            <span>{`${t('iAgreeTo')} `}</span>
            <a
              href="https://corporate.britannica.com/termsofuse.html"
              target="_blank"
            >
              {`${t('termsAndConditions')} `}
            </a>
            <span>{t('ofThisSite')}</span>
          </div>
        </div>
      );
    }
    return (
      <AppInput
        key={field.id}
        shouldTranslate
        className={classes(classNames.field, {
          [classNames.fieldError]: field.error,
        })}
        inputWrapperClassName={classes(classNames.inputWrapperClassName, {
          [classNames.placeHolder]:
            (field.name === 'password' || field.name === 'confirmPassword') &&
            localInterfaceLanguage === 'he',
          [classNames.ltr]:
            field.name === 'email' ||
            field.name === 'password' ||
            field.name === 'confirmPassword',
        })}
        {...field}
      />
    );
  });

  const btnText = role === 'admin' ? t('next') : t('submit');

  const shouldDisable = fields?.some(
    (field) =>
      !field.value ||
      (field.id === 'terms_and_conditions' && field.value === 'false')
  );
  return (
    <form className={classNames.personalDetails} onSubmit={emitSubmit}>
      <div className={classNames.fields}>
        {isMobile ? (
          firstnameAndLastnameFields
        ) : (
          <div className={classNames.firstnameAndLastnameFields}>
            {firstnameAndLastnameFields}
          </div>
        )}
        {remainedFields}
      </div>
      <TheButton
        className={classNames.submitBtn}
        disabled={shouldDisable}
        text={btnText}
        type="submit"
      />
    </form>
  );
};

export default PersonalDetails;
