import classNames from './LevelTestIntro.module.scss';
import SVG from 'react-inlinesvg';

import hat from '@/assets/svg/hat.svg';
import { useTranslation } from 'react-i18next';
import TheButton from '@/components/TheButton/TheButton';
import { useState } from 'react';
import LevelTestLogOut from '../LevelTestLogOut/LevelTestLogOut';
import { useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

interface LevelTestIntroProps {
  emitBegin: () => void;
  reVisited: boolean;
}

const LevelTestIntro = ({
  emitBegin,
  reVisited = false,
}: LevelTestIntroProps) => {
  const { t } = useTranslation();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { user } = useAppSelector(authSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTeacher = user?.role === 'teacher';
  return (
    <div className={classNames.levelTestIntro}>
      <LevelTestLogOut
        showLogoutModal={showLogoutModal}
        setShowLogoutModal={setShowLogoutModal}
      />
      <div className={classNames.signOut}>
        <button
          onClick={() => {
            setShowLogoutModal(true);
          }}
        >
          {isTeacher ? t('exit') : t('sign_out')}
        </button>
      </div>
      <div className={classNames.top}>
        <div className={classNames.info}>
          <SVG src={hat} />
          <span>{t(isTeacher ? 'testYourEnglish' : 'levelTest')}</span>
          {reVisited == true && !isTeacher && <h2>{t('we_want_to_know')}</h2>}
          {(reVisited !== true || isTeacher) && <h2>{t('howsYourEnglish')}</h2>}
        </div>
        {reVisited == true && !isTeacher && (
          <p>
            {t('in_order_to_start')}
            <br />
            <span style={{ fontWeight: 'bold' }}>
              {t('know_your_level')}
            </span>{' '}
          </p>
        )}
        <p>{t(isTeacher ? 'yourStudentQuiz' : 'letsAssess')}</p>
      </div>

      <div>
        {reVisited == true && (
          <TheButton
            className={classNames.button}
            text={t('continue')}
            emitOnClick={emitBegin}
            showArrow={false}
          />
        )}
        {reVisited != true && (
          <TheButton
            className={classNames.button}
            text={t(isTeacher ? 'teacherLetsBeginn' : 'letsBegin')}
            emitOnClick={emitBegin} //todo this is temp solution for teacher need to fix
            showArrow={false}
          />
        )}
        {user?.role === 'teacher' && (
          <button
            onClick={() => {
              if (localStorage.getItem('teacher-level') === null) {
                localStorage.setItem('teacher-level', '1');
                dispatch(authActions.setUserLevel(1));
                dispatch(authActions.setUserCoins(100));
              }
              navigate('/dashboard/my-students');
            }}
            className={classNames.skipBtn}
          >
            {t('skip')}
          </button>
        )}
      </div>
    </div>
  );
};

export default LevelTestIntro;
