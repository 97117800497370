import { useTranslation } from 'react-i18next';
import classNames from './YourClasses.module.scss';
import classes from 'classnames';
import { ClassConfig, TeacherGroup } from '@/types/user';
import SVG from 'react-inlinesvg';

import TheButton from '../TheButton/TheButton';
import SectionSeperator from '../SectionSeperator/SectionSeperator';
import Detail from '../UserInfo/Detail/Detail';

import rightArrow from '@/assets/svg/right-arrow-large.svg';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';

interface YourClassesProps {
  className?: string;
  classConfig: ClassConfig | null;
  filterdClassrooms: TeacherGroup[];
  selectedFilter?: string;
  onCreateClass: () => void;
  onEditClass: (classroom: TeacherGroup) => void;
}

const YourClasses = ({
  className,
  classConfig,
  filterdClassrooms,
  selectedFilter,
  onCreateClass,
  onEditClass,
}: YourClassesProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const isRtl = interfaceLanguage?.direction === 'rtl';
  const isHebrew = interfaceLanguage?.code === 'he';

  const gradesText = `${t('grade')}'s`;

  let gradeText = '';

  const isAll = selectedFilter === '*';

  const isUniversityGrade = selectedFilter === 'university';
  const isNoneGrade = selectedFilter === 'none';

  if (isAll) {
    gradeText = t('yourClasses');
  } else if (isUniversityGrade || isNoneGrade) {
    gradeText = t(selectedFilter);
  } else {
    gradeText =
      isNaN(+(selectedFilter ?? '')) ||
      Number.isInteger(+(selectedFilter ?? ''))
        ? t(`grade_${commonUtils.fixGradeSymbol(selectedFilter ?? '')}`)
        : selectedFilter ?? '';
  }

  const titleText = isAll
    ? gradeText
    : `${gradeText}${
        !isUniversityGrade && !isNoneGrade ? ` ${gradesText}` : ''
      } ${t('classes')}`;

  const hebrewTitleText = isAll ? gradeText : `${t('classes')} ${gradeText}`;

  const filterdClassroomsElements = filterdClassrooms.map(
    (classRoom, index) => {
      const isSelected = classRoom.id === classConfig?.id;
      const isLast = filterdClassrooms.length - 1 === index;
      const classObj = user?.teacherGroups?.find((i) => i.id === classRoom.id);

      const studentsAmount = classObj?.totalStudents ?? 0;

      return (
        <div
          key={classRoom.id}
          className={classes(classNames.classroom, {
            [classNames.classroomSelected]: isSelected,
          })}
          onClick={() => onEditClass(classRoom)}
        >
          <div className={classNames.content}>
            <div className={classNames.texts}>
              <span className={classNames.name}>{classRoom.name}</span>
              <Detail
                className={classNames.detail}
                topic={t('students')}
                value={studentsAmount}
              />
            </div>
            <SVG
              className={classes({
                [classNames.arrowSvgRtl]: isRtl,
              })}
              src={rightArrow}
            />
          </div>
          {isLast && <SectionSeperator className={classNames.seperator} />}
        </div>
      );
    }
  );

  return (
    <div className={classes(classNames.yourClasses, className)}>
      <div className={classNames.header}>
        <span className={classNames.title}>
          {isHebrew ? hebrewTitleText : titleText}
        </span>
        <TheButton
          className={classNames.addBtn}
          text={t('createNewClass')}
          plain
          shouldRecolor={false}
          emitOnClick={onCreateClass}
        />
      </div>
      {filterdClassrooms.length === 0 && <SectionSeperator className="mt-2" />}
      <div className={classNames.filterdClassrooms}>
        {filterdClassroomsElements}
      </div>
    </div>
  );
};

export default YourClasses;
