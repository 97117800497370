import classNames from './PracticeSummary.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import game, { gameSelector } from '@/store/reducers/game';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import WordsListItem from '../../PopUpWordsList/WordsListItem/WordsListItem';

import hat from '@/assets/svg/hat.svg';
import { PopUpSummaryProps } from '@/types/game';
import TheButton from '@/components/TheButton/TheButton';
import React, { useEffect, useRef, useState } from 'react';
import { Question } from '@/types/question';
import UseScreenSize from '@/hooks/UseScreenSize';

const PracticeSummary = ({
  emitOnAknowledge,
  emitStartQuizFromPractice,
  emitPracticeAgain,
  emitOnQuit,
}: PopUpSummaryProps) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = UseScreenSize();
  const { gameQuestions } = useAppSelector(gameSelector);
  const buttonsRef = useRef<HTMLDivElement>(null);

  const [wordsList, setWorsList] = useState<Question[]>([]);

  useEffect(() => {
    setWorsList(() => [...gameQuestions]);
  }, [gameQuestions]);

  const onPlainButtonClick = () => {
    if (isDesktop) {
      emitOnQuit?.();
      return;
    }

    emitPracticeAgain?.();
  };

  const wordsListItems = wordsList.map((wordItem, index) => {
    const isLast = index === gameQuestions.length - 1;

    return (
      <div className={classNames.wordsListItem} key={wordItem.questionId}>
        <WordsListItem
          question={wordItem}
          emitOnAknowledge={emitOnAknowledge}
        />
        {!isLast && <SectionSeperator className="my-4" />}
      </div>
    );
  });

  const description = isMobile ? (
    <>
      <span>{t('itsTime') + ' '}</span>
      <span className="font-bold">{t('letsKeepLearning')}</span>
    </>
  ) : (
    <>
      <span>{t('youPracticed')}</span>
      <span className="font-bold">{t('areYouReadyForTheQuiz')}</span>
    </>
  );

  return (
    <div className={classNames.practiceSummary}>
      <div className={classNames.top}>
        <SVG className={classNames.image} src={hat} />
        <h2>{t('great')}</h2>
        <p>{description}</p>
      </div>
      <div className={classes('container', classNames.wordsList)}>
        {wordsListItems}
      </div>
      <div ref={buttonsRef} className={classNames.buttons}>
        <TheButton
          className={isDesktop ? 'flex-1' : ''}
          text={t('startQuiz')}
          emitOnClick={emitStartQuizFromPractice}
        />
        <TheButton
          className={isDesktop ? 'flex-1' : ''}
          plain
          text={isDesktop ? t('backToTopics') : t('practiceAgain')}
          emitOnClick={onPlainButtonClick}
        />
      </div>
    </div>
  );
};

export default React.memo(PracticeSummary);
