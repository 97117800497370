import { useTranslation } from 'react-i18next';
import classNames from './Header.module.scss';
import {
  GenerateReportProps,
  ReportTypes,
} from '../../../../../types/generate-report';
import classes from 'classnames';
import DateUtils from '@/utils/dateUtils';
import UseLevel from '@/hooks/UseLevel';
import commonUtils from '@/utils/common';

const Header = ({
  reportType,
  currentClassroom,
  currentStudent,
  reportContent,
}: GenerateReportProps) => {
  const { t } = useTranslation();
  const { getLevelText } = UseLevel();
  const isClassReport = reportType === ReportTypes.class;
  const isStudentReport = reportType === ReportTypes.student;
  const levelText = getLevelText(reportContent?.averageLevel);

  const currentClassGrade = currentClassroom?.grade ?? '';

  const isUniversityGrade = currentClassGrade.toLowerCase() === 'university';
  const isNoneGrade = currentClassGrade.toLowerCase() === 'none';

  let gradeValue = null;

  if (!isNoneGrade) {
    if (isUniversityGrade) {
      gradeValue = t('university');
    } else {
      gradeValue =
        isNaN(+currentClassGrade) || Number.isInteger(+currentClassGrade)
          ? t(`grade_${commonUtils.fixGradeSymbol(currentClassGrade)}`)
          : currentClassGrade;
    }
  }

  return (
    <div className={classNames.header}>
      <div className={classNames.headerItem}>
        <h3>{isStudentReport ? t('studentName') : t('className')}</h3>
        <h2>
          {isClassReport
            ? currentClassroom?.name
            : currentStudent?.firstName + ' ' + currentStudent?.lastName}
        </h2>
      </div>
      {isClassReport && (
        <div
          className={classes(classNames.headerItem, {
            [classNames.borders]: true,
          })}
        >
          <h3>{t('grade')}</h3>
          {/*TODO: need to change the 10th with real student data or class data */}
          <h2>{gradeValue}</h2>
        </div>
      )}
      <div
        className={classes(classNames.headerItem, {
          [classNames.borders]: true,
        })}
      >
        <h3>
          {isStudentReport ? t('generalLevel') : t('averageStudentLevel')}
        </h3>
        {/*TODO: need to wait for moran response in slack if i get it from the response or not */}
        <h2>
          {reportContent?.averageLevel} | {levelText}
        </h2>
      </div>
      <div className={classNames.headerItem}>
        <h3>{t('date')}</h3>
        <h2>{DateUtils.formatUTC(new Date(), 'dd MMM yyyy')}</h2>
      </div>
    </div>
  );
};

export default Header;
