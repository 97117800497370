import { useEffect, useMemo, useState } from 'react';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';
import levelTestThunks from '@/store/thunks/level-test';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  levelTestActions,
  levelTestSelector,
} from '@/store/reducers/level-test';
import commonUtils from '@/utils/common';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useNavigate } from 'react-router-dom';
import GameUtils from '@/utils/gameUtils';
import { authSelector } from '@/store/reducers/auth';
import UseLevelTestGame from '@/hooks/UseLevelTestGame';

const UseLevelTest = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, skillSettings } = useAppSelector(authSelector);
  const { quizzes, currentQuizIndex, isFinishLevelTest } =
    useAppSelector(levelTestSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { startLevelTestGame } = UseLevelTestGame();
  const { isLevelTest } = useAppSelector(gameSelector);

  const onBegin = async () => {
    setIsLoading(true);

    dispatch(gameActions.setIsLevelTest(true));
    dispatch(levelTestThunks.fetchNewLevelTestQuestions());
  };

  // START LEVEL TEST, QUIZZES ARE LOADED
  useEffect(() => {
    if (quizzes?.length && currentQuizIndex === -1) {
      startLevelTestGame(0);

      setIsLoading(false);
      navigate('/dashboard/quiz');
    }
  }, [quizzes, currentQuizIndex]);

  const onSubmit = () => {
    setIsSubmitted(true);
  };

  return {
    isLoading,
    showSummary,
    isSubmitted,
    onSubmit,
    onBegin,
    setShowSummary,
  };
};

export default UseLevelTest;
