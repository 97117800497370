import { RootState } from '@/types/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { progressService, skillService } from '@/services';
import { appSettingsActions } from '../reducers/settings';
import { TopicTypes } from '@/types/topic';

const quizzesThunks = {
  refreshTopicQuizzes: createAsyncThunk(
    'refreshTopicQuizzes',
    async (topicId: number | undefined, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const { selectedType, selectedTopic } = state.game;

        const user = state.auth.user;

        if (!user || !selectedTopic || !selectedType) {
          return;
        }

        console.log('Fetching quizzes...')

        const quizzesResponse = await skillService.fetchQuizzes(
          selectedType,
          user,
          topicId ?? selectedTopic.id
        );

        const quizzes = quizzesResponse?.data ?? [];

        const progress =
          (
            await progressService.fetchQuizProgress(
              selectedType,
              user,
              topicId ?? selectedTopic.id
            )
          )?.data ?? {};

        for (const quiz of quizzes) {
          const quizProgress = progress[quiz.id];

          if (quizProgress) {
            quiz.userData = quizProgress;
          }
        }

        dispatch(
          appSettingsActions.appendQuizzesWithData({
            topicId: topicId ?? selectedTopic.id,
            quizzes: quizzes,
          })
        );

        return quizzes;
      } catch (error) {
        console.log(error);

        throw error;
      }
    }
  ),
};

export default quizzesThunks;
