import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';

interface DrawerContextProps {
  isDrawerOpen: boolean;
  isMobileDrawerOpen: boolean;
  isContactUsDrawerOpen: boolean;
  isAddClassDrawerOpen: boolean;
  isEditClassDrawerOpen: boolean;
  isAddNewStudentDrawerOpen: boolean;
  classId: string;
  groupId: string;
  openDrawer: () => void;
  closeDrawer: () => void;
  openAddClass: () => void;
  closeAddClass: () => void;
  openEditClass: (id: string) => void;
  closeEditClass: () => void;
  openAddStudent: (groupId: string) => void;
  closeAddStudent: () => void;
  toggleDrawer: () => void;
  openContactUsDrawer: () => void;
  closeContactUsDrawer: () => void;
  openMobileDrawer: () => void;
  closeMobileDrawer: () => void;
  toggleMobileDrawer: () => void;
}

const DrawerContext = createContext<DrawerContextProps | undefined>(undefined);

export const useDrawer = (): DrawerContextProps => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};

interface DrawerProviderProps {
  children: ReactNode;
}

export const DrawerProvider: React.FC<DrawerProviderProps> = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [isContactUsDrawerOpen, setIsContactUsDrawerOpen] = useState(false);
  const [isAddClassDrawerOpen, setIsAddClassDrawerOpen] = useState(false);
  const [isEditClassDrawerOpen, setIsEditClassDrawerOpen] = useState(false);
  const [isAddNewStudentDrawerOpen, setIsAddNewStudentDrawerOpen] = useState(false);
  const [classId, setClassId] = useState('');
  const [groupId, setGroupId] = useState('');

  const { loggedIn } = useAppSelector(authSelector);

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);
  const openContactUsDrawer = () => setIsContactUsDrawerOpen(true);
  const closeContactUsDrawer = () => setIsContactUsDrawerOpen(false);
  const openAddClass = () => setIsAddClassDrawerOpen(true);
  const closeAddClass = () => setIsAddClassDrawerOpen(false);
  const openAddStudent = (groupId: string) => {
    setIsAddNewStudentDrawerOpen(true);
    setGroupId(groupId);
  }
  const closeAddStudent = () => setIsAddNewStudentDrawerOpen(false);
  const openEditClass = (id: string) => {
    setIsEditClassDrawerOpen(true);
    setClassId(id);
  };
  const closeEditClass = () => setIsEditClassDrawerOpen(false);
  const toggleDrawer = () => setDrawerOpen((prevState) => !prevState);

  const openMobileDrawer = () => setMobileDrawerOpen(true);
  const closeMobileDrawer = () => setMobileDrawerOpen(false);
  const toggleMobileDrawer = () =>
    setMobileDrawerOpen((prevState) => !prevState);

  useEffect(() => {
    closeDrawer();
    closeMobileDrawer();
  }, [loggedIn]);

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        isMobileDrawerOpen,
        isContactUsDrawerOpen,
        isAddClassDrawerOpen,
        isEditClassDrawerOpen,
        isAddNewStudentDrawerOpen,
        classId,
        groupId,
        closeAddClass,
        openAddClass,
        closeEditClass,
        openEditClass,
        openDrawer,
        closeDrawer,
        toggleDrawer,
        openContactUsDrawer,
        closeContactUsDrawer,
        openMobileDrawer,
        closeMobileDrawer,
        toggleMobileDrawer,
        openAddStudent,
        closeAddStudent
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
