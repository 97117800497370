import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';

import styles from './TutorGroupMessages.module.scss';
import TutorImage from '@/assets/svg/tutor/chat/tutor.svg';
import TutorPencil from '@/assets/svg/tutor/pencilIcon.svg';
import LikeIcon from '@/assets/svg/tutor/chat/tutor_like.svg';
import DisLikeIcon from '@/assets/svg/tutor/chat/tutor_dislike.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/store';
import TutorMessage from './TutorMessage/TutorMessage';
import UseScreenSize from '@/hooks/UseScreenSize';
import {
  TutorGroupMessages as TutorGroupMessagesTypes,
  TutorSender,
} from '@/types/tutor';
import { tutorActions } from '@/store/reducers/tutor';

// Component's props definition
export interface TutorGroupMessagesProps {
  index: number;
  payload: TutorGroupMessagesTypes;
}

const TutorGroupMessages: React.FC<TutorGroupMessagesProps> = ({
  index,
  payload,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isMobile, isDesktop } = UseScreenSize();

  const { sender, message, includeThumbs, timeStamp } = payload;

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  // Determining if the message is from user or tutor
  const isUser = sender === TutorSender.user;
  const isTutor = sender === TutorSender.tutor;

  const isExercice = !!message[message.length - 1].exercise;

  const icon = isExercice ? TutorPencil : TutorImage;

  const generateClassNames = (style: string) => {
    const autoMargin = {
      [styles.marginLeft]: isUser,
      [styles.marginRight]: isTutor,
    };
    return classNames(styles[style], autoMargin);
  };

  useEffect(() => {
    if (message[currentMessageIndex]?.hasSeen) {
      setCurrentMessageIndex((current) => current + 1);
    }
  }, [message, currentMessageIndex]);

  return (
    <div key={index} className={generateClassNames('bubbleContainer')}>
      <div className={generateClassNames('messageContainer')}>
        <div
          className={classNames({
            [styles.displayNone]: isUser,
            [styles.icon]: isTutor,
            [styles.iconDesktop]: isTutor && isDesktop,
            [styles.pencil]: isExercice,
            [styles.pencilDesktop]: isTutor && isExercice,
          })}
        >
          <img src={icon} alt="tutor icon" />
        </div>

        <div className={classNames(styles.messageGroup)}>
          {message.slice(0, currentMessageIndex + 1).map((payload, index) => (
            <TutorMessage
              key={index}
              messageIndex={index}
              payload={payload}
              sender={sender}
            />
          ))}
        </div>
      </div>
      <div
        className={classNames(styles.info, {
          [styles.displayNone]: timeStamp,
          [styles.infoDesktop]: isDesktop,
        })}
      >
        <p
          className={classNames({
            [styles.marginLeft]: isUser,
            [styles.marginRight]: isTutor,
          })}
        >
          {isTutor ? t('tutorPrivate') : t('tutorUser')} • {timeStamp}
        </p>
        {includeThumbs && isTutor && (
          <div className={styles.like}>
            <img
              src={DisLikeIcon}
              alt="dislike icon"
              onClick={() => {
                dispatch(tutorActions.setUserFeedback('n'));
              }}
            />
            <img
              src={LikeIcon}
              alt="like icon"
              onClick={() => {
                dispatch(tutorActions.setUserFeedback('y'));
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TutorGroupMessages);
