import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from './TutorExitConfirm.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import TheButton from '@/components/TheButton/TheButton';
import tutorIcon from '@/assets/svg/tutor/tutorInprogress.svg';
import { tutorActions } from '@/store/reducers/tutor';
import UseScreenSize from '@/hooks/UseScreenSize';

type TutorExitConfirm = {
  emitClosePopUp: () => void;
  emitNavigateAway: () => void;
};

const TutorExitConfirm: React.FC<TutorExitConfirm> = ({
  emitClosePopUp,
  emitNavigateAway,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile, isDesktop } = UseScreenSize();

  return (
    <div
      className={classes(classNames.exitPopUp, {
        [classNames.isMobile]: isMobile,
      })}
    >
      <div
        className={classes(classNames.header, {
          [classNames.isMobile]: isMobile,
        })}
      >
        <p>{t('tutorExitTitle')}</p>
      </div>
      <div className={classNames.body}>
        <img src={tutorIcon} alt="tutor" />
        <span>{t('tutorExitText')}</span>
      </div>
      <div className={classNames.buttons}>
        <TheButton
          plain
          text={t('tutorExitYes')}
          emitOnClick={emitNavigateAway}
          className={classNames.close}
        />

        <TheButton
          shouldRecolor={false}
          text={t('tutorExitNo')}
          emitOnClick={emitClosePopUp}
          className={classNames.theButton}
        />
      </div>
    </div>
  );
};

export default React.memo(TutorExitConfirm);
