import React from 'react';
import {
  GameTypeProps,
  GameTypes,
  MultiChoiceProps,
  ClosedSpellingProps,
} from '@/types/game';

import MultiChoice from './MultiChoice/MultiChoice';
import ClosedSpelling from './ClosedSpelling/ClosedSpelling';
import Pronunciation from './Pronunciation/Pronunciation';
import Practice from './Practice/Practice';
import game from '@/store/reducers/game';
import MultiChoiceTutor from './MultiChoiceTutor/MultiChoiceTutor';

// Define a type for the components mapping
type GameComponentMapping = {
  type: GameTypes;
  component: React.ComponentType<
    GameTypeProps & (MultiChoiceProps | ClosedSpellingProps)
  >;
};

const GameTypeComponents: GameComponentMapping[] = [
  {
    type: GameTypes.Multichoice,
    component: MultiChoice,
  },
  {
    type: GameTypes.ClosedSpelling,
    component: ClosedSpelling,
  },
  {
    type: GameTypes.Pronunciation,
    component: Pronunciation,
  },
  {
    type: GameTypes.Practice,
    component: Practice,
  },
];

const GameType = (
  props: GameTypeProps & (MultiChoiceProps | ClosedSpellingProps)
) => {
  const gameTypeComponent = GameTypeComponents.find(
    (component) => component.type === props.type
  );

  // Now TypeScript knows the exact type of gameTypeComponent
  let ComponentToRender = gameTypeComponent?.component;

  // Tutor
  if (props.isTutor && gameTypeComponent?.type === GameTypes.Multichoice) {
    ComponentToRender = MultiChoiceTutor;
  }

  return ComponentToRender ? <ComponentToRender {...props} /> : null;
};

export default React.memo(GameType);
