import classes from 'classnames';
import { Topic, TopicTypes } from '@/types/topic';
import classNames from './TopicInfo.module.scss';
import commonUtils from '@/utils/common';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import UseSkills from '@/hooks/UseSkills';
import { gameSelector } from '@/store/reducers/game';

import QuizBadge from '@/components/QuizBadge/QuizBadge';
import finalAssessment from '@/assets/svg/finalAssessment.svg';
import trophy from '@/assets/svg/trophy.svg';
import lock from '@/assets/svg/lock.svg';
import star from '@/assets/svg/star.svg';
import UseLocalLang from '@/hooks/UseLocalLang';
import React from 'react';

interface TopicInfoProps {
  className?: string;
  isLocked: boolean;
  isComplete: boolean;
  topic: Topic;
  index: number;
  emitOpenModal: (topicId: number) => void;
}

const TopicInfo = ({
  className,
  isLocked,
  isComplete,
  topic,
  index,
  emitOpenModal,
}: TopicInfoProps) => {
  const { skillsAssets } = UseSkills();
  const { selectedType } = useAppSelector(gameSelector);
  const localLang = UseLocalLang();

  const skillAssets = selectedType && skillsAssets[selectedType];
  const { EndOfSkillTest } = TopicTypes;
  const setStatusSrc = () => {
    if (!isLocked) {
      return isComplete
        ? trophy
        : topic.topicType !== EndOfSkillTest
        ? skillAssets?.medal
        : finalAssessment;
    }

    return lock;
  };

  const handleOpenTopic = () => {
    if (isLocked) {
      return;
    }

    emitOpenModal(topic.id);
  };

  return (
    <div
      className={classes(
        classNames.topicInfo,
        { [classNames.topicInfoLocked]: isLocked },
        className
      )}
      onClick={handleOpenTopic}
    >
      <QuizBadge
        img={setStatusSrc() ?? ''}
        isComplete={isComplete}
        isLocked={isLocked}
        isEndOfSkillTest={topic.topicType === EndOfSkillTest}
        index={index}
      />
      <span className={classNames.title}>
        {topic.name &&
        topic.name.includes(' ') &&
        topic?.topicType === EndOfSkillTest
          ? topic.name.split(' ').map((word, index) => (
              <React.Fragment key={index}>
                {word}
                <br />
              </React.Fragment>
            ))
          : topic.name}
      </span>
      {!isLocked && topic.topicType !== EndOfSkillTest && (
        <div className={classNames.progress}>
          <SVG src={star} />
          <span>
            {topic.userData?.receivedStars ?? 0}/
            {commonUtils.totalTopicStars(topic)}
          </span>
        </div>
      )}
    </div>
  );
};

export default TopicInfo;
