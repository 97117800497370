import { useEffect, useState } from 'react';
import classNames from './LevelBySkillSelect.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UseLevel from '@/hooks/UseLevel';
import { useTranslation } from 'react-i18next';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';

interface LevelBySkillSelectProps {
  className?: string;
  skill: string;
  selectedLevel: number;
  shouldClose: boolean;
  emitSelect: (skill: string, level: number) => void;
  emitOpen: (skill: string) => void;
}

const LevelBySkillSelect = ({
  className,
  skill,
  selectedLevel,
  shouldClose,
  emitSelect,
  emitOpen,
}: LevelBySkillSelectProps) => {
  const { t } = useTranslation();
  const { levels } = useAppSelector(authSelector);
  const { getLevelText } = UseLevel();

  const [showLevelsDropdown, setShowLevelsDropdown] = useState(false);

  const selectedLevelText = selectedLevel ? getLevelText(+selectedLevel) : null;
  const levelArrowSrc = showLevelsDropdown ? upArrow : downArrow;

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showLevelsDropdown) {
        return;
      }

      onCloseLevelsDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showLevelsDropdown]);

  useEffect(() => {
    if (shouldClose) {
      onCloseLevelsDropdown();
    }
  }, [shouldClose]);

  const onToggleLevelsDropdown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    emitOpen(!showLevelsDropdown ? skill : '');
    setShowLevelsDropdown((prevState) => !prevState);
  };

  const onCloseLevelsDropdown = () => {
    setShowLevelsDropdown(false);
  };

  const onSelectedLevel = (
    e: React.MouseEvent<HTMLElement>,
    levelId: number
  ) => {
    e.stopPropagation();
    onCloseLevelsDropdown();
    emitSelect(skill, levelId);
    emitOpen('');
  };

  const levelOptions = levels.map((_level) => {
    const levelText = getLevelText(_level.id);

    return (
      <span
        key={_level.id}
        onClick={($event) => onSelectedLevel($event, _level.id)}
      >{`${_level.id} | ${levelText}`}</span>
    );
  });

  const levelsDropdown = showLevelsDropdown && (
    <div className={classNames.dropdown}>{levelOptions}</div>
  );

  return (
    <div className={classes(classNames.levelBySkillSelect, className)}>
      <div className={classNames.select}>
        <span className={classNames.label}>{t(`skills.${skill}`)}</span>
        <div className={classNames.input} onClick={onToggleLevelsDropdown}>
          <span>{`${selectedLevel} | ${selectedLevelText}`}</span>
          <SVG className={classNames.arrow} src={levelArrowSrc} />
        </div>
      </div>
      {levelsDropdown}
    </div>
  );
};

export default LevelBySkillSelect;
