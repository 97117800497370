import { AppInputProps } from '@/types/common';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COUNTRIES } from '@/utils/countries';

import commonUtils from '@/utils/common';

import mexicanFlag from '@/assets/svg/mexican-flag.svg';

const UseOrganizationEditor = () => {
  const { t } = useTranslation();

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = target;

    setOrganizationDetailsFields((prevState) => {
      const updatedState = [...prevState];

      const fieldIndex = updatedState.findIndex((field) => field.name === name);

      if (name === 'termsAndConditions') {
        updatedState[fieldIndex].value =
          updatedState[fieldIndex].value === 'false' ? 'true' : 'false';
      } else {
        updatedState[fieldIndex].value = value;
      }

      updatedState[fieldIndex].isValid = false;
      updatedState[fieldIndex].error = '';

      return updatedState;
    });
  };

  const onSelect = (name: string, value: string, label?: string) => {
    setOrganizationDetailsFields((prevState) => {
      const updatedState = [...prevState];

      const fieldIndex = updatedState.findIndex((field) => field.name === name);
      const cityFieldIndex = updatedState.findIndex(
        (field) => field.name === 'city'
      );

      if (name === 'state') {
        updatedState[cityFieldIndex].value = '';
        updatedState[cityFieldIndex].label = '';
        updatedState[cityFieldIndex].isValid = false;
        updatedState[cityFieldIndex].error = '';
      }

      updatedState[fieldIndex].value = value;
      updatedState[fieldIndex].label = label;
      updatedState[fieldIndex].isValid = false;
      updatedState[fieldIndex].error = '';

      return updatedState;
    });
  };

  const onOrganizationDetailsSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setOrganizationDetailsFields((prevState) => {
      const updatedState = [...prevState];

      for (let i = 0; i < updatedState.length; i++) {
        const { id, value } = updatedState[i];

        if (!value) continue;

        const isSchoolNameField = id === 'school_name';
        const isStateField = id === 'state';
        const isCityField = id === 'city';

        if (isSchoolNameField || isStateField || isCityField) {
          updatedState[i].isValid = Boolean(value.trim());

          if (!value.trim()) {
            updatedState[i].error = t('invalidField');
          }
        } else if (id === 'terms_and_conditions') {
          const isTermsAndConditionsValid = value === 'true';

          updatedState[i].isValid = isTermsAndConditionsValid;

          if (!isTermsAndConditionsValid) {
            updatedState[i].error = t('fieldIsMandatory');
          }
        }
      }

      return updatedState;
    });
  };

  const initialOrganizationDetailsFields: AppInputProps[] = [
    {
      id: 'school_name',
      name: 'schoolName',
      label: 'schoolName',
      placeholder: 'schoolName',
      value: '',
      error: '',
      isRequired: true,
      isValid: false,
      emitChange: onInputChange,
    },
    {
      id: 'state',
      name: 'state',
      label: 'state',
      placeholder: 'state',
      value: '',
      error: '',
      isRequired: true,
      isValid: false,
      emitSelect: onSelect,
    },
    {
      id: 'city',
      name: 'city',
      label: 'city',
      placeholder: 'city',
      value: '',
      error: '',
      isRequired: true,
      isValid: false,
      emitSelect: onSelect,
    },
    {
      id: 'country',
      name: 'country',
      label: 'country',
      placeholder: '',
      value: '',
      error: '',
      isDisabled: true,
      countryFlagImg: '',
      isValid: false,
      emitSelect: onSelect,
    },
    {
      id: 'native_language_code',
      name: 'nativeLanguageCode',
      label: 'language',
      placeholder: '',
      value: '',
      error: '',
      isDisabled: true,
      hideValue: true,
      img: '',
      isValid: false,
      emitChange: onInputChange,
    },
    {
      id: 'terms_and_conditions',
      name: 'termsAndConditions',
      type: 'checkbox',
      value: 'false',
      error: '',
      isRequired: true,
      isValid: false,
      emitChange: onInputChange,
    },
  ];

  const [organizationDetailsFields, setOrganizationDetailsFields] = useState<
    AppInputProps[]
  >(initialOrganizationDetailsFields);

  const onUpdateOrganizationDetailsFields = (
    country: string,
    nativeLanguageCode: string
  ) => {
    setOrganizationDetailsFields((prevState) => {
      const updatedState = [...prevState];

      const countryIndex = updatedState.findIndex(
        (field) => field.id === 'country'
      );
      const nativeLanguageCodeIndex = updatedState.findIndex(
        (field) => field.id === 'native_language_code'
      );

      const isSpanish = nativeLanguageCode === 'es';

      const countryObj = COUNTRIES.find(
        (_country) => _country.name.toLowerCase() === country.toLowerCase()
      );

      updatedState[countryIndex].value = country;
      updatedState[countryIndex].isValid = true;
      updatedState[countryIndex].countryFlagImg =
        countryObj?.code.toLocaleLowerCase();

      updatedState[nativeLanguageCodeIndex].value = nativeLanguageCode;
      updatedState[nativeLanguageCodeIndex].isValid = true;
      updatedState[nativeLanguageCodeIndex].placeholder =
        t(nativeLanguageCode) ?? '';
      updatedState[nativeLanguageCodeIndex].img =
        commonUtils.getLanguageIcon(nativeLanguageCode);

      return updatedState;
    });
  };

  return {
    organizationDetailsFields,
    onUpdateOrganizationDetailsFields,
    onOrganizationDetailsSubmit,
  };
};

export default UseOrganizationEditor;
