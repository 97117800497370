import SVG from 'react-inlinesvg';
import classNames from './GenerateReport.module.scss';
import downLoadSvg from '../../../../assets/svg/download.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  GenerateReportProps,
  ReportTypes,
} from '../../../../types/generate-report';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Header from './Header/Header';
import TopCard from './TopCard/TopCard';
import StudentSkillProgress from './StudentSkillProgress/StudentSkillProgress';
import ClassStudentLevels from './ClassStudentLevels/ClassStudentLevels';
import AverageGameScores from './AverageGameScores/AverageGameScores';
import ActivityCircleChart from './ActivityCircleChart/ActivityCircleChart';
import AverageData from './AverageData/AverageData';
import AppModal from '@/components/AppModal';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import { useAppDispatch, useAppSelector } from '@/store';
import reportThunks from '@/store/thunks/report';
import { reportSelector } from '@/store/reducers/report';

const GenerateReport = ({
  reportType,
  currentClassroom,
  currentStudent,
}: GenerateReportProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { reportContent } = useAppSelector(reportSelector);
  const [loadingReport, setLoadingReport] = useState(false);
  const [isErrorDownloading, setIsErrorDownloading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);

  const isStudentReport = reportType === ReportTypes.student && currentStudent;
  const isClassReport = reportType === ReportTypes.class && currentClassroom;

  const getReportContent = async () => {
    if (isStudentReport) {
      await dispatch(reportThunks.getStudentReport(currentStudent)).unwrap();
    }
    if (isClassReport) {
      await dispatch(reportThunks.getClassReport(currentClassroom)).unwrap();
    }
  };

  useEffect(() => {
    getReportContent();
  }, [reportType]);

  const generateReportPdf = (elementId: string) => {
    if (!reportContent) {
      setIsServerError(true);
      return;
    }

    const element = document.getElementById(elementId);

    if (!element) return;

    element.style.display = 'flex';

    html2canvas(element, { scale: 5 })
      .then((canvas) => {
        element.style.display = 'none';

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', [297, 210]);

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        const hRatio = imgHeight / imgWidth;

        const width = pdf.internal.pageSize.getWidth();
        const height = width * hRatio;

        pdf.addImage(imgData, 'PNG', width * 0, 0, width * 1, height * 0.95);

        pdf.save(
          isStudentReport
            ? `${t('studentReport')}.pdf`
            : `${t('classReport')}.pdf`
        );
        setLoadingReport(false);
      })
      .catch((error) => {
        setIsErrorDownloading(true);
        setLoadingReport(false);
      });
  };

  return (
    <>
      {loadingReport && (
        <AppModal>
          <div className={classNames.appModal}>
            <AppLoaderCircle />
          </div>
        </AppModal>
      )}

      {(isErrorDownloading || isServerError) && (
        <AppModal>
          <div className={classNames.errorModal}>
            <div className={classNames.card}>
              <h1>{t('weCouldNotProduce')}</h1>
              <div>
                <h2>{t('weStillDontHaveAll')}</h2>
                <h3>{t('tryAgainLater')}</h3>
              </div>
              <button onClick={() => location.reload()}>{t('done')}</button>
            </div>
          </div>
        </AppModal>
      )}
      <button
        className={classNames.button}
        onClick={() => {
          setLoadingReport(true);
          setTimeout(() => {
            generateReportPdf(`toCapture-${reportType}`);
          }, 1000);
        }}
      >
        <SVG src={downLoadSvg} />
        <h2>
          {isStudentReport ? t('generateReport') : t('generateClassReport')}
        </h2>
      </button>

      <div id={`toCapture-${reportType}`} className={classNames.pdfContainer}>
        <Header
          reportType={reportType}
          currentClassroom={currentClassroom}
          currentStudent={currentStudent}
          reportContent={reportContent}
        />
        <div
          className={classNames.contentContainer}
          style={{ gap: !isStudentReport ? '20px' : '' }}
        >
          <TopCard reportType={reportType} reportContent={reportContent} />
          {isStudentReport ? (
            <StudentSkillProgress reportContent={reportContent} />
          ) : (
            <ClassStudentLevels reportContent={reportContent} />
          )}
          <AverageGameScores
            reportContent={reportContent}
            reportType={reportType}
          />
          <div className={classNames.bottomCardsContainer}>
            <ActivityCircleChart
              reportContent={reportContent}
              reportType={reportType}
            />
            <AverageData reportContent={reportContent} />
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateReport;
