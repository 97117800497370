import { RootState } from '@/types/common';
import { GenerateReportProps, ReportContent } from '@/types/generate-report';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import reportThunks from '../thunks/report';

const initialState: GenerateReportProps = {
  reportContent: null,
};

const report = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: {
    [reportThunks.getStudentReport.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<{ data: ReportContent }>
    ) => {
      state.reportContent = payload.data;
    },
    [reportThunks.getClassReport.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<{ data: ReportContent }>
    ) => {
      state.reportContent = payload.data;
    },
  },
});

export const reportActions = report.actions;

export const reportSelector = (state: RootState) => state.report;

export default report.reducer;
