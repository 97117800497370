import React from 'react';
import { COLORS, Skills } from '../../../../types/generate-report';
import { useTranslation } from 'react-i18next';

const SkillCircle = ({ skills }: { skills?: Skills }) => {
  const { t } = useTranslation();

  const calculatePath = (startPercent: number, endPercent: number) => {
    const startAngle = (startPercent / 100) * 2 * Math.PI - Math.PI / 2;
    const endAngle = (endPercent / 100) * 2 * Math.PI - Math.PI / 2;

    const largeArcFlag = endPercent - startPercent > 50 ? 1 : 0;

    const startX = Math.cos(startAngle);
    const startY = Math.sin(startAngle);
    const endX = Math.cos(endAngle);
    const endY = Math.sin(endAngle);

    return `M ${startX} ${startY} A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`;
  };

  let startPercent = 0;

  if (!skills) {
    return <> </>;
  }

  const isNoActivity = !skills || Object.values(skills).every((v) => v === 0);

  return (
    <>
      <svg width="200" height="200" viewBox="-1.2 -1 2.4 2">
        {isNoActivity ? (
          <>
            <path
              d={calculatePath(0, 99.9999)}
              fill="none"
              stroke={'rgba(217, 217, 217, 1)'}
              strokeWidth={0.3}
            />
            <text
              font-size="0.16"
              x="0"
              y="0.08"
              text-anchor="middle"
              fill="rgba(0, 0, 0, 0.8)"
            >
              {t('noDataToDisplay')}
            </text>
          </>
        ) : (
          Object.entries(skills).map(([skill, percent]) => {
            const endPercent = startPercent + (percent - 0.001);
            const path = calculatePath(startPercent, endPercent);
            startPercent = endPercent;
            return (
              <path
                key={skill}
                d={path}
                fill="none"
                stroke={(COLORS as any)[skill]}
                strokeWidth={0.3}
              />
            );
          })
        )}
      </svg>
    </>
  );
};

export default SkillCircle;
