import { useTranslation } from 'react-i18next';
import classNames from './PopUpFinalAssessmentSummary.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { finalAssessmentSelector } from '@/store/reducers/final-assessment';
import { PopUpFinalAssessmentSummaryProps } from '@/types/game';
import SVG from 'react-inlinesvg';

import TheButton from '@/components/TheButton/TheButton';

import trophyHighest from '@/assets/svg/final-assessment-trophy-highest.svg';
import trophyLowest from '@/assets/svg/final-assessment-trophy-lowest.svg';
import trophyFailed from '@/assets/svg/final-assessment-trophy-failed.svg';

const PopUpFinalAssessmentSummary = ({
  emitOnClosePopUp,
}: PopUpFinalAssessmentSummaryProps) => {
  const { t } = useTranslation();
  const { progress } = useAppSelector(finalAssessmentSelector);

  const { score, isFailed } = progress ?? {};

  const userScore = score ?? 0;
  const lowestScore = 60;
  const highestScore = 80;

  const scoreText = t('you_got_a_score_of', { score: userScore });

  let title = t('youDidNotPass');
  let description = `${t('weRecommendThatYouGoBackToYourPreviousGames')} ${t(
    'thisWillHelpYouUnderstandTheMaterialBetter'
  )} ${t('andYouCanImproveYouScores')}`;
  let imageSrc = trophyFailed;

  const isHighestScore = userScore >= highestScore;
  const isLowestScore = userScore >= lowestScore && userScore < highestScore;

  if (isHighestScore) {
    title = `${t('excellent')}!`;
    description = t('itLooksLikeYouReallyKnowTheMaterial');
    imageSrc = trophyHighest;
  } else if (isLowestScore) {
    title = `${t('notBad')}!`;
    description = `${t('weRecommendThatYouGoBackToYourPreviousGames')} ${t(
      'thisWillHelpYouUnderstandTheMaterialBetter'
    )}.`;
    imageSrc = trophyLowest;
  }

  return (
    <div className={classNames.popUpFinalAssessmentSummary}>
      <div className={classNames.content}>
        <h2>{title}</h2>
        <SVG src={imageSrc} />
        <p
          className={classNames.description}
        >{`${scoreText}. ${description}`}</p>
        <TheButton
          className={classNames.btn}
          text={t('goBackToSkills')}
          showArrow
          shouldRecolor={false}
          emitOnClick={emitOnClosePopUp}
        />
      </div>
    </div>
  );
};

export default PopUpFinalAssessmentSummary;
