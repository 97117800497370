import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { registerService } from '@/services/register';
import { AdminOrganizationDetails } from '@/types/register';
import commonUtils from '@/utils/common';
import UseOrganizationEditor from '@/hooks/UseOrganizationEditor';

import AdminOnboarding from '@/components/AdminOnboarding/AdminOnboarding';
import TeacherOnboarding from '@/components/TeacherOnboarding/TeacherOnboarding';
import OrganizationDetails from '@/components/OrganizationDetails/OrganizationDetails';

const OnboardingView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user, loggedIn, nativeLanguage } = useAppSelector(authSelector);
  const navigate = useNavigate();

  const isStudent = UserUtils.isStudent(user);
  const isAdmin = UserUtils.isAdmin(user);

  const {
    organizationDetailsFields,
    onOrganizationDetailsSubmit,
    onUpdateOrganizationDetailsFields,
  } = UseOrganizationEditor();

  useEffect(() => {
    if (isStudent) {
      const to = loggedIn ? '/dashboard/games' : '/login';
      return navigate(to);
    }

    if (isAdmin && !user?.isCreateOrgComplete) {
      onUpdateOrganizationDetailsFields(
        user?.country ?? '',
        nativeLanguage ?? ''
      );
    }
  }, []);

  useEffect(() => {
    const checkGeneralDetailsForm = async () => {
      const isValid = organizationDetailsFields.every((field) => field.isValid);

      if (isValid) {
        try {
          const organizationName =
            organizationDetailsFields
              .find((field) => field.id === 'school_name')
              ?.value?.trim() ?? '';

          const cityOrDistrict =
            organizationDetailsFields
              .find((field) => field.id === 'city')
              ?.label?.trim() ?? '';

          const state =
            organizationDetailsFields
              .find((field) => field.id === 'state')
              ?.label?.trim() ?? '';

          const organization: AdminOrganizationDetails = {
            organizationName,
            cityOrDistrict,
            state,
          };

          const adminOrganizationResponse = (
            await registerService.createOrganization(
              user,
              user?.id ?? '',
              organization
            )
          )?.data.data;

          if (adminOrganizationResponse) {
            dispatch(authActions.setIsCreateOrgComplete(true));
          }
        } catch (e: any) {
          commonUtils.showToast(t(e as string));
        }
      }
    };

    if (isAdmin) {
      checkGeneralDetailsForm();
    }
  }, [organizationDetailsFields]);

  let content = <TeacherOnboarding shouldNavigate />;

  if (isAdmin) {
    content = user?.isCreateOrgComplete ? (
      <AdminOnboarding />
    ) : (
      <OrganizationDetails
        fields={organizationDetailsFields}
        emitSubmit={onOrganizationDetailsSubmit}
      />
    );
  } else {
    content = <TeacherOnboarding shouldNavigate />;
  }

  return <div className="onboarding-view w-full h-full">{content}</div>;
};

export default OnboardingView;
