import classNames from './PopUpWordsList.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { PopUpWordsListProps } from '@/types/game';
import React from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useTranslation } from 'react-i18next';

import WordsList from './WordsList/WordsList';

import close from '@/assets/svg/close.svg';

const PopUpWordsList = ({
  className,
  emitOnClosePopUp,
  emitOnAknowledge,
}: PopUpWordsListProps) => {
  const { isMobile } = UseScreenSize();
  const { t } = useTranslation();

  return (
    <div className={classes('container', className, classNames.popUpWordsList)}>
      {isMobile && (
        <div className={classNames.close} onClick={() => emitOnClosePopUp?.()}>
          <SVG src={close} />
        </div>
      )}
      <h2 className={classNames.title}>{t('wordsList')}</h2>
      <WordsList emitOnAknowledge={emitOnAknowledge} />
    </div>
  );
};

export default React.memo(PopUpWordsList);
