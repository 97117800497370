import { DateTime } from 'luxon';

const baseFormat = 'DD MMM YY HH:mm';

const DateUtils = {
  formatUTC: (date: Date, format = baseFormat) =>
    DateTime.fromJSDate(date).toUTC().toFormat(format),

  dayDiff: (startDate: DateTime, endDate: DateTime) =>
    endDate.diff(startDate, ['days']).toObject(),
};

export default DateUtils;
