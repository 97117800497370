import React from 'react';
import classNames from './SkillsSection.module.scss';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UseUserProgress from '@/hooks/UseUserProgress';

import SkillsGrid from '../SkillsGrid/SkillsGrid';

interface SkillsSectionProps {
  handleScrollToMap: () => void;
}

const SkillsSection = ({ handleScrollToMap }: SkillsSectionProps) => {
  const { userProgress } = useAppSelector(authSelector);
  const { loadingProgress } = UseUserProgress();
  return (
    <div className={classNames.skillsSection}>
      <SkillsGrid
        handleScrollToMap={handleScrollToMap}
        className="h-full"
        loading={loadingProgress}
        progress={userProgress}
        shouldExpand={false}
        isLarge={true}
      />
    </div>
  );
};

export default React.memo(SkillsSection);
