import { useTranslation } from 'react-i18next';
import classNames from './AverageData.module.scss';
import {
  GenerateReportProps,
  ReportTypes,
} from '../../../../../types/generate-report';
import classes from 'classnames';
import commonUtils from '@/utils/common';
const AverageData = ({ reportContent }: GenerateReportProps) => {
  const { t } = useTranslation();

  const averageTimeSpent = commonUtils.parseTime(
    reportContent?.averageTimeSpent
  );

  return (
    <div className={classNames.averageDataCard}>
      <div
        className={classes(classNames.card, {
          [classNames.borderBottom]: true,
        })}
      >
        <div>
          <h1>📍{t('avgAttempts')}</h1>
          <h3>{t('perGame')}</h3>
        </div>
        <div className={classNames.number}>
          <h2>
            {reportContent?.averageAttempts
              ? reportContent.averageAttempts
              : '-'}
          </h2>
        </div>
      </div>
      <div className={classNames.card}>
        <div>
          <h1>⏱️ {t('avgTimeSpent')}</h1>
          <h3>{t('perGame')}</h3>
        </div>
        <div className={classNames.number}>
          <h2>
            {averageTimeSpent && reportContent
              ? reportContent.averageTimeSpent
              : '-'}
          </h2>
        </div>
      </div>
      <div
        className={classes(classNames.card, {
          [classNames.borderTop]: true,
        })}
      >
        <div>
          <h1>💡 {t('totalHintsUsed')}</h1>
          <h3>{t('inAllGames')}</h3>
        </div>
        <div className={classNames.number}>
          <h2>
            {reportContent?.totalHintsUsed ? reportContent.totalHintsUsed : '-'}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default AverageData;
