import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const auth = useAppSelector(authSelector);

  if (auth.loggedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};

export default PublicRoute;
