export const TRANSLATIONS_EN = {
  CodeMismatchException: 'Invalid code',
  ExpiredCodeException: 'Invalid code',
  IAlreadyHaveAnAccount: 'I already have an account',
  LimitExceededException: 'Attempt limit exceeded, please try after some time.',
  UserNotConfirmedException:
    'Your account is not verified. Please enter the verification code sent to your email.',
  accountVerifiedCanLoginNow:
    'Your account has been verified, you can login now',
  account_settings: 'Account Settings',
  achievmentsSection: {
    feedback: 'You’ve showed some extraordinary skills!',
    greatJob: 'Great job!',
    in: 'In',
    weeklyAchievement: 'Achievment of the week',
  },
  addClass: 'Add Class',
  addNewClass: 'Add New Class',
  advanceToTheNextLevel: 'advance to the next level.',
  advanced: 'Advanced',
  again: 'again',
  all: 'All',
  allDone: 'All done!',
  allLevels: 'All Levels',
  allowIndividualAdvancement: 'Allow Level Advancement',
  almostFinish: 'Almost finished!',
  almostHalfWayThere: "You're almost half-way there!",
  almostMadeIt: 'Almost made it',
  almostThere: "You're almost there...",
  almost_made_it: 'Almost made it',
  amazingJob: 'Amazing Job!',
  amazing_job: 'Amazing job',
  anErrorOccurred: 'An error has occurred',
  anUnauthorizedRequestErrorOccurred: 'An unauthorized request error occurred.',
  andBeginYour: 'and begin your English-learning journey.',
  andInviteYourStudents: 'and invite your students',
  andThenInvite: 'and then invite your students to join the class',
  andYouCanImproveYouScores: 'and you can improve your scores.',
  and_got_all_the_stars: 'and got all the stars',
  answeredIncorrectly: 'answered incorrectly.',
  appName: 'Britannica ELL',
  appTitle: 'Britannica',
  app_banner: 'App Banner',
  ar: 'عربيه',
  areYouReadyForTheQuiz: "Do you think your're ready for the game?",
  areYouSure: 'Are you sure?',
  asSoonAsTheyStart:
    'As soon as they start  their journey, you can view their progress here.',
  assessment: 'Assessment',
  assessments: 'Assessments',
  assignments: 'Assignments',
  atLeastOneNumber: 'Contains at least 1 number',
  atLeastOneSpecial: 'Contains at least 1 special character',
  atLeastOneUppercase: 'Contains at least 1 uppercase letter',
  averageStudentLevel: 'Average Student Level',
  avgAttempts: 'Avg. Attempts',
  avgClassScores: 'Avg. Class Scores',
  avgFailureRate: 'Avg Failure Rate',
  avgGameScores: 'Avg. Game Scores',
  avgPassRate: 'Avg Pass Rate',
  avgQuizzes: 'Avg. quizzes completed per student',
  avgTimeSpent: 'Avg. Time Spent',
  back: 'Back',
  backHome: 'Back Home',
  backToHomePage: 'Back to Home Page',
  backToTopics: 'Back to topics',
  back_home: 'Back Home',
  badgesSection: {
    link: 'View all',
    title: 'My Recent Badges',
  },
  britannicaEducationWith: 'Britannica Education with',
  byClickingOn:
    'By clicking on the student’s name, you’ll see how they are performing in each skill and how often they use the app . You can also decide which interface language they should use.',
  cancel: 'Cancel',
  cancelAnyway: 'Cancel anyway',
  changeLevel: 'Change Level',
  change_password: 'Change Password',
  characters: 'Characters',
  checkInSpamMessage:
    'Didn’t receive it? Try checking your <0>spam</0> folder.',
  checkInSpamMessageTeacher: 'spanish_only_do_not_touch',
  choose: 'Choose',
  chooseAClass: 'Choose a class',
  chooseAvatar: 'Choose avatar',
  chooseDate: 'Choose Date',
  chooseGrade: 'Choose grade',
  chooseTheCorrectAnswer: 'Choose the correct answer to fill in sentence',
  chooseYourFavoriteAvatar: 'Choose your favorite avatar and color',
  city: 'City or District',
  class: 'Class',
  classActivity: 'Class Activity',
  classAvg: 'Class Avg',
  classCreated: 'The class has been created successfully!',
  classCreatedDescription:
    'Your students have been sent an email with instructions on resetting their password. Once they’ve done that, they’ll begin their English learning journey.',
  className: 'Class Name',
  classReport: 'Class Report',
  classes: 'Classes',
  clickOnAStudent: 'Click on a student',
  clickToCopy: 'Click to copy your invitation',
  clickToTryAgain: 'Click to try again',
  clickToWatchAssessments: 'Click to watch student’s assessments!',
  coins: 'Coins',
  coinsWillBeLost: 'and the coins you won will be lost',
  complete: 'Complete',
  confirmPassword: 'Confirm Password',
  contactOurTeam: 'Contact our team at',
  contactOurTeamWithQuestions: 'Contact our team with questions and concerns.',
  contactUs: 'Contact Us',
  continue: 'Continue',
  continueCreating: 'Continue Creating',
  continueOn: 'Continue on',
  continueOnWeb: 'Continue on web',
  continueQuiz: 'Continue Game',
  continueToQuestions: 'Continue To Questions',
  continue_level_test: 'Continue Level Test',
  copy: 'Copy',
  copyInvitationLink: 'Copy Invitation Link',
  copyLink: 'Copy Link',
  copyLinkOnly: 'Copy link only',
  country: 'Country',
  create: 'Create',
  createClass: 'Create Class',
  createNewClass: 'Create new class',
  createYourClass: 'Create your class and invite your students',
  createYourFirstClass: 'Create your first class',
  currentLevel: 'Current level',
  current_password: 'Current Password',
  customYourLearning:
    "Soon we'll be able to customize your learning experience!",
  dashboard: 'Dashboard',
  date: 'Date',
  dearSchoolAdmin: 'Dear school administrator, you’ve been',
  dearStudent: 'Dear student, you’ve been invited by ',
  dearTeacher: 'Dear teacher, you’ve been invited to join',
  dearTeacherLevel: 'Dear Teacher,',
  dearTeacherPleaseJoin:
    'Dear teacher, please join {{schoolName}} so that you will be able to create your classes and start using Britannica’s English learning platform.',
  deleteClass: 'Delete class',
  dictionary: 'Dictionary',
  didYouMean: 'Did you mean',
  didntQuiteHearThat: "Didn't quite hear that. Try again.",
  discoverAWorld:
    'Discover a world of dynamic learning paths and robust tools to elevate your English language skills.',
  doingGreat: "You're doing great!",
  done: 'Done',
  dontKnow: "Don't Know",
  downloadAndView: 'Download and view',
  editClass: 'Edit class',
  email: 'Email',
  emailAlreadyExists: 'An account with the given email already exists',
  emailExample: 'example@gmail.com',
  emailIsValidReceiveVerificationCode:
    'If the email account is valid, you will receive a verification code to continue the password reset process.',
  emailVerification: 'Email Verification',
  empowerEnrichAndInspire: 'empower, enrich, and inspire us',
  en: 'English',
  endOfLevelAssessment: 'End of Level Assessment',
  english: 'English',
  englishLearningJourney: 'English-learning journey with Britannica English.',
  englishLearningJourneyMorfix:
    'English-learning journey with Morfixschool 2.0.',
  englishSkillsAssessment: 'English Skills Assessment',
  englishSkillsAssessments: 'English Skills Assessments',
  englishSkillsProgress: 'English Skills Progress',
  enterCodeHere: 'Enter code here',
  enterNewPassword: 'Enter new password',
  enterVerificationCode: 'Enter Verification Code',
  enterVerificationCodeAndNewPassword:
    'Enter verification code and new password',
  es: 'Español',
  exampleEmail: 'example@gmail.com',
  exampleSentences: 'Example sentences',
  examples: 'examples',
  excellent: 'Excellent',
  exit: 'Exit',
  extra: 'Extra',
  extraCoins: 'Extra Coins',
  failed_quiz_message_again:
    "You still didn't pass this game, so no extra coins, no stars.",
  failed_quiz_message_first:
    "Unfortunately, you didn't pass, so you won't receive any coins for this game.",
  failed_quiz_message_passed:
    "You didn't pass. Don't worry, your previous score has been saved.",
  failureRate: 'Failure Rate',
  fewMoreSeconds: 'Few more seconds',
  fieldIsMandatory: 'This field is mandatory',
  filter: 'Filter',
  finalAssessment: 'Final Assessment',
  finalEnglishLevelTest: 'Final English level Test',
  firstname: 'First Name',
  focus: 'Focus!',
  for: 'for',
  forgotPassword: 'Forgot password',
  foundation: 'Foundation',
  friday: 'friday',
  fridayShort: 'Fri',
  from: 'From',
  fullVersionAvilableOnlyOnDesktop: 'Full version available only on desktop',
  generalLevel: 'General Level',
  generateClassReport: 'Generate Class Report',
  generateReport: 'Generate Report',
  getApp: 'Get the app',
  getFullExperience: 'Get the full experience with the Britannica English app',
  getStarted: 'Get Started',
  gettingStarted: 'Getting Started',
  giveFeedback: 'Contact Us',
  goAheadAndShareThisLinkWithYourStudentsNow:
    'Go ahead and share this link with your students now. ',
  goAheadJoinMyClass: 'Hi, Go ahead join my class using this link',
  goBackToSkills: 'Go back to skills',
  goToLevelTest: 'Go to Level Test',
  goToSkill: 'Go to {{skill}}',
  go_back_btn: 'Go Back Button',
  good: 'Good',
  goodJob: 'Good job!',
  goodLuck: 'Good luck!',
  gotAllStars: 'and got all the stars',
  gotThis: 'Got this!',
  grade: 'Grade',
  grade_1: '1st',
  grade_10: '10th',
  grade_10th: '10th',
  grade_11: '11th',
  grade_11th: '11th',
  grade_12: '12th',
  grade_12th: '12th',
  grade_1st: '1st',
  grade_2: '2nd',
  grade_2nd: '2nd',
  grade_3: '3rd',
  grade_3rd: '3rd',
  grade_4: '4th',
  grade_4th: '4th',
  grade_5: '5th',
  grade_5th: '5th',
  grade_6: '6th',
  grade_6th: '6th',
  grade_7: '7th',
  grade_7th: '7th',
  grade_8: '8th',
  grade_8th: '8th',
  grade_9: '9th',
  grade_9th: '9th',
  grammar_tip: 'Grammar Tip',
  great: 'Great!',
  greatJob: 'Great job!',
  he: 'Hebrew',
  hello: 'Hello',
  helloWorld: 'Hello, World!',
  hi: 'Hi',
  hints: 'Hints',
  hints_options: {
    add_more_time: 'Add Time on Clock',
    hear_the_english_word: 'Hear the English word',
    remove_a_letter: 'Remove a letter',
    remove_one_answer: 'Remove one answer',
    see_the_translation: 'See the translation',
    see_the_word: 'See the word',
    show_a_letter: 'Show a letter',
  },
  homepage: 'Home Page',
  howsYourEnglish: "How's Your English?",
  iAgreeTo: 'I agree to the',
  id: 'ID',
  illDoItLater: 'I’ll do it later',
  improveYourRecentGames: 'Improve your recent games',
  improveYourRecentVocabularyQuizzes: 'Improve your recent vocabulary games',
  inAllGames: 'In All Games',
  in_order_to_proceed_ask_teacher:
    'In order to proceed to the next level, your teacher must allow you to do so.',
  in_order_to_start: 'In order to start your learning journey, we need to',
  incorrect: 'Incorrect!',
  incorrect_answer: 'incorrect.',
  initialLevelSetTest: 'Initial Level Set Test',
  initialLevelTest: 'Initial Level Test',
  instructionalMaterial: 'Instructional Material',
  interfaceLanguage: 'Interface language',
  interface_language: 'Interface Language',
  intermediate: 'Intermediate',
  invalidCode: 'Invalid code',
  invalidEmail: 'Invalid email',
  invalidField: 'Invalid field',
  invalidFirstname: 'Invalid firstname',
  invalidLastname: 'Invalid lastname',
  invalidPassword: 'Invalid password',
  invalidUsernameOrPassword: 'Invalid username or password',
  invitationLink: 'Invitation Link',
  invitationLinkWontChange: "Don't worry- invitation link won't change",
  inviteYourStudents: 'Invite Your Students',
  inviteYourTeachers: 'Invite your teachers to create their classes.',
  invitedToJoin: 'invited to join Britannica English.',
  invitedToJoinMorfix: 'invited to join Morfixschool 2.0.',
  itLooksLikeYouReallyKnowTheMaterial:
    'It looks like you really know the material and are advancing nicely. Focus on completing all skills and you will continue to advance.',
  itsTime: "It's time to choose whether you're ready for the game or not,",
  joinHere: 'Join here:',
  joinNow: 'Join Now',
  keepGoing: 'Keep going',
  keepPlaying: 'Keep playing!',
  keepUpTheGoodWork: 'Keep up the good work',
  know: 'Know',
  know_your_level: 'know your level of English.',
  language: 'Language',
  language_of_study: 'Language of study',
  languages: {
    ar: 'Arabic',
    en: 'English',
    es: 'Spanish',
    he: 'Hebrew',
    pt: 'Portuguese',
  },
  lastname: 'Last Name',
  leave_level_test_and_sign_out: 'Leave level test and sign out?',
  letsAssess:
    'Let’s check your English with a short quiz. That way, we can provide you with the best learning experience.It may take anywhere from 5-15 minutes based on your performance. Are you ready?',
  letsBegin: "Let's Begin",
  letsFinish: "Lets's Finish",
  letsGo: "Let's Go",
  letsGoFinalAssessment: 'Let’s Go',
  letsKeepLearning: "let's keep learning!",
  letsSee: "Let's see how well you know your vocabulary.",
  letsSeeIfYouReady: "Let's see if you're ready to",
  level: 'Level',
  levelAchievement: 'Level Achievement',
  levelPerSkill: 'Level Per Skill',
  levelTest: 'Level test',
  levelTestIncomplete: 'Level Test Incomplete',
  linkCopied: 'Link Copied!',
  listen_tip: 'Listen Tip',
  listeningTapToFinish: 'Listening. Tap to finish',
  loading_spinner: 'Loading Spinner',
  loginFromDifferent: 'Log in from different account',
  logout: 'Log Out',
  looksLikeYouOnARoll: "Looks like you're on a roll",
  melingoCalculateYourLevel: 'Melingo calculate your level ...',
  menu_button: 'Menu Button',
  message: 'Message',
  monday: 'monday',
  mondayShort: 'Mon',
  moreTime: 'more time',
  moreTimes: 'more times',
  mustFirstCreateClass:
    'In order for your students to begin using the platform, you must first create a class and then invite them to join.',
  myProfile: 'My Profile',
  myProgress: 'My Progress',
  myRecentTranslations: 'My recent translations',
  needHelp: 'Need Help?',
  new_password: 'New Password',
  new_password_verification: 'Re-Enter New Password',
  next: 'Next',
  nextQuestion: 'Next question',
  nextQuiz: 'Next game',
  nextUpInYourLearningPath: 'Next up in your learning path',
  nextWord: 'Next Word',
  next_button: 'Next Button',
  noCoins: 'No Coins',
  noDataToDisplay: 'No data to display',
  noExtraCoins: 'No Extra Coins',
  noExtraCoinsThisTime: 'so no extra coins this time',
  noResultsFor: 'No Results for {{word}}',
  none: 'None',
  notBad: 'Not Bad',
  notMadeIt: "It looks like you didn't make it this time, you can",
  notMadeItInTime:
    'It looks like you didn’t make it this time. We’re moving on to the next question!',
  ofThisSite: 'of this site.',
  ok: 'OK',
  okLetsBegin: "OK, let's begin!",
  okLetsGo: "OK, Let's go!",
  oneMoreMistake: 'One more mistake will end the game,',
  onlyNumbersAllowed: 'Only numbers are allowed',
  oops: 'Oops',
  orClickHereToSendAgain: 'Or <0>click here</0> to send the code again.',
  orClickHereToSendAgainTeacher: 'spanish_only_do_not_touch',
  outOf: 'out of',
  overallProgress: 'Overall Progress',
  passRate: 'Pass Rate',
  password: 'Password',
  passwordMinimumLength: '8 characters minimum length',
  password_changed_successfully: 'Password changed successfully',
  passwordsDontMatch: "Passwords don't match",
  passwords_must_match: 'Passwords must be matched',
  pause_game: 'Pause Game',
  paussed: 'Paused',
  perGame: 'Per Game',
  perLevel: 'Per Level',
  perSkill: 'Per Skill',
  phone: 'Phone',
  phraseOfTheDay: 'Phrase of the day',
  phrasesFor: 'Phrases for',
  pictureOfTheDay: 'Picture Of The Day',
  play_sound: 'Play Sound',
  pleaseChooseYourOwnPassword: 'Please choose your own password',
  pleaseCompleteTheFollowingTest:
    'Please complete the following test. Take your time, read the instructions and do your best.',
  pleaseContact:
    'Please contact {{studentName}} directly and tell them that they must log out and log back in to see the changes.',
  pleaseContactYourSchoolManagerForGuidance:
    'Please contact your school manager for guidance.',
  pleaseEnter: 'Please enter',
  pleaseEnterTheFollowing:
    'Please enter the following information to begin your',
  pleaseEnterYourDetails: 'Please enter your details',
  pleaseSelectYourAccount:
    'Please select your account or log in from another one',
  pleaseTakeAMoment: 'Please take a moment to register your school.',
  pleaseTryAgainLaterWeApologize:
    'Please try again later. We apologize for any inconvenience.',
  pleaseWriteOnlyLetters: 'Please write only letters',
  powerFulWords: 'empower, enrich, and inspire us',
  practice: 'Practice',
  practiceAgain: 'Practice again',
  practiceProperPronunciation: 'Practice proper pronunciation.',
  practiceYourVocabulary: 'Practice your vocabulary',
  practice_makes_perfect: 'Practice makes perfect!',
  preferences: 'Preferences',
  premiumSection: {
    description:
      'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    title: 'Try lorem ipsum for free',
    unlock: 'Unlock Premium',
  },
  previous_button: 'Previous Button',
  processing: 'Processing',
  proficiency: 'Proficiency',
  profile: 'Profile',
  progress: 'Progress',
  pt: 'Portuguese',
  qs: 'Qs',
  question: 'Question',
  questions: 'Questions',
  questionsAndConcerns: 'questions and concerns.',
  questionsLeft: 'number of questions left',
  quiz: 'Game',
  quizzes: 'Quizzes',
  quizzesCompleted: 'Games completed',
  quizzesSection: {
    comingUpQuizzesTitle: 'Coming up games',
    lastQuizzesSubTitle: 'Improve from 1/2 stars to 3 stars',
    lastQuizzesTitle: 'Improve your last games',
  },
  ran_out_of_time:
    'It looks like you ran out of time again. This question will be marked as',
  readMore: 'Read more',
  readTheText: 'Read the text and answer the questions',
  read_tip: 'Read Tip',
  readyToChooseYourAvatar: 'Ready to choose your avatar?',
  receiveTipsAndUpdates:
    "I'd like to receive product tips and updates from Britannica from time to time",
  receiveTipsAndUpdatesMorfix:
    "I'd like to receive product tips and updates from Morfixschool 2.0 from time to time",
  recentLogin: 'Recent Login',
  rememberThis: 'Remember This?',
  remind_teacher_to_move_up: 'Remind your teacher to move you up.',
  requirements: 'Requirements',
  resendVerificationCode: 'Didn’t receive it? Send code again',
  resultForTheTranslation: 'result for the translation of',
  results: 'Results',
  resultsForTheTranslation: 'results for the translation of',
  resume: 'Resume',
  reward: 'Reward',
  saturday: 'saturday',
  saturdayShort: 'Sat',
  save: 'Save',
  saveChanges: 'Save Changes?',
  schoolName: 'School Name',
  score: 'Score',
  searchForAStudent: 'Search for a student',
  searchForStudent: 'Search for student',
  selectSkill: 'Select Skill',
  selfPracticeProgress: 'Self Practice Progress',
  selfPracticeStreak: 'Self Practice Streak',
  send: 'Send',
  sendNow: 'Send now',
  settings: {
    my_tutor: 'My Tutor',
    about_me: 'About Me',
    about_the_product: 'About The Product',
    add_students: {
      add_student: 'Add New Student',
      add_students: 'Add new students',
      add_students_manually: 'Add students manually',
      button_next: 'Next',
      click_to_browse: 'click to browse',
      create_students: 'Create new students',
      download: 'Download',
      download_sample: 'a sample Excel (xlsx) file',
      drag_here: 'Drag files here or ',
      email_will_be_send: 'An email will be sent',
      enroll_students: 'Enroll students',
      errors: {
        bad_formatting:
          'Your file is not formated correctly. <br/> Please check your data and make corrections as needed',
        email_in_use: 'This email address is already registered',
        email_incorrect: 'Please enter a valid email address.',
        file_empty: 'The file you are trying to upload is empty',
        file_too_large: 'The file is too large. Maximum size allowed is 256KB.',
        first_name_incorrect: 'First name contains invalid characters.',
        first_name_missing: 'First name is required.',
        last_name_incorrect: 'Last name contains invalid characters.',
        last_name_missing: 'Last name is required.',
        students_not_registered: 'Some students were not registered',
        wrong_format:
          'The file format is incorrect. <br/> Please ensure you are uploading an Excel (xlsx) file.',
      },
      invite_students: 'Invite Students',
      preview: 'Student Roster',
      success_description:
        "Your students have been sent an email with instructions on resetting their password. Once they've done that, they'll begin their English learning journey.",
      success_title: 'The class has been created successfully!',
      upload_description:
        'Once the file has been uploaded successfully, your students will be registered and receive a temporary password in their email and can log in to Britannica English.',
      upload_file:
        'Create your student roster by uploading an Excel (xlsx) file.',
      upload_instruction: 'Download and view the template for guidance.',
      upload_successful: 'Your file has been uploaded successfully',
    },
    change_password: 'Change Password',
    class: 'My Class',
    current_level: 'Current Level',
    grade: 'Grade',
    home: 'Home',
    language: 'Language',
    logout: 'Log Out',
    my_classes: 'My Classes',
    my_games: 'My Games',
    my_students: 'My Students',
    native_language: 'My Home Language',
    privacy_policy: 'Privacy Policy',
    profile: 'Profile',
    school_id: 'My School',
    security: 'Security',
    sound: 'Sound',
    title: 'Settings',
    username: 'My Name',
  },
  showMore: 'show more',
  signIn: 'Sign in',
  signYouIn: 'Signing you in ...',
  sign_out: 'Sign Out',
  sincerely: 'Sincerely, {{name}}',
  sixDigits: '6 digits',
  sixDigitsAtLeast: 'The code must be 6 digits at least',
  skills: {
    grammar: 'Grammar',
    listen: 'Listen',
    read: 'Read',
    speak: 'Speak',
    spelling: 'Spelling',
    vocabulary: 'Vocabulary',
  },
  skillsTestTryAgain:
    'You can try again now or go back to the pervious games in this level and practice some more.',
  skip: 'Skip',
  sorryThisLinkIsNoLongerValid: 'Sorry, this link is no longer valid',
  sortBy: 'Sort by:',
  speakNow: 'Speak now',
  speak_tip: 'Speak Tip',
  spelling_tip: 'Spelling Tip',
  stars: 'Stars',
  start: 'Start',
  startLevelTest: 'Start Level Test',
  startNextQuiz: 'Start Next Quiz',
  startQuiz: 'Start game',
  startTheNextLevel: 'Start the Next Level',
  startTheNextQuiz: 'Start the Next game',
  startingReward: 'Starting reward',
  state: 'State',
  studentActivity: 'Student Activity',
  studentInstructions: 'Student Instructions',
  studentLevels: 'Student Levels',
  studentName: 'Student Name',
  studentReport: 'Student Report',
  students: 'Students',
  studentsAreNotPermittedToAdvanceToTheNextLevel:
    'Students are not permitted to advance to the next level. If you decide to change the student’s level based on their performance, you may do so by entering the student profile and manually changing their level for each skill.',
  study_language: 'Language Of Study',
  subject: 'Subject',
  submit: 'Submit',
  suggested_languages: 'Suggested Languages',
  sunday: 'sunday',
  sundayShort: 'Sun',
  synonyms: 'Synonyms',
  tapToSpeak: 'Tap to speak',
  tapToTranslate: 'Tap for translate...',
  teacherEndLevelTest:
    'You and your students will start speaking and spelling at the beginner level. This will make the overall level appear slightly lower. You can manually adjust the level of each student in each skill by accessing their profile on your dashboard, whereas you can access all games in all levels.',
  teacherInstructions: 'Teacher Instructions',
  teacherLetsBeginn: "Let's Begin!",
  teacherName: 'Teacher Name',
  teachers: 'Teachers',
  teachersThatHaveRegistered: 'Teachers that have registered',
  termsAndConditions: 'Terms and Conditions',
  testYourEnglish: 'Test your English level',
  thankYou: 'Thank you!',
  thatsTheWay: 'That’s the way!',
  thats_the_way: 'That’s the way!',
  theGamesIn: 'the games in',
  theWordWasntPronouncedCorrectly: "The word wasn't pronounced correctly.",
  theWordsWerentPronouncedCorrectly: "The words weren't pronounced correctly.",
  thereAre: 'There are',
  thereIs: 'There is',
  thisStudentHasNot:
    'This student has not yet completed the level test. Once they have begun their journey, you will see their progress here.',
  thisTestIsDesignedToAssessYourStudentsInTheirGeneralEnglishLevel:
    'When students have completed all the skill activities at their level, they can receive an end of level test which will assess their proficiency on all they have learned. Schedule this test using the date indicator below.',
  thisTestWillHelpYouSeeHowMuchEnglishYouHaveLearnedSoFar:
    'This test will help you see how much English you’ve learned so far. It includes grammar, vocabulary, reading, listening and speaking. After the test, you can continue to play games and keep learning.',
  thisWillHelpYouUnderstandTheMaterialBetter:
    'This will help you understand the material better',
  thursday: 'thursday',
  thursdayShort: 'Thurs',
  thwWordYouEntered:
    'The word you’ve entered isn’t in the dictionary. Try to search a different word',
  timeIsUp: 'Time is up!',
  tip: 'Tip!',
  tipsAndTricks: 'Tips and Tricks',
  to: 'To',
  toImproveYourVocabulary: 'To improve your vocabulary,',
  toJoin: 'to join',
  toNextQuestion: 'to the next question',
  toViewItsOverallLearningProgress: 'to view its overall learning progress',
  toViewTheirIndividualProgress: 'to view their individual progress',
  toViewTheirOverallProgress: 'to view their overall progress',
  todayQuestion: "Today's question",
  topics: 'Topics',
  totalCoins: 'Total Coins',
  totalHintsUsed: 'Total Hints Used',
  totalQuizzes: 'Total games',
  totalWords: 'Total words',
  trasnlateAWordOrPhrase:
    'Translate a word or phrase in English or in {{nativeLanguage}}...',
  tries: 'tries',
  tryAgain: 'Try again',
  tryAgainBtn: 'Try again one more time',
  tryAgainLater: 'Try again later!',
  tryAgainOneMore: 'try again 1 more time or move on',
  tryAgainOrKeepGoing: 'Try again or keep going',
  tryBetter: 'Try better next time',
  tuesday: 'tuesday',
  tuesdayShort: 'Tues',
  understandingYourEnglishLevel:
    'We are starting to understand your English level.',
  unfortunately:
    "Unfortunately, you didn't pass, so you won't receive any coins for this game.",
  university: 'University',
  update: 'Update',
  uponRegistrationStudentsWillReceiveAnInitialLevelTest:
    'When students log in for the first time, they will receive a level set test. Once completed, their individual learning path will be set based on their proficiency level on each skill. If disabled, the student will begin at level 1.',
  useTheCoins: "Use the coins you've earned to get hints",
  userStreak: {
    subTitle: 'Practice every day to become a MASTER',
    title: 'Keep the streak!',
  },
  username: 'Username',
  usingYourMicrophone:
    'Using your microphone, speak slowly and clearly and make sure there is no background noise.',
  validation: {
    code_required: 'Code is required',
    confirm_password_required: 'Confirm Password is required',
    email_required: 'Email is required',
    password_required: 'Password is required',
    username_required: 'Username is required',
  },
  validationCodeInvalid: 'Validation code invalid',
  verificationCode: 'Verification Code',
  verificationCodeSent: 'A verification code has been sent to',
  viewAll: 'View all',
  viewAnswerChoices: 'View answer choices',
  viewDetails: 'View Details',
  viewProfile: 'View Profile',
  vocabularyMistakes: 'Vocabulary Mistakes',
  vocabularyPractice: 'Vocabulary practice',
  vocabulary_tip: 'Vocabulary Tip',
  weAreSorry: 'We are sorry',
  weCouldNotProduce: 'We could not produce the report at the moment.',
  weRecommendThatYouGoBackToYourPreviousGames:
    'We recommend that you go back to your previous games and do them again.',
  weStillDontHaveAll:
    "We still don't have all the necessary information, or there is a temporary glitch.",
  weWantYourFeedback: 'Hi {{firstname}}, We want your feedback!',
  we_want_to_know: 'We want to know your level',
  wednesday: 'wednesday',
  wednesdayShort: 'Wed',
  welcome: 'Welcome',
  welcomeBack: 'Welcome back',
  welcomeConfetti: 'Welcome',
  welcomeTo: 'Welcome to',
  wellDone: 'Well Done',
  whatDidYouLearnAtThisLevel: 'What did you learn at this level?',
  whenStudentsWillHaveCompletedAParticularSkill:
    'When students complete all the skill activities at their designated level, they will be tested on what they have learned. A grade of 80% or higher will be regarded as proficient. If a student does not pass the test, they will be prompted to retake the test.',
  whenYourStudentsReceiveAPassingGrade:
    'When students receive a passing grade on the English Skills Assessment, they will be allowed to advance to the next level for that skill. Disabling this feature will prevent students from advancing to the next level.',
  with_a_score_of: 'with a score of',
  wordOfTheDay: 'Word of the day',
  words: 'Words',
  wordsDictionary: 'Words',
  wordsList: 'Words list',
  workHard: 'Work hard to get all the stars.',
  work_hard_to_get_all_stars: 'Work hard to get all the stars',
  wow: 'WOW',
  wow_you_got_a_score_of: 'WOW, you got a score of {{score}}',
  writeClassNameHere: 'Write class name here',
  writeHere: 'Write Here',
  wrongAnswer: 'Wrong answer!',
  youAlmostThere: "You're almost there...",
  youAreOnYourWay: "You're on your way!",
  youCanCreateMore: 'You can create more than one class',
  youCanPractice:
    'you can practice words by game or by using flash cards and a list.',
  youCanTryAssessment:
    'You can now try the level {{level}} assessment that your students will receive before they can advance to the next level.',
  youCompletedTheTest: 'You completed the test',
  youDidNotPass: 'You didn’t pass',
  youGot: 'You got',
  youMayNowShare: 'You may now share this link with your ',
  youPassedAll: "You've passed all",
  youPassedAllTheGamesIn: "You've passed all the games in",
  youPassedTheQuiz: 'You passed the game with a score of',
  youPracticed: "You've practiced. You've studied",
  youStill: "You still didn't pass this game, so no extra coins, nor stars.",
  youWillLose: 'You will lose',
  you_can_now_practice_level: 'You can now practice level {{level}}',
  you_did_it: 'You Did It!',
  you_got_a_score_of: 'You got a score of {{score}}',
  you_passed_assessment: 'You passed the assessment',
  you_passed_quiz: 'You passed the game',
  your: 'Your',
  yourChangesWillAffectTheGeneralLevel:
    'Your changes will affect the general level',
  yourClasses: 'Your classes',
  yourMessageHasBeenSentSuccessfully:
    'Your message has been sent successfully. Thank you for taking the time to provide us with valuable feedback!',
  yourMessageHere: 'Your message here',
  yourPasswordHere: 'Your password here',
  yourPhoneHere: 'Your Phone here',
  yourSchoolHasBeenCreated: 'Your school has been created successfully',
  yourStartingLevel: 'Your Starting Level',
  yourStreak: 'Your Streak',
  yourStudentQuiz:
    'This is the quiz that your students will take when they enter the app for the first time. Go ahead and try it now, or you can try it later.',
  yourStudentsHaveNotJoined: 'Your students haven’t joined the class yet',
  yourStudentsHaveNotJoinedYet: 'Your students haven’t joined the class yet',
  yourStudentsInThisClassWillNotTakeTheInitialLevelTest:
    'Your students in this class will not take the initial level test upon registration. They will all start at Level 1 for all English skills.',
  yourStudentsWillNotReceiveAFinalExam:
    'Your students will not receive a final exam.',
  yourSubjectHere: 'Your subject here',

  tutorKeepGoing:
    "You're Great! Take brief study breaks for improved focus and memory.",
  tutorPractice: 'Practice to perfection',
  tutorContinueQuiz: 'Continue quiz',
  tutorLetsPractice: "Let's practice before continuing?",
  tutorClickToPractice: 'Click to practice!',
  tutorContinue: 'Continue without practice',
  tutorPracticeWithMeTitle: 'Practice with me to enhance your grammar skills.',
  tutorPracticeWithMe: 'Practice with me',
  tutorGetSessionTitle:
    'Get a personalized session so later will easier and more fun',
  tutorGetSession: 'Get a personalized session',
  tutorChalangeTitle: 'Take brief study breaks for improved focus and memory.',
  tutorChallenge: 'I want a challenge',
  tutorPrivate: 'Private Tutor',
  tutorTurnOff: 'Turn off',
  tutorUser: 'User',
  tutorSkill: 'Skill',
  tutorStartedAt: 'Chat started at {{timeStart}}',
  tutorLetsPracticeOption: 'Let’s Practice',
  tutorEndChatOption: 'End chat and go back',
  tutorExerciseOption: 'Let’s exercise',
  tutorExampleOption: 'I need more example',
  tutorChooseAnswer: 'Choose the correct answer to fill in sentence:',
  tutorCorrectTitle: 'Correct!',
  tutorWasClear: 'Was that clear?',
  tutorNoMoreHelp: 'No, I need more help',
  tutorYesGoBack: 'Yes, go back to Quiz',
  tutorCorrectBody: 'You’re getting the hang of it!',
  tutorInCorrectTitle: 'Oops. Incorrect.',
  tutorInCorrectBody: 'Your answer, “{{answer}}” is wrong.',
  tutorFeedback: 'Would you like to give some feedback?',
  tutorLetMeExplain: 'Let me explain…',
  tutorCorrectSentence: 'The correct answer is…',
  tutorWasHelpful: 'Was this helpful to you?',
  tutorMoreHelp: 'More Help',
  tutorHowHelp: 'How can i help you?',
  tutorNeedHint: 'I need a hint',
  tutorMoreExplanation: 'More explanation',
  tutorGiveExample: 'Give me more example',
  tutorCorrectAnswer: 'Show me the correct answer',
  tutorMoreTips: 'More Tips',
  tutorExplainMore: 'Explore with Tutor',
  tutorGotIt: 'Got It',
  tutorNotification: 'Quiz notification',
  tutorNotificationBody:
    'Turn off the option to receive explanations after each incorrect answer until the end of the quiz?',
  tutorNotificationOff: 'Yes, turn off',
  tutorNotificationMuted:
    'The quiz notification has been muted until the end of the quiz',
  tutorYesAndContinue: 'Yes, and continue',
  tutorNoAndContinue: 'No, and continue',
  tutorNotificationBack: 'Go back',
  tutorPracticeIsRecommended: 'Practice is recommended',
  tutorMore: 'Practice is recommended',
  tutorPrivateTutorTip: 'Private Tutor Tip!',
  tutorTryBetterTitle: 'Try better next time',
  tutorWelcomeTitle: 'Welcome to Your Private Tutor',
  tutorBannerTitle:
    'Take your learning to the next level! Based on your game play, we’ve come up with some suggestions for you to improve your knowledge of English and challenge you to succeed.',
  tutor_quizzes_taken: 'Turn Weaknesses into Strengths',
  tutor_quizzes_not_taken: 'Join Me for More Practice!',
  tutorLetsStart: "Let's start!",
  tutorNewtag: 'New',
  tutorBack: '< Back',
  tutorExitTitle: 'Practice In progress',
  tutorExitText:
    "Please note that closing the Tutor will delete your current chat. Don't worry. You can always start a new chat.",
  tutorExitYes: 'End Practice',
  tutorExitNo: 'Keep going',
  tutorShifting:
    "I'm getting the {{quizName}} game ready. Almost there...just a few more seconds.",
  tutorCorrectAnswerIs: 'The correct answer is "{{answer}}".',
  tutorCorrectAnswerIsShort:
    'The correct answer is <strong>{{answer}}</strong>',
  tutorEmptyDashboard:
    "Oops! It's a bit empty here... Our team is working on bringing you more content!",
};
