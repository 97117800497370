import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { authSelector } from '@/store/reducers/auth';
import { useEffect, useRef } from 'react';
import commonUtils from '@/utils/common';
import UseSkill from '../skill/UseSkill';
import UseDictionary from '@/hooks/UseDictionary';
import ProgressSection from '@/components/ProgressSection/ProgressSection';
import SkillsSection from '@/components/SkillsSection/SkillsSection';
import RoadmapSection from '@/components/RoadmapSection/RoadmapSection';
import PremiumSection from '@/components/PremiumSection/PremiumSection';
import MyProfileDesktop from '@/components/MyProfile/MyProfileDesktop';
import DictionaryDesktop from '@/components/Dictionary/DictionaryDesktop/DictionaryDesktop';
import DictionaryDesktopComponents from '@/components/Dictionary/DictionaryDesktopComponents/DictionaryDesktopComponents';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import MyStudentsViewDesktop from '../my-students/MyStudentsViewDesktop';
import AppBanner from '@/components/AppBanner';
import { useSearchParams } from 'react-router-dom';
import UserUtils from '@/utils/user';
import GoToLevelTestDesktop from '@/components/GoToLevelTestDesktop/GoToLevelTestDesktop';
import FinalAssessmentStartPopUp from '@/components/FinalAssessmentStartPopUp/FinalAssessmentStartPopUp';

const MainViewDesktop = () => {
  const dispatch = useAppDispatch();

  const {
    searchValue,
    selectedWord,
    recentTranslations,
    autoCompleteResults,
    suggestions,
    cards,
    phrases,
    pictureOfTheDay,
    options,
    question,
    quizzes,
    isSearching,
    onInputChange,
    onClear,
    onSelectOption,
    onSearch,
    onValueSearch,
    onBack,
  } = UseDictionary();

  const { topics, groupedTopics, isLoading: skillsLoading } = UseSkill(true);
  const { selectedType } = useAppSelector(gameSelector);
  const { skillSettings, displayDictionary } = useAppSelector(authSelector);
  const [searchParams] = useSearchParams();
  const { user } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);
  const mapRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (!selectedType && skillSettings.length) {
      const firstSkill = skillSettings[0];
      const type = commonUtils.skillByName(firstSkill.name ?? '');

      dispatch(
        gameActions.setSelectedSkill({
          type: type,
          skill: firstSkill,
        })
      );
    }

    if (searchParams.get('skill')) {
      setTimeout(() => {
        executeScroll();
      }, 150);
    }
  }, []);

  const dictionaryComponents = (
    <DictionaryDesktopComponents
      selectedWord={selectedWord}
      cards={cards}
      phrases={phrases}
      question={question}
      pictureOfTheDay={pictureOfTheDay}
      options={options}
      quizzes={quizzes}
      emitBack={onBack}
      onValueSearch={onValueSearch}
    />
  );

  const executeScroll = () => {
    if (mapRef.current !== null) {
      mapRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const handleScrollToMap = () => {
    setTimeout(() => {
      const element =
        mapRef.current?.lastChild?.firstChild?.firstChild?.firstChild;
      const toFocus = element as HTMLDivElement;
      if (toFocus !== null) {
        toFocus.focus();
      }
    }, 400);
    executeScroll();
  };

  const mainViewComponents = (
    <>
      <SkillsSection handleScrollToMap={handleScrollToMap} />
      {!isTeacher && <ProgressSection />}
      <RoadmapSection
        groupedTopics={groupedTopics}
        topics={topics}
        mapRef={mapRef}
        loading={skillsLoading}
      />
      {isTeacher && <GoToLevelTestDesktop />}
      {/* {isTeacher && <PremiumSection />} */}
    </>
  );

  let content = (
    <>
      <FinalAssessmentStartPopUp />
      {displayDictionary ? (
        <DictionaryDesktop
          searchValue={searchValue}
          recentTranslations={recentTranslations}
          results={autoCompleteResults}
          suggestions={suggestions}
          onInputChange={onInputChange}
          onClear={onClear}
          onSelectOption={onSelectOption}
          onSearch={onSearch}
        />
      ) : (
        <AppBanner />
      )}
      {isSearching ? (
        <AppLoaderCircle className="mx-auto" />
      ) : selectedWord ? (
        dictionaryComponents
      ) : (
        mainViewComponents
      )}
    </>
  );

  return content;
};

export default MainViewDesktop;
