import React, { useState } from 'react';
import classNames from './QuizPageControls.module.scss';
import classes from 'classnames';
import commonUtils from '@/utils/common';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { GameStatus, GameTypes } from '@/types/game';
import { useDispatch } from 'react-redux';
import UseScreenSize from '@/hooks/UseScreenSize';
import { SkillTypes } from '@/types/skill';
import { authActions, interfaceLanguageSelector } from '@/store/reducers/auth';
import { useNavigate } from 'react-router-dom';

import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';

import play from '@/assets/svg/play.svg';
import pause from '@/assets/svg/pause.svg';
import close from '@/assets/svg/close.svg';
import list from '@/assets/svg/list.svg';
import leftArrow from '@/assets/svg/left-arrow.svg';
import rightArrow from '@/assets/svg/right-arrow.svg';
import { Transition } from 'react-transition-group';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import userThunks from '@/store/thunks/user';
import { appSettingsActions } from '@/store/reducers/settings';
import i18n from '@/locales/i18n';
import { useTranslation } from 'react-i18next';

interface QuizPageControlsProps {
  className?: string;
  currentQuestionIndex?: number;
  totalQuestions?: number;
  actual: number;
  total: number;
  isMuted?: boolean;
  isPaused?: boolean;
  loading?: boolean;
  disableNext?: boolean;
  disablePrevious?: boolean;
  progress?: number;
  allowDynamicDirection?: boolean;
  onMute?: () => void;
  onPause?: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
}

const QuizPageControls = ({
  className,
  actual,
  total,
  isPaused,
  loading,
  disableNext,
  disablePrevious,
  progress,
  allowDynamicDirection = true,
  onMute,
  onPause,
  onPrevious,
  onNext,
}: QuizPageControlsProps) => {
  const { isMobile } = UseScreenSize();
  const { selectedType, gameType } = useAppSelector(gameSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { windowWidth, largeWidth } = UseScreenSize();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const quizProgress =
    progress ?? commonUtils.calcPercentage(loading ? 0 : actual, total);

  const isPractice = gameType === GameTypes.Practice;
  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);
  const isRead = selectedType === SkillTypes.Read;

  const onGoBack = () => {
    return navigate('/dashboard/games');
  };

  const onShowWordsList = () => {
    dispatch(gameActions.toggleGameStatus(GameStatus.SHOW_WORDS_LIST));
  };

  let leftControl = null;

  const logOut = () => {
    localStorage.removeItem('userEmail');
    dispatch(userThunks.signOut());
    dispatch(authActions.signOut());
    dispatch(appSettingsActions.clearAppSettings());
    i18n.changeLanguage('en');
  };

  const popUpComponent = (
    <Transition
      in={showLogoutModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
              emitClose={() => {
                setShowLogoutModal(false);
              }}
            />
            <AppModal>
              <div className={classes(classNames.logoutModal, `fade-${state}`)}>
                <div className={classNames.logoutModalTitle}>
                  {t('leave_level_test_and_sign_out')}
                </div>
                <div className={classNames.logoutBtnWrapper}>
                  <button
                    onClick={() => {
                      logOut();
                    }}
                  >
                    {t('sign_out')}
                  </button>
                  <button
                    onClick={() => {
                      setShowLogoutModal(false);
                    }}
                  >
                    {t('continue_level_test')}
                  </button>
                </div>
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const previousBtn = (
    <button
      disabled={disablePrevious}
      className={classes(classNames.button, classNames.navBtn, {
        [classNames[`navBtn-${interfaceLanguage?.direction}`]]:
          allowDynamicDirection,
      })}
      onClick={() => (disablePrevious ? null : onPrevious?.())}
    >
      <SVG src={leftArrow} />
    </button>
  );

  const nextBtn = (
    <button
      disabled={disableNext}
      className={classes(classNames.button, classNames.navBtn, {
        [classNames[`navBtn-${interfaceLanguage?.direction}`]]:
          allowDynamicDirection,
      })}
      onClick={() => (disableNext ? null : onNext?.())}
    >
      <SVG src={rightArrow} />
    </button>
  );

  if (isLevelTest) {
    if (isMobile) {
      leftControl = (
        <button
          className={classes(classNames.button, classNames.quiz_logout)}
          onClick={() => {
            return navigate('/dashboard/level-test', { state: true });
          }}
        >
          <SVG src={close} />
        </button>
      );
    }
  } else {
    if (isRead && isMobile) {
      leftControl = previousBtn;
    }

    if (!isFinalAssessment) {
      leftControl = (
        <button
          aria-label={t('pause_game').toString()}
          className={classNames.button}
          onClick={onPause}
        >
          <SVG src={isPaused ? play : pause} />
        </button>
      );
    }
  }

  if (isPractice) {
    leftControl = (
      <GoBackBtn
        className={classNames.goBackBtn}
        showText={false}
        emitClick={onGoBack}
      />
    );
  }

  let rightControl = null;

  if (isPractice && windowWidth < largeWidth) {
    rightControl = (
      <button className={classNames.button} onClick={onShowWordsList}>
        <SVG src={list} />
      </button>
    );
  } else if (isRead) {
    rightControl = isMobile ? (
      nextBtn
    ) : (
      <div className={classNames.navBtns}>
        {previousBtn}
        {nextBtn}
      </div>
    );
  }

  return (
    <div
      className={classes(classNames.quizPageControls, className, {
        [classNames.quizPageControlsLevelTest]: isLevelTest,
        [classNames.quizPageControlsRead]: isRead,
      })}
    >
      {popUpComponent}
      {!isLevelTest && leftControl}
      <div className={classNames.progress}>
        <div className={classNames.progressBar}>
          <div
            className={classNames.progressBarInner}
            style={{ width: `${quizProgress}%` }}
          ></div>
        </div>
        <span>
          {loading ? 0 : actual}/{total >= 0 ? total : 0}
        </span>
      </div>
      {!isLevelTest && rightControl}
    </div>
  );
};

export default React.memo(QuizPageControls);
