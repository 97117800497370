import { SkillTypes } from '@/types/skill';
import commonUtils from '@/utils/common';
import React from 'react';
import SVG from 'react-inlinesvg';

interface SkillIconProps {
  className?: string;
  skill: SkillTypes;
}

const SkillIcon = ({ className, skill }: SkillIconProps) => {
  return <SVG className={className} src={commonUtils.skillIcon(skill)} />;
};

export default React.memo(SkillIcon);
