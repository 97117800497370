import { useTranslation } from 'react-i18next';
import classNames from './StudentSkillProgress.module.scss';
import {
  GenerateReportProps,
  Skills,
  StudentLevelsValueObject,
} from '../../../../../types/generate-report';
import classes from 'classnames';

const StudentSkillProgress = ({ reportContent }: GenerateReportProps) => {
  const { t } = useTranslation();

  const skillsYAxis = Array.from(
    { length: 5 },
    (_, i) => `${t('level')} ${i + 1}`
  );

  const barWidth = 43 + (skillsYAxis.length - 1) * 100;
  const levelWidth = 100;

  return (
    <div className={classNames.chartContainer}>
      <div>
        <h1>{t('englishSkillsProgress')}</h1>
        <h5>{t('perLevel')}</h5>
      </div>
      <div className={classNames.chart}>
        <div className={classNames.xAxisValues}>
          {reportContent &&
            reportContent.progressPerSkill &&
            Object.entries(reportContent.progressPerSkill).map(([skill]) => (
              <div>
                <h4>{t(`skills.${skill}`)}</h4>
              </div>
            ))}
        </div>
        <div className={classNames.skillsProgressChartContainer}>
          <div className={classNames.skillsProgressBarsContainer}>
            {reportContent &&
              reportContent.progressPerSkill &&
              Object.entries(
                reportContent.progressPerSkill as Record<
                  keyof Skills,
                  StudentLevelsValueObject
                >
              ).map(([skill, { startingLevel, currentLevel }]) => (
                <div
                  style={{ width: `${barWidth}px` }}
                  className={classNames.skillsProgressBarLayer1}
                >
                  <div
                    style={{
                      width: `${barWidth - levelWidth * (startingLevel - 1)}px`,
                    }}
                    className={classNames.skillsProgressBarLayer2}
                  >
                    <div
                      style={{
                        width:
                          currentLevel === 5
                            ? '100%'
                            : startingLevel === currentLevel
                            ? '10px'
                            : `${(currentLevel - startingLevel) * 115}px`,
                      }}
                      className={classes(classNames.skillsProgressBarLayer3, {
                        [classNames[
                          `bar-${skill[0].toUpperCase() + skill.slice(1)}`
                        ]]: true,
                      })}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className={classNames.skillProgressLevelsContainer}>
            {skillsYAxis.map((level) => (
              <h2>{level}</h2>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentSkillProgress;
