import classes from 'classnames';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useTranslation } from 'react-i18next';
('../assets/styles/components/_app-banner.scss');
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UserAvatar from './UserAvatar/UserAvatar';
import Detail from './UserInfo/Detail/Detail';
import DetailSeperator from './DetailSeperator/DetailSeperator';
import AppMorfixLogo from './AppMorfixLogo/AppMorfixLogo';
import UserUtils from '@/utils/user';
import { gameSelector } from '@/store/reducers/game';

import logo from '@/assets/images/logo_light.png';
import commonUtils from '@/utils/common';

interface AppBannerProps {
  className?: string;
  isFixed?: boolean;
  showProfileInfo?: boolean;
}

const AppBanner = ({
  className,
  isFixed = true,
  showProfileInfo = false,
}: AppBannerProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = UseScreenSize();
  const { user, nativeLanguage } = useAppSelector(authSelector);
  const { selectedType } = useAppSelector(gameSelector);
  const isNativeLanguageHE = nativeLanguage === 'he';
  const showMorfixLogo = isDesktop && isNativeLanguageHE;
  const isTeacher = UserUtils.isTeacher(user);
  const isStudent = UserUtils.isStudent(user);

  const userGrade = user?.grade ?? '';

  const isUniversityGrade = userGrade.toLowerCase() === 'university';
  const isNoneGrade = userGrade.toLowerCase() === 'none';

  let gradeValue = null;

  if (!isNoneGrade) {
    if (isUniversityGrade) {
      gradeValue = t('university');
    } else {
      gradeValue =
        isNaN(+userGrade) || Number.isInteger(+userGrade)
          ? t(`grade_${commonUtils.fixGradeSymbol(userGrade)}`)
          : userGrade;
    }
  }

  let levelText = user?.level ?? '';

  if (isTeacher) {
    levelText = user?.currentLevelByskill?.[selectedType ?? ''] ?? '';
  }

  return (
    <div
      id="app-banner"
      role="img"
      aria-label={t('app_banner').toString()}
      className={classes('app-banner', className, {
        'app-banner-fixed': isMobile && isFixed,
      })}
    >
      {!isNativeLanguageHE && (
        <span className={classes('app-banner-bg-img')}></span>
      )}
      <div
        className={classes('app-banner-content', {
          'app-banner-content-relative': showProfileInfo,
        })}
      >
        {!isNativeLanguageHE && <img className="app-banner-logo" src={logo} />}
        {showMorfixLogo ? (
          <AppMorfixLogo />
        ) : (
          isDesktop && !isNativeLanguageHE && <h2>{t('appTitle')}</h2>
        )}
      </div>

      {showProfileInfo && (
        <div className="app-banner-profile">
          <UserAvatar
            className="app-banner-avatar"
            img={user?.avatar?.avatarName}
            userFirstName={user?.firstName ?? ''}
            backgroundColor={user?.avatar?.avatarColor}
            // notificationsAmount={8}
          />
          <div className="app-banner-info">
            <h2 className="app-banner-fullname">{user?.firstName}</h2>
            <div className="app-banner-details">
              <Detail topic={t('level')} value={levelText} />

              {isStudent && gradeValue && (
                <>
                  <DetailSeperator />
                  <Detail topic={t('grade')} value={gradeValue} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppBanner;
