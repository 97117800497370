import { ContactUsValues } from '@/types/contact-us';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import axios from 'axios';
import config from '@/config';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useDrawer } from '@/context/DrawerContext';
import { userService } from '@/services';

const UseContactUs = () => {
  const { t } = useTranslation();
  const { user, languages } = useAppSelector(authSelector);
  const drawer = useDrawer();

  const [isLoading, setIsLoading] = useState(false);
  const [showMessageSent, setShowMessageSent] = useState(false);

  const InitialContactUsValues = {
    subject: '',
    email: '',
    phone: '',
    message: '',
    shouldReceiveUpdates: true,
  };

  const ContactUsSchema = Yup.object().shape({
    subject: Yup.string().required(t('fieldIsMandatory').toString()),
    email: Yup.string().email().required(t('fieldIsMandatory').toString()),
    phone: Yup.string().matches(/^[0-9]+$/, t('onlyNumbersAllowed').toString()),
    message: Yup.string().required(t('fieldIsMandatory').toString()),
    shouldReceiveUpdates: Yup.boolean(),
  });

  const OnContactUsSubmitted = async (values: ContactUsValues) => {
    setIsLoading(true);

    const { subject, email, phone, message, shouldReceiveUpdates } = values;
    const fullname = `${user?.firstName} ${user?.lastName}`;

    const nativeLanguage =
      languages[0].localLanguages.find(
        (lang) => lang.code === user?.nativeLanguage
      )?.interfaceLanguage ?? '';

    try {
      const info = {
        userId: user?.id,
        subject,
        email,
        phone,
        message,
        marketingAgree: shouldReceiveUpdates,
      };

      const response = (await userService.sendContactInfo(user, info))?.data
        .data;

      if (response) {
        document.body.style.overflowY = 'hidden';
        window.scrollTo({
          top: 0,
        });
        setShowMessageSent(true);
        drawer.closeContactUsDrawer();
      }

      if (response) {
        document.body.style.overflowY = 'hidden';
        window.scrollTo({
          top: 0,
        });
        setShowMessageSent(true);
        drawer.closeContactUsDrawer();
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const onCloseMessageSent = () => {
    setShowMessageSent(false);
    document.body.style.overflowY = '';
  };

  return {
    isLoading,
    InitialContactUsValues,
    ContactUsSchema,
    showMessageSent,
    onCloseMessageSent,
    OnContactUsSubmitted,
  };
};

export default UseContactUs;
