import LevelTestLogOut from '@/components/LevelTest/LevelTestLogOut/LevelTestLogOut';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LevelTestLogoutComponent = ({ className }: { className: string }) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const isTeacher = user?.role === 'teacher';

  return (
    <>
      <LevelTestLogOut
        showLogoutModal={showLogoutModal}
        setShowLogoutModal={setShowLogoutModal}
      />
      <div className={className}>
        <button
          onClick={() => {
            setShowLogoutModal(true);
          }}
        >
          {isTeacher ? t('exit') : t('sign_out')}
        </button>
      </div>
    </>
  );
};

export default LevelTestLogoutComponent;
