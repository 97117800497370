import classNames from './ProgressSection.module.scss';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

import Skeleton from 'react-loading-skeleton';
import ProfileProgress from '@/components/ProfileProgress/ProfileProgress';
import ProfileSkillsProgress from '../ProfileSkillsProgress/ProfileSkillsProgress';

const ProgressSection = () => {
  const { user, userProgress } = useAppSelector(authSelector);
  const progressEntries = userProgress ? Object.entries(userProgress) : [];

  const allActual = progressEntries.reduce(
    (acc, entry) => acc + (entry[1]?.actual ?? 0),
    0
  );

  const allTotal = progressEntries.reduce(
    (acc, entry) => acc + (entry[1]?.total ?? 0),
    0
  );
  return (
    <div className={classNames.progressSection}>
      {/* {!userProgress ? (
          <Skeleton containerClassName={classNames.profileProgressSkeleton} />
        ) : (
          
          <ProfileProgress
            className={classNames.profileProgress}
            level={user?.level}
            allActual={allActual}
            allTotal={allTotal}
          />
        )} */}
      {!userProgress ? (
        <Skeleton
          containerClassName={classNames.profileSkillsProgressSkeleton}
        />
      ) : (
        <ProfileSkillsProgress
          className={classNames.profileSkillsProgress}
          progress={userProgress}
        />
      )}
    </div>
  );
};

export default ProgressSection;
